import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { HashRouter } from 'react-router-dom'
import { Router } from 'react-router-dom';
import { Provider } from "react-redux";
import { ToastProvider } from 'react-toast-notifications'
import { createBrowserHistory } from 'history';
import store from "./store/configureStore";
import { authRoutes } from "./routes";
import { Layout } from "./common/layout";
import { config } from "./config/config";

import decode from "jwt-decode";
import '../node_modules/leaflet-geosearch/dist/geosearch.css';

export const history = createBrowserHistory();

const checkAuth = () => {
  const token = localStorage.getItem(config.tokenKey);
  if (!token) { // || !refreshToken
    return false;
  }
  try {
    const { exp } = decode(token);
    if (exp < new Date().getUTCSeconds()) {
      return false;
    }
    return true;
  } catch (err) {
    return false;
  }
};

const AuthRoute = ({ component: Component, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      checkAuth()
        ? <Component {...props} />
        : <Redirect to={{ pathname: "/login" }} />}
  />;

const App = () => {
  return ( 
    <Provider store={store}>
      <ToastProvider>
        <HashRouter>
          
          <Switch> 
            {authRoutes.map((route, idx) => {
              return route.component ? (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => <route.component {...props} />}
                />
              ) : null;
            })}
            <AuthRoute path="*" name="Home" component={Layout} />           
          </Switch>
        </HashRouter>
      </ToastProvider>
    </Provider>
  );
}

export default App;
