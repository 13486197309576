import {

    FETCH_TELEMETRY_TEMPERATURE_PENDING,
    FETCH_TELEMETRY_TEMPERATURE_FULFILLED,
    FETCH_TELEMETRY_TEMPERATURE_REJECTED,

    CHANGE_TELEMETRY_TEMPERATURE

} from './telemetryTemperatureActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfTelemetryTemperature: [],
    telemetryTemperature: {
        veiculo: 0,
        motorista: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function telemetryTemperatureReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_TELEMETRY_TEMPERATURE:
            return Object.assign({}, state, {
                telemetryTemperature: {
                    ...state.telemetryTemperature,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_TELEMETRY_TEMPERATURE_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfTelemetryTemperature: initialState.listOfTelemetryTemperature,
            })
        case FETCH_TELEMETRY_TEMPERATURE_FULFILLED:
            console.log(action.payload)
            return Object.assign({}, state, {
                loading: false,
                listOfTelemetryTemperature: action.payload
            })
        case FETCH_TELEMETRY_TEMPERATURE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfTelemetryTemperature: initialState.listOfTelemetryTemperature,
                error: action.payload
            })

        default:
            return state
    }
}