import {

    FETCH_TYPES_POINT_PENDING,
    FETCH_TYPES_POINT_FULFILLED,
    FETCH_TYPES_POINT_REJECTED,

    FETCH_TYPE_POINT_PENDING,
    FETCH_TYPE_POINT_FULFILLED,
    FETCH_TYPE_POINT_REJECTED,

    CREATE_TYPE_POINT_PENDING,
    CREATE_TYPE_POINT_FULFILLED,
    CREATE_TYPE_POINT_REJECTED,

    UPDATE_TYPE_POINT_PENDING,
    UPDATE_TYPE_POINT_FULFILLED,
    UPDATE_TYPE_POINT_REJECTED,

    DELETE_TYPE_POINT_PENDING,
    DELETE_TYPE_POINT_FULFILLED,
    DELETE_TYPE_POINT_REJECTED,
    
    CHANGE_TYPE_POINT,
    
    ADD_TYPE_POINT

} from './typePointActions'

import { titleCase } from '../../../../../common/utils'

const initialState = {
    loading: false,
    listOfTypesPoint: [],
    typePoint: {
        codigo: 0,
        matriz: 0,
        descricao: '',
        clienteMatriz: {
            codigo: 0,
            razaoSocial: '',
        }
    },
    error: null,
}

export function typePointReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_TYPE_POINT: 
            let value = ""
                
            if (action.payload.name.includes('mail')) {
                value = action.payload.value.toLowerCase();
            } else 
                if (action.payload.name.includes('apelido')) {
                    value = action.payload.value;
                } else {
                    value = titleCase(action.payload.value);
                }

            return Object.assign({}, state, {
                typePoint: { ...state.typePoint, 
                    [action.payload.name]: value
                }
            })
        case ADD_TYPE_POINT: 
            return Object.assign({}, state, {
                typePoint: { ...initialState.typePoint }
            })
        case FETCH_TYPES_POINT_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfTypesPoint: [],
            })
        case FETCH_TYPES_POINT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfTypesPoint: action.payload
            })
        case FETCH_TYPES_POINT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_TYPE_POINT_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //typePoint: { ...initialState.typePoint },
            })
        case CREATE_TYPE_POINT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                typePoint: action.payload
            })
        case CREATE_TYPE_POINT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_TYPE_POINT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_TYPE_POINT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                typePoint: action.payload
            })
        case UPDATE_TYPE_POINT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_TYPE_POINT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_TYPE_POINT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                typePoint: action.payload
            })
        case FETCH_TYPE_POINT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_TYPE_POINT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_TYPE_POINT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_TYPE_POINT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}