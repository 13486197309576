import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Shortcut, Navigation } from "../../navigation";
import { HoverText } from '../../utils';
import { io } from "socket.io-client";

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { findStatsVehicles } from './stats/store';

import Ribbon from "./Ribbon";
import LayoutSwitcher from "./LayoutSwitcher";

import Header from "./header";
import Footer from "./Footer"; 

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import ActEvent from './ActEvent'

import { routes } from "../../../routes";
import { config } from '../../../config/config';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let isShowEvent = false;

const Layout = (props) => { 
  const [events, setEvents] = useState([]);
  const [eventPlate, setEventPlate] = useState('');
  const [eventDescription, setEventDescription] = useState('');
  const [eventMessage, setEventMessage] = useState('');
  const [eventDateTime, setEventDateTime] = useState('');
  const [eventColor, setEventColor] = useState('#fff000');
  const [eventLatitude, setEventLatitude] = useState(0.0);
  const [eventLongitude, setEventLongitude] = useState(0.0);
  const [showEvent, setShowEvent] = useState(false);
  const [showActEvent, setShowActEvent] = useState(false);

  useEffect(() => {
    props.findStatsVehicles();
    let timerStats = setTimeout(() => {
      props.findStatsVehicles();
    }, 60 * 1000);
    return () => {
      clearTimeout(timerStats);
    };
  }, []);

  const handleClose = () => {
    setShowEvent(false);
    setShowActEvent(false);
    setShowEvent((state) => {
      isShowEvent = state;
      setInterval(notifyEvents(), 1000);
      return state;
    });
  };

  const handleActEvent = () => {
    setShowEvent(false);
    setShowActEvent(true);
  };

  const eventsOcurredListen = async () => {
    const token = await localStorage.getItem(config.tokenKey); 
      var socket = io(process.env.REACT_APP_SOCKET, {
        reconnectionDelayMax: 10000,
        autoConnect: true,
        transports: ['websocket'],
        query: { token: token }
      });
 
      socket.io.on("reconnect_error", (error) => {
        console.log(error);
      });

      socket.io.on("reconnect_failed", () => {
        console.log("reconnect fail");
      });

      socket.on('eventOcurred', (data) => {
        console.log(data);
        /* let event = JSON.parse(data);
        events.push(event);
        setEvents(events);
        notifyEvents(); */
      });
  }

  const notifyEvents = () => {
    if ((events.length > 0) && (!isShowEvent)) {
      let event = events.shift();
      if (event.mensagem.trim().length > 0) {
        setEventPlate(event.placa.trim());
        setEventDescription(event.descricao.trim());
        setEventMessage(event.mensagem.trim());
        setEventDateTime(event.datahoragps);
        setEventColor(event.cor);
        setEventLatitude(event.latitude);
        setEventLongitude(event.longitude);
        setShowEvent(true);
        setShowEvent((state) => {
          isShowEvent = state;
          return state;
        });
      }
    }
  }
    
  return (
    <div>
      <Header />
      <Navigation />
      <div id="main" role="main">
        {/* 
        <LayoutSwitcher />
        <Ribbon />
        */}
        <Switch>
          {routes.map((route, idx) => {
            return route.component ? (
              <Route
                key={idx} 
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={props => <route.component {...props} />}
              />
            ) : null;
          })}
          
          {/* 
          <Redirect from="/" to="/misc/404" /> 
          <Redirect from="/" to="/dashboard/analytics" />
          */}
        </Switch>
      </div>

      <Footer />
      <Shortcut />

      <Dialog
        maxWidth={'md'}
        fullWidth={true}
        disableEscapeKeyDown
        open={showEvent}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
      >
        <DialogTitle id="alert-dialog-title">{"MonitorAS - Ocorrência de Evento"}</DialogTitle>
        <DialogContent dividers  style={{ backgroundColor: eventColor ? eventColor : 'gray', 
        padding: '20px', color: '#fff', fontSize: '18px' }}>
            <div className="row">
              <div>Veículo: {eventPlate} - {eventDescription}</div>
              <div>Descrição do Evento: {eventMessage}</div>
              <div>Data e Hora Ocorrência: {eventDateTime}</div>
              <div>Localização: {eventLatitude.toFixed(6)}, {eventLongitude.toFixed(6)} <HoverText onClick={() => { }} ><i style={{ color: '#fff', fontSize: '18px' }} className="fa fa-street-view" aria-hidden="true" title={"Visualizar Street View"} /></HoverText></div>
            </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary">
            Confirmar
          </Button>
          <Button onClick={handleActEvent} variant="contained" color="secondary">
            Atuar no Evento
          </Button> 
        </DialogActions>
      </Dialog>
      
      <ActEvent 
        showActEvent={showActEvent} 
        eventMessage={eventMessage} 
        eventDateTime={eventDateTime} 
        eventLatitude={eventLatitude}
        eventLongitude={eventLongitude}
        eventColor={eventColor}
        handleClose={handleClose}
        />

    </div>
  );

}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  findStatsVehicles
}, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
