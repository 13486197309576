import api from '../../../../../api'

export const FETCH_MAINTENCE_PLANS_PENDING = 'FETCH_MAINTENCE_PLANS_PENDING'
export const FETCH_MAINTENCE_PLANS_FULFILLED = 'FETCH_MAINTENCE_PLANS_FULFILLED'
export const FETCH_MAINTENCE_PLANS_REJECTED = 'FETCH_MAINTENCE_PLANS_REJECTED'

export const FETCH_MAINTENCE_PLAN_PENDING = 'FETCH_MAINTENCE_PLAN_PENDING'
export const FETCH_MAINTENCE_PLAN_FULFILLED = 'FETCH_MAINTENCE_PLAN_FULFILLED'
export const FETCH_MAINTENCE_PLAN_REJECTED = 'FETCH_MAINTENCE_PLAN_REJECTED'

export const CREATE_MAINTENCE_PLAN_PENDING = 'CREATE_MAINTENCE_PLAN_PENDING'
export const CREATE_MAINTENCE_PLAN_FULFILLED = 'CREATE_MAINTENCE_PLAN_FULFILLED'
export const CREATE_MAINTENCE_PLAN_REJECTED = 'CREATE_MAINTENCE_PLAN_REJECTED'

export const UPDATE_MAINTENCE_PLAN_PENDING = 'UPDATE_MAINTENCE_PLAN_PENDING'
export const UPDATE_MAINTENCE_PLAN_FULFILLED = 'UPDATE_MAINTENCE_PLAN_FULFILLED'
export const UPDATE_MAINTENCE_PLAN_REJECTED = 'UPDATE_MAINTENCE_PLAN_REJECTED'

export const DELETE_MAINTENCE_PLAN_PENDING = 'DELETE_MAINTENCE_PLAN_PENDING'
export const DELETE_MAINTENCE_PLAN_FULFILLED = 'DELETE_MAINTENCE_PLAN_FULFILLED'
export const DELETE_MAINTENCE_PLAN_REJECTED = 'DELETE_MAINTENCE_PLAN_REJECTED'

export const CHANGE_MAINTENCE_PLAN = 'CHANGE_MAINTENCE_PLAN'

export const ADD_MAINTENCE_PLAN = 'ADD_MAINTENCE_PLAN'

export function changeMaintencePlan(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_MAINTENCE_PLAN, payload: { name, value } })
  }
}
 
export function addMaintencePlan() {
  return dispatch => {
    dispatch({ type: ADD_MAINTENCE_PLAN });
  };
}

export function findAllMaintencePlans(first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_MAINTENCE_PLANS_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
                query findAllMaintencePlans {
                  planosManutencao (first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                    tipoManutencao
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_MAINTENCE_PLANS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { planosManutencao } = data
        dispatch({ type: FETCH_MAINTENCE_PLANS_FULFILLED, payload: planosManutencao });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_MAINTENCE_PLANS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAllMaintencePlansByDescription(search = '', first = 1000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_MAINTENCE_PLANS_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
                query findAllMaintencePlansByDescription {
                  planosManutencaoPorDescricao (search: "${search}" first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                    tipoManutencao
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_MAINTENCE_PLANS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { planosManutencaoPorDescricao } = data
        dispatch({ type: FETCH_MAINTENCE_PLANS_FULFILLED, payload: planosManutencaoPorDescricao });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_MAINTENCE_PLANS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findMaintencePlan(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_MAINTENCE_PLAN_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                    query findMaintencePlan {
                      planoManutencao (codigo: ${codigo}) {
                        codigo
                        matriz
                        descricao
                        unidade
                        intervalo
                        antecipacao
                        tipoAntecipacao
                        tipoManutencao
                        clienteMatriz {
                          codigo
                          razaoSocial
                        }
                      }
                    }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_MAINTENCE_PLAN_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { planoManutencao } = data
        dispatch({ type: FETCH_MAINTENCE_PLAN_FULFILLED, payload: planoManutencao });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_MAINTENCE_PLAN_REJECTED, error });
      resolve(error);
    });

  });

}

export function createMaintencePlan(maintencePlan) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_MAINTENCE_PLAN_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation createMaintencePlan {
            createPlanoManutencao (input: {
              codigo: ${maintencePlan.codigo}
              matriz: ${maintencePlan.matriz}
              descricao: "${maintencePlan.descricao}"
              unidade: ${maintencePlan.unidade}
              intervalo: ${maintencePlan.intervalo}
              antecipacao: ${maintencePlan.antecipacao}
              tipoAntecipacao: ${maintencePlan.tipoAntecipacao}
              tipoManutencao: ${maintencePlan.tipoManutencao}
            } ) {
              codigo
              matriz
              descricao
              unidade
              intervalo
              antecipacao
              tipoAntecipacao
              tipoManutencao
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_MAINTENCE_PLAN_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createPlanoManutencao } = data
        dispatch({ type: CREATE_MAINTENCE_PLAN_FULFILLED, payload: createPlanoManutencao });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_MAINTENCE_PLAN_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateMaintencePlan(maintencePlan) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_MAINTENCE_PLAN_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation UpdateMaintencePlan {
            updatePlanoManutencao (input: {
              codigo: ${maintencePlan.codigo}
              matriz: ${maintencePlan.matriz}
              descricao: "${maintencePlan.descricao}"
              unidade: ${maintencePlan.unidade}
              intervalo: ${maintencePlan.intervalo}
              antecipacao: ${maintencePlan.antecipacao}
              tipoAntecipacao: ${maintencePlan.tipoAntecipacao}
              tipoManutencao: ${maintencePlan.tipoManutencao}
            } ) {
              codigo
              matriz
              descricao
              unidade
              intervalo
              antecipacao
              tipoAntecipacao
              tipoManutencao
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_MAINTENCE_PLAN_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updatePlanoManutencao } = data
        dispatch({ type: UPDATE_MAINTENCE_PLAN_FULFILLED, payload: updatePlanoManutencao });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_MAINTENCE_PLAN_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
} 

export function removeMaintencePlan(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_MAINTENCE_PLAN_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation RemoveMaintencePlan {
            deletePlanoManutencao (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_MAINTENCE_PLAN_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deletePlanoManutencao } = data
        dispatch({ type: DELETE_MAINTENCE_PLAN_FULFILLED, payload: deletePlanoManutencao });
        dispatch(findAllMaintencePlans());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_MAINTENCE_PLAN_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
