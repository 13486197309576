import {

    FETCH_SUPPLIERS_PENDING,
    FETCH_SUPPLIERS_FULFILLED,
    FETCH_SUPPLIERS_REJECTED,

    FETCH_SUPPLIER_PENDING,
    FETCH_SUPPLIER_FULFILLED,
    FETCH_SUPPLIER_REJECTED,

    CREATE_SUPPLIER_PENDING,
    CREATE_SUPPLIER_FULFILLED,
    CREATE_SUPPLIER_REJECTED,

    UPDATE_SUPPLIER_PENDING,
    UPDATE_SUPPLIER_FULFILLED,
    UPDATE_SUPPLIER_REJECTED,

    DELETE_SUPPLIER_PENDING,
    DELETE_SUPPLIER_FULFILLED,
    DELETE_SUPPLIER_REJECTED,
    
    CHANGE_SUPPLIER,
    
    ADD_SUPPLIER

} from './supplierActions'

const titleCase = (str) => {
    if (!str) return;
    return str.toLowerCase().split(' ').map(function(word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}

const initialState = {
    loading: false,
    listOfSuppliers: [],
    supplier: {
        codigo: 0,
        matriz: 0,
        status: 1,
        atividade: 0,
        razaoSocial: '',
        endereco: '',
        cep: '',
        bairro: '',
        complemento: '',
        estado: '',
        cidade: '',
        cpfCnpj: '',
        telefone: '',
        celular: '',
        nomeContato: '',
        celularContato: '',
        emailContato: '',
        ramoAtividade: {
            codigo: 0,
            descricao: ''
        },
        clienteMatriz: {
            codigo: 0,
            razaoSocial: ''
        }
    },
    error: null,
}

export function supplierReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_SUPPLIER: 
            let value = ""
                
            if (action.payload.name.includes('mail')) {
                value = action.payload.value.toLowerCase();
            } else 
                if (action.payload.name.includes('apelido')) {
                    value = action.payload.value;
                } else {
                    value = titleCase(action.payload.value);
                }

            return Object.assign({}, state, {
                supplier: { ...state.supplier, 
                    [action.payload.name]: value
                }
            })
        case ADD_SUPPLIER: 
            return Object.assign({}, state, {
                supplier: { ...initialState.supplier }
            })
        case FETCH_SUPPLIERS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfSuppliers: [],
            })
        case FETCH_SUPPLIERS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfSuppliers: action.payload
            })
        case FETCH_SUPPLIERS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_SUPPLIER_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //supplier: { ...initialState.supplier },
            })
        case CREATE_SUPPLIER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                supplier: action.payload
            })
        case CREATE_SUPPLIER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_SUPPLIER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_SUPPLIER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                supplier: action.payload
            })
        case UPDATE_SUPPLIER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_SUPPLIER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_SUPPLIER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                supplier: action.payload
            })
        case FETCH_SUPPLIER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_SUPPLIER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_SUPPLIER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_SUPPLIER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}