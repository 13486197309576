import {

    FETCH_FIRMWARES_PENDING,
    FETCH_FIRMWARES_FULFILLED,
    FETCH_FIRMWARES_REJECTED,

    FETCH_FIRMWARE_PENDING,
    FETCH_FIRMWARE_FULFILLED,
    FETCH_FIRMWARE_REJECTED,

    CREATE_FIRMWARE_PENDING,
    CREATE_FIRMWARE_FULFILLED,
    CREATE_FIRMWARE_REJECTED,

    UPDATE_FIRMWARE_PENDING,
    UPDATE_FIRMWARE_FULFILLED,
    UPDATE_FIRMWARE_REJECTED,

    DELETE_FIRMWARE_PENDING,
    DELETE_FIRMWARE_FULFILLED,
    DELETE_FIRMWARE_REJECTED,
    
    CHANGE_FIRMWARE,
    
    ADD_FIRMWARE

} from './firmwareActions'

const titleCase = (str) => {
    if (!str) return;
    return str.toLowerCase().split(' ').map(function(word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}

const initialState = {
    loading: false,
    listOfFirmwares: [],
    firmware: {
        codigo: 0,
        matriz: 0,
        descricao: '',
        clienteMatriz: {
            codigo: 0,
            razaoSocial: '',
        }
    },
    error: null,
}

export function firmwareReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_FIRMWARE: 
            let value = ""
                
            if (action.payload.name.includes('mail')) {
                value = action.payload.value.toLowerCase();
            } else 
                if (action.payload.name.includes('apelido')) {
                    value = action.payload.value;
                } else {
                    value = titleCase(action.payload.value);
                }

            return Object.assign({}, state, {
                firmware: { ...state.firmware, 
                    [action.payload.name]: value
                }
            })
        case ADD_FIRMWARE: 
            return Object.assign({}, state, {
                firmware: { ...initialState.firmware }
            })
        case FETCH_FIRMWARES_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfFirmwares: [],
            })
        case FETCH_FIRMWARES_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfFirmwares: action.payload
            })
        case FETCH_FIRMWARES_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_FIRMWARE_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //firmware: { ...initialState.firmware },
            })
        case CREATE_FIRMWARE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                firmware: action.payload
            })
        case CREATE_FIRMWARE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_FIRMWARE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_FIRMWARE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                firmware: action.payload
            })
        case UPDATE_FIRMWARE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_FIRMWARE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_FIRMWARE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                firmware: action.payload
            })
        case FETCH_FIRMWARE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_FIRMWARE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_FIRMWARE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_FIRMWARE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}