import {

    FETCH_VEHICLE_RESERVATIONS_PENDING,
    FETCH_VEHICLE_RESERVATIONS_FULFILLED,
    FETCH_VEHICLE_RESERVATIONS_REJECTED,

    FETCH_VEHICLE_RESERVATION_PENDING,
    FETCH_VEHICLE_RESERVATION_FULFILLED,
    FETCH_VEHICLE_RESERVATION_REJECTED,

    CREATE_VEHICLE_RESERVATION_PENDING,
    CREATE_VEHICLE_RESERVATION_FULFILLED,
    CREATE_VEHICLE_RESERVATION_REJECTED,

    UPDATE_VEHICLE_RESERVATION_PENDING,
    UPDATE_VEHICLE_RESERVATION_FULFILLED,
    UPDATE_VEHICLE_RESERVATION_REJECTED,

    DELETE_VEHICLE_RESERVATION_PENDING,
    DELETE_VEHICLE_RESERVATION_FULFILLED,
    DELETE_VEHICLE_RESERVATION_REJECTED,
    
    CHANGE_VEHICLE_RESERVATION,
    
    ADD_VEHICLE_RESERVATION

} from './vehicleReservationActions'

const titleCase = (str) => {
    if (!str) return;
    return str.toLowerCase().split(' ').map(function(word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}

const initialState = {
    loading: false,
    listOfVehicleReservations: [],
    vehicleReservation: {
        codigo: 0,
        veiculo: 0,
        motorista: 0,
        descricao: '',
        dataSaida: '',
        horaSaida: '',
        dataPrevisao: '',
        horaPrevisao: '',
        observacoes: '',
        veiculoReserva: {
            codigo: 0,
            placa: '',
            descricao: ''
        }
    },
    error: null,
}

export function vehicleReservationReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_VEHICLE_RESERVATION:
            
        let value = ""
                
        if (action.payload.name.includes('mail')) {
            value = action.payload.value.toLowerCase();
        } else 
            if (action.payload.name.includes('apelido')) {
                value = action.payload.value;
            } else {
                value = titleCase(action.payload.value);
            }
        return Object.assign({}, state, {
            vehicleReservation: { ...state.vehicleReservation, 
                [action.payload.name]: value
            }
        })
        case ADD_VEHICLE_RESERVATION: 
            return Object.assign({}, state, {
                vehicleReservation: { ...initialState.vehicleReservation }
            })
        case FETCH_VEHICLE_RESERVATIONS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfVehicleReservations: [],
            })
        case FETCH_VEHICLE_RESERVATIONS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfVehicleReservations: action.payload
            })
        case FETCH_VEHICLE_RESERVATIONS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_VEHICLE_RESERVATION_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //vehicleReservation: { ...initialState.vehicleReservation },
            })
        case CREATE_VEHICLE_RESERVATION_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                vehicleReservation: action.payload
            })
        case CREATE_VEHICLE_RESERVATION_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_VEHICLE_RESERVATION_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_VEHICLE_RESERVATION_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                vehicleReservation: action.payload
            })
        case UPDATE_VEHICLE_RESERVATION_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_VEHICLE_RESERVATION_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_VEHICLE_RESERVATION_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                vehicleReservation: action.payload
            })
        case FETCH_VEHICLE_RESERVATION_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_VEHICLE_RESERVATION_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_VEHICLE_RESERVATION_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_VEHICLE_RESERVATION_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}