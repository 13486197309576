import api from '../../../../../api'

export const FETCH_SIMCARDS_PENDING = 'FETCH_SIMCARDS_PENDING'
export const FETCH_SIMCARDS_FULFILLED = 'FETCH_SIMCARDS_FULFILLED'
export const FETCH_SIMCARDS_REJECTED = 'FETCH_SIMCARDS_REJECTED'

export const FETCH_SIMCARD_PENDING = 'FETCH_SIMCARD_PENDING'
export const FETCH_SIMCARD_FULFILLED = 'FETCH_SIMCARD_FULFILLED'
export const FETCH_SIMCARD_REJECTED = 'FETCH_SIMCARD_REJECTED'

export const CREATE_SIMCARD_PENDING = 'CREATE_SIMCARD_PENDING'
export const CREATE_SIMCARD_FULFILLED = 'CREATE_SIMCARD_FULFILLED'
export const CREATE_SIMCARD_REJECTED = 'CREATE_SIMCARD_REJECTED'

export const UPDATE_SIMCARD_PENDING = 'UPDATE_SIMCARD_PENDING'
export const UPDATE_SIMCARD_FULFILLED = 'UPDATE_SIMCARD_FULFILLED'
export const UPDATE_SIMCARD_REJECTED = 'UPDATE_SIMCARD_REJECTED'

export const DELETE_SIMCARD_PENDING = 'DELETE_SIMCARD_PENDING'
export const DELETE_SIMCARD_FULFILLED = 'DELETE_SIMCARD_FULFILLED'
export const DELETE_SIMCARD_REJECTED = 'DELETE_SIMCARD_REJECTED'

export const CHANGE_SIMCARD = 'CHANGE_SIMCARD'

export const ADD_SIMCARD = 'ADD_SIMCARD'

export function changeSimCard(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_SIMCARD, payload: { name, value } })
  }
}

export function addSimCard() {
  return dispatch => {
    dispatch({ type: ADD_SIMCARD });
  };
}

export function findAllSimCards(first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SIMCARDS_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                query findAllSimCards {
                  simCards (first: ${first} offset: ${offset}) {
                    codigo
                    iccid
                    ddd
                    numero
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SIMCARDS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { simCards } = data
        dispatch({ type: FETCH_SIMCARDS_FULFILLED, payload: simCards });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SIMCARDS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAllSimCardsByICCID(search = '', first = 1000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SIMCARDS_PENDING });

    if (search.trim().length <= 2) {
      dispatch(findAllSimCards());
      return;
    }

    api({
      method: 'post',         
      data: {
        query: `
                query findAllSimCards {
                  simCardsPorICCID (search: "${search}" first: ${first} offset: ${offset}) {
                    codigo
                    iccid
                    ddd
                    numero
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SIMCARDS_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { simCardsPorICCID } = data
        dispatch({ type: FETCH_SIMCARDS_FULFILLED, payload: simCardsPorICCID });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SIMCARDS_REJECTED, error });
      reject(error);
    });

  });

}

export function findAllSimCardsNotInUse(codigo = 0, first = 1000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SIMCARDS_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                query findAllSimCardsNotInUse {
                  simCardsNotInUse (first: ${first} offset: ${offset} codigo: ${codigo}) {
                    codigo
                    iccid
                    ddd
                    numero
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SIMCARDS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { simCardsNotInUse } = data
        dispatch({ type: FETCH_SIMCARDS_FULFILLED, payload: simCardsNotInUse });
        resolve(simCardsNotInUse);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SIMCARDS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findSimCard(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SIMCARD_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                query findSimCard {
                  simCard (codigo: ${codigo}) {
                    codigo
                    matriz
                    fornecedor
                    simCardFornecedor {
                      codigo
                      razaoSocial
                    }
                    iccid
                    ddd
                    numero
                    pin
                    puk
                    contrato
                    operadora
                    tipoPacote
                    clienteMatriz {
                      codigo
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SIMCARD_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { simCard } = data
        dispatch({ type: FETCH_SIMCARD_FULFILLED, payload: simCard });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SIMCARD_REJECTED, error });
      resolve(error);
    });

  });

}

export function createSimCard(simCard) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_SIMCARD_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation createSimCard {
            createSimCard (input: {
              codigo: ${simCard.codigo}
              matriz: ${simCard.matriz}
              fornecedor: ${simCard.fornecedor}
              iccid: "${simCard.iccid}"
              ddd: ${simCard.ddd}
              numero: "${simCard.numero}"
              pin: "${simCard.pin}"
              puk: "${simCard.puk}"
              contrato: "${simCard.contrato}"
              operadora: ${simCard.operadora}
              tipoPacote: ${simCard.tipoPacote}
            } ) {
              codigo
              matriz
              fornecedor
              simCardFornecedor {
                codigo
                razaoSocial
              }
              iccid
              ddd
              numero
              pin
              puk
              contrato
              operadora
              tipoPacote
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_SIMCARD_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createSimCard } = data
        dispatch({ type: CREATE_SIMCARD_FULFILLED, payload: createSimCard });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_SIMCARD_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateSimCard(simCard) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_SIMCARD_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation UpdateSimCard {
            updateSimCard (input: {
              codigo: ${simCard.codigo}
              matriz: ${simCard.matriz}
              fornecedor: ${simCard.fornecedor}
              iccid: "${simCard.iccid}"
              ddd: ${simCard.ddd}
              numero: "${simCard.numero}"
              pin: "${simCard.pin}"
              puk: "${simCard.puk}"
              contrato: "${simCard.contrato}"
              operadora: ${simCard.operadora}
              tipoPacote: ${simCard.tipoPacote}
            } ) {
              codigo
              matriz
              fornecedor
              simCardFornecedor {
                codigo
                razaoSocial
              }
              iccid
              ddd
              numero
              pin
              puk
              contrato
              operadora
              tipoPacote
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_SIMCARD_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateSimCard } = data
        dispatch({ type: UPDATE_SIMCARD_FULFILLED, payload: updateSimCard });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_SIMCARD_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
}

export function removeSimCard(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_SIMCARD_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation RemoveSimCard {
            deleteSimCard (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_SIMCARD_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteSimCard } = data
        dispatch({ type: DELETE_SIMCARD_FULFILLED, payload: deleteSimCard });
        dispatch(findAllSimCards());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_SIMCARD_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
