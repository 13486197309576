import {

    FETCH_ECONOMETER_HISTORY_PENDING,
    FETCH_ECONOMETER_HISTORY_FULFILLED,
    FETCH_ECONOMETER_HISTORY_REJECTED,

    CHANGE_ECONOMETER_HISTORY,
    DEFAULT_ECONOMETER_HISTORY,

} from './econometerHistoryActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfEconometerHistory: [],
    econometerHistory: {
        veiculo: 0,
        motorista: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function econometerHistoryReducer(state = initialState, action) {
    switch (action.type) {
        case DEFAULT_ECONOMETER_HISTORY:
            return Object.assign({}, state, {
                ...initialState
            })
        case CHANGE_ECONOMETER_HISTORY:
            return Object.assign({}, state, {
                econometerHistory: {
                    ...state.econometerHistory,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_ECONOMETER_HISTORY_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfEconometerHistory: [],
            })
        case FETCH_ECONOMETER_HISTORY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfEconometerHistory: action.payload
            })
        case FETCH_ECONOMETER_HISTORY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfEconometerHistory: [],
                error: action.payload
            })

        default:
            return state
    }
}