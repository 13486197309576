import {

    FETCH_DRIVER_SCORE_PENDING,
    FETCH_DRIVER_SCORE_FULFILLED,
    FETCH_DRIVER_SCORE_REJECTED,

    DELETE_DRIVER_SCORE_PENDING,
    DELETE_DRIVER_SCORE_FULFILLED,
    DELETE_DRIVER_SCORE_REJECTED,

    CHANGE_DRIVER_SCORE

} from './driverScoreActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfDriverScore: [],
    driverScore: {
        matriz: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function driverScoreReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_DRIVER_SCORE:
            return Object.assign({}, state, {
                driverScore: {
                    ...state.driverScore,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_DRIVER_SCORE_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfDriverScore: [],
            })
        case FETCH_DRIVER_SCORE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfDriverScore: action.payload
            })
        case FETCH_DRIVER_SCORE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfDriverScore: [],
                error: action.payload
            })

        case DELETE_DRIVER_SCORE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_DRIVER_SCORE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_DRIVER_SCORE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}