import api from '../../../../api'

export const FETCH_SPEEDING_EVENTS_PENDING = 'FETCH_SPEEDING_EVENTS_PENDING'
export const FETCH_SPEEDING_EVENTS_FULFILLED = 'FETCH_SPEEDING_EVENTS_FULFILLED'
export const FETCH_SPEEDING_EVENTS_REJECTED = 'FETCH_SPEEDING_EVENTS_REJECTED'

export const DELETE_SPEEDING_EVENTS_PENDING = 'DELETE_SPEEDING_EVENTS_PENDING'
export const DELETE_SPEEDING_EVENTS_FULFILLED = 'DELETE_SPEEDING_EVENTS_FULFILLED'
export const DELETE_SPEEDING_EVENTS_REJECTED = 'DELETE_SPEEDING_EVENTS_REJECTED'

export const CHANGE_SPEEDING_EVENTS = 'CHANGE_SPEEDING_EVENTS'

export function changeSpeedingEvents(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_SPEEDING_EVENTS, payload: { name, value } })
  }
}

export function findAllSpeedingEventss(matriz = 0, startPeriod = '', stopPeriod = '') {
  
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SPEEDING_EVENTS_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllSpeedingEventss {
                  eventosVelocidadePorCliente (
                    matriz: ${matriz} 
                    startPeriod: "${startPeriod}",
                    stopPeriod: "${stopPeriod}"
                  ) {
                    dataHoraInicial
                    dataHoraFinal
                    placa
                    motorista
                    descricao
                    cliente
                    localizacao
                    velocidade
                    rpm
                    duracao
                    latitudeInicial
                    longitudeInicial
                    latitudeFinal
                    longitudeFinal
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SPEEDING_EVENTS_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { eventosVelocidadePorCliente } = data
        dispatch({ type: FETCH_SPEEDING_EVENTS_FULFILLED, payload: eventosVelocidadePorCliente });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SPEEDING_EVENTS_REJECTED, error });
      reject(error);
    });

  });

}
