import api from '../../../../api'
import * as moment from 'moment';

export const FETCH_MAINTENANCE_HISTORY_PENDING = 'FETCH_MAINTENANCE_HISTORY_PENDING'
export const FETCH_MAINTENANCE_HISTORY_FULFILLED = 'FETCH_MAINTENANCE_HISTORY_FULFILLED'
export const FETCH_MAINTENANCE_HISTORY_REJECTED = 'FETCH_MAINTENANCE_HISTORY_REJECTED'

export const DELETE_MAINTENANCE_HISTORY_PENDING = 'DELETE_MAINTENANCE_HISTORY_PENDING'
export const DELETE_MAINTENANCE_HISTORY_FULFILLED = 'DELETE_MAINTENANCE_HISTORY_FULFILLED'
export const DELETE_MAINTENANCE_HISTORY_REJECTED = 'DELETE_MAINTENANCE_HISTORY_REJECTED'

export const CHANGE_MAINTENANCE_HISTORY = 'CHANGE_MAINTENANCE_HISTORY'

export const DEFAULT_MAINTENANCE_HISTORY = 'DEFAULT_MAINTENANCE_HISTORY'

export function defaultMaintenanceHistory() { 
  return dispatch => {
    dispatch({ type: DEFAULT_MAINTENANCE_HISTORY })
  }
}

export function changeMaintenanceHistory(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_MAINTENANCE_HISTORY, payload: { name, value } })
  }
}

export function findAllMaintenanceHistorys(filter, first = 12000, offset = 0) {
  
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_MAINTENANCE_HISTORY_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllMaintenanceHistorys {
                  historicoManutencoes (input: {
                    matriz: ${filter.matriz}
                    veiculo: ${filter.veiculo}
                    dataInicial: "${moment.utc(filter.dataInicial, 'DD/MM/YYYY').format('YYYY-MM-DD')}"
                    dataFinal: "${moment.utc(filter.dataFinal, 'DD/MM/YYYY').format('YYYY-MM-DD')}"
                  } first: ${first} offset: ${offset}) {
                    codigo
                    veiculo
                    manutencaoPlano {
                      descricao
                      unidade
                      intervalo
                      antecipacao
                      tipoAntecipacao
                    } 
                    dataInicial
                    odometroAtual
                    dataExecucao
                    odometroExecucao
                    observacoes
                    agendaVeiculo {
                      placa
                      descricao
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_MAINTENANCE_HISTORY_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { historicoManutencoes } = data
        dispatch({ type: FETCH_MAINTENANCE_HISTORY_FULFILLED, payload: historicoManutencoes });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_MAINTENANCE_HISTORY_REJECTED, error });
      reject(error);
    });

  });

}
