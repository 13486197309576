import {

    FETCH_TRACKING_HISTORY_PENDING,
    FETCH_TRACKING_HISTORY_FULFILLED,
    FETCH_TRACKING_HISTORY_REJECTED,

    FETCH_TRACKING_HISTORY_WITH_STOPPEDS_PENDING,
    FETCH_TRACKING_HISTORY_WITH_STOPPEDS_FULFILLED,
    FETCH_TRACKING_HISTORY_WITH_STOPPEDS_REJECTED,

    DELETE_TRACKING_HISTORY_PENDING,
    DELETE_TRACKING_HISTORY_FULFILLED,
    DELETE_TRACKING_HISTORY_REJECTED,

    DEFAULT_TRACKING_HISTORY,

    CHANGE_TRACKING_HISTORY

} from './trackingHistoryActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfTrackingHistory: [],
    trackingHistory: {
        veiculo: 0,
        motorista: 0,
        garagem: 0,
        velocidadeTipo: 0,
        velocidadeValor: 0,
        entrada1: 0,
        entrada2: 0,
        entrada3: 0,
        entrada4: 0,
        saida1: 0,
        saida2: 0,
        saida3: 0,
        saida4: 0,
        modulo: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function trackingHistoryReducer(state = initialState, action) {
    switch (action.type) {
        case DEFAULT_TRACKING_HISTORY: 
            return Object.assign({}, state, {
                ...initialState,
            })
        case CHANGE_TRACKING_HISTORY:
            return Object.assign({}, state, {
                trackingHistory: {
                    ...state.trackingHistory,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_TRACKING_HISTORY_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfTrackingHistory: [],
            })
        case FETCH_TRACKING_HISTORY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfTrackingHistory: action.payload
            })
        case FETCH_TRACKING_HISTORY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfTrackingHistory: [],
                error: action.payload
            })
        case FETCH_TRACKING_HISTORY_WITH_STOPPEDS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfTrackingHistoryWithStoppeds: [],
            })
        case FETCH_TRACKING_HISTORY_WITH_STOPPEDS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfTrackingHistoryWithStoppeds: action.payload
            })
        case FETCH_TRACKING_HISTORY_WITH_STOPPEDS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfTrackingHistoryWithStoppeds: [],
                error: action.payload
            })

        case DELETE_TRACKING_HISTORY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_TRACKING_HISTORY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_TRACKING_HISTORY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}