import {

    FETCH_FLEET_USAGE_PENDING,
    FETCH_FLEET_USAGE_FULFILLED,
    FETCH_FLEET_USAGE_REJECTED,

    DELETE_FLEET_USAGE_PENDING,
    DELETE_FLEET_USAGE_FULFILLED,
    DELETE_FLEET_USAGE_REJECTED,

    CHANGE_FLEET_USAGE

} from './fleetUsageActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfFleetUsage: [],
    fleetUsage: {
        matriz: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function fleetUsageReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_FLEET_USAGE:
            return Object.assign({}, state, {
                fleetUsage: {
                    ...state.fleetUsage,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_FLEET_USAGE_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfFleetUsage: [],
            })
        case FETCH_FLEET_USAGE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfFleetUsage: action.payload
            })
        case FETCH_FLEET_USAGE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfFleetUsage: [],
                error: action.payload
            })

        case DELETE_FLEET_USAGE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_FLEET_USAGE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_FLEET_USAGE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}