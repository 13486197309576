import { SHOW_ERROR_TOAST_MESSAGE } from '../../../../common/layout/components/header/store';
import api from '../../../../api';

export const FETCH_TEMPERATURE_3_ZONES_PENDING = 'FETCH_TEMPERATURE_3_ZONES_PENDING';
export const FETCH_TEMPERATURE_3_ZONES_FULFILLED = 'FETCH_TEMPERATURE_3_ZONES_FULFILLED';
export const FETCH_TEMPERATURE_3_ZONES_REJECTED = 'FETCH_TEMPERATURE_3_ZONES_REJECTED';
 
export const CHANGE_TEMPERATURE_3_ZONES = 'CHANGE_TEMPERATURE_3_ZONES';
export const DEFAULT_TEMPERATURE_3_ZONES = 'DEFAULT_TEMPERATURE_3_ZONES';

export function showMessageSegment() {
  return dispatch => {
    dispatch({ type: SHOW_ERROR_TOAST_MESSAGE, payload: "ATENÇÃO!\nO Período contém erros na coleta de dados!" })
  }
}

export function defaultTemperature3Zones() { 
  return dispatch => {
    dispatch({ type: DEFAULT_TEMPERATURE_3_ZONES })
  }
}

export function changeTemperature3Zones(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_TEMPERATURE_3_ZONES, payload: { name, value } })
  }
}

export function findAllTemperature3Zoness(filter, first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_TEMPERATURE_3_ZONES_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllTemperature3Zoness {
                  historicoPosicoes3ZonasTemperatura (input: {
                    veiculo: ${filter.veiculo}
                    dataHoraInicial: "${filter.dataHoraInicial}"
                    dataHoraFinal: "${filter.dataHoraFinal}"

                  } first: ${first} offset: ${offset}) {
                    modulo
                    dataHoraServidor
                    dataHoraGps
                    latitude
                    longitude
                    temperaturaUm
                    temperaturaDois
                    temperaturaTres
                    statusTemperaturaUm
                    statusTemperaturaDois
                    statusTemperaturaTres
                    veiculo {
                      placa
                      descricao
                      clienteMatriz {
                        razaoSocial
                      }
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_TEMPERATURE_3_ZONES_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { historicoPosicoes3ZonasTemperatura } = data
        dispatch({ type: FETCH_TEMPERATURE_3_ZONES_FULFILLED, payload: historicoPosicoes3ZonasTemperatura });
        resolve(historicoPosicoes3ZonasTemperatura);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_TEMPERATURE_3_ZONES_REJECTED, error });
      reject(error);
    });

  });

}
