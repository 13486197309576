import api from '../../../../../api'

export const FETCH_PRODUCT_OR_SERVICES_PENDING = 'FETCH_PRODUCT_OR_SERVICES_PENDING'
export const FETCH_PRODUCT_OR_SERVICES_FULFILLED = 'FETCH_PRODUCT_OR_SERVICES_FULFILLED'
export const FETCH_PRODUCT_OR_SERVICES_REJECTED = 'FETCH_PRODUCT_OR_SERVICES_REJECTED'

export const FETCH_PRODUCT_OR_SERVICE_PENDING = 'FETCH_PRODUCT_OR_SERVICE_PENDING'
export const FETCH_PRODUCT_OR_SERVICE_FULFILLED = 'FETCH_PRODUCT_OR_SERVICE_FULFILLED'
export const FETCH_PRODUCT_OR_SERVICE_REJECTED = 'FETCH_PRODUCT_OR_SERVICE_REJECTED'

export const CREATE_PRODUCT_OR_SERVICE_PENDING = 'CREATE_PRODUCT_OR_SERVICE_PENDING'
export const CREATE_PRODUCT_OR_SERVICE_FULFILLED = 'CREATE_PRODUCT_OR_SERVICE_FULFILLED'
export const CREATE_PRODUCT_OR_SERVICE_REJECTED = 'CREATE_PRODUCT_OR_SERVICE_REJECTED'

export const UPDATE_PRODUCT_OR_SERVICE_PENDING = 'UPDATE_PRODUCT_OR_SERVICE_PENDING'
export const UPDATE_PRODUCT_OR_SERVICE_FULFILLED = 'UPDATE_PRODUCT_OR_SERVICE_FULFILLED'
export const UPDATE_PRODUCT_OR_SERVICE_REJECTED = 'UPDATE_PRODUCT_OR_SERVICE_REJECTED'

export const DELETE_PRODUCT_OR_SERVICE_PENDING = 'DELETE_PRODUCT_OR_SERVICE_PENDING'
export const DELETE_PRODUCT_OR_SERVICE_FULFILLED = 'DELETE_PRODUCT_OR_SERVICE_FULFILLED'
export const DELETE_PRODUCT_OR_SERVICE_REJECTED = 'DELETE_PRODUCT_OR_SERVICE_REJECTED'

export const CHANGE_PRODUCT_OR_SERVICE = 'CHANGE_PRODUCT_OR_SERVICE'

export const ADD_PRODUCT_OR_SERVICE = 'ADD_PRODUCT_OR_SERVICE'

const toFloat = (value) => {
  value = `${value}`.replace(",",".");
  return parseFloat(value);
}

export function changeProductOrService(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_PRODUCT_OR_SERVICE, payload: { name, value } })
  }
}

export function addProductOrService() {
  return dispatch => {
    dispatch({ type: ADD_PRODUCT_OR_SERVICE });
  };
}

export function findAllProductOrServices(first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PRODUCT_OR_SERVICES_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                query findAllProductOrServices {
                  produtosServicos (first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                    tipo
                    fornecedor
                    valor
                    duracaoMedia
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_PRODUCT_OR_SERVICES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { produtosServicos } = data
        dispatch({ type: FETCH_PRODUCT_OR_SERVICES_FULFILLED, payload: produtosServicos });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PRODUCT_OR_SERVICES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAllProductOrServicesByDescription(search = '', first = 1000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PRODUCT_OR_SERVICES_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                query findAllProductOrServicesByDescription {
                  produtosServicosPorDescricao (search: "${search}" first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                    tipo
                    fornecedor
                    valor
                    duracaoMedia
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_PRODUCT_OR_SERVICES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { produtosServicosPorDescricao } = data
        dispatch({ type: FETCH_PRODUCT_OR_SERVICES_FULFILLED, payload: produtosServicosPorDescricao });
        resolve(produtosServicosPorDescricao);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PRODUCT_OR_SERVICES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAllProductOrServicesByType(type = 0, first = 1000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PRODUCT_OR_SERVICES_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllProductOrServicesByType {
                  produtosServicosPorTipo (tipo: ${type} first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                    tipo
                    fornecedor
                    valor
                    duracaoMedia
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_PRODUCT_OR_SERVICES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { produtosServicosPorTipo } = data
        dispatch({ type: FETCH_PRODUCT_OR_SERVICES_FULFILLED, payload: produtosServicosPorTipo });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PRODUCT_OR_SERVICES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findProductOrService(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PRODUCT_OR_SERVICE_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findProductOrService {
                  produtoServico (codigo: ${codigo}) {
                    codigo
                    matriz
                    descricao
                    tipo
                    fornecedor
                    produtoFornecedor {
                      codigo
                      razaoSocial
                    }
                    valor
                    grupoServico
                    duracaoMedia
                    clienteMatriz {
                      codigo
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_PRODUCT_OR_SERVICE_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { produtoServico } = data
        dispatch({ type: FETCH_PRODUCT_OR_SERVICE_FULFILLED, payload: produtoServico });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PRODUCT_OR_SERVICE_REJECTED, error });
      resolve(error);
    });

  });

}

export function createProductOrService(produtoServico) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_PRODUCT_OR_SERVICE_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation createProductOrService {
            createProdutoServico (input: {
              codigo: ${produtoServico.codigo}
              matriz: ${produtoServico.matriz}
              descricao: "${produtoServico.descricao}"
              tipo: ${produtoServico.tipo}
              grupoServico: ${produtoServico.grupoServico}
              fornecedor: ${produtoServico.fornecedor}
              valor: ${toFloat(produtoServico.valor)}
              duracaoMedia: ${produtoServico.duracaoMedia}
            } ) {
              codigo
              matriz
              descricao
              tipo
              grupoServico
              fornecedor
              produtoFornecedor {
                codigo
                razaoSocial
              }
              valor
              duracaoMedia
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_PRODUCT_OR_SERVICE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createProdutoServico } = data
        dispatch({ type: CREATE_PRODUCT_OR_SERVICE_FULFILLED, payload: createProdutoServico });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_PRODUCT_OR_SERVICE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateProductOrService(produtoServico) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_PRODUCT_OR_SERVICE_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation UpdateProductOrService {
            updateProdutoServico (input: {
              codigo: ${produtoServico.codigo}
              matriz: ${produtoServico.matriz}
              descricao: "${produtoServico.descricao}"
              tipo: ${produtoServico.tipo}
              grupoServico: ${produtoServico.grupoServico}
              fornecedor: ${produtoServico.fornecedor}
              valor: ${toFloat(produtoServico.valor)}
              duracaoMedia: ${produtoServico.duracaoMedia}
            } ) {
              codigo
              matriz
              descricao
              tipo
              grupoServico
              fornecedor
              produtoFornecedor {
                codigo
                razaoSocial
              }
              valor
              duracaoMedia
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_PRODUCT_OR_SERVICE_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateProdutoServico } = data
        dispatch({ type: UPDATE_PRODUCT_OR_SERVICE_FULFILLED, payload: updateProdutoServico });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_PRODUCT_OR_SERVICE_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
}

export function removeProductOrService(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_PRODUCT_OR_SERVICE_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation RemoveProductOrService {
            deleteProdutoServico (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_PRODUCT_OR_SERVICE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteProdutoServico } = data
        dispatch({ type: DELETE_PRODUCT_OR_SERVICE_FULFILLED, payload: deleteProdutoServico });
        dispatch(findAllProductOrServices());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_PRODUCT_OR_SERVICE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
