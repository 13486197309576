import api from '../../../../api'

export const FETCH_ACCESS_COUNTER_HISTORY_PENDING = 'FETCH_ACCESS_COUNTER_HISTORY_PENDING'
export const FETCH_ACCESS_COUNTER_HISTORY_FULFILLED = 'FETCH_ACCESS_COUNTER_HISTORY_FULFILLED'
export const FETCH_ACCESS_COUNTER_HISTORY_REJECTED = 'FETCH_ACCESS_COUNTER_HISTORY_REJECTED'

export const DELETE_ACCESS_COUNTER_HISTORY_PENDING = 'DELETE_ACCESS_COUNTER_HISTORY_PENDING'
export const DELETE_ACCESS_COUNTER_HISTORY_FULFILLED = 'DELETE_ACCESS_COUNTER_HISTORY_FULFILLED'
export const DELETE_ACCESS_COUNTER_HISTORY_REJECTED = 'DELETE_ACCESS_COUNTER_HISTORY_REJECTED'

export const DEFAULT_ACCESS_COUNTER_HISTORY = 'DEFAULT_ACCESS_COUNTER_HISTORY'

export const CHANGE_ACCESS_COUNTER_HISTORY = 'CHANGE_ACCESS_COUNTER_HISTORY'

export function defaultAccessCounterHistory() { 
  return dispatch => {
    dispatch({ type: DEFAULT_ACCESS_COUNTER_HISTORY })
  }
}

export function changeAccessCounterHistory(name, value) { 
  return dispatch => {
    dispatch({ type: CHANGE_ACCESS_COUNTER_HISTORY, payload: { name, value } })
  }
}

export function findAllAccessCounterHistorys(filter, first = 12000, offset = 0) {
  
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_ACCESS_COUNTER_HISTORY_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllAccessCounterHistorys {
                  historicoPosicoesContadorAcesso (input: {
                    veiculo: ${filter.veiculo}
                    motorista: ${filter.motorista}
                    dataHoraInicial: "${filter.dataHoraInicial}"
                    dataHoraFinal: "${filter.dataHoraFinal}"
                  } first: ${first} offset: ${offset}) {
                    veiculo {
                      placa
                      descricao
                      veiculoTipo {
                        ligadaIcon
                        desligadaIcon
                        panicoIcon
                        terminalIcon
                      }
                      clienteMatriz {
                        razaoSocial
                      }
                      veiculoGaragem {
                        descricao
                      }
                    }
                    motorista {
                      nome
                    }
                    modulo
                    dataHoraServidor
                    dataHoraGps
                    latitude
                    longitude
                    numeroLog
                    tipoEquipamento
                    codigoLog
                    dataHoraTelemetria
                    contadorAcessoUm
                    contadorAcessoDois
                    contadorAcessoTres
                    contadorAcessoQuatro
                    contadorAcessoCinco
                    contadorAcessoSeis
                    contadorAcessoSete
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_ACCESS_COUNTER_HISTORY_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { historicoPosicoesContadorAcesso } = data
        dispatch({ type: FETCH_ACCESS_COUNTER_HISTORY_FULFILLED, payload: historicoPosicoesContadorAcesso });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_ACCESS_COUNTER_HISTORY_REJECTED, error });
      reject(error);
    });

  });

}

export function removeAccessCounterHistory(veiculo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_ACCESS_COUNTER_HISTORY_PENDING });

    api({
      method: 'post',
      data: {
        query: `
          mutation RemoveAccessCounterHistory {
            deleteHistoricoPosicoesContadorAcesso (veiculo: ${veiculo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_ACCESS_COUNTER_HISTORY_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteHistoricoPosicoesContadorAcesso } = data
        dispatch({ type: DELETE_ACCESS_COUNTER_HISTORY_FULFILLED, payload: deleteHistoricoPosicoesContadorAcesso });
        dispatch(findAllAccessCounterHistorys());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_ACCESS_COUNTER_HISTORY_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
