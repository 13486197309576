import React, { useEffect, useState } from "react";
import ToggleShortcut from "./ToggleShortcut";
import { connect } from "react-redux";
import { config } from "../../../config/config";

import decode from "jwt-decode";

const LoginInfo = () => { 
  const [userName, setUserName] = useState('');
  
  useEffect(() => {
    loadUserInfo();
  },[]);

  const loadUserInfo = async () => {
    const token = await localStorage.getItem(config.tokenKey);
    try {
      const { login } = decode(token);
      setUserName(login || 'Usuário');
    } catch (err) {
      setUserName('Usuário');
    }
  };

  return (
      <span>
        <ToggleShortcut>
          {/* <img src={this.props.picture} alt="me" className="online" /> */}
          <span style={{ paddingTop: -3, paddingLeft: 10, paddingRight: 10 }}>{userName}</span>
          <i className="fa fa-angle-down" />
        </ToggleShortcut>
      </span>
  );

}

const mapStateToProps = state => state.user;
export default connect(mapStateToProps)(LoginInfo);
