import api from '../../../../api'

export const FETCH_MONTHLY_CLOSING_PENDING = 'FETCH_MONTHLY_CLOSING_PENDING'
export const FETCH_MONTHLY_CLOSING_FULFILLED = 'FETCH_MONTHLY_CLOSING_FULFILLED'
export const FETCH_MONTHLY_CLOSING_REJECTED = 'FETCH_MONTHLY_CLOSING_REJECTED'

export const CHANGE_MONTHLY_CLOSING = 'CHANGE_MONTHLY_CLOSING'

export function changeMonthlyClosing(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_MONTHLY_CLOSING, payload: { name, value } })
  }
}

export function findAllMonthlyClosing(matriz = 0, year = 2020, month = 1) {
  
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_MONTHLY_CLOSING_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllMonthlyClosings {
                  veiculosFechamentoMensal (ano: ${year} mes: ${month} matriz: ${matriz}) {
                    razaoSocial
                    detalhes { 
                      razaoSocial
                      veiculos {
                        placa
                        equipamentoPrimario
                        equipamentoSecundario
                        descricao
                        iccid
                        perfil
                        status
                        diasCobrado
                        valorMensal
                        valorCobrado
                      }
                      veiculosTotal
                      valorTotal
                    }
                    veiculosTotal
                    valorTotal
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_MONTHLY_CLOSING_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { veiculosFechamentoMensal } = data
        dispatch({ type: FETCH_MONTHLY_CLOSING_FULFILLED, payload: veiculosFechamentoMensal });
        resolve(veiculosFechamentoMensal);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_MONTHLY_CLOSING_REJECTED, error });
      reject(error);
    });

  });

}
