import {

    FETCH_SYNTHETIC_DETAILED_DRIVER_PENDING,
    FETCH_SYNTHETIC_DETAILED_DRIVER_FULFILLED,
    FETCH_SYNTHETIC_DETAILED_DRIVER_REJECTED,

    DELETE_SYNTHETIC_DETAILED_DRIVER_PENDING,
    DELETE_SYNTHETIC_DETAILED_DRIVER_FULFILLED,
    DELETE_SYNTHETIC_DETAILED_DRIVER_REJECTED,

    CHANGE_SYNTHETIC_DETAILED_DRIVER

} from './syntheticDetailedDriverActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfSyntheticDetailedDriver: [],
    syntheticDetailedDriver: {
        motorista: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function syntheticDetailedDriverReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_SYNTHETIC_DETAILED_DRIVER:
            return Object.assign({}, state, {
                syntheticDetailedDriver: {
                    ...state.syntheticDetailedDriver,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_SYNTHETIC_DETAILED_DRIVER_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfSyntheticDetailedDriver: [],
            })
        case FETCH_SYNTHETIC_DETAILED_DRIVER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfSyntheticDetailedDriver: action.payload
            })
        case FETCH_SYNTHETIC_DETAILED_DRIVER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfSyntheticDetailedDriver: [],
                error: action.payload
            })

        case DELETE_SYNTHETIC_DETAILED_DRIVER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_SYNTHETIC_DETAILED_DRIVER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_SYNTHETIC_DETAILED_DRIVER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}