import { SHOW_SAVED_TOAST_MESSAGE, SHOW_REMOVED_TOAST_MESSAGE } from '../../../../common/layout/components/header/store';

import api from '../../../../api';

export const FETCH_CUSTOMERS_PENDING = 'FETCH_CUSTOMERS_PENDING'
export const FETCH_CUSTOMERS_FULFILLED = 'FETCH_CUSTOMERS_FULFILLED'
export const FETCH_CUSTOMERS_REJECTED = 'FETCH_CUSTOMERS_REJECTED'

export const FETCH_CUSTOMER_PENDING = 'FETCH_CUSTOMER_PENDING'
export const FETCH_CUSTOMER_FULFILLED = 'FETCH_CUSTOMER_FULFILLED'
export const FETCH_CUSTOMER_REJECTED = 'FETCH_CUSTOMER_REJECTED'

export const CREATE_CUSTOMER_PENDING = 'CREATE_CUSTOMER_PENDING'
export const CREATE_CUSTOMER_FULFILLED = 'CREATE_CUSTOMER_FULFILLED'
export const CREATE_CUSTOMER_REJECTED = 'CREATE_CUSTOMER_REJECTED'

export const UPDATE_CUSTOMER_PENDING = 'UPDATE_CUSTOMER_PENDING'
export const UPDATE_CUSTOMER_FULFILLED = 'UPDATE_CUSTOMER_FULFILLED'
export const UPDATE_CUSTOMER_REJECTED = 'UPDATE_CUSTOMER_REJECTED'

export const DELETE_CUSTOMER_PENDING = 'DELETE_CUSTOMER_PENDING'
export const DELETE_CUSTOMER_FULFILLED = 'DELETE_CUSTOMER_FULFILLED'
export const DELETE_CUSTOMER_REJECTED = 'DELETE_CUSTOMER_REJECTED'

export const CHANGE_CUSTOMER = 'CHANGE_CUSTOMER'
export const ADD_CUSTOMER = 'ADD_CUSTOMER'

export function changeCustomer(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_CUSTOMER, payload: { name, value } })
  }
}

export function addCustomer() {
  return dispatch => {
    dispatch({ type: ADD_CUSTOMER });
  };
}

export function findAllCustomers(first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CUSTOMERS_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
                query findAllCustomers {
                  clientes (first: ${first} offset: ${offset}) {
                    codigo
                    status
                    matriz
                    razaoSocial
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CUSTOMERS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { clientes } = data
        dispatch({ type: FETCH_CUSTOMERS_FULFILLED, payload: clientes });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CUSTOMERS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAllCustomerByName(search = '', first = 30, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CUSTOMERS_PENDING });
    
    if (search.trim().length <= 2) {
      dispatch(findAllCustomers());
      return;
    }

    api({
      method: 'post',     
      data: {
        query: `
                query findAllCustomersByName {
                  clientesPorNome (search: "${search}" first: ${first} offset: ${offset}) {
                    codigo
                    status
                    matriz
                    razaoSocial
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CUSTOMERS_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { clientesPorNome } = data
        dispatch({ type: FETCH_CUSTOMERS_FULFILLED, payload: clientesPorNome });
        resolve(clientesPorNome);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CUSTOMERS_REJECTED, error });
      reject(error);
    });

  });

}

export function findCustomer(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CUSTOMER_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                query findCustomer {
                  cliente (codigo: ${codigo}) {
                    codigo
                    status
                    matriz
                    razaoSocial
                    endereco
                    cep
                    bairro
                    complemento
                    estado
                    cidade
                    cpfCnpj
                    telefone
                    celular
                    nomeContato
                    celularContato
                    emailContato
                    emailsEventos
                    clienteMatriz {
                        razaoSocial
                    }
                    municipio {
                      nome
                    }
                    pontoExcessoVelocidadeSeco
                    pontoExcessoVelocidadeChuva
                    pontoExcessoRPM
                    pontoConducaoBanguela
                    pontoFreadaBrusca
                    pontoAceleracaoBruscaParado
                    pontoAceleracaoBruscaMovimento
                    pontoExcessoTemperatura
                  }
                }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CUSTOMER_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { cliente } = data
        dispatch({ type: FETCH_CUSTOMER_FULFILLED, payload: cliente });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CUSTOMER_REJECTED, error });
      resolve(error);
    });

  });

}

export function createCustomer(customer) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_CUSTOMER_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation createCustomer {
            createCustomer (input: {
              codigo: ${customer.codigo}
              status: ${customer.status}
              matriz: ${customer.matriz}
              razaoSocial: "${customer.razaoSocial}"
              endereco: "${customer.endereco}"
              cep: "${customer.cep}"
              bairro: "${customer.bairro}"
              complemento: "${customer.complemento}"
              estado: ${customer.estado}
              cidade: ${customer.cidade}
              cpfCnpj: "${customer.cpfCnpj}"
              telefone: "${customer.telefone}"
              celular: "${customer.celular}"
              nomeContato: "${customer.nomeContato}"
              celularContato: "${customer.celularContato}"
              emailContato: "${customer.emailContato}"
              emailsEventos: "${customer.emailsEventos}"
              pontoExcessoVelocidadeSeco: ${customer.pontoExcessoVelocidadeSeco}
              pontoExcessoVelocidadeChuva: ${customer.pontoExcessoVelocidadeChuva}
              pontoExcessoRPM: ${customer.pontoExcessoRPM}
              pontoConducaoBanguela: ${customer.pontoConducaoBanguela}
              pontoFreadaBrusca: ${customer.pontoFreadaBrusca}
              pontoAceleracaoBruscaParado: ${customer.pontoAceleracaoBruscaParado}
              pontoAceleracaoBruscaMovimento: ${customer.pontoAceleracaoBruscaMovimento}
              pontoExcessoTemperatura: ${customer.pontoExcessoTemperatura}
            } ) {
              codigo
              status
              matriz
              razaoSocial
              endereco
              cep
              bairro
              complemento
              estado
              cidade
              municipio {
                nome
              }
              cpfCnpj
              telefone
              celular
              nomeContato
              celularContato
              emailContato
              emailsEventos
              clienteMatriz {
                  razaoSocial
              }
              pontoExcessoVelocidadeSeco
              pontoExcessoVelocidadeChuva
              pontoExcessoRPM
              pontoConducaoBanguela
              pontoFreadaBrusca
              pontoAceleracaoBruscaParado
              pontoAceleracaoBruscaMovimento
              pontoExcessoTemperatura
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_CUSTOMER_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createCustomer } = data
        dispatch({ type: CREATE_CUSTOMER_FULFILLED, payload: createCustomer });
        dispatch({ type: SHOW_SAVED_TOAST_MESSAGE });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_CUSTOMER_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateCustomer(customer) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_CUSTOMER_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation UpdateCustomer {
            updateCustomer (input: {
              codigo: ${customer.codigo}
              status: ${customer.status}
              matriz: ${customer.matriz}
              razaoSocial: "${customer.razaoSocial}"
              endereco: "${customer.endereco}"
              cep: "${customer.cep}"
              bairro: "${customer.bairro}"
              complemento: "${customer.complemento}"
              estado: ${customer.estado}
              cidade: ${customer.cidade}
              cpfCnpj: "${customer.cpfCnpj}"
              telefone: "${customer.telefone}"
              celular: "${customer.celular}"
              nomeContato: "${customer.nomeContato}"
              celularContato: "${customer.celularContato}"
              emailContato: "${customer.emailContato}"
              emailsEventos: "${customer.emailsEventos}"
              pontoExcessoVelocidadeSeco: ${customer.pontoExcessoVelocidadeSeco}
              pontoExcessoVelocidadeChuva: ${customer.pontoExcessoVelocidadeChuva}
              pontoExcessoRPM: ${customer.pontoExcessoRPM}
              pontoConducaoBanguela: ${customer.pontoConducaoBanguela}
              pontoFreadaBrusca: ${customer.pontoFreadaBrusca}
              pontoAceleracaoBruscaParado: ${customer.pontoAceleracaoBruscaParado}
              pontoAceleracaoBruscaMovimento: ${customer.pontoAceleracaoBruscaMovimento}
              pontoExcessoTemperatura: ${customer.pontoExcessoTemperatura}
            } ) {
              codigo
              status
              matriz
              razaoSocial
              endereco
              cep
              bairro
              complemento
              estado
              cidade
              municipio {
                nome
              }
              cpfCnpj
              telefone
              celular
              nomeContato
              celularContato
              emailContato
              emailsEventos
              clienteMatriz {
                razaoSocial
              }
              pontoExcessoVelocidadeSeco
              pontoExcessoVelocidadeChuva
              pontoExcessoRPM
              pontoConducaoBanguela
              pontoFreadaBrusca
              pontoAceleracaoBruscaParado
              pontoAceleracaoBruscaMovimento
              pontoExcessoTemperatura
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_CUSTOMER_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateCustomer } = data
        dispatch({ type: UPDATE_CUSTOMER_FULFILLED, payload: updateCustomer });
        dispatch({ type: SHOW_SAVED_TOAST_MESSAGE });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_CUSTOMER_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
}

export function removeCustomer(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_CUSTOMER_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation RemoveCustomer {
            deleteCustomer (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_CUSTOMER_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteCustomer } = data
        dispatch({ type: DELETE_CUSTOMER_FULFILLED, payload: deleteCustomer });
        dispatch({ type: SHOW_REMOVED_TOAST_MESSAGE });
        dispatch(findAllCustomers());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_CUSTOMER_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
