import {

    FETCH_EVENTS_DRIVERS_PENDING,
    FETCH_EVENTS_DRIVERS_FULFILLED,
    FETCH_EVENTS_DRIVERS_REJECTED,

    DELETE_EVENTS_DRIVERS_PENDING,
    DELETE_EVENTS_DRIVERS_FULFILLED,
    DELETE_EVENTS_DRIVERS_REJECTED,

    CHANGE_EVENTS_DRIVERS

} from './eventsDriversPeriodActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfEventsDriversPeriod: [],
    eventsDriversPeriod: {
        matriz: 0,
        motorista: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function eventsDriversPeriodReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_EVENTS_DRIVERS:
            return Object.assign({}, state, {
                eventsDriversPeriod: {
                    ...state.eventsDriversPeriod,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_EVENTS_DRIVERS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfEventsDriversPeriod: initialState.listOfEventsDriversPeriod,
            })
        case FETCH_EVENTS_DRIVERS_FULFILLED:
            console.log(action.payload)
            return Object.assign({}, state, {
                loading: false,
                listOfEventsDriversPeriod: action.payload
            })
        case FETCH_EVENTS_DRIVERS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfEventsDriversPeriod: initialState.listOfEventsDriversPeriod,
                error: action.payload
            })

        case DELETE_EVENTS_DRIVERS_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_EVENTS_DRIVERS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_EVENTS_DRIVERS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}