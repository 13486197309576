import api from '../../../../api'

export const FETCH_IRREGULAR_DRIVERS_PENDING = 'FETCH_IRREGULAR_DRIVERS_PENDING'
export const FETCH_IRREGULAR_DRIVERS_FULFILLED = 'FETCH_IRREGULAR_DRIVERS_FULFILLED'
export const FETCH_IRREGULAR_DRIVERS_REJECTED = 'FETCH_IRREGULAR_DRIVERS_REJECTED'

export const DELETE_IRREGULAR_DRIVERS_PENDING = 'DELETE_IRREGULAR_DRIVERS_PENDING'
export const DELETE_IRREGULAR_DRIVERS_FULFILLED = 'DELETE_IRREGULAR_DRIVERS_FULFILLED'
export const DELETE_IRREGULAR_DRIVERS_REJECTED = 'DELETE_IRREGULAR_DRIVERS_REJECTED'

export const CHANGE_IRREGULAR_DRIVERS = 'CHANGE_IRREGULAR_DRIVERS'

export function changeIrregularDrivers(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_IRREGULAR_DRIVERS, payload: { name, value } })
  }
}

export function findAllIrregularDriverss(matriz = 0, startPeriod = "", 
  endPeriod = "", first = 12000, offset = 0) {
  
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_IRREGULAR_DRIVERS_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllIrregularDriverss {
                  motoristasIrregulares (
                    matriz: ${matriz}
                    startPeriod: "${startPeriod}"
                    endPeriod: "${endPeriod}"
                    first: ${first} offset: ${offset}) {
                      matriz
                      nome
                      apelido
                      endereco
                      complemento
                      estado
                      cidade
                      cpf
                      rg
                      matricula
                      celular
                      cnh
                      validadeCnh
                      clienteMatriz {
                        razaoSocial
                      }
                    }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_IRREGULAR_DRIVERS_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { motoristasIrregulares } = data
        dispatch({ type: FETCH_IRREGULAR_DRIVERS_FULFILLED, payload: motoristasIrregulares });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_IRREGULAR_DRIVERS_REJECTED, error });
      reject(error);
    });

  });

}
