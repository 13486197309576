import api from '../../../../../api'

export const CREATE_FORGOT_PENDING = 'CREATE_FORGOT_PENDING'
export const CREATE_FORGOT_FULFILLED = 'CREATE_FORGOT_FULFILLED'
export const CREATE_FORGOT_REJECTED = 'CREATE_FORGOT_REJECTED'

export const CHANGE_FORGOT = 'CHANGE_FORGOT'

export function changeForgot(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_FORGOT, payload: { name, value } })
  }
}

export function forgotPassword(email, login) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_FORGOT_PENDING });

    api({
      method: 'post',
      data: {
        query: `
          mutation ForgotPassword {
            forgotPassword ( email: "${email}" login: "${login}" )
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_FORGOT_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { forgotPassword } = data
        dispatch({ type: CREATE_FORGOT_FULFILLED, payload: forgotPassword });
        resolve(forgotPassword);
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_FORGOT_REJECTED, errors });
      resolve(false);
    });
  });
}

