import { SHOW_SAVED_TOAST_MESSAGE, SHOW_REMOVED_TOAST_MESSAGE } from '../../../../common/layout/components/header/store';
import api from '../../../../api';

export const FETCH_USERS_PENDING = 'FETCH_USERS_PENDING';
export const FETCH_USERS_FULFILLED = 'FETCH_USERS_FULFILLED';
export const FETCH_USERS_REJECTED = 'FETCH_USERS_REJECTED';

export const FETCH_USER_PENDING = 'FETCH_USER_PENDING';
export const FETCH_USER_FULFILLED = 'FETCH_USER_FULFILLED';
export const FETCH_USER_REJECTED = 'FETCH_USER_REJECTED';

export const CREATE_USER_PENDING = 'CREATE_USER_PENDING';
export const CREATE_USER_FULFILLED = 'CREATE_USER_FULFILLED';
export const CREATE_USER_REJECTED = 'CREATE_USER_REJECTED';

export const UPDATE_USER_PENDING = 'UPDATE_USER_PENDING';
export const UPDATE_USER_FULFILLED = 'UPDATE_USER_FULFILLED';
export const UPDATE_USER_REJECTED = 'UPDATE_USER_REJECTED';

export const UPDATE_PASSWORD_USER_PENDING = 'UPDATE_PASSWORD_USER_PENDING';
export const UPDATE_PASSWORD_USER_FULFILLED = 'UPDATE_PASSWORD_USER_FULFILLED';
export const UPDATE_PASSWORD_USER_REJECTED = 'UPDATE_PASSWORD_USER_REJECTED';

export const DELETE_USER_PENDING = 'DELETE_USER_PENDING';
export const DELETE_USER_FULFILLED = 'DELETE_USER_FULFILLED';
export const DELETE_USER_REJECTED = 'DELETE_USER_REJECTED';

export const CHANGE_USER = 'CHANGE_USER';
export const ADD_USER = 'ADD_USER';

export function changeUser(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_USER, payload: { name, value } })
  }
}
 
export function addUser() {
  return dispatch => {
    dispatch({ type: ADD_USER });
  };
}

export function findAllUsers(first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_USERS_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                query findAllUsers {
                  usuarios (first: ${first} offset: ${offset}) {
                    codigo
                    nome
                    login
                    validade
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_USERS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { usuarios } = data
        dispatch({ type: FETCH_USERS_FULFILLED, payload: usuarios });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_USERS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAllUsersByName(search = '') {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_USERS_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                query findAllUsersByName {
                  usuariosPorNome (search: "${search}") {
                    codigo
                    nome
                    login
                    validade
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_USERS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { usuariosPorNome } = data
        dispatch({ type: FETCH_USERS_FULFILLED, payload: usuariosPorNome });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_USERS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findUser(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_USER_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                    query findUser {
                      usuario (codigo: ${codigo}) {
                        codigo
                        matriz
                        status
                        perfil
                        nome
                        endereco
                        cep
                        bairro
                        complemento
                        estado
                        cidade
                        cpf
                        celular
                        matricula
                        validade
                        login
                        primeiroImei
                        segundoImei
                        email
                        clienteMatriz {
                          razaoSocial
                        }
                        municipio {
                          nome
                        }
                      }
                    }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_USER_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { usuario } = data
        dispatch({ type: FETCH_USER_FULFILLED, payload: usuario });
        resolve(usuario);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_USER_REJECTED, error });
      resolve(error);
    });

  });

}

export function createUser(usuario) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_USER_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation createUser {
            createUsuario (input: {
              codigo: ${usuario.codigo}
              matriz: ${usuario.matriz}
              status: ${usuario.status}
              perfil: ${usuario.perfil}
              nome: "${usuario.nome}"
              endereco: "${usuario.endereco}"
              cep: "${usuario.cep}"
              bairro: "${usuario.bairro}"
              complemento: "${usuario.complemento}"
              estado: ${usuario.estado}
              cidade: ${usuario.cidade}
              cpf: "${usuario.cpf}"
              celular: "${usuario.celular}"
              matricula: "${usuario.matricula}"
              validade: "${usuario.validade}"
              login: "${usuario.login}"
              senha: "${usuario.senha}"
              primeiroImei: "${usuario.primeiroImei}"
              segundoImei: "${usuario.segundoImei}"
              email: "${usuario.email}"
            } ) {
              codigo
              matriz
              status
              perfil
              nome
              endereco
              cep
              bairro
              complemento
              estado
              cidade
              cpf
              celular
              matricula
              validade
              login
              primeiroImei
              segundoImei
              email
              clienteMatriz {
                razaoSocial
              }
              municipio {
                nome
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_USER_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { createUsuario } = data
        dispatch({ type: CREATE_USER_FULFILLED, payload: createUsuario });
        dispatch({ type: SHOW_SAVED_TOAST_MESSAGE });
        dispatch(findAllUsers());
        resolve({ status: true, data: createUsuario });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_USER_REJECTED, errors });
      reject(errors);
    });
  });
}

export function updateUser(usuario) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_USER_PENDING });

    api({
      method: 'post',     
      data: {
        query: `
          mutation UpdateUser {
            updateUsuario (input: {
              codigo: ${usuario.codigo}
              matriz: ${usuario.matriz}
              status: ${usuario.status}
              perfil: ${usuario.perfil}
              nome: "${usuario.nome}"
              endereco: "${usuario.endereco}"
              cep: "${usuario.cep}"
              bairro: "${usuario.bairro}"
              complemento: "${usuario.complemento}"
              estado: ${usuario.estado}
              cidade: ${usuario.cidade}
              cpf: "${usuario.cpf}"
              celular: "${usuario.celular}"
              matricula: "${usuario.matricula}"
              validade: "${usuario.validade}"
              login: "${usuario.login}"
              senha: "${usuario.senha}"
              primeiroImei: "${usuario.primeiroImei}"
              segundoImei: "${usuario.segundoImei}"
              email: "${usuario.email}"
            } ) {
              codigo
              matriz
              status
              perfil
              nome
              endereco
              cep
              bairro
              complemento
              estado
              cidade
              cpf
              celular
              matricula
              validade
              login
              primeiroImei
              segundoImei
              email
              clienteMatriz {
                razaoSocial
              }
              municipio {
                nome
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_USER_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { updateUsuario } = data
        dispatch({ type: UPDATE_USER_FULFILLED, payload: updateUsuario });
        dispatch({ type: SHOW_SAVED_TOAST_MESSAGE });
        dispatch(findAllUsers());
        resolve({ status: true, data: updateUsuario });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_USER_REJECTED, errors });
      reject(errors);
    });
  });
} 

export function removeUser(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_USER_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation RemoveUser {
            deleteUsuario (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_USER_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { deleteUsuario } = data
        dispatch({ type: DELETE_USER_FULFILLED, payload: deleteUsuario });
        dispatch({ type: SHOW_REMOVED_TOAST_MESSAGE });
        dispatch(findAllUsers());
        resolve();
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_USER_REJECTED, errors });
      reject(errors);
    });
  });
}

export function updatePassword(id, oldPassword, newPassword) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_PASSWORD_USER_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation UpdatePassword {
            updatePassword(id: ${id} oldPassword: "${oldPassword}" newPassword: "${newPassword}")
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_PASSWORD_USER_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { updatePassword } = data
        dispatch({ type: UPDATE_PASSWORD_USER_FULFILLED, payload: updatePassword });
        resolve(updatePassword);
      }
    }).catch((error) => {
      dispatch({ type: UPDATE_PASSWORD_USER_REJECTED, error });
      reject(error);
    });
  });
}
