import api from '../../../../../api'

export const FETCH_TYPES_POINT_PENDING = 'FETCH_TYPES_POINT_PENDING'
export const FETCH_TYPES_POINT_FULFILLED = 'FETCH_TYPES_POINT_FULFILLED'
export const FETCH_TYPES_POINT_REJECTED = 'FETCH_TYPES_POINT_REJECTED'

export const FETCH_TYPE_POINT_PENDING = 'FETCH_TYPE_POINT_PENDING'
export const FETCH_TYPE_POINT_FULFILLED = 'FETCH_TYPE_POINT_FULFILLED'
export const FETCH_TYPE_POINT_REJECTED = 'FETCH_TYPE_POINT_REJECTED'

export const CREATE_TYPE_POINT_PENDING = 'CREATE_TYPE_POINT_PENDING'
export const CREATE_TYPE_POINT_FULFILLED = 'CREATE_TYPE_POINT_FULFILLED'
export const CREATE_TYPE_POINT_REJECTED = 'CREATE_TYPE_POINT_REJECTED'

export const UPDATE_TYPE_POINT_PENDING = 'UPDATE_TYPE_POINT_PENDING'
export const UPDATE_TYPE_POINT_FULFILLED = 'UPDATE_TYPE_POINT_FULFILLED'
export const UPDATE_TYPE_POINT_REJECTED = 'UPDATE_TYPE_POINT_REJECTED'

export const DELETE_TYPE_POINT_PENDING = 'DELETE_TYPE_POINT_PENDING'
export const DELETE_TYPE_POINT_FULFILLED = 'DELETE_TYPE_POINT_FULFILLED'
export const DELETE_TYPE_POINT_REJECTED = 'DELETE_TYPE_POINT_REJECTED'

export const CHANGE_TYPE_POINT = 'CHANGE_TYPE_POINT'

export const ADD_TYPE_POINT = 'ADD_TYPE_POINT'

export function changeTypePoint(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_TYPE_POINT, payload: { name, value } })
  }
}
 
export function addTypePoint() {
  return dispatch => {
    dispatch({ type: ADD_TYPE_POINT });
  };
}

export function findAllTypesPoint(first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_TYPES_POINT_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
                query findAllTypesPoint {
                  tiposPonto (first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_TYPES_POINT_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { tiposPonto } = data
        dispatch({ type: FETCH_TYPES_POINT_FULFILLED, payload: tiposPonto });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_TYPES_POINT_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAllTypesPointByDescription(search = '', first = 1000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_TYPES_POINT_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
                query findAllTypesPoint {
                  tiposPontoPorDescricao (search: "${search}" first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_TYPES_POINT_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { tiposPontoPorDescricao } = data
        dispatch({ type: FETCH_TYPES_POINT_FULFILLED, payload: tiposPontoPorDescricao });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_TYPES_POINT_REJECTED, error });
      resolve(error);
    });

  });

}

export function findTypePoint(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_TYPE_POINT_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                    query findTypePoint {
                      tipoPonto (codigo: ${codigo}) {
                        codigo
                        matriz
                        descricao
                        clienteMatriz {
                          codigo
                          razaoSocial
                        }
                      }
                    }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_TYPE_POINT_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { tipoPonto } = data
        dispatch({ type: FETCH_TYPE_POINT_FULFILLED, payload: tipoPonto });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_TYPE_POINT_REJECTED, error });
      resolve(error);
    });

  });

}

export function createTypePoint(grupo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_TYPE_POINT_PENDING });

    api({
      method: 'post',    
      data: {
        query: `
          mutation createTypePoint {
            createTipoPonto (input: {
              codigo: ${grupo.codigo}
              matriz: ${grupo.matriz}
              descricao: "${grupo.descricao}"
            } ) {
              codigo
              matriz
              descricao
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_TYPE_POINT_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createTipoPonto } = data
        dispatch({ type: CREATE_TYPE_POINT_FULFILLED, payload: createTipoPonto });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_TYPE_POINT_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateTypePoint(grupo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_TYPE_POINT_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation UpdateTypePoint {
            updateTipoPonto (input: {
              codigo: ${grupo.codigo}
              matriz: ${grupo.matriz}
              descricao: "${grupo.descricao}"
            } ) {
              codigo
              matriz
              descricao
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_TYPE_POINT_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateTipoPonto } = data
        dispatch({ type: UPDATE_TYPE_POINT_FULFILLED, payload: updateTipoPonto });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_TYPE_POINT_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
}

export function removeTypePoint(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_TYPE_POINT_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation RemoveTypePoint {
            deleteTipoPonto (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_TYPE_POINT_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteTipoPonto } = data
        dispatch({ type: DELETE_TYPE_POINT_FULFILLED, payload: deleteTipoPonto });
        dispatch(findAllTypesPoint());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_TYPE_POINT_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
