import api from '../../../../api'
import { toFloat } from '../../../../common';

export const FETCH_FUEL_SUPPLIES_PENDING = 'FETCH_FUEL_SUPPLIES_PENDING'
export const FETCH_FUEL_SUPPLIES_FULFILLED = 'FETCH_FUEL_SUPPLIES_FULFILLED'
export const FETCH_FUEL_SUPPLIES_REJECTED = 'FETCH_FUEL_SUPPLIES_REJECTED'

export const FETCH_FUEL_SUPPLY_PENDING = 'FETCH_FUEL_SUPPLY_PENDING'
export const FETCH_FUEL_SUPPLY_FULFILLED = 'FETCH_FUEL_SUPPLY_FULFILLED'
export const FETCH_FUEL_SUPPLY_REJECTED = 'FETCH_FUEL_SUPPLY_REJECTED'

export const CREATE_FUEL_SUPPLY_PENDING = 'CREATE_FUEL_SUPPLY_PENDING'
export const CREATE_FUEL_SUPPLY_FULFILLED = 'CREATE_FUEL_SUPPLY_FULFILLED'
export const CREATE_FUEL_SUPPLY_REJECTED = 'CREATE_FUEL_SUPPLY_REJECTED'

export const UPDATE_FUEL_SUPPLY_PENDING = 'UPDATE_FUEL_SUPPLY_PENDING'
export const UPDATE_FUEL_SUPPLY_FULFILLED = 'UPDATE_FUEL_SUPPLY_FULFILLED'
export const UPDATE_FUEL_SUPPLY_REJECTED = 'UPDATE_FUEL_SUPPLY_REJECTED'

export const DELETE_FUEL_SUPPLY_PENDING = 'DELETE_FUEL_SUPPLY_PENDING'
export const DELETE_FUEL_SUPPLY_FULFILLED = 'DELETE_FUEL_SUPPLY_FULFILLED'
export const DELETE_FUEL_SUPPLY_REJECTED = 'DELETE_FUEL_SUPPLY_REJECTED'

export const CHANGE_FUEL_SUPPLY = 'CHANGE_FUEL_SUPPLY'

export const ADD_FUEL_SUPPLY = 'ADD_FUEL_SUPPLY'

export function changeFuelSupply(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_FUEL_SUPPLY, payload: { name, value } })
  }
}
 
export function addFuelSupply() {
  return dispatch => {
    dispatch({ type: ADD_FUEL_SUPPLY });
  };
}

export function findAllFuelSupplies(first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_FUEL_SUPPLIES_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findAllFuelSupplies {
                  abastecimentos (first: ${first} offset: ${offset}) {
                    codigo
                    combustivel
                    dataMovimento
                    horaMovimento
                    odometro
                    quantidade
                    valorTotal
                    valorLitro
                    vale
                    observacoes
                    veiculoAbastecido {
                      codigo
                      placa
                      descricao
                    }
                    motoristaVeiculo {
                      codigo
                      nome
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_FUEL_SUPPLIES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { abastecimentos } = data
        dispatch({ type: FETCH_FUEL_SUPPLIES_FULFILLED, payload: abastecimentos });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_FUEL_SUPPLIES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findFuelSupply(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_FUEL_SUPPLY_PENDING });

    api({
      method: 'post',     
      data: {
        query: `
                    query findFuelSupply {
                      abastecimento (codigo: ${codigo}) {
                        codigo
                        matriz
                        descricao
                        clienteMatriz {
                          codigo
                          razaoSocial
                        }
                      }
                    }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_FUEL_SUPPLY_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { grupo } = data
        dispatch({ type: FETCH_FUEL_SUPPLY_FULFILLED, payload: grupo });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_FUEL_SUPPLY_REJECTED, error });
      resolve(error);
    });

  });

}

export function createFuelSupply(grupo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_FUEL_SUPPLY_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation createFuelSupply {
            createAbastecimento (input: {
              codigo: ${grupo.codigo}
              veiculo: ${grupo.veiculo}
              motorista: ${grupo.motorista}
              combustivel: ${grupo.combustivel}
              dataMovimento: "${grupo.dataMovimento}"
              horaMovimento: "${grupo.horaMovimento}"
              odometro: ${toFloat(grupo.odometro)}
              quantidade: ${toFloat(grupo.quantidade)}
              valorTotal: ${toFloat(grupo.valorTotal)}
              valorLitro: ${(toFloat(grupo.valorTotal) / toFloat(grupo.quantidade))}
              vale: "${grupo.vale}"
              observacoes: "${grupo.observacoes}"
            } ) {
              codigo
              veiculo
              motorista
              combustivel
              dataMovimento
              horaMovimento
              odometro
              quantidade
              valorTotal
              valorLitro
              vale
              observacoes
              veiculoAbastecido {
                codigo
                placa
                descricao
              }
              motoristaVeiculo {
                codigo
                nome
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_FUEL_SUPPLY_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createAbastecimento } = data
        dispatch({ type: CREATE_FUEL_SUPPLY_FULFILLED, payload: createAbastecimento });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_FUEL_SUPPLY_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateFuelSupply(grupo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_FUEL_SUPPLY_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation UpdateFuelSupply {
            updateAbastecimento (input: {
              codigo: ${grupo.codigo}
              matriz: ${grupo.matriz}
              descricao: "${grupo.descricao}"
            } ) {
              codigo
              matriz
              descricao
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_FUEL_SUPPLY_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateAbastecimento } = data
        dispatch({ type: UPDATE_FUEL_SUPPLY_FULFILLED, payload: updateAbastecimento });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_FUEL_SUPPLY_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
} 

export function removeFuelSupply(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_FUEL_SUPPLY_PENDING });

    api({
      method: 'post',     
      data: {
        query: `
          mutation RemoveFuelSupply {
            deleteAbastecimento (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_FUEL_SUPPLY_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteAbastecimento } = data
        dispatch({ type: DELETE_FUEL_SUPPLY_FULFILLED, payload: deleteAbastecimento });
        dispatch(findAllFuelSupplies());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_FUEL_SUPPLY_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
