 // logica para verificar se existem erros
 const verifyErrors = (field) => {
    let foundError = false;
    for(let error in field.validity) {
        // se não for customError
        // então verifica se tem erro
        if (field.validity[error] && !field.validity.valid ) {
            foundError = error
        }
    }
    return foundError;
  }

  const customMessage = (field, typeError) => {
    const messages = {
        selectone: {
          valueMissing: "Preencher este campo!"
        },
        text: {
            valueMissing: "Preencher este campo!"
        },
        email: {
            valueMissing: "Email é obrigatório",
            typeMismatch: "Preencher um email válido"
        }
    }
    return messages[field.type.replace("-","")][typeError]
  }

  const setCustomMessage = (field, message) => {
    const spanError = field.parentNode.parentNode.querySelector("span.error")
    if (spanError) {
      if (message) {
        spanError.classList.add("active")
        spanError.innerHTML = message
      } else {
        spanError.classList.remove("active")
        spanError.innerHTML = ""
      }
    }
  }

  const ValidateField = (field) => {
    return () => {
        const error = verifyErrors(field);
        if (error) {
            const message = customMessage(field, error);
            field.style.borderColor = "red"
            setCustomMessage(field, message)
        } else {
            field.style.borderColor = "green"
            setCustomMessage(field)
        }
    }
  }

  const customValidation = (event) => {
      const field = event.target
      const validation = ValidateField(field);
      validation();
  }

  const validateDocumentFields = (document) => {
    const fields = document.querySelectorAll("[required]");
    fields.forEach((field) => {
      field.addEventListener('invalid', (event) => { 
        // eliminar o bubble
        event.preventDefault();
        customValidation(event);
      });
      field.addEventListener("blur", customValidation);
    });
  }

  export default validateDocumentFields;