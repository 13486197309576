import api from '../../../../api'

export const FETCH_STOP_TIME_HISTORY_PENDING = 'FETCH_STOP_TIME_HISTORY_PENDING'
export const FETCH_STOP_TIME_HISTORY_FULFILLED = 'FETCH_STOP_TIME_HISTORY_FULFILLED'
export const FETCH_STOP_TIME_HISTORY_REJECTED = 'FETCH_STOP_TIME_HISTORY_REJECTED'

export const CHANGE_STOP_TIME_HISTORY = 'CHANGE_STOP_TIME_HISTORY'

export function changeStopTimeHistory(name, value) {
  return dispatch => {
    dispatch({ 
      type: CHANGE_STOP_TIME_HISTORY, 
      payload: { name, value } 
    })
  }
}

export function findAllStopTimeHistorys(filter) {
  
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_STOP_TIME_HISTORY_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllStopTimeHistorys {
                  historicoTempoParado (
                    input: {
                      veiculo: ${filter.veiculo}
                      dataHoraInicial: "${filter.dataHoraInicial}"
                      dataHoraFinal: "${filter.dataHoraFinal}"
                    }) {
                      dataHoraDesligado
                      dataHoraLigado
                      pontoProximo
                      pontoEndereco
                      latitude
                      longitude
                      tempoParado
                    }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_STOP_TIME_HISTORY_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { historicoTempoParado } = data
        dispatch({ type: FETCH_STOP_TIME_HISTORY_FULFILLED, payload: historicoTempoParado });
        resolve(historicoTempoParado);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_STOP_TIME_HISTORY_REJECTED, error });
      reject(error);
    });

  });

}
