import { SHOW_SAVED_TOAST_MESSAGE, SHOW_REMOVED_TOAST_MESSAGE } from '../../../../../common/layout/components/header/store';
import api from '../../../../../api';

export const FETCH_GARAGES_PENDING = 'FETCH_GARAGES_PENDING';
export const FETCH_GARAGES_FULFILLED = 'FETCH_GARAGES_FULFILLED';
export const FETCH_GARAGES_REJECTED = 'FETCH_GARAGES_REJECTED';

export const FETCH_GARAGE_PENDING = 'FETCH_GARAGE_PENDING';
export const FETCH_GARAGE_FULFILLED = 'FETCH_GARAGE_FULFILLED';
export const FETCH_GARAGE_REJECTED = 'FETCH_GARAGE_REJECTED';

export const CREATE_GARAGE_PENDING = 'CREATE_GARAGE_PENDING';
export const CREATE_GARAGE_FULFILLED = 'CREATE_GARAGE_FULFILLED';
export const CREATE_GARAGE_REJECTED = 'CREATE_GARAGE_REJECTED';

export const UPDATE_GARAGE_PENDING = 'UPDATE_GARAGE_PENDING';
export const UPDATE_GARAGE_FULFILLED = 'UPDATE_GARAGE_FULFILLED';
export const UPDATE_GARAGE_REJECTED = 'UPDATE_GARAGE_REJECTED';

export const DELETE_GARAGE_PENDING = 'DELETE_GARAGE_PENDING';
export const DELETE_GARAGE_FULFILLED = 'DELETE_GARAGE_FULFILLED';
export const DELETE_GARAGE_REJECTED = 'DELETE_GARAGE_REJECTED';

export const CHANGE_GARAGE = 'CHANGE_GARAGE';
export const ADD_GARAGE = 'ADD_GARAGE';

export function changeGarage(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_GARAGE, payload: { name, value } })
  }
}

export function addGarage() {
  return dispatch => {
    dispatch({ type: ADD_GARAGE });
  };
}

export function findAllGaragesByDescription(search = '') {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_GARAGES_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllGaragesByDescription {
                  garagensPorDescricao (search: "${search}") {
                    codigo
                    descricao
                    apelido
                    telefone
                    nomeContato
                    celularContato
                    emailContato
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_GARAGES_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { garagensPorDescricao } = data
        dispatch({ type: FETCH_GARAGES_FULFILLED, payload: garagensPorDescricao });
        resolve(garagensPorDescricao);
      }
    }).catch((errors) => {
      dispatch({ type: FETCH_GARAGES_REJECTED, errors });
      reject(errors);
    });

  });

}

export function findAllGarages(first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_GARAGES_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllGarages {
                  garagens (first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                    apelido
                    telefone
                    nomeContato
                    celularContato
                    emailContato
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_GARAGES_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { garagens } = data
        dispatch({ type: FETCH_GARAGES_FULFILLED, payload: garagens });
        resolve(data);
      }
    }).catch((errors) => {
      dispatch({ type: FETCH_GARAGES_REJECTED, errors });
      reject(errors);
    });

  });

}

export function findGarage(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_GARAGE_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                    query findGarage {
                      garagem (codigo: ${codigo}) {
                        codigo
                        matriz
                        descricao
                        apelido
                        endereco
                        cep
                        bairro
                        complemento
                        estado
                        cidade
                        telefone
                        nomeContato
                        celularContato
                        emailContato
                        areaLocalizacao
                        eventoEntrada
                        eventoSaida
                        municipio {
                          codigo
                          nome
                        }
                        clienteMatriz {
                          codigo
                          razaoSocial
                        }
                      }
                    }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_GARAGE_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { garagem } = data
        dispatch({ type: FETCH_GARAGE_FULFILLED, payload: garagem });
        resolve(data);
      }
    }).catch((errors) => {
      dispatch({ type: FETCH_GARAGE_REJECTED, errors });
      reject(errors);
    });

  });

}

export function createGarage(garage) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_GARAGE_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation createGarage {
            createGaragem (input: {
              codigo: ${garage.codigo}
              matriz: ${garage.matriz}
              descricao: "${garage.descricao}"
              apelido: "${garage.apelido}"
              endereco: "${garage.endereco}"
              cep: "${garage.cep}"
              bairro: "${garage.bairro}"
              complemento: "${garage.complemento}"
              estado: ${garage.estado}
              cidade: ${garage.cidade}
              telefone: "${garage.telefone}"
              nomeContato: "${garage.nomeContato}"
              celularContato: "${garage.celularContato}"
              emailContato: "${garage.emailContato}"
              areaLocalizacao: ${garage.areaLocalizacao}
              eventoEntrada: ${garage.eventoEntrada}
              eventoSaida: ${garage.eventoSaida}
            } ) {
              codigo
              matriz
              descricao
              apelido
              endereco
              cep
              bairro
              complemento
              estado
              cidade
              telefone
              nomeContato
              celularContato
              emailContato
              areaLocalizacao
              eventoEntrada
              eventoSaida
              municipio {
                codigo
                nome
              }
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_GARAGE_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { createGaragem } = data
        dispatch({ type: CREATE_GARAGE_FULFILLED, payload: createGaragem });
        dispatch({ type: SHOW_SAVED_TOAST_MESSAGE });
        dispatch(findAllGarages());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_GARAGE_REJECTED, errors });
      reject(errors);
    });
  });
}

export function updateGarage(garage) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_GARAGE_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation UpdateGarage {
            updateGaragem (input: {
              codigo: ${garage.codigo}
              matriz: ${garage.matriz}
              descricao: "${garage.descricao}"
              apelido: "${garage.apelido}"
              endereco: "${garage.endereco}"
              cep: "${garage.cep}"
              bairro: "${garage.bairro}"
              complemento: "${garage.complemento}"
              estado: ${garage.estado}
              cidade: ${garage.cidade}
              telefone: "${garage.telefone}"
              nomeContato: "${garage.nomeContato}"
              celularContato: "${garage.celularContato}"
              emailContato: "${garage.emailContato}"
              areaLocalizacao: ${garage.areaLocalizacao}
              eventoEntrada: ${garage.eventoEntrada}
              eventoSaida: ${garage.eventoSaida}
            } ) {
              codigo
              matriz
              descricao
              apelido
              endereco
              cep
              bairro
              complemento
              estado
              cidade
              telefone
              nomeContato
              celularContato
              emailContato
              areaLocalizacao
              eventoEntrada
              eventoSaida
              municipio {
                codigo
                nome
              }
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_GARAGE_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { updateGaragem } = data
        dispatch({ type: UPDATE_GARAGE_FULFILLED, payload: updateGaragem });
        dispatch({ type: SHOW_SAVED_TOAST_MESSAGE });
        dispatch(findAllGarages());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_GARAGE_REJECTED, errors });
      reject(errors);
    });
  });
}

export function removeGarage(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_GARAGE_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation RemoveGarage {
            deleteGaragem (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_GARAGE_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { deleteGaragem } = data
        dispatch({ type: DELETE_GARAGE_FULFILLED, payload: deleteGaragem });
        dispatch({ type: SHOW_REMOVED_TOAST_MESSAGE });
        dispatch(findAllGarages());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_GARAGE_REJECTED, errors });
      reject(errors);
    });
  });
}
