import api from '../../../../api'

export const FETCH_KNOWN_POINTS_PENDING = 'FETCH_KNOWN_POINTS_PENDING'
export const FETCH_KNOWN_POINTS_FULFILLED = 'FETCH_KNOWN_POINTS_FULFILLED'
export const FETCH_KNOWN_POINTS_REJECTED = 'FETCH_KNOWN_POINTS_REJECTED'

export const FETCH_KNOWN_POINT_PENDING = 'FETCH_KNOWN_POINT_PENDING'
export const FETCH_KNOWN_POINT_FULFILLED = 'FETCH_KNOWN_POINT_FULFILLED'
export const FETCH_KNOWN_POINT_REJECTED = 'FETCH_KNOWN_POINT_REJECTED'

export const CREATE_KNOWN_POINT_PENDING = 'CREATE_KNOWN_POINT_PENDING'
export const CREATE_KNOWN_POINT_FULFILLED = 'CREATE_KNOWN_POINT_FULFILLED'
export const CREATE_KNOWN_POINT_REJECTED = 'CREATE_KNOWN_POINT_REJECTED'

export const UPDATE_KNOWN_POINT_PENDING = 'UPDATE_KNOWN_POINT_PENDING'
export const UPDATE_KNOWN_POINT_FULFILLED = 'UPDATE_KNOWN_POINT_FULFILLED'
export const UPDATE_KNOWN_POINT_REJECTED = 'UPDATE_KNOWN_POINT_REJECTED'

export const DELETE_KNOWN_POINT_PENDING = 'DELETE_KNOWN_POINT_PENDING'
export const DELETE_KNOWN_POINT_FULFILLED = 'DELETE_KNOWN_POINT_FULFILLED'
export const DELETE_KNOWN_POINT_REJECTED = 'DELETE_KNOWN_POINT_REJECTED'

export const CHANGE_KNOWN_POINT = 'CHANGE_KNOWN_POINT'

export const ADD_KNOWN_POINT = 'ADD_KNOWN_POINT'

export function changeKnownPoint(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_KNOWN_POINT, payload: { name, value } })
  }
}
 
export function addKnownPoint() {
  return dispatch => {
    dispatch({ type: ADD_KNOWN_POINT });
  };
}

export function findAllKnownPoints(first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_KNOWN_POINTS_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findAllKnownPoints {
                  pontosConhecido (first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_KNOWN_POINTS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { pontosConhecido } = data
        dispatch({ type: FETCH_KNOWN_POINTS_FULFILLED, payload: pontosConhecido });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_KNOWN_POINTS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAllKnownPointsByDescription(search = '', first = 1000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_KNOWN_POINTS_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findAllKnownPoints {
                  pontosConhecidoPorDescricao (search: "${search}" first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_KNOWN_POINTS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { pontosConhecidoPorDescricao } = data
        dispatch({ type: FETCH_KNOWN_POINTS_FULFILLED, payload: pontosConhecidoPorDescricao });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_KNOWN_POINTS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findKnownPoint(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_KNOWN_POINT_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                    query findKnownPoint {
                      pontoConhecido (codigo: ${codigo}) {
                        codigo
                        matriz
                        descricao
                        tipoPonto
                        endereco
                        latitude
                        longitude
                        raio
                        pontoPublico
                        pontoProximo
                        eventoProximidade
                        clienteMatriz {
                          codigo
                          razaoSocial
                        }
                      }
                    }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_KNOWN_POINT_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { pontoConhecido } = data
        dispatch({ type: FETCH_KNOWN_POINT_FULFILLED, payload: pontoConhecido });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_KNOWN_POINT_REJECTED, error });
      resolve(error);
    });

  });

}

export function createKnownPoint(knownPoint) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_KNOWN_POINT_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation createKnownPoint {
            createPontoConhecido (input: {
              codigo: ${knownPoint.codigo}
              matriz: ${knownPoint.matriz}
              descricao: "${knownPoint.descricao}"
              tipoPonto: ${knownPoint.tipoPonto}
              endereco: "${knownPoint.endereco}"
              latitude: ${parseFloat(String(knownPoint.latitude).replace(",","."))}
              longitude: ${parseFloat(String(knownPoint.longitude).replace(",","."))}
              raio: ${knownPoint.raio}
              pontoPublico: ${knownPoint.pontoPublico}
              pontoProximo: ${knownPoint.pontoProximo}
              eventoProximidade: ${knownPoint.eventoProximidade}
            } ) {
              codigo
              matriz
              descricao
              tipoPonto
              endereco
              latitude
              longitude
              raio
              pontoPublico
              pontoProximo
              eventoProximidade
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_KNOWN_POINT_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createPontoConhecido } = data
        dispatch({ type: CREATE_KNOWN_POINT_FULFILLED, payload: createPontoConhecido });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_KNOWN_POINT_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateKnownPoint(knownPoint) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_KNOWN_POINT_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation UpdateKnownPoint {
            updatePontoConhecido (input: {
              codigo: ${knownPoint.codigo}
              matriz: ${knownPoint.matriz}
              descricao: "${knownPoint.descricao}"
              tipoPonto: ${knownPoint.tipoPonto}
              endereco: "${knownPoint.endereco}"
              latitude: ${parseFloat(String(knownPoint.latitude).replace(",","."))}
              longitude: ${parseFloat(String(knownPoint.longitude).replace(",","."))}
              raio: ${knownPoint.raio}
              pontoPublico: ${knownPoint.pontoPublico}
              pontoProximo: ${knownPoint.pontoProximo}
              eventoProximidade: ${knownPoint.eventoProximidade}
            } ) {
              codigo
              matriz
              descricao
              tipoPonto
              endereco
              latitude
              longitude
              raio
              pontoPublico
              pontoProximo
              eventoProximidade
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_KNOWN_POINT_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updatePontoConhecido } = data
        dispatch({ type: UPDATE_KNOWN_POINT_FULFILLED, payload: updatePontoConhecido });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_KNOWN_POINT_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
} 

export function removeKnownPoint(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_KNOWN_POINT_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation RemoveKnownPoint {
            deletePontoConhecido (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_KNOWN_POINT_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deletePontoConhecido } = data
        dispatch({ type: DELETE_KNOWN_POINT_FULFILLED, payload: deletePontoConhecido });
        dispatch(findAllKnownPoints());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_KNOWN_POINT_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
