import api from '../../../../api'

export const FETCH_ECONOMIC_USE_PENDING = 'FETCH_ECONOMIC_USE_PENDING'
export const FETCH_ECONOMIC_USE_FULFILLED = 'FETCH_ECONOMIC_USE_FULFILLED'
export const FETCH_ECONOMIC_USE_REJECTED = 'FETCH_ECONOMIC_USE_REJECTED'

export const CHANGE_ECONOMIC_USE = 'CHANGE_ECONOMIC_USE'

export const DEFAULT_ECONOMIC_USE = 'DEFAULT_ECONOMIC_USE'

export function defaultEconomicUse() { 
  return dispatch => {
    dispatch({ type: DEFAULT_ECONOMIC_USE })
  }
}

export function changeEconomicUse(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_ECONOMIC_USE, payload: { name, value } })
  }
}

export function findAllEconomicUses(filter, first = 2000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_ECONOMIC_USE_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllEconomicUses {
                  graficoPosicoesEconometro (input: {
                    veiculo: ${filter.veiculo}
                    motorista: ${filter.motorista}
                    dataHoraInicial: "${filter.dataHoraInicial}"
                    dataHoraFinal: "${filter.dataHoraFinal}"
                  } first: ${first} offset: ${offset}) {
                    detalhe {
                      veiculo {
                        placa
                        descricao
                        clienteMatriz {
                          razaoSocial
                        }
                        veiculoGaragem {
                          descricao
                        }
                      }
                      modulo
                      dataHoraServidor
                      dataHoraGps
                      latitude
                      longitude
                      numeroLog
                      tipoEquipamento
                      codigoLog
                      dataHoraTelemetria
                      serialRfid
                      dataHoraInicial
                      dataHoraFinal
                      tempoIgnicaoLigada
                      tempoMotorLigado
                      tempoMarchaLenta
                      tempoRpmAbaixo
                      tempoRpmEconomica
                      tempoRpmAcima
                      tempoRpmSuperior
                      tempoRpmMaximo
                    }
                    totalizador {
                      tempoIgnicaoLigada
                      tempoMotorLigado
                      tempoMarchaLenta
                      tempoRpmAbaixo
                      tempoRpmEconomica
                      tempoRpmAcima
                      tempoRpmSuperior
                      tempoRpmMaximo
                      tempoEfetivo
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_ECONOMIC_USE_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { graficoPosicoesEconometro } = data
        dispatch({ type: FETCH_ECONOMIC_USE_FULFILLED, payload: graficoPosicoesEconometro[0] });
        resolve(graficoPosicoesEconometro[0]);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_ECONOMIC_USE_REJECTED, error });
      reject(error);
    });

  });

}
