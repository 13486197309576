import api from '../../../../api'

export const FETCH_EVENTS_PENDING = 'FETCH_EVENTS_PENDING'
export const FETCH_EVENTS_FULFILLED = 'FETCH_EVENTS_FULFILLED'
export const FETCH_EVENTS_REJECTED = 'FETCH_EVENTS_REJECTED'

export const FETCH_EVENT_PENDING = 'FETCH_EVENT_PENDING'
export const FETCH_EVENT_FULFILLED = 'FETCH_EVENT_FULFILLED'
export const FETCH_EVENT_REJECTED = 'FETCH_EVENT_REJECTED'

export const CREATE_EVENT_PENDING = 'CREATE_EVENT_PENDING'
export const CREATE_EVENT_FULFILLED = 'CREATE_EVENT_FULFILLED'
export const CREATE_EVENT_REJECTED = 'CREATE_EVENT_REJECTED'

export const UPDATE_EVENT_PENDING = 'UPDATE_EVENT_PENDING'
export const UPDATE_EVENT_FULFILLED = 'UPDATE_EVENT_FULFILLED'
export const UPDATE_EVENT_REJECTED = 'UPDATE_EVENT_REJECTED'

export const DELETE_EVENT_PENDING = 'DELETE_EVENT_PENDING'
export const DELETE_EVENT_FULFILLED = 'DELETE_EVENT_FULFILLED'
export const DELETE_EVENT_REJECTED = 'DELETE_EVENT_REJECTED'

export const CHANGE_EVENT = 'CHANGE_EVENT'
export const ADD_EVENT = 'ADD_EVENT'

export function changeEvent(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_EVENT, payload: { name, value } })
  }
}
 
export function addEvent() {
  return dispatch => {
    dispatch({ type: ADD_EVENT });
  };
}

export function findAllEvents(first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_EVENTS_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
                query findAllEvents {
                  eventos (first: ${first} offset: ${offset}) {
                    codigo
                    mensagem
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_EVENTS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { eventos } = data
        dispatch({ type: FETCH_EVENTS_FULFILLED, payload: eventos });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_EVENTS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findEvent(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_EVENT_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                    query findEvent {
                      evento (codigo: ${codigo}) {
                        codigo
                        matriz
                        mensagem
                        cliente
                        veiculo
                        tipoVeiculo
                        primeiraRegra
                        primeiroValor
                        segundaRegra
                        segundoValor
                        terceiraRegra
                        terceiroValor
                        quartaRegra
                        quartoValor
                        pontos
                        acao
                        cor
                        som
                        validadeInicial
                        validadeFinal
                        notificaEmail
                        notificaSms
                        notificaPush
                        baixaAutomatica
                        clienteMatriz {
                          razaoSocial
                        }
                        eventoVeiculo {
                          placa
                          descricao
                        }
                      }
                    }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_EVENT_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { evento } = data
        dispatch({ type: FETCH_EVENT_FULFILLED, payload: evento });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_EVENT_REJECTED, error });
      resolve(error);
    });

  });

}

export function createEvent(evento) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_EVENT_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation createEvent {
            createEvento (input: {
              codigo: ${evento.codigo}
              matriz: ${evento.matriz}
              mensagem: "${evento.mensagem}"
              cliente: ${evento.cliente}
              veiculo: ${evento.veiculo}
              tipoVeiculo: ${evento.tipoVeiculo}
              primeiraRegra: ${evento.primeiraRegra}
              primeiroValor: ${evento.primeiroValor}
              segundaRegra: ${evento.segundaRegra}
              segundoValor: ${evento.segundoValor}
              terceiraRegra: ${evento.terceiraRegra}
              terceiroValor: ${evento.terceiroValor}
              quartaRegra: ${evento.quartaRegra}
              quartoValor: ${evento.quartoValor}
              pontos: ${evento.pontos}
              acao: ${evento.acao}
              cor: "${evento.cor}"
              som: "${evento.som}"
              validadeInicial: "${evento.validadeInicial}"
              validadeFinal: "${evento.validadeFinal}"
              notificaEmail: ${evento.notificaEmail}
              notificaSms: ${evento.notificaSms}
              notificaPush: ${evento.notificaPush}
              baixaAutomatica: ${evento.baixaAutomatica}
        
            } ) {
              codigo
              matriz
              mensagem
              cliente
              veiculo
              tipoVeiculo
              primeiraRegra
              primeiroValor
              segundaRegra
              segundoValor
              terceiraRegra
              terceiroValor
              quartaRegra
              quartoValor
              pontos
              acao
              cor
              som
              validadeInicial
              validadeFinal
              notificaEmail
              notificaSms
              notificaPush
              baixaAutomatica
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_EVENT_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createEvento } = data
        dispatch({ type: CREATE_EVENT_FULFILLED, payload: createEvento });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_EVENT_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateEvent(evento) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_EVENT_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation UpdateEvent {
            updateEvento (input: {
              codigo: ${evento.codigo}
              matriz: ${evento.matriz}
              mensagem: "${evento.mensagem}"
              cliente: ${evento.cliente}
              veiculo: ${evento.veiculo}
              tipoVeiculo: ${evento.tipoVeiculo}
              primeiraRegra: ${evento.primeiraRegra}
              primeiroValor: ${evento.primeiroValor}
              segundaRegra: ${evento.segundaRegra}
              segundoValor: ${evento.segundoValor}
              terceiraRegra: ${evento.terceiraRegra}
              terceiroValor: ${evento.terceiroValor}
              quartaRegra: ${evento.quartaRegra}
              quartoValor: ${evento.quartoValor}
              pontos: ${evento.pontos}
              acao: ${evento.acao}
              cor: "${evento.cor}"
              som: "${evento.som}"
              validadeInicial: "${evento.validadeInicial}"
              validadeFinal: "${evento.validadeFinal}"
              notificaEmail: ${evento.notificaEmail}
              notificaSms: ${evento.notificaSms}
              notificaPush: ${evento.notificaPush}
              baixaAutomatica: ${evento.baixaAutomatica}
            } ) {
              codigo
              matriz
              mensagem
              cliente
              veiculo
              tipoVeiculo
              primeiraRegra
              primeiroValor
              segundaRegra
              segundoValor
              terceiraRegra
              terceiroValor
              quartaRegra
              quartoValor
              pontos
              acao
              cor
              som
              validadeInicial
              validadeFinal
              notificaEmail
              notificaSms
              notificaPush
              baixaAutomatica
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_EVENT_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateEvento } = data
        dispatch({ type: UPDATE_EVENT_FULFILLED, payload: updateEvento });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_EVENT_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
} 

export function removeEvent(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_EVENT_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation RemoveEvent {
            deleteEvento (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_EVENT_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteEvento } = data
        dispatch({ type: DELETE_EVENT_FULFILLED, payload: deleteEvento });
        dispatch(findAllEvents());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_EVENT_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
