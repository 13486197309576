import api from '../../../../api'

export const FETCH_SYNTHETIC_DETAILED_FLEET_PENDING = 'FETCH_SYNTHETIC_DETAILED_FLEET_PENDING'
export const FETCH_SYNTHETIC_DETAILED_FLEET_FULFILLED = 'FETCH_SYNTHETIC_DETAILED_FLEET_FULFILLED'
export const FETCH_SYNTHETIC_DETAILED_FLEET_REJECTED = 'FETCH_SYNTHETIC_DETAILED_FLEET_REJECTED'

export const DELETE_SYNTHETIC_DETAILED_FLEET_PENDING = 'DELETE_SYNTHETIC_DETAILED_FLEET_PENDING'
export const DELETE_SYNTHETIC_DETAILED_FLEET_FULFILLED = 'DELETE_SYNTHETIC_DETAILED_FLEET_FULFILLED'
export const DELETE_SYNTHETIC_DETAILED_FLEET_REJECTED = 'DELETE_SYNTHETIC_DETAILED_FLEET_REJECTED'

export const CHANGE_SYNTHETIC_DETAILED_FLEET = 'CHANGE_SYNTHETIC_DETAILED_FLEET'

export function changeSyntheticDetailedFleet(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_SYNTHETIC_DETAILED_FLEET, payload: { name, value } })
  }
}

export function findAllSyntheticDetailedFleets(filter, first = 12000, offset = 0) {
  
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SYNTHETIC_DETAILED_FLEET_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllSyntheticDetailedFleets {
                  reportSinteticoDetalhadoFrota (input: {
                    matriz: ${filter.matriz}
                    dataHoraInicial: "${filter.dataHoraInicial}"
                    dataHoraFinal: "${filter.dataHoraFinal}"
                  } first: ${first} offset: ${offset}) {
                    diaMes
                    veiculo
                    placa
                    distancia
                    tempoIgnicao
                    tempoMotor
                    tempoMarchaLenta
                    tempoMovimento
                    tempoChuva
                    tempoExcessoVelocidade
                    quantidadeEventosVelocidade
                    maximaVelocidadeSeco
                    maximaVelocidadeChuva
                    quantidadeEventosBanguela
                    maximaBanguela
                    tempoExcessoRPM
                    quantidadeEventosRPM
                    maximaRPM
                    quantidadeEventosTemperatura
                    maximaTemperatura
                    quantidadeEventosFreadaBrusca
                    maximaFreadaBrusca
                    quantidadeEventosAceleracaoParado
                    maximaAceleracaoParado
                    quantidadeEventosAceleracaoMovimento
                    maximaAceleracaoMovimento
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SYNTHETIC_DETAILED_FLEET_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { reportSinteticoDetalhadoFrota } = data
        dispatch({ type: FETCH_SYNTHETIC_DETAILED_FLEET_FULFILLED, payload: reportSinteticoDetalhadoFrota });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SYNTHETIC_DETAILED_FLEET_REJECTED, error });
      reject(error);
    });

  });

}
