import {

    FETCH_TRIPS_MADE_PENDING,
    FETCH_TRIPS_MADE_FULFILLED,
    FETCH_TRIPS_MADE_REJECTED,

    DELETE_TRIPS_MADE_PENDING,
    DELETE_TRIPS_MADE_FULFILLED,
    DELETE_TRIPS_MADE_REJECTED,

    CHANGE_TRIPS_MADE

} from './tripsMadeActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfTripsMade: [],
    tripsMade: {
        matriz: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function tripsMadeReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_TRIPS_MADE:
            return Object.assign({}, state, {
                tripsMade: {
                    ...state.tripsMade,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_TRIPS_MADE_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfTripsMade: [],
            })
        case FETCH_TRIPS_MADE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfTripsMade: action.payload
            })
        case FETCH_TRIPS_MADE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfTripsMade: [],
                error: action.payload
            })

        case DELETE_TRIPS_MADE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_TRIPS_MADE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_TRIPS_MADE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}