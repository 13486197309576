import {

    CREATE_FORGOT_PENDING,
    CREATE_FORGOT_FULFILLED,
    CREATE_FORGOT_REJECTED,
    
    CHANGE_FORGOT

} from './forgotActions'


const initialState = {
    loading: false,
    recovered: false,
    login: '',
    email: '',
    error: null,
}

export function forgotReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_FORGOT:
            
        let value = action.payload.value.toLowerCase();
        return Object.assign({}, state, { [action.payload.name]: value })

        case CREATE_FORGOT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })

        case CREATE_FORGOT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                recovered: action.payload
            })

        case CREATE_FORGOT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}