import api from '../../../../api'

export const FETCH_FLEET_USAGE_PENDING = 'FETCH_FLEET_USAGE_PENDING'
export const FETCH_FLEET_USAGE_FULFILLED = 'FETCH_FLEET_USAGE_FULFILLED'
export const FETCH_FLEET_USAGE_REJECTED = 'FETCH_FLEET_USAGE_REJECTED'

export const DELETE_FLEET_USAGE_PENDING = 'DELETE_FLEET_USAGE_PENDING'
export const DELETE_FLEET_USAGE_FULFILLED = 'DELETE_FLEET_USAGE_FULFILLED'
export const DELETE_FLEET_USAGE_REJECTED = 'DELETE_FLEET_USAGE_REJECTED'

export const CHANGE_FLEET_USAGE = 'CHANGE_FLEET_USAGE'

export function changeFleetUsage(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_FLEET_USAGE, payload: { name, value } })
  }
}

export function findAllFleetUsages(filter, first = 12000, offset = 0) {
  
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_FLEET_USAGE_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllFleetUsages {
                  historicoAbastecimentos (input: {
                    veiculo: ${filter.veiculo}
                    motorista: ${filter.motorista}
                    garagem: ${filter.garagem}
                    dataHoraInicial: "${filter.dataHoraInicial}"
                    dataHoraFinal: "${filter.dataHoraFinal}"
                  } first: ${first} offset: ${offset}) {
                    dataMovimento
                    veiculoAbastecido {
                      placa
                      descricao
                    }
                    odometro
                    motoristaVeiculo {
                      nome
                    }
                    combustivel
                    quantidade
                    valorLitro
                    valorTotal
                    vale
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_FLEET_USAGE_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { historicoAbastecimentos } = data
        dispatch({ type: FETCH_FLEET_USAGE_FULFILLED, payload: historicoAbastecimentos });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_FLEET_USAGE_REJECTED, error });
      reject(error);
    });

  });

}
