import {

    FETCH_COUNTRY_PENDING,
    FETCH_COUNTRY_FULFILLED,
    FETCH_COUNTRY_REJECTED,

    CLEAR_COUNTRY_FULFILLED,

} from './countryActions'

const initialState = {
    isFetchingCountry: false,
    listOfCountries: [],
    errorOfCountry: ''
}

export function countryReducer(state = initialState, action) {
    switch (action.type) {
        case CLEAR_COUNTRY_FULFILLED:
            return Object.assign({}, state, {
                listOfCountries: [],
            })
        case FETCH_COUNTRY_PENDING:
            return Object.assign({}, state, {
                isFetchingCountry: true,
                listOfCountries: [],
            })
        case FETCH_COUNTRY_FULFILLED:
            return Object.assign({}, state, {
                isFetchingCountry: false,
                listOfCountries: action.payload
            })
        case FETCH_COUNTRY_REJECTED:
            return Object.assign({}, state, {
                isFetchingCountry: false,
                listOfCountries: [],
                errorOfCountry: action.payload
            })
        default:
            return state
    }
}