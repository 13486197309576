import {

    FETCH_ECONOMIC_USE_PENDING,
    FETCH_ECONOMIC_USE_FULFILLED,
    FETCH_ECONOMIC_USE_REJECTED,

    CHANGE_ECONOMIC_USE,

    DEFAULT_ECONOMIC_USE,

} from './economicUseActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfEconomicUse: {
        detalhe: [],
        totalizador: {
            tempoIgnicaoLigada: 0,
            tempoMotorLigado: 0,
            tempoMarchaLenta: 0,
            tempoRpmAbaixo: 0,
            tempoRpmEconomica: 0,
            tempoRpmAcima: 0,
            tempoRpmSuperior: 0,
            tempoRpmMaximo: 0,
            tempoEfetivo: 0,
        }
    },
    economicUse: {
        veiculo: 0,
        motorista: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function economicUseReducer(state = initialState, action) {
    switch (action.type) {
        case DEFAULT_ECONOMIC_USE:
            return Object.assign({}, state, {
                ...initialState
            })
        case CHANGE_ECONOMIC_USE:
            return Object.assign({}, state, {
                economicUse: {
                    ...state.economicUse,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_ECONOMIC_USE_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfEconomicUse: initialState.listOfEconomicUse,
            })
        case FETCH_ECONOMIC_USE_FULFILLED:
            console.log(action.payload)
            return Object.assign({}, state, {
                loading: false,
                listOfEconomicUse: action.payload
            })
        case FETCH_ECONOMIC_USE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfEconomicUse: initialState.listOfEconomicUse,
                error: action.payload
            })

        default:
            return state
    }
}