import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const SyntheticDetailedVehicle = Loadable({
  loader: () => import("./syntheticDetailedVehicle/SyntheticDetailedVehicle"),
  loading: Loading
});

const SyntheticDetailedFleet = Loadable({
  loader: () => import("./syntheticDetailedFleet/SyntheticDetailedFleet"),
  loading: Loading
});

const SyntheticDetailedDriver = Loadable({
  loader: () => import("./syntheticDetailedDriver/SyntheticDetailedDriver"),
  loading: Loading
});

const FleetUsage = Loadable({
  loader: () => import("./fleetUsage/FleetUsage"),
  loading: Loading
});

const SpeedingEvents = Loadable({
  loader: () => import("./speedingEvents/SpeedingEvents"),
  loading: Loading
});

const TripsMade = Loadable({
  loader: () => import("./tripsMade/TripsMade"),
  loading: Loading
});

const TrackerTripsMade = Loadable({
  loader: () => import("./trackerTripsMade/TrackerTripsMade"),
  loading: Loading
});

const DriverScore = Loadable({
  loader: () => import("./driverScore/DriverScore"),
  loading: Loading
});

export const routes = [
  {
    path: "/reports/syntheticDetailedFleet",
    exact: true,
    component: SyntheticDetailedFleet,
    name: "Synthetic Detailed Fleet"
  },

  {
    path: "/reports/syntheticDetailedVehicle",
    exact: true,
    component: SyntheticDetailedVehicle,
    name: "Synthetic Detailed Vehicle"
  },

  {
    path: "/reports/syntheticDetailedDriver",
    exact: true,
    component: SyntheticDetailedDriver,
    name: "Synthetic Detailed Driver"
  },

  {
    path: "/reports/fleetUsage",
    exact: true,
    component: FleetUsage,
    name: "Fleet Usage"
  },

  {
    path: "/reports/speedingEvents",
    exact: true,
    component: SpeedingEvents,
    name: "Speending Events"
  },

  {
    path: "/reports/tripsMade",
    exact: true,
    component: TripsMade,
    name: "Trips Made"
  },

  {
    path: "/reports/trackerTripsMade",
    exact: true,
    component: TrackerTripsMade,
    name: "Tracker Trips Made"
  },

  {
    path: "/reports/driverScore",
    exact: true,
    component: DriverScore,
    name: "Driver Score"
  },
  
];
