import {

    FETCH_DRIVERS_PENDING,
    FETCH_DRIVERS_FULFILLED,
    FETCH_DRIVERS_REJECTED,

    FETCH_DRIVER_PENDING,
    FETCH_DRIVER_FULFILLED,
    FETCH_DRIVER_REJECTED,

    CREATE_DRIVER_PENDING,
    CREATE_DRIVER_FULFILLED,
    CREATE_DRIVER_REJECTED,

    UPDATE_DRIVER_PENDING,
    UPDATE_DRIVER_FULFILLED,
    UPDATE_DRIVER_REJECTED,

    DELETE_DRIVER_PENDING,
    DELETE_DRIVER_FULFILLED,
    DELETE_DRIVER_REJECTED,
    
    CHANGE_DRIVER,
    
    ADD_DRIVER

} from './driverActions'

const titleCase = (str) => {
    if (typeof str !== 'string') return str;
    return str.toLowerCase().split(' ').map(function(word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}

const initialState = {
    loading: false,
    listOfDrivers: [],
    driver: {
        codigo: 0,
        matriz: 0,
        nome: '',
        apelido: '',
        endereco: '',
        cep: '',
        bairro: '',
        complemento: '',
        estado: 0,
        cidade: 0,
        cpf: '',
        rg: '',
        matricula: '',
        celular: '',
        cnh: '',
        validadeCnh: ''
    },
    error: null,
}

export function driverReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_DRIVER: 
            let value = ""
                
            if (action.payload.name.includes('mail')) {
                value = action.payload.value.toLowerCase();
            } else 
                if (action.payload.name.includes('apelido')) {
                    value = action.payload.value;
                } else {
                    value = titleCase(action.payload.value);
                }

            return Object.assign({}, state, {
                driver: { ...state.driver, 
                    [action.payload.name]: value
                }
            })
        case ADD_DRIVER: 
            return Object.assign({}, state, {
                driver: { ...initialState.driver }
            })
        case FETCH_DRIVERS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfDrivers: [],
            })
        case FETCH_DRIVERS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfDrivers: action.payload
            })
        case FETCH_DRIVERS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_DRIVER_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //driver: { ...initialState.driver },
            })
        case CREATE_DRIVER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                driver: action.payload
            })
        case CREATE_DRIVER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_DRIVER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_DRIVER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                driver: action.payload
            })
        case UPDATE_DRIVER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_DRIVER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_DRIVER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                driver: action.payload
            })
        case FETCH_DRIVER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_DRIVER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_DRIVER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_DRIVER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}