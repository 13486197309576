import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import swal from 'sweetalert'
import * as TodoActions from "../TodoActions";

class TodoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      title: "",
      description: "",
      type: 0,
      archive: "",
    };
  }

  addTodo = () => {

    if (this.state.id > 0) {
      this.closeTodo();
      return;
    }

    if (!this.state.title) return;
    swal({
      closeOnClickOutside: false,
      text: "Confirma o Envio dos Dados?",
      //text: "Once deleted, you will not be able to recover this imaginary file!",
      //icon: "info",
      buttons: ['CANCELAR', 'CONFIRMAR'],
      dangerMode: false,
    })
      .then((response) => {
        if (response) {
          this.props.createTechnicalCall({ ...this.state })
            .then(() => {
              swal("Dados Gravados com Sucesso!", {
                icon: "success",
              });
              this.setState({
                id: 0,
                title: "",
                description: "",
                type: 0,
                archive: "",
              })
            });
          this.props.toggleNewTodo();
        }
      });

  };

  closeTodo = () => {
    if (!this.state.resolution) return;
    swal({
      closeOnClickOutside: false,
      title: "Confirma o Envio dos Dados?",
      //text: "Once deleted, you will not be able to recover this imaginary file!",
      //icon: "info",
      buttons: ['CANCELAR', 'CONFIRMAR'],
      dangerMode: false,
    })
      .then((response) => {
        if (response) {
          this.props.closeTechnicalCall({ ...this.state })
            .then(() => {
              swal("Dados Gravados com Sucesso!", {
                icon: "success",
              });
              this.setState({
                id: 0,
                title: "",
                description: "",
                type: 0,
                archive: "",
              })
            });
          this.props.toggleNewTodo();
        }
      });

  };

  onChange = (e) => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  };

  componentDidUpdate(prevProps) {
    if (prevProps.todo !== this.props.todo) {
      const { todo } = this.props
      this.setState({
        id: todo.codigo,
        title: todo.titulo,
        description: todo.descricao,
        type: todo.classificacao,
        archive: todo.aquivo,
      })
    }

  }

  render() {
    return (
      <form name="newTodoForm" className="smart-form">
        <fieldset>

          <section>
            <div className="col-xs-12">
              <label className="label">Título</label>
              <label className="input">
                <input
                  required
                  className="input-lg"
                  value={this.state.title}
                  onChange={this.onChange}
                  id="title" name="title"
                  placeholder="informe por favor o assunto do chamado..."
                />
              </label>
            </div>
          </section>

          <section>
            <div className="col-xs-12">
              <label className="label">Descrição</label>
              <label className="input">
                <textarea
                  rows={10}
                  cols={50}
                  required
                  className="input-lg"
                  style={{ minWidth: '100%' }}
                  value={this.state.description}
                  onChange={this.onChange}
                  id="description" name="description"
                  placeholder="descreva por favor o problema identificado..."
                  disabled={(this.state.id > 0)}
                />
              </label>
            </div>
          </section>

          {this.state.id > 0 &&
            <section>
              <div className="col-xs-12">
                <label className="label">Descrição</label>
                <label className="input">
                  <textarea
                    rows={10}
                    cols={50}
                    required
                    className="input-lg"
                    style={{ minWidth: '100%' }}
                    value={this.state.resolution}
                    onChange={this.onChange}
                    id="resolution" name="resolution"
                    placeholder="descreva por favor a solução para o problema..."
                  />
                </label>
              </div>
            </section>}

          <section>
            <div className="col-xs-3">
              <label className="label">Classificação</label>
              <label className="select">
                <select id="type" name="type" className="input-sm" value={this.state.type}
                  onChange={this.onChange} disabled={(this.state.id > 0)}
                >
                  <option value="1">Urgente</option>
                  <option value="0">Importante</option>
                </select>
              </label>
            </div>
            <div className="col-xs-1" />
            <div className="col-xs-8">
              <label className="label">Anexar documento</label>
              <label className="input">
                <input id="archive" name="archive" type="file" placeholder="endereco"
                  disabled={(this.state.id > 0)} />
              </label>
            </div>
          </section>
        </fieldset>
        <footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.addTodo}
          >
            CONFIRMAR
          </button>
          <button
            type="button"
            className="btn btn-default"
            onClick={this.props.toggleNewTodo}
          >
            CANCELAR
          </button>
        </footer>
      </form>
    );
  }
}

export default connect(
  store => store.todo,
  dispatch => bindActionCreators(TodoActions, dispatch)
)(TodoForm);
