import {

    FETCH_POSICOES_PENDING,
    FETCH_POSICOES_FULFILLED,
    FETCH_POSICOES_REJECTED,

    FETCH_TELEMETRY_POSITIONS_PENDING,
    FETCH_TELEMETRY_POSITIONS_FULFILLED,
    FETCH_TELEMETRY_POSITIONS_REJECTED,

    FETCH_GRID_TEMPERATURE_3_ZONES_PENDING,
    FETCH_GRID_TEMPERATURE_3_ZONES_FULFILLED,
    FETCH_GRID_TEMPERATURE_3_ZONES_REJECTED,

    FETCH_GRID_TEMPERATURE_UMIDITY_PENDING,
    FETCH_GRID_TEMPERATURE_UMIDITY_FULFILLED,
    FETCH_GRID_TEMPERATURE_UMIDITY_REJECTED,

    FETCH_GRID_CONTAINER_PENDING,
    FETCH_GRID_CONTAINER_FULFILLED,
    FETCH_GRID_CONTAINER_REJECTED,

} from './mapActions'

const initialState = {
    loading: false,
    listOfPositions: [],
    listOfTelemetryPositions: [],
    listOfTemperature3ZonesPositions: [],
    listOfTemperatureUmidityPositions: [],
    listOfContainerPositions: [],
}

export function mapReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_POSICOES_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_POSICOES_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfPositions: action.payload
            })
        case FETCH_POSICOES_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfPositions: []
            })

        case FETCH_TELEMETRY_POSITIONS_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_TELEMETRY_POSITIONS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfTelemetryPositions: action.payload
            })
        case FETCH_TELEMETRY_POSITIONS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfTelemetryPositions: []
            })

        case FETCH_GRID_TEMPERATURE_3_ZONES_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_GRID_TEMPERATURE_3_ZONES_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfTemperature3ZonesPositions: action.payload
            })
        case FETCH_GRID_TEMPERATURE_3_ZONES_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfTemperature3ZonesPositions: []
            })

        case FETCH_GRID_TEMPERATURE_UMIDITY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_GRID_TEMPERATURE_UMIDITY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfTemperatureUmidityPositions: action.payload
            })
        case FETCH_GRID_TEMPERATURE_UMIDITY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfTemperatureUmidityPositions: []
            })

        case FETCH_GRID_CONTAINER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_GRID_CONTAINER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfContainerPositions: action.payload
            })
        case FETCH_GRID_CONTAINER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfContainerPositions: []
            })
        default:
            return state
    }
}