import {

    FETCH_SUPPLIER_ACTIVITIES_PENDING,
    FETCH_SUPPLIER_ACTIVITIES_FULFILLED,
    FETCH_SUPPLIER_ACTIVITIES_REJECTED,

    FETCH_SUPPLIER_ACTIVITY_PENDING,
    FETCH_SUPPLIER_ACTIVITY_FULFILLED,
    FETCH_SUPPLIER_ACTIVITY_REJECTED,

    CREATE_SUPPLIER_ACTIVITY_PENDING,
    CREATE_SUPPLIER_ACTIVITY_FULFILLED,
    CREATE_SUPPLIER_ACTIVITY_REJECTED,

    UPDATE_SUPPLIER_ACTIVITY_PENDING,
    UPDATE_SUPPLIER_ACTIVITY_FULFILLED,
    UPDATE_SUPPLIER_ACTIVITY_REJECTED,

    DELETE_SUPPLIER_ACTIVITY_PENDING,
    DELETE_SUPPLIER_ACTIVITY_FULFILLED,
    DELETE_SUPPLIER_ACTIVITY_REJECTED,
    
    CHANGE_SUPPLIER_ACTIVITY,
    
    ADD_SUPPLIER_ACTIVITY

} from './supplierActivityActions'

const titleCase = (str) => {
    if (!str) return;
    return str.toLowerCase().split(' ').map(function(word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}

const initialState = {
    loading: false,
    listOfSupplierActivities: [],
    supplierActivity: {
        codigo: 0,
        descricao: '',
    },
    error: null,
}

export function supplierActivityReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_SUPPLIER_ACTIVITY: 
            let value = ""
                
            if (action.payload.name.includes('mail')) {
                value = action.payload.value.toLowerCase();
            } else 
                if (action.payload.name.includes('apelido')) {
                    value = action.payload.value;
                } else {
                    value = titleCase(action.payload.value);
                }

            return Object.assign({}, state, {
                supplierActivity: { ...state.supplierActivity, 
                    [action.payload.name]: value
                }
            })
        case ADD_SUPPLIER_ACTIVITY: 
            return Object.assign({}, state, {
                supplierActivity: { ...initialState.supplierActivity }
            })
        case FETCH_SUPPLIER_ACTIVITIES_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfSupplierActivities: [],
            })
        case FETCH_SUPPLIER_ACTIVITIES_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfSupplierActivities: action.payload
            })
        case FETCH_SUPPLIER_ACTIVITIES_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_SUPPLIER_ACTIVITY_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //supplierActivity: { ...initialState.supplierActivity },
            })
        case CREATE_SUPPLIER_ACTIVITY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                supplierActivity: action.payload
            })
        case CREATE_SUPPLIER_ACTIVITY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_SUPPLIER_ACTIVITY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_SUPPLIER_ACTIVITY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                supplierActivity: action.payload
            })
        case UPDATE_SUPPLIER_ACTIVITY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_SUPPLIER_ACTIVITY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_SUPPLIER_ACTIVITY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                supplierActivity: action.payload
            })
        case FETCH_SUPPLIER_ACTIVITY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_SUPPLIER_ACTIVITY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_SUPPLIER_ACTIVITY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_SUPPLIER_ACTIVITY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}