import {

    FETCH_GARAGES_PENDING,
    FETCH_GARAGES_FULFILLED,
    FETCH_GARAGES_REJECTED,

    FETCH_GARAGE_PENDING,
    FETCH_GARAGE_FULFILLED,
    FETCH_GARAGE_REJECTED,

    CREATE_GARAGE_PENDING,
    CREATE_GARAGE_FULFILLED,
    CREATE_GARAGE_REJECTED,

    UPDATE_GARAGE_PENDING,
    UPDATE_GARAGE_FULFILLED,
    UPDATE_GARAGE_REJECTED,

    DELETE_GARAGE_PENDING,
    DELETE_GARAGE_FULFILLED,
    DELETE_GARAGE_REJECTED,
    
    CHANGE_GARAGE,
    
    ADD_GARAGE

} from './garageActions'
import { titleCase } from '../../../../../common'

const initialState = {
    loading: false,
    listOfGarages: [],
    garage: {
        codigo: 0,
        matriz:0,
        descricao: '',
        apelido: '',
        endereco: '',
        cep: '',
        bairro: '',
        complemento: '',
        estado: '',
        cidade: '',
        telefone: '',
        nomeContato: '',
        celularContato: '',
        emailContato: '',
        areaLocalizacao: 0,
        eventoEntrada: false,
        eventoSaida: false,
        clienteMatriz: {
            codigo: 0,
            razaoSocial: '',
        }
    },
    error: null,
}

export function garageReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_GARAGE: 
            let value = ""
                
            if (action.payload.name.includes('mail')) {
                value = action.payload.value.toLowerCase();
            } else 
                if (action.payload.name.includes('apelido')) {
                    value = action.payload.value;
                } else {
                    value = titleCase(action.payload.value);
                }

            return Object.assign({}, state, {
                garage: { ...state.garage, 
                    [action.payload.name]: value
                }
            })
        case ADD_GARAGE: 
            return Object.assign({}, state, {
                garage: { ...initialState.garage }
            })
        case FETCH_GARAGES_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfGarages: [],
            })
        case FETCH_GARAGES_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfGarages: action.payload
            })
        case FETCH_GARAGES_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_GARAGE_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //garage: { ...initialState.garage },
            })
        case CREATE_GARAGE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                garage: action.payload
            })
        case CREATE_GARAGE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_GARAGE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_GARAGE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                garage: action.payload
            })
        case UPDATE_GARAGE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_GARAGE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_GARAGE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                garage: action.payload
            })
        case FETCH_GARAGE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_GARAGE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_GARAGE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_GARAGE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}