import {

    FETCH_VEHICLES_PENDING,
    FETCH_VEHICLES_FULFILLED,
    FETCH_VEHICLES_REJECTED,

    FETCH_VEHICLE_PENDING,
    FETCH_VEHICLE_FULFILLED,
    FETCH_VEHICLE_REJECTED,

    CREATE_VEHICLE_PENDING,
    CREATE_VEHICLE_FULFILLED,
    CREATE_VEHICLE_REJECTED,

    UPDATE_VEHICLE_PENDING,
    UPDATE_VEHICLE_FULFILLED,
    UPDATE_VEHICLE_REJECTED,

    DELETE_VEHICLE_PENDING,
    DELETE_VEHICLE_FULFILLED,
    DELETE_VEHICLE_REJECTED,

    FETCH_CARD_POSITIONS_PENDING,
    FETCH_CARD_POSITIONS_FULFILLED,
    FETCH_CARD_POSITIONS_REJECTED,
    
    CHANGE_VEHICLE,
    
    ADD_VEHICLE

} from './vehicleActions'

import { titleCase } from '../../../../common/utils'

const initialState = {
    loading: false,
    listOfVehicles: [],
    listOfCardPositions: [],
    vehicle: {
        codigo: 0,
        matriz: 0,
        tipoVeiculo: 0,
        perfilPortas: 0,
        equipamentoPrimario: 0,
        equipamentoSecundario: 0,
        garagem: 0,
        grupo: 0,
        placa: '',
        descricao: '',
        chassi: '',
        prefixo: '',
        marca: '',
        modelo: '',
        cor: '',
        fabricante: 0,
        anoFabricacao: 0,
        numeroFrota: 0,
        status: 0,
        observacoes: '',
        configuracoes: 0,
        capacidadeTanque: 0,
        tensaoVazio: 0,
        tensao1Quarto: 0,
        tensaoMeio: 0,
        tensao3Quartos: 0,
        tensaoCheio: 0,
        mediaConsumo: 0,
        leituraInversa: false,
        rpmMarchaLenta: 0,
        rpmEconomicaAbaixo: 0,
        rpmEconomica: 0,
        rpmEconomicaAcima: 0,
        rpmEconomicaMaximo: 0,
        tempoLogMinimo: 0,
        pulsosPorKm: 0,
        odometro: 0,
        velocidadeMinima: 0,
        velocidadeMaxima: 0,
        rpmMinimo: 0,
        rpmMaximo: 0,
        temperaturaMinima: 0,
        temperaturaMaxima: 0,
        analogicaMinima: 0,
        analogicaMaxima: 0,
        anoRelogio: 0,
        tempoLogEntradaUm: 0,
        tempoLogEntradaDois: 0,
        tempoLogEntradaTres: 0,
        tempoLogEntradaQuatro: 0,
        tempoLogEntradaCinco: 0,
        tempoLogEntradaSeis: 0,
        tempoLogEntradaSete: 0,
        coeficienteRpm: 0,
        velocidadeMaximaChuva: 0,
        frequencia100Km: 0,
        aceleracaoBruscaParado: 0,
        aceleracaoBruscaMovimento: 0,
        freadaBrusca: 0,
        velocidadeMinimaSaida: 0,
        velocidadeMaximaSaida: 0,
        rpmMinimoSaida: 0,
        rpmMaximoSaida: 0,
        temperaturaMinimaSaida: 0,
        temperaturaMaximaSaida: 0,
        analogicaMinimaSaida: 0,
        analogicaMaximaSaida: 0,
        entradaUmSaida: 0,
        entradaDoisSaida: 0,
        entradaTresSaida: 0,
        entradaQuatroSaida: 0,
        entradaCincoSaida: 0,
        entradaSeisSaida: 0,
        estadoNormalSaidas: 0,
        filtroLogs: 65535,
        tolBanguela: '00:00:03',
        tolVelocidadeSeco: '00:00:03',
        tolVelocidadeChuva: '00:00:03',
        tolExcessoRpm: '00:00:03',
        tolFreadaBrusca: 0,
        tolAceleracaoBruscaMovimento: 0,
        tolAceleracaoBruscaParado: 0,
        tolExcessoTemperatura: 0,
        tempoLeituraEntradaUm: '00:00:03',
        tempoLeituraEntradaDois: '00:00:03',
        tempoLeituraEntradaTres: '00:00:03',
        tempoLeituraEntradaQuatro: '00:00:03',
        tempoLeituraEntradaCinco: '00:00:03',
        tempoLeituraEntradaSeis: '00:00:03',
        tempoLeituraEntradaSete: '00:00:03',
        sensorTemperaturaMinima: 0,
        sensorTemperaturaMaxima: 0,
        sensorUmidadeMinima: 0,
        sensorUmidadeMaxima: 0,
        sensorTemperaturaMinimaUm: 0,
        sensorTemperaturaMaximaUm: 0,
        sensorTemperaturaMinimaDois: 0,
        sensorTemperaturaMaximaDois: 0,
        sensorTemperaturaMinimaTres: 0,
        sensorTemperaturaMaximaTres: 0,
        sensorTemperaturaMinimaQuatro: 0,
        sensorTemperaturaMaximaQuatro: 0,
        valorMensalidade: '0,00',
        valorChip: '0,00',
        versaoTelemetria: '',
        clienteMatriz: {
            codigo: 0,
            razaoSocial: '',
        }
    },
    error: null,
}

export function vehicleReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_VEHICLE: 
            let value = ""
            if (action.payload.name.includes('descricao')) {
                value = titleCase(action.payload.value);
            } else {
                value = action.payload.value;
            }

            return Object.assign({}, state, {
                vehicle: { ...state.vehicle, 
                    [action.payload.name]: value
                }
            })
        case ADD_VEHICLE: 
            return Object.assign({}, state, {
                vehicle: { ...initialState.vehicle }
            })
        case FETCH_VEHICLES_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfVehicles: [],
            })
        case FETCH_VEHICLES_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfVehicles: action.payload
            })
        case FETCH_VEHICLES_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_VEHICLE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case CREATE_VEHICLE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                vehicle: action.payload
            })
        case CREATE_VEHICLE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_VEHICLE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_VEHICLE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                vehicle: action.payload
            })
        case UPDATE_VEHICLE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_VEHICLE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_VEHICLE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                vehicle: action.payload
            })
        case FETCH_VEHICLE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_VEHICLE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_VEHICLE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_VEHICLE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_CARD_POSITIONS_PENDING:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_CARD_POSITIONS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfCardPositions: action.payload
            })

        case FETCH_CARD_POSITIONS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}