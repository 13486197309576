import {

    FETCH_ACCESS_COUNTER_HISTORY_PENDING,
    FETCH_ACCESS_COUNTER_HISTORY_FULFILLED,
    FETCH_ACCESS_COUNTER_HISTORY_REJECTED,

    DELETE_ACCESS_COUNTER_HISTORY_PENDING,
    DELETE_ACCESS_COUNTER_HISTORY_FULFILLED,
    DELETE_ACCESS_COUNTER_HISTORY_REJECTED,

    DEFAULT_ACCESS_COUNTER_HISTORY,

    CHANGE_ACCESS_COUNTER_HISTORY,

} from './accessCounterHistoryActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfAccessCounterHistory: [],
    accessCounterHistory: {
        veiculo: 0,
        motorista: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function accessCounterHistoryReducer(state = initialState, action) {
    switch (action.type) {
        case DEFAULT_ACCESS_COUNTER_HISTORY:
            console.log({
                ...initialState.accessCounterHistory
            })
            return Object.assign({}, state, {
                    ...initialState
            })
        case CHANGE_ACCESS_COUNTER_HISTORY:
            return Object.assign({}, state, {
                accessCounterHistory: {
                    ...state.accessCounterHistory,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_ACCESS_COUNTER_HISTORY_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfAccessCounterHistory: [],
            })
        case FETCH_ACCESS_COUNTER_HISTORY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfAccessCounterHistory: action.payload
            })
        case FETCH_ACCESS_COUNTER_HISTORY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfAccessCounterHistory: [],
                error: action.payload
            })

        case DELETE_ACCESS_COUNTER_HISTORY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_ACCESS_COUNTER_HISTORY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_ACCESS_COUNTER_HISTORY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}