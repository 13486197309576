import {

    FETCH_TECHNICALS_PENDING,
    FETCH_TECHNICALS_FULFILLED,
    FETCH_TECHNICALS_REJECTED,

    FETCH_TECHNICAL_PENDING,
    FETCH_TECHNICAL_FULFILLED,
    FETCH_TECHNICAL_REJECTED,

    POST_TECHNICAL_PENDING,
    POST_TECHNICAL_CREATED,
    POST_TECHNICAL_UPDATED,
    POST_TECHNICAL_REJECTED,

    CREATE_TECHNICAL,

    DATA_CHANGE_TECHNICAL,

} from './technicalActions'

const titleCase = (str) => {
    if ((!str) || (str.trim().length <= 0)) return;
    return str.toLowerCase().split(' ').map(function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}

const initialState = {
    loading: false,
    isUpdatingTechnical: false,
    listOfTechnicals: [],
    errorOfTechnical: '',
    technical: {
        codigo: 0,
        matriz: 0,
        nome: '',
        endereco: '',
        cep: '',
        bairro: '',
        complemento: '',
        estado: 0,
        cidade: 0,
        municipio: {
            codigo: 0,
            nome: ''
        },
        cpf: '',
        matricula: '',
        celular: '',
        clienteMatriz: {
            codigo: 0,
            razaoSocial: ''
        }
    }
}

export function technicalReducer(state = initialState, action) {
    switch (action.type) {
        case DATA_CHANGE_TECHNICAL:
            let value = ""
            
            if (action.payload.name.includes('mail')) {
                value = action.payload.value.toLowerCase();
            } else 
                if (action.payload.name.includes('apelido')) {
                    value = action.payload.value;
                } else {
                    value = titleCase(action.payload.value);
                }

            return Object.assign({}, state, {
                technical: { ...state.technical, 
                    [action.payload.name]: value
                }
            })
        case CREATE_TECHNICAL:
            return Object.assign({}, state, {
                technical: { ...initialState.technical }
            })
        case FETCH_TECHNICALS_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_TECHNICALS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfTechnicals: action.payload
            })
        case FETCH_TECHNICALS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                errorOfTechnical: action.payload
            })
        case FETCH_TECHNICAL_PENDING:
            return Object.assign({}, state, {
                isFetchingTechnical: true,
            })
        case FETCH_TECHNICAL_FULFILLED:
            return Object.assign({}, state, {
                isFetchingTechnical: false,
                technical: action.payload
            })
        case FETCH_TECHNICAL_REJECTED:
            return Object.assign({}, state, {
                isFetchingTechnical: false,
                errorOfTechnical: action.payload
            })
        case POST_TECHNICAL_PENDING:
            return Object.assign({}, state, {
                isUpdatingTechnical: true
            })
        case POST_TECHNICAL_CREATED:
            return Object.assign({}, state, {
                isUpdatingTechnical: false,
                technical: action.payload
            })
        case POST_TECHNICAL_UPDATED:
            return Object.assign({}, state, {
                isUpdatingTechnical: false,
                technical: action.payload
            })
        case POST_TECHNICAL_REJECTED:
            return Object.assign({}, state, {
                isUpdatingTechnical: false,
                errorOfTechnical: action.payload
            })
        default:
            return state
    }
}