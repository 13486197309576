import {

    FETCH_PERIPHERALS_PENDING,
    FETCH_PERIPHERALS_FULFILLED,
    FETCH_PERIPHERALS_REJECTED,

    FETCH_PERIPHERAL_PENDING,
    FETCH_PERIPHERAL_FULFILLED,
    FETCH_PERIPHERAL_REJECTED,

    CREATE_PERIPHERAL_PENDING,
    CREATE_PERIPHERAL_FULFILLED,
    CREATE_PERIPHERAL_REJECTED,

    UPDATE_PERIPHERAL_PENDING,
    UPDATE_PERIPHERAL_FULFILLED,
    UPDATE_PERIPHERAL_REJECTED,

    DELETE_PERIPHERAL_PENDING,
    DELETE_PERIPHERAL_FULFILLED,
    DELETE_PERIPHERAL_REJECTED,
    
    CHANGE_PERIPHERAL,
    
    ADD_PERIPHERAL

} from './peripheralActions'

import { titleCase } from '../../../../../common';


const initialState = {
    loading: false,
    listOfPeripherals: [],
    peripheral: {
        codigo: 0,
        matriz: 0,
        modelo: 0,
        firmware: 0,
        simCard: 0,
        serial: 0,
        controleInterno: 0,
        clienteMatriz: {
            codigo: 0,
            razaoSocial: '',
        }
    },
    error: null,
}

export function peripheralReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_PERIPHERAL: 
            return Object.assign({}, state, {
                peripheral: { ...state.peripheral, 
                    [action.payload.name]: titleCase(action.payload.value)
                }
            })
        case ADD_PERIPHERAL: 
            return Object.assign({}, state, {
                peripheral: { ...initialState.peripheral }
            })
        case FETCH_PERIPHERALS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfPeripherals: [],
            })
        case FETCH_PERIPHERALS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfPeripherals: action.payload
            })
        case FETCH_PERIPHERALS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_PERIPHERAL_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //peripheral: { ...initialState.peripheral },
            })
        case CREATE_PERIPHERAL_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                peripheral: action.payload
            })
        case CREATE_PERIPHERAL_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_PERIPHERAL_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_PERIPHERAL_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                peripheral: action.payload
            })
        case UPDATE_PERIPHERAL_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_PERIPHERAL_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_PERIPHERAL_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                peripheral: action.payload
            })
        case FETCH_PERIPHERAL_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_PERIPHERAL_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_PERIPHERAL_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_PERIPHERAL_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}