import { SHOW_ERROR_TOAST_MESSAGE } from '../../../../common/layout/components/header/store';
import api from '../../../../api'

export const FETCH_CONTAINER_USE_PENDING = 'FETCH_CONTAINER_USE_PENDING'
export const FETCH_CONTAINER_USE_FULFILLED = 'FETCH_CONTAINER_USE_FULFILLED'
export const FETCH_CONTAINER_USE_REJECTED = 'FETCH_CONTAINER_USE_REJECTED'

export const CHANGE_CONTAINER_USE = 'CHANGE_CONTAINER_USE'

export const DEFAULT_CONTAINER_USE = 'DEFAULT_CONTAINER_USE'

export function showMessageSegment() { 
  return dispatch => {
    dispatch({ type: SHOW_ERROR_TOAST_MESSAGE, payload: "ATENÇÃO!\nO Período contém erros na coleta de dados!" })
  }
}

export function defaultContainerUse() { 
  return dispatch => {
    dispatch({ type: DEFAULT_CONTAINER_USE })
  }
}
 
export function changeContainerUse(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_CONTAINER_USE, payload: { name, value } })
  }
}

export function findAllContainerUses(filter, first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CONTAINER_USE_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllContainerUses {
                  historicoPosicoesContainer (input: {
                    veiculo: ${filter.veiculo}
                    dataHoraInicial: "${filter.dataHoraInicial}"
                    dataHoraFinal: "${filter.dataHoraFinal}"
                  } first: ${first} offset: ${offset}) {
                    modulo
                    dataHoraServidor
                    dataHoraGps
                    latitude
                    longitude
                    tipoEquipamento
                    serial
                    valorSetpoint
                    valorSupply
                    valorReturn
                    valorHorimetro
                    valorAnalogico
                    valorCombustivel
                    entradaUm
                    entradaDois
                    entradaTres
                    entradaQuatro
                    saidaUm
                    saidaDois
                    saidaTres
                    saidaQuatro
                    tensaoInterna
                    tensaoExterna
                    serialDois
                    entradaCinco
                    entradaSeis
                    entradaSete
                    entradaOito
                    valorHorimetroDois
                    valorHorimetroTres
                    tensaoDisplay
                    tensaoBateria
                    temperaturaAgua
                    frequenciaRede
                    tensaoRede
                    correnteGerador
                    correnteUso
                    rpmMotor
                    statusErro
                    statusSpeed
                    ecuPower
                    ecuStatus
                    veiculo {
                      placa
                      descricao
                      clienteMatriz {
                        razaoSocial
                      }
                      veiculoGaragem {
                        descricao
                      }
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CONTAINER_USE_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { historicoPosicoesContainer } = data
        dispatch({ type: FETCH_CONTAINER_USE_FULFILLED, payload: historicoPosicoesContainer });
        resolve(historicoPosicoesContainer);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CONTAINER_USE_REJECTED, error });
      reject(error);
    });

  });

}
