import {

    FETCH_CONTAINER_USE_PENDING,
    FETCH_CONTAINER_USE_FULFILLED,
    FETCH_CONTAINER_USE_REJECTED,

    CHANGE_CONTAINER_USE,

    DEFAULT_CONTAINER_USE,

} from './containerUseActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfContainerUse: [],
    containerUse: {
        veiculo: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function containerUseReducer(state = initialState, action) {
    switch (action.type) {
        case DEFAULT_CONTAINER_USE:
            return Object.assign({}, state, {
                ...initialState
            })
        case CHANGE_CONTAINER_USE:
            return Object.assign({}, state, {
                containerUse: {
                    ...state.containerUse,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_CONTAINER_USE_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfContainerUse: initialState.listOfContainerUse,
            })
        case FETCH_CONTAINER_USE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfContainerUse: action.payload
            })
        case FETCH_CONTAINER_USE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfContainerUse: initialState.listOfContainerUse,
                error: action.payload
            })

        default:
            return state
    }
}