import api from '../../../../api'

export const FETCH_SUPPLIERS_PENDING = 'FETCH_SUPPLIERS_PENDING'
export const FETCH_SUPPLIERS_FULFILLED = 'FETCH_SUPPLIERS_FULFILLED'
export const FETCH_SUPPLIERS_REJECTED = 'FETCH_SUPPLIERS_REJECTED'

export const FETCH_SUPPLIER_PENDING = 'FETCH_SUPPLIER_PENDING'
export const FETCH_SUPPLIER_FULFILLED = 'FETCH_SUPPLIER_FULFILLED'
export const FETCH_SUPPLIER_REJECTED = 'FETCH_SUPPLIER_REJECTED'

export const CREATE_SUPPLIER_PENDING = 'CREATE_SUPPLIER_PENDING'
export const CREATE_SUPPLIER_FULFILLED = 'CREATE_SUPPLIER_FULFILLED'
export const CREATE_SUPPLIER_REJECTED = 'CREATE_SUPPLIER_REJECTED'

export const UPDATE_SUPPLIER_PENDING = 'UPDATE_SUPPLIER_PENDING'
export const UPDATE_SUPPLIER_FULFILLED = 'UPDATE_SUPPLIER_FULFILLED'
export const UPDATE_SUPPLIER_REJECTED = 'UPDATE_SUPPLIER_REJECTED'

export const DELETE_SUPPLIER_PENDING = 'DELETE_SUPPLIER_PENDING'
export const DELETE_SUPPLIER_FULFILLED = 'DELETE_SUPPLIER_FULFILLED'
export const DELETE_SUPPLIER_REJECTED = 'DELETE_SUPPLIER_REJECTED'

export const CHANGE_SUPPLIER = 'CHANGE_SUPPLIER'

export const ADD_SUPPLIER = 'ADD_SUPPLIER'

export function changeSupplier(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_SUPPLIER, payload: { name, value } })
  }
}

export function addSupplier() {
  return dispatch => {
    dispatch({ type: ADD_SUPPLIER });
  };
}

export function findAllSuppliers(first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SUPPLIERS_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllSuppliers {
                  fornecedores (first: ${first} offset: ${offset}) {
                    codigo
                    matriz
                    atividade
                    razaoSocial
                    ramoAtividade {
                      descricao
                    }
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SUPPLIERS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { fornecedores } = data
        dispatch({ type: FETCH_SUPPLIERS_FULFILLED, payload: fornecedores });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SUPPLIERS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAllSuppliersByName(search = '', first = 1000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SUPPLIERS_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllSuppliersByName {
                  fornecedoresPorNome (search: "${search}" first: ${first} offset: ${offset}) {
                    codigo
                    matriz
                    atividade
                    razaoSocial
                    ramoAtividade {
                      descricao
                    }
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SUPPLIERS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { fornecedoresPorNome } = data
        dispatch({ type: FETCH_SUPPLIERS_FULFILLED, payload: fornecedoresPorNome });
        resolve(fornecedoresPorNome);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SUPPLIERS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findSupplier(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SUPPLIER_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findSupplier {
                  fornecedor (codigo: ${codigo}) {
                    codigo
                    matriz
                    status
                    atividade
                    razaoSocial
                    endereco
                    cep
                    bairro
                    complemento
                    estado
                    cidade
                    cpfCnpj
                    telefone
                    celular
                    nomeContato
                    celularContato
                    emailContato
                    ramoAtividade {
                        descricao
                    }
                    clienteMatriz {
                        razaoSocial
                    }
                    municipio {
                      nome
                    }
                  }
                }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SUPPLIER_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { fornecedor } = data
        dispatch({ type: FETCH_SUPPLIER_FULFILLED, payload: fornecedor });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SUPPLIER_REJECTED, error });
      resolve(error);
    });

  });

}

export function createSupplier(supplier) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_SUPPLIER_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation createSupplier {
            createSupplier (input: {
              codigo: ${supplier.codigo}
              matriz: ${supplier.matriz}
              status: ${supplier.status}
              atividade: ${supplier.atividade}
              razaoSocial: "${supplier.razaoSocial}"
              endereco: "${supplier.endereco}"
              cep: "${supplier.cep}"
              bairro: "${supplier.bairro}"
              complemento: "${supplier.complemento}"
              estado: ${supplier.estado}
              cidade: ${supplier.cidade}
              cpfCnpj: "${supplier.cpfCnpj}"
              telefone: "${supplier.telefone}"
              celular: "${supplier.celular}"
              nomeContato: "${supplier.nomeContato}"
              celularContato: "${supplier.celularContato}"
              emailContato: "${supplier.emailContato}"
            } ) {
              codigo
              matriz
              status
              atividade
              razaoSocial
              endereco
              cep
              bairro
              complemento
              estado
              cidade
              cpfCnpj
              telefone
              celular
              nomeContato
              celularContato
              emailContato
              ramoAtividade {
                  descricao
              },
              clienteMatriz {
                  razaoSocial
              }
              municipio {
                nome
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_SUPPLIER_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createSupplier } = data
        dispatch({ type: CREATE_SUPPLIER_FULFILLED, payload: createSupplier });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_SUPPLIER_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateSupplier(supplier) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_SUPPLIER_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation UpdateSupplier {
            updateSupplier (input: {
              codigo: ${supplier.codigo}
              matriz: ${supplier.matriz}
              status: ${supplier.status}
              atividade: ${supplier.atividade}
              razaoSocial: "${supplier.razaoSocial}"
              endereco: "${supplier.endereco}"
              cep: "${supplier.cep}"
              bairro: "${supplier.bairro}"
              complemento: "${supplier.complemento}"
              estado: ${supplier.estado}
              cidade: ${supplier.cidade}
              cpfCnpj: "${supplier.cpfCnpj}"
              telefone: "${supplier.telefone}"
              celular: "${supplier.celular}"
              nomeContato: "${supplier.nomeContato}"
              celularContato: "${supplier.celularContato}"
              emailContato: "${supplier.emailContato}"
            } ) {
              codigo
              matriz
              status
              atividade
              razaoSocial
              endereco
              cep
              bairro
              complemento
              estado
              cidade
              cpfCnpj
              telefone
              celular
              nomeContato
              celularContato
              emailContato
              ramoAtividade {
                  descricao
              },
              clienteMatriz {
                  razaoSocial
              }
              municipio {
                nome
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_SUPPLIER_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateSupplier } = data
        dispatch({ type: UPDATE_SUPPLIER_FULFILLED, payload: updateSupplier });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_SUPPLIER_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
}

export function removeSupplier(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_SUPPLIER_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation RemoveSupplier {
            deleteSupplier (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_SUPPLIER_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteSupplier } = data
        dispatch({ type: DELETE_SUPPLIER_FULFILLED, payload: deleteSupplier });
        dispatch(findAllSuppliers());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_SUPPLIER_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
