import {

    FETCH_EXPENSE_HISTORY_PENDING,
    FETCH_EXPENSE_HISTORY_FULFILLED,
    FETCH_EXPENSE_HISTORY_REJECTED,

    DELETE_EXPENSE_HISTORY_PENDING,
    DELETE_EXPENSE_HISTORY_FULFILLED,
    DELETE_EXPENSE_HISTORY_REJECTED,

    CHANGE_EXPENSE_HISTORY

} from './expenseHistoryActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfExpenseHistory: [],
    expenseHistory: {
        matriz: 0,
        veiculo: 0,
        fornecedor: 0,
        fornecedor: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        dataFinal: moment().utc().format("DD/MM/YYYY"),
    },
    error: null,
}

export function expenseHistoryReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_EXPENSE_HISTORY:
            return Object.assign({}, state, {
                expenseHistory: {
                    ...state.expenseHistory,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_EXPENSE_HISTORY_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfExpenseHistory: [],
            })
        case FETCH_EXPENSE_HISTORY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfExpenseHistory: action.payload
            })
        case FETCH_EXPENSE_HISTORY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfExpenseHistory: [],
                error: action.payload
            })

        case DELETE_EXPENSE_HISTORY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_EXPENSE_HISTORY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_EXPENSE_HISTORY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}