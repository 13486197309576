import api from '../../../../api'

export const FETCH_VEHICLE_RESERVATIONS_PENDING = 'FETCH_VEHICLE_RESERVATIONS_PENDING'
export const FETCH_VEHICLE_RESERVATIONS_FULFILLED = 'FETCH_VEHICLE_RESERVATIONS_FULFILLED'
export const FETCH_VEHICLE_RESERVATIONS_REJECTED = 'FETCH_VEHICLE_RESERVATIONS_REJECTED'

export const FETCH_VEHICLE_RESERVATION_PENDING = 'FETCH_VEHICLE_RESERVATION_PENDING'
export const FETCH_VEHICLE_RESERVATION_FULFILLED = 'FETCH_VEHICLE_RESERVATION_FULFILLED'
export const FETCH_VEHICLE_RESERVATION_REJECTED = 'FETCH_VEHICLE_RESERVATION_REJECTED'

export const CREATE_VEHICLE_RESERVATION_PENDING = 'CREATE_VEHICLE_RESERVATION_PENDING'
export const CREATE_VEHICLE_RESERVATION_FULFILLED = 'CREATE_VEHICLE_RESERVATION_FULFILLED'
export const CREATE_VEHICLE_RESERVATION_REJECTED = 'CREATE_VEHICLE_RESERVATION_REJECTED'

export const UPDATE_VEHICLE_RESERVATION_PENDING = 'UPDATE_VEHICLE_RESERVATION_PENDING'
export const UPDATE_VEHICLE_RESERVATION_FULFILLED = 'UPDATE_VEHICLE_RESERVATION_FULFILLED'
export const UPDATE_VEHICLE_RESERVATION_REJECTED = 'UPDATE_VEHICLE_RESERVATION_REJECTED'

export const DELETE_VEHICLE_RESERVATION_PENDING = 'DELETE_VEHICLE_RESERVATION_PENDING'
export const DELETE_VEHICLE_RESERVATION_FULFILLED = 'DELETE_VEHICLE_RESERVATION_FULFILLED'
export const DELETE_VEHICLE_RESERVATION_REJECTED = 'DELETE_VEHICLE_RESERVATION_REJECTED'

export const CHANGE_VEHICLE_RESERVATION = 'CHANGE_VEHICLE_RESERVATION'

export const ADD_VEHICLE_RESERVATION = 'ADD_VEHICLE_RESERVATION'

export function changeVehicleReservation(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_VEHICLE_RESERVATION, payload: { name, value } })
  }
}
 
export function addVehicleReservation() {
  return dispatch => {
    dispatch({ type: ADD_VEHICLE_RESERVATION });
  };
}

export function findAllVehicleReservations(first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_VEHICLE_RESERVATIONS_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findAllVehicleReservations {
                  veiculoReservas (first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                    dataSaida
                    horaSaida
                    dataPrevisao
                    horaPrevisao
                    veiculoReserva {
                      placa
                      descricao
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_VEHICLE_RESERVATIONS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { veiculoReservas } = data
        dispatch({ type: FETCH_VEHICLE_RESERVATIONS_FULFILLED, payload: veiculoReservas });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_VEHICLE_RESERVATIONS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findVehicleReservation(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_VEHICLE_RESERVATION_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                    query findVehicleReservation {
                      veiculoReserva (codigo: ${codigo}) {
                        codigo
                        veiculo
                        motorista
                        motoristaReserva {
                          nome
                        }
                        descricao
                        dataSaida
                        horaSaida
                        dataPrevisao
                        horaPrevisao
                        observacoes
                        veiculoReserva {
                          placa
                          descricao
                        }
                      }
                    }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_VEHICLE_RESERVATION_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { veiculoReserva } = data
        dispatch({ type: FETCH_VEHICLE_RESERVATION_FULFILLED, payload: veiculoReserva });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_VEHICLE_RESERVATION_REJECTED, error });
      resolve(error);
    });

  });

}

export function createVehicleReservation(vehicleReservation) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_VEHICLE_RESERVATION_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation createVehicleReservation {
            createVeiculoReserva (input: {
              codigo: ${vehicleReservation.codigo}
              veiculo: ${vehicleReservation.veiculo}
              motorista: ${vehicleReservation.motorista}
              descricao: "${vehicleReservation.descricao}"
              dataSaida: "${vehicleReservation.dataSaida}"
              horaSaida: "${vehicleReservation.horaSaida}"
              dataPrevisao: "${vehicleReservation.dataPrevisao}"
              horaPrevisao: "${vehicleReservation.horaPrevisao}"
              observacoes: "${vehicleReservation.observacoes}"
            } ) {
              codigo
              veiculo
              motorista
              motoristaReserva {
                nome
              }
              descricao
              dataSaida
              horaSaida
              dataPrevisao
              horaPrevisao
              observacoes
              veiculoReserva {
                codigo
                placa
                descricao
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_VEHICLE_RESERVATION_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createVeiculoReserva } = data
        dispatch({ type: CREATE_VEHICLE_RESERVATION_FULFILLED, payload: createVeiculoReserva });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_VEHICLE_RESERVATION_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateVehicleReservation(vehicleReservation) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_VEHICLE_RESERVATION_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation UpdateVehicleReservation {
            updateVeiculoReserva (input: {
              codigo: ${vehicleReservation.codigo}
              veiculo: ${vehicleReservation.veiculo}
              motorista: ${vehicleReservation.motorista}
              descricao: "${vehicleReservation.descricao}"
              dataSaida: "${vehicleReservation.dataSaida}"
              horaSaida: "${vehicleReservation.horaSaida}"
              dataPrevisao: "${vehicleReservation.dataPrevisao}"
              horaPrevisao: "${vehicleReservation.horaPrevisao}"
              observacoes: "${vehicleReservation.observacoes}"
            } ) {
              codigo
              veiculo
              motorista
              motoristaReserva {
                nome
              }
              descricao
              dataSaida
              horaSaida
              dataPrevisao
              horaPrevisao
              observacoes
              veiculoReserva {
                codigo
                placa
                descricao
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_VEHICLE_RESERVATION_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateVeiculoReserva } = data
        dispatch({ type: UPDATE_VEHICLE_RESERVATION_FULFILLED, payload: updateVeiculoReserva });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_VEHICLE_RESERVATION_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
} 

export function removeVehicleReservation(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_VEHICLE_RESERVATION_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation RemoveVehicleReservation {
            deleteVeiculoReserva (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_VEHICLE_RESERVATION_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteVeiculoReserva } = data
        dispatch({ type: DELETE_VEHICLE_RESERVATION_FULFILLED, payload: deleteVeiculoReserva });
        dispatch(findAllVehicleReservations());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_VEHICLE_RESERVATION_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
