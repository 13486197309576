import {

    FETCH_TEMPERATURE_AND_UMIDITY_PENDING,
    FETCH_TEMPERATURE_AND_UMIDITY_FULFILLED,
    FETCH_TEMPERATURE_AND_UMIDITY_REJECTED,

    CHANGE_TEMPERATURE_AND_UMIDITY

} from './temperatureAndUmidityActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfTemperatureAndUmidity: [],
    temperatureAndUmidity: {
        veiculo: 0,
        motorista: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function temperatureAndUmidityReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_TEMPERATURE_AND_UMIDITY:
            return Object.assign({}, state, {
                temperatureAndUmidity: {
                    ...state.temperatureAndUmidity,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_TEMPERATURE_AND_UMIDITY_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfTemperatureAndUmidity: initialState.listOfTemperatureAndUmidity,
            })
        case FETCH_TEMPERATURE_AND_UMIDITY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfTemperatureAndUmidity: action.payload
            })
        case FETCH_TEMPERATURE_AND_UMIDITY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfTemperatureAndUmidity: initialState.listOfTemperatureAndUmidity,
                error: action.payload
            })

        default:
            return state
    }
}