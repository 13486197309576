import {

    FETCH_SYNTHETIC_DETAILED_VEHICLE_PENDING,
    FETCH_SYNTHETIC_DETAILED_VEHICLE_FULFILLED,
    FETCH_SYNTHETIC_DETAILED_VEHICLE_REJECTED,

    DELETE_SYNTHETIC_DETAILED_VEHICLE_PENDING,
    DELETE_SYNTHETIC_DETAILED_VEHICLE_FULFILLED,
    DELETE_SYNTHETIC_DETAILED_VEHICLE_REJECTED,

    CHANGE_SYNTHETIC_DETAILED_VEHICLE

} from './syntheticDetailedVehicleActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfSyntheticDetailedVehicle: [],
    syntheticDetailedVehicle: {
        veiculo: 0,
        garagem: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function syntheticDetailedVehicleReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_SYNTHETIC_DETAILED_VEHICLE:
            return Object.assign({}, state, {
                syntheticDetailedVehicle: {
                    ...state.syntheticDetailedVehicle,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_SYNTHETIC_DETAILED_VEHICLE_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfSyntheticDetailedVehicle: [],
            })
        case FETCH_SYNTHETIC_DETAILED_VEHICLE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfSyntheticDetailedVehicle: action.payload
            })
        case FETCH_SYNTHETIC_DETAILED_VEHICLE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfSyntheticDetailedVehicle: [],
                error: action.payload
            })

        case DELETE_SYNTHETIC_DETAILED_VEHICLE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_SYNTHETIC_DETAILED_VEHICLE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_SYNTHETIC_DETAILED_VEHICLE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}