import api from '../../../../api'

export const FETCH_TRIGGER_TIME_HISTORY_PENDING = 'FETCH_TRIGGER_TIME_HISTORY_PENDING'
export const FETCH_TRIGGER_TIME_HISTORY_FULFILLED = 'FETCH_TRIGGER_TIME_HISTORY_FULFILLED'
export const FETCH_TRIGGER_TIME_HISTORY_REJECTED = 'FETCH_TRIGGER_TIME_HISTORY_REJECTED'

export const DELETE_TRIGGER_TIME_HISTORY_PENDING = 'DELETE_TRIGGER_TIME_HISTORY_PENDING'
export const DELETE_TRIGGER_TIME_HISTORY_FULFILLED = 'DELETE_TRIGGER_TIME_HISTORY_FULFILLED'
export const DELETE_TRIGGER_TIME_HISTORY_REJECTED = 'DELETE_TRIGGER_TIME_HISTORY_REJECTED'

export const CHANGE_TRIGGER_TIME_HISTORY = 'CHANGE_TRIGGER_TIME_HISTORY'

export function changeTriggerTimeHistory(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_TRIGGER_TIME_HISTORY, payload: { name, value } })
  }
}

export function findAllTriggerTimeHistorys(filter, first = 12000, offset = 0) {
  
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_TRIGGER_TIME_HISTORY_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllTriggerTimeHistorys {
                  historicoPosicoesTempoAcionamento (input: {
                    veiculo: ${filter.veiculo}
                    motorista: ${filter.motorista}
                    dataHoraInicial: "${filter.dataHoraInicial}"
                    dataHoraFinal: "${filter.dataHoraFinal}"
                  } first: ${first} offset: ${offset}) {
                    veiculo {
                      placa
                      descricao
                      veiculoTipo {
                        ligadaIcon
                        desligadaIcon
                        panicoIcon
                        terminalIcon
                      }
                      clienteMatriz {
                        razaoSocial
                      }
                      veiculoGaragem {
                        descricao
                      }
                    }
                    motorista {
                      nome
                    }
                    modulo
                    dataHoraServidor
                    dataHoraGps
                    latitude
                    longitude
                    numeroLog
                    tipoEquipamento
                    codigoLog
                    dataHoraTelemetria
                    tempoAcionamentoUm
                    tempoAcionamentoDois
                    tempoAcionamentoTres
                    tempoAcionamentoQuatro
                    tempoAcionamentoCinco
                    tempoAcionamentoSeis
                    tempoAcionamentoSete
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_TRIGGER_TIME_HISTORY_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { historicoPosicoesTempoAcionamento } = data
        dispatch({ type: FETCH_TRIGGER_TIME_HISTORY_FULFILLED, payload: historicoPosicoesTempoAcionamento });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_TRIGGER_TIME_HISTORY_REJECTED, error });
      reject(error);
    });

  });

}

export function removeTriggerTimeHistory(veiculo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_TRIGGER_TIME_HISTORY_PENDING });

    api({
      method: 'post',
      data: {
        query: `
          mutation RemoveTriggerTimeHistory {
            deleteHistoricoPosicoesTempoAcionamento (veiculo: ${veiculo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_TRIGGER_TIME_HISTORY_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteHistoricoPosicoesTempoAcionamento } = data
        dispatch({ type: DELETE_TRIGGER_TIME_HISTORY_FULFILLED, payload: deleteHistoricoPosicoesTempoAcionamento });
        dispatch(findAllTriggerTimeHistorys());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_TRIGGER_TIME_HISTORY_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
