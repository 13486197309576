import api from '../../../../../api'

export const FETCH_STATS_PENDING = 'FETCH_STATS_PENDING'
export const FETCH_STATS_FULFILLED = 'FETCH_STATS_FULFILLED'
export const FETCH_STATS_REJECTED = 'FETCH_STATS_REJECTED'

export function findStatsVehicles() {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_STATS_PENDING });

    api({
      method: 'post',          
      data: {
        query: `
                query findStatsVehicles {
                  statusVeiculos {
                    veiculosAtivos
                    veiclosOnline
                    veiculosOffline
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_STATS_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { statusVeiculos } = data
        dispatch({ type: FETCH_STATS_FULFILLED, payload: statusVeiculos });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_STATS_REJECTED, error });
      reject(error);
    });

  });

}
