import {

    FETCH_EVENTS_DRIVER_PENDING,
    FETCH_EVENTS_DRIVER_FULFILLED,
    FETCH_EVENTS_DRIVER_REJECTED,

    DELETE_EVENTS_DRIVER_PENDING,
    DELETE_EVENTS_DRIVER_FULFILLED,
    DELETE_EVENTS_DRIVER_REJECTED,

    CHANGE_EVENTS_DRIVER

} from './eventsDriverPeriodActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfEventsDriverPeriod: [],
    eventsDriverPeriod: {
        matriz: 0,
        motorista: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function eventsDriverPeriodReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_EVENTS_DRIVER:
            return Object.assign({}, state, {
                eventsDriverPeriod: {
                    ...state.eventsDriverPeriod,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_EVENTS_DRIVER_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfEventsDriverPeriod: initialState.listOfEventsDriverPeriod,
            })
        case FETCH_EVENTS_DRIVER_FULFILLED:
            console.log(action.payload)
            return Object.assign({}, state, {
                loading: false,
                listOfEventsDriverPeriod: action.payload
            })
        case FETCH_EVENTS_DRIVER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfEventsDriverPeriod: initialState.listOfEventsDriverPeriod,
                error: action.payload
            })

        case DELETE_EVENTS_DRIVER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_EVENTS_DRIVER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_EVENTS_DRIVER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}