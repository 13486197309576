import {

    FETCH_RISK_AREAS_PENDING,
    FETCH_RISK_AREAS_FULFILLED,
    FETCH_RISK_AREAS_REJECTED,

    FETCH_RISK_AREA_PENDING,
    FETCH_RISK_AREA_FULFILLED,
    FETCH_RISK_AREA_REJECTED,

    CREATE_RISK_AREA_PENDING,
    CREATE_RISK_AREA_FULFILLED,
    CREATE_RISK_AREA_REJECTED,

    UPDATE_RISK_AREA_PENDING,
    UPDATE_RISK_AREA_FULFILLED,
    UPDATE_RISK_AREA_REJECTED,

    DELETE_RISK_AREA_PENDING,
    DELETE_RISK_AREA_FULFILLED,
    DELETE_RISK_AREA_REJECTED,
    
    CHANGE_RISK_AREA,
    
    ADD_RISK_AREA

} from './riskAreaActions'

import { titleCase } from '../../../../common/utils'

const initialState = {
    loading: false,
    listOfRiskAreas: [],
    riskArea: {
        codigo: 0,
        matriz: 0,
        descricao: '',
        poligono: '',
        latitude: 0.0,
        longitude: 0.0,
        raio: 0,
        eventoEntrada: false,
        eventoSaida: false,
        comandoEntrada: 0,
        comandoSaida: 0,
        areaVeiculos: '',
        clienteMatriz: {
            codigo: 0,
            razaoSocial: '',
        }
    },
    error: null,
}

export function riskAreaReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_RISK_AREA:
        return Object.assign({}, state, {
            riskArea: { ...state.riskArea, 
                [action.payload.name]: 
                (action.payload.name !== 'poligono' && action.payload.name !== 'eventoEntrada' && action.payload.name !== 'eventoSaida') ? titleCase(action.payload.value) : action.payload.value
            } 
        })
        case ADD_RISK_AREA: 
            return Object.assign({}, state, {
                riskArea: { ...initialState.riskArea }
            })
        case FETCH_RISK_AREAS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfRiskAreas: [],
            })
        case FETCH_RISK_AREAS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfRiskAreas: action.payload
            })
        case FETCH_RISK_AREAS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_RISK_AREA_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //riskArea: { ...initialState.riskArea },
            })
        case CREATE_RISK_AREA_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                riskArea: action.payload
            })
        case CREATE_RISK_AREA_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_RISK_AREA_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_RISK_AREA_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                riskArea: action.payload
            })
        case UPDATE_RISK_AREA_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_RISK_AREA_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_RISK_AREA_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                riskArea: action.payload
            })
        case FETCH_RISK_AREA_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_RISK_AREA_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_RISK_AREA_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_RISK_AREA_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}