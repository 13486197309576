import api from '../../../../../api'

export const FETCH_FIRMWARES_PENDING = 'FETCH_FIRMWARES_PENDING'
export const FETCH_FIRMWARES_FULFILLED = 'FETCH_FIRMWARES_FULFILLED'
export const FETCH_FIRMWARES_REJECTED = 'FETCH_FIRMWARES_REJECTED'

export const FETCH_FIRMWARE_PENDING = 'FETCH_FIRMWARE_PENDING'
export const FETCH_FIRMWARE_FULFILLED = 'FETCH_FIRMWARE_FULFILLED'
export const FETCH_FIRMWARE_REJECTED = 'FETCH_FIRMWARE_REJECTED'

export const CREATE_FIRMWARE_PENDING = 'CREATE_FIRMWARE_PENDING'
export const CREATE_FIRMWARE_FULFILLED = 'CREATE_FIRMWARE_FULFILLED'
export const CREATE_FIRMWARE_REJECTED = 'CREATE_FIRMWARE_REJECTED'

export const UPDATE_FIRMWARE_PENDING = 'UPDATE_FIRMWARE_PENDING'
export const UPDATE_FIRMWARE_FULFILLED = 'UPDATE_FIRMWARE_FULFILLED'
export const UPDATE_FIRMWARE_REJECTED = 'UPDATE_FIRMWARE_REJECTED'

export const DELETE_FIRMWARE_PENDING = 'DELETE_FIRMWARE_PENDING'
export const DELETE_FIRMWARE_FULFILLED = 'DELETE_FIRMWARE_FULFILLED'
export const DELETE_FIRMWARE_REJECTED = 'DELETE_FIRMWARE_REJECTED'

export const CHANGE_FIRMWARE = 'CHANGE_FIRMWARE'

export const ADD_FIRMWARE = 'ADD_FIRMWARE'

export function changeFirmware(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_FIRMWARE, payload: { name, value } })
  }
}

export function addFirmware() {
  return dispatch => {
    dispatch({ type: ADD_FIRMWARE });
  };
}

export function findAllFirmwares(first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_FIRMWARES_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                query findAllFirmwares {
                  firmwares (first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_FIRMWARES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { firmwares } = data
        dispatch({ type: FETCH_FIRMWARES_FULFILLED, payload: firmwares });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_FIRMWARES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findFirmware(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_FIRMWARE_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findFirmware {
                  firmware (codigo: ${codigo}) {
                    codigo
                    matriz
                    descricao
                    clienteMatriz {
                      codigo
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_FIRMWARE_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { firmware } = data
        dispatch({ type: FETCH_FIRMWARE_FULFILLED, payload: firmware });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_FIRMWARE_REJECTED, error });
      resolve(error);
    });

  });

}

export function createFirmware(firmware) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_FIRMWARE_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation createFirmware {
            createFirmware (input: {
              codigo: ${firmware.codigo}
              matriz: ${firmware.matriz}
              descricao: "${firmware.descricao}"
            } ) {
              codigo
              matriz
              descricao
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_FIRMWARE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createFirmware } = data
        dispatch({ type: CREATE_FIRMWARE_FULFILLED, payload: createFirmware });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_FIRMWARE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateFirmware(firmware) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_FIRMWARE_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation UpdateFirmware {
            updateFirmware (input: {
              codigo: ${firmware.codigo}
              matriz: ${firmware.matriz}
              descricao: "${firmware.descricao}"
            } ) {
              codigo
              matriz
              descricao
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_FIRMWARE_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateFirmware } = data
        dispatch({ type: UPDATE_FIRMWARE_FULFILLED, payload: updateFirmware });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_FIRMWARE_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
}

export function removeFirmware(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_FIRMWARE_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation RemoveFirmware {
            deleteFirmware (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_FIRMWARE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteFirmware } = data
        dispatch({ type: DELETE_FIRMWARE_FULFILLED, payload: deleteFirmware });
        dispatch(findAllFirmwares());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_FIRMWARE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
