export const SHOW_SAVED_TOAST_MESSAGE = 'SHOW_SAVED_TOAST_MESSAGE'
export const SHOW_ERROR_TOAST_MESSAGE = 'SHOW_ERROR_TOAST_MESSAGE'
export const SHOW_REMOVED_TOAST_MESSAGE = 'SHOW_REMOVED_TOAST_MESSAGE'
export const SHOW_INFO_TOAST_MESSAGE = 'SHOW_INFO_TOAST_MESSAGE'

export const CLEAR_TOAST_MESSAGE = 'CLEAR_TOAST_MESSAGE'

export function clearToastMessage() {
    return dispatch => {
      dispatch({ type: CLEAR_TOAST_MESSAGE })
    }
  }

export function showErrorMessage(message) {
    return dispatch => {
      dispatch({ type: SHOW_ERROR_TOAST_MESSAGE, payload: message })
    }
  }

export function showMessage(message) {
    return dispatch => {
      dispatch({ type: SHOW_INFO_TOAST_MESSAGE, payload: message })
    }
  }