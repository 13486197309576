import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { composeWithDevTools } from "redux-devtools-extension";

import { config } from "../config/config";
import {
  handleBodyClasses,
  dumpLayoutToStorage,
  layoutReducer,
  layoutInit
} from "../common/layout";

import { userReducer } from "../views/forms/user/store";
import { navigationReducer } from "../common/navigation";
import { chatReducer, chatInit } from "../common/chat";
import { eventsReducer } from "../common/calendar";
import { todoReducer } from "../common/todo";
import { i18nReducer, i18nInit } from "../common/i18n";
import { outlookReducer } from "../views/outlook";

import { statsReducer } from "../common/layout/components/stats/store";
import { headerReducer } from "../common/layout/components/header/store";

import { loginReducer } from "../views/auth/components/Login/store";
import { forgotReducer } from "../views/auth/components/Forgot/store";
import { customerReducer } from "../views/forms/customer/store";
import { regionReducer } from "../views/forms/region/store";
import { countryReducer } from "../views/forms/country/store";
import { technicalReducer } from "../views/forms/technical/store";
import { driverReducer } from "../views/forms/driver/store";
import { cardReducer } from "../views/forms/driver/card/store";
import { cardLibraryReducer } from "../views/forms/driver/cardLibrary/store";
import { groupReducer } from "../views/forms/vehicle/group/store";
import { expenseReducer } from "../views/forms/expense/store";
import { garageReducer } from "../views/forms/vehicle/garage/store";
import { vehicleTypeReducer } from "../views/forms/vehicle/vehicleType/store";
import { userTypeReducer } from "../views/forms/user/userType/store";
import { vehiclePortReducer } from "../views/forms/vehicle/vehiclePort/store";
import { vehicleReducer } from "../views/forms/vehicle/store";
import { firmwareReducer } from "../views/forms/peripheral/firmware/store";
import { simCardReducer } from "../views/forms/peripheral/simCard/store";
import { supplierActivityReducer } from "../views/forms/supplier/supplierActivity/store";
import { supplierReducer } from "../views/forms/supplier/store";
import { peripheralReducer } from "../views/forms/peripheral/peripheral/store";
import { serviceGroupReducer } from "../views/forms/supplier/serviceGroup/store";
import { productOrServiceReducer } from "../views/forms/supplier/productOrService/store";
import { typePointReducer } from "../views/forms/knownPoint/typePoint/store";
import { fuelSupplyReducer } from "../views/forms/fuelSupply/store";
import { maintencePlanReducer } from "../views/forms/maintence/maintencePlan/store";
import { maintenceScheduleReducer } from "../views/forms/maintence/maintenceSchedule/store";
import { vehicleReservationReducer } from "../views/forms/reservation/store";
import { knownPointReducer } from "../views/forms/knownPoint/store";
import { riskAreaReducer } from "../views/forms/riskArea/store";
import { eventReducer } from "../views/forms/event/store";
import { scoreReducer } from "../views/forms/event/score/store";

import { trackingHistoryReducer } from "../views/historical/trackingHistory/store";
import { telemetryHistoryReducer } from "../views/historical/telemetryHistory/store";
import { econometerHistoryReducer } from "../views/historical/econometerHistory/store";
import { monthlyClosingReducer } from "../views/historical/monthlyClosing/store";
import { idleEngineReducer } from "../views/historical/idleEngineHistory/store";

import { economicUseReducer } from "../views/graphs/economicUse/store";
import { eventsVehiclePeriodReducer } from "../views/graphs/eventsVehiclePeriod/store";
import { eventsPeriodReducer } from "../views/graphs/eventsPeriod/store";
import { eventsDriverPeriodReducer } from "../views/graphs/eventsDriverPeriod/store";
import { eventsDriversPeriodReducer } from "../views/graphs/eventsDriversPeriod/store";
import { fuelRpmSpeedTemperatureReducer } from "../views/graphs/fuelRpmSpeedTemperature/store";
import { temperatureAndUmidityReducer } from "../views/graphs/temperatureAndUmidity/store";
import { telemetryTemperatureReducer } from "../views/graphs/telemetryTemperature/store";
import { temperature3ZonesReducer } from "../views/graphs/temperature3Zones/store";

import { accessCounterHistoryReducer } from "../views/historical/accessCounterHistory/store";
import { triggerTimeHistoryReducer } from "../views/historical/triggerTimeHistory/store";
import { containerUseReducer } from "../views/graphs/containerUse/store";
import { gensetSG3000eReducer } from "../views/graphs/gensetSG3000e/store";

import { irregularDriversReducer } from "../views/historical/irregularDrivers/store";
import { fuelHistoryReducer } from "../views/historical/fuelHistory/store";
import { commandHistoryReducer } from "../views/historical/commandHistory/store";
import { eventHistoryReducer } from "../views/historical/eventHistory/store";
import { stopTimeHistoryReducer } from "../views/historical/stopTimeHistory/store";
import { expenseHistoryReducer } from "../views/historical/expenseHistory/store";

import { maintenanceHistoryReducer } from "../views/historical/maintenanceHistory/store";
import { syntheticDetailedFleetReducer } from "../views/reports/syntheticDetailedFleet/store";
import { syntheticDetailedVehicleReducer } from "../views/reports/syntheticDetailedVehicle/store";
import { syntheticDetailedDriverReducer } from "../views/reports/syntheticDetailedDriver/store";

import { fleetUsageReducer } from "../views/reports/fleetUsage/store";
import { speedingEventsReducer } from "../views/reports/speedingEvents/store";
import { tripsMadeReducer } from "../views/reports/tripsMade/store";
import { trackerTripsMadeReducer } from "../views/reports/trackerTripsMade/store";
import { driverScoreReducer } from "../views/reports/driverScore/store";

import { mapReducer } from "../views/maps/store";

import {
  voiceReducer,
  VoiceMiddleware,
  voiceControlOn
} from "../common/voice-control";

export const rootReducer = combineReducers({
  layout: layoutReducer,
  navigation: navigationReducer,
  outlook: outlookReducer,
  user: userReducer,
  chat: chatReducer,
  events: eventsReducer,
  voice: voiceReducer,
  todo: todoReducer,
  i18n: i18nReducer,
  stats: statsReducer,
  header: headerReducer,
  login: loginReducer,
  customer: customerReducer,
  region: regionReducer,
  country: countryReducer,
  technical: technicalReducer,
  driver: driverReducer,
  map: mapReducer,
  card: cardReducer,
  cardLibrary: cardLibraryReducer,
  group: groupReducer,
  garage: garageReducer,
  vehicleType: vehicleTypeReducer,
  userType: userTypeReducer,
  vehiclePort: vehiclePortReducer,
  vehicle: vehicleReducer,
  firmware: firmwareReducer,
  simCard: simCardReducer,
  supplierActivity: supplierActivityReducer,
  supplier: supplierReducer,
  peripheral: peripheralReducer,
  serviceGroup: serviceGroupReducer,
  productOrService: productOrServiceReducer,
  typePoint: typePointReducer,
  fuelSupply: fuelSupplyReducer,
  maintencePlan: maintencePlanReducer,
  maintenceSchedule: maintenceScheduleReducer,
  vehicleReservation: vehicleReservationReducer,
  expense: expenseReducer,
  knownPoint: knownPointReducer,
  riskArea: riskAreaReducer,
  event: eventReducer,
  score: scoreReducer,
  eventsVehiclePeriod: eventsVehiclePeriodReducer,
  eventsDriverPeriod: eventsDriverPeriodReducer,
  eventsDriversPeriod: eventsDriversPeriodReducer,
  fuelRpmSpeedTemperature: fuelRpmSpeedTemperatureReducer,
  temperatureAndUmidity: temperatureAndUmidityReducer,
  telemetryTemperature: telemetryTemperatureReducer,
  temperature3Zones: temperature3ZonesReducer,
  trackingHistory: trackingHistoryReducer,
  telemetryHistory: telemetryHistoryReducer,
  econometerHistory: econometerHistoryReducer,
  commandHistory: commandHistoryReducer,
  eventHistory: eventHistoryReducer,
  stopTimeHistory: stopTimeHistoryReducer,
  expenseHistory: expenseHistoryReducer,
  economicUse: economicUseReducer,
  irregularDrivers: irregularDriversReducer,
  accessCounterHistory: accessCounterHistoryReducer,
  triggerTimeHistory:triggerTimeHistoryReducer,
  containerUse: containerUseReducer,
  gensetSG3000e: gensetSG3000eReducer,
  fuelHistory: fuelHistoryReducer,
  maintenanceHistory: maintenanceHistoryReducer,
  idleEngine: idleEngineReducer,
  syntheticDetailedFleet: syntheticDetailedFleetReducer,
  syntheticDetailedVehicle: syntheticDetailedVehicleReducer,
  syntheticDetailedDriver: syntheticDetailedDriverReducer,
  fleetUsage: fleetUsageReducer,
  speedingEvents: speedingEventsReducer,
  tripsMade: tripsMadeReducer,
  trackerTripsMade: trackerTripsMadeReducer,
  driverScore: driverScoreReducer,
  monthlyClosing: monthlyClosingReducer,
  eventsPeriod: eventsPeriodReducer,
  forgot: forgotReducer,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(
      thunk,
      handleBodyClasses,
      dumpLayoutToStorage,
      VoiceMiddleware
    )
  )
);

store.dispatch(layoutInit());
store.dispatch(chatInit());
//store.dispatch(requestUserInfo());
store.dispatch(i18nInit());

if (config.voice_command_auto) {
  store.dispatch(voiceControlOn());
}

export default store;
