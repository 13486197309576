import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const GensetDashboard = Loadable({
  loader: () => import("./components/gensetDashboard/GensetDashboard"),
  loading: Loading
});

const SpeedVehicles = Loadable({
  loader: () => import("./components/speedVehicles/SpeedVehicles"),
  loading: Loading
});

const MapVehicles = Loadable({
  loader: () => import("./components/mapVehicles/MapVehicles"),
  loading: Loading
});

const Dashboard = Loadable({
  loader: () => import("./components/Dashboard"),
  loading: Loading
});

const Social = Loadable({
  loader: () => import("./components/Social"),
  loading: Loading
});

export const routes = [
  {
    path:  "/dashboard/mapVehicles",
    exact: true,
    name: "Dashboard",
    component: MapVehicles
  },
  {
    path:  "/dashboard/speedVehicles",
    exact: true,
    name: "Dashboard",
    component: SpeedVehicles
  },
  {
    path:  "/dashboard/gensetDashboard",
    exact: true,
    name: "Dashboard",
    component: GensetDashboard
  },
  {
    path:  "/dashboard/analytics",
    exact: true,
    name: "Dashboard",
    component: Dashboard
  },
  {
    path: "/dashboard/social-wall",
    exact: true,
    name: "Social",
    component: Social
  }
];
