import api from '../../../../api'

import { toFloat } from '../../../../common'

export const FETCH_EXPENSES_PENDING = 'FETCH_EXPENSES_PENDING'
export const FETCH_EXPENSES_FULFILLED = 'FETCH_EXPENSES_FULFILLED'
export const FETCH_EXPENSES_REJECTED = 'FETCH_EXPENSES_REJECTED'

export const FETCH_EXPENSE_PENDING = 'FETCH_EXPENSE_PENDING'
export const FETCH_EXPENSE_FULFILLED = 'FETCH_EXPENSE_FULFILLED'
export const FETCH_EXPENSE_REJECTED = 'FETCH_EXPENSE_REJECTED'

export const CREATE_EXPENSE_PENDING = 'CREATE_EXPENSE_PENDING'
export const CREATE_EXPENSE_FULFILLED = 'CREATE_EXPENSE_FULFILLED'
export const CREATE_EXPENSE_REJECTED = 'CREATE_EXPENSE_REJECTED'

export const UPDATE_EXPENSE_PENDING = 'UPDATE_EXPENSE_PENDING'
export const UPDATE_EXPENSE_FULFILLED = 'UPDATE_EXPENSE_FULFILLED'
export const UPDATE_EXPENSE_REJECTED = 'UPDATE_EXPENSE_REJECTED'

export const DELETE_EXPENSE_PENDING = 'DELETE_EXPENSE_PENDING'
export const DELETE_EXPENSE_FULFILLED = 'DELETE_EXPENSE_FULFILLED'
export const DELETE_EXPENSE_REJECTED = 'DELETE_EXPENSE_REJECTED'

export const CHANGE_EXPENSE = 'CHANGE_EXPENSE'

export const ADD_EXPENSE = 'ADD_EXPENSE'

export const ADD_PRODUCT = 'ADD_PRODUCT'
export const DEL_PRODUCT = 'DEL_PRODUCT'

export const ADD_SERVICE = 'ADD_SERVICE'
export const DEL_SERVICE = 'DEL_SERVICE'

export const ADD_ADDITIONAL = 'ADD_ADDITIONAL'
export const DEL_ADDITIONAL = 'DEL_ADDITIONAL'

export const ADD_DIVERSE = 'ADD_DIVERSE'
export const DEL_DIVERSE = 'DEL_DIVERSE'

export function changeExpense(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_EXPENSE, payload: { name, value } })
  }
}

export function addExpense() {
  return dispatch => {
    dispatch({ type: ADD_EXPENSE });
  };
}

export function findAllExpenses(first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_EXPENSES_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                query findAllExpenses {
                  despesas (first: ${first} offset: ${offset}) {
                    codigo
                    numeroNota
                    dataEmissao
                    dataVencimento
                    fornecedor
                    odometro
                    veiculo
                    motorista
                    descricao
                    valorTotal
                    veiculoDespesa {
                      placa
                      descricao
                    }
                    fornecedorDespesa {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_EXPENSES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { despesas } = data
        dispatch({ type: FETCH_EXPENSES_FULFILLED, payload: despesas });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_EXPENSES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAllExpensesByPlate(search = '', first = 1000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_EXPENSES_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                query findAllExpensesByPlace {
                  despesasPorPlaca (search: "${search}" first: ${first} offset: ${offset}) {
                    codigo
                    numeroNota
                    dataEmissao
                    dataVencimento
                    fornecedor
                    odometro
                    veiculo
                    motorista
                    descricao
                    valorTotal
                    veiculoDespesa {
                      placa
                      descricao
                    }
                    fornecedorDespesa {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_EXPENSES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { despesasPorPlaca } = data
        dispatch({ type: FETCH_EXPENSES_FULFILLED, payload: despesasPorPlaca });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_EXPENSES_REJECTED, error });
      resolve(error);
    });

  });

}
 
export function findExpense(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_EXPENSE_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                    query findExpense {
                      despesa (codigo: ${codigo}) {
                        codigo
                        numeroNota
                        dataEmissao
                        dataVencimento
                        fornecedor
                        odometro
                        veiculo
                        motorista
                        descricao
                        produtos {
                          codigo
                          produto
                          descricao
                          quantidade
                          valorUnitario
                        },
                        servicos {
                          codigo
                          servico
                          descricao
                          quantidade
                          valorUnitario
                        }
                      }
                    }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_EXPENSE_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { despesa } = data
        dispatch({ type: FETCH_EXPENSE_FULFILLED, payload: despesa });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_EXPENSE_REJECTED, error });
      reject(error);
    });

  });

}

const moment = require('moment');
export function createExpense(expense) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_EXPENSE_PENDING });
    let valorTotal = 0;
    let produtos = expense.produtos.map((produto) => {
      valorTotal += (parseInt(produto.quantidade) * toFloat(produto.valorUnitario));
      return ({
        produto: parseInt(produto.produto),
        quantidade: parseInt(produto.quantidade),
        valorUnitario: toFloat(produto.valorUnitario)
      })
    });

    produtos = JSON.stringify(produtos).replace(/\"([^"]+)\":/g,"$1:").replace(/\uFFFF/g,"\\\"");

    let servicos = expense.servicos.map((servico) => {
      valorTotal += (parseInt(servico.quantidade) * toFloat(servico.valorUnitario));
      return ({
        servico: parseInt(servico.servico),
        quantidade: parseInt(servico.quantidade),
        valorUnitario: toFloat(servico.valorUnitario)
      })
    });

    servicos = JSON.stringify(servicos).replace(/\"([^"]+)\":/g,"$1:").replace(/\uFFFF/g,"\\\"");

    let adicionais = expense.adicionais.map((adicional) => {
      valorTotal += (parseInt(adicional.quantidade) * toFloat(adicional.valorUnitario));
      return ({
        adicional: parseInt(adicional.adicional),
        quantidade: parseInt(adicional.quantidade),
        valorUnitario: toFloat(adicional.valorUnitario)
      })
    });
    
    adicionais = JSON.stringify(adicionais).replace(/\"([^"]+)\":/g,"$1:").replace(/\uFFFF/g,"\\\"");

    let diversas = expense.diversas.map((diversa) => {
      valorTotal += (parseInt(diversa.quantidade) * toFloat(diversa.valorUnitario));
      return ({
        descricao: diversa.descricao,
        quantidade: parseInt(diversa.quantidade),
        valorUnitario: toFloat(diversa.valorUnitario)
      })
    });
    
    diversas = JSON.stringify(diversas).replace(/\"([^"]+)\":/g,"$1:").replace(/\uFFFF/g,"\\\"");


    api({
      method: 'post',         
      data: {
        query: `
          mutation createExpense {
            createDespesa (input: {
              numeroNota: ${toFloat(expense.numeroNota)}
              dataEmissao: "${moment(expense.dataEmissao, 'DD/MM/YYYY').format('YYYY-MM-DD')}"
              dataVencimento: "${moment(expense.dataVencimento, 'DD/MM/YYYY').format('YYYY-MM-DD')}"
              fornecedor: ${expense.fornecedor}
              odometro: ${toFloat(expense.odometro)}
              veiculo: ${expense.veiculo}
              motorista: ${expense.motorista}
              descricao: "${expense.descricao}"
              valorTotal: ${toFloat(valorTotal)}
              produtos: ${produtos}
              servicos: ${servicos}
              adicionais: ${adicionais}
              diversas: ${diversas}
            } ) {
              codigo
              numeroNota
              dataEmissao
              dataVencimento
              fornecedor
              odometro
              veiculo
              motorista
              descricao
              valorTotal
              produtos {
                produto
                quantidade
                valorUnitario
              },
              servicos {
                servico
                quantidade
                valorUnitario
              },
              adicionais {
                adicional
                quantidade
                valorUnitario
              },
              diversas {
                descricao
                quantidade
                valorUnitario
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_EXPENSE_REJECTED, payload: errors });
        reject({ status: false, error: errors });
      } else {
        const { createDespesa } = data
        dispatch({ type: CREATE_EXPENSE_FULFILLED, payload: createDespesa });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_EXPENSE_REJECTED, errors });
      reject({ status: false, error: errors });
    });
  });
}

export function removeExpense(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_EXPENSE_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation RemoveExpense {
            deleteDespesa (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_EXPENSE_REJECTED, payload: errors });
        reject({ status: false, error: errors });
      } else {
        const { deleteDespesa } = data
        dispatch({ type: DELETE_EXPENSE_FULFILLED, payload: deleteDespesa });
        dispatch(findAllExpenses());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_EXPENSE_REJECTED, errors });
      reject({ status: false, error: errors });
    });
  });
}

export function addProduct(product) {
  return dispatch => {
    dispatch({ type: ADD_PRODUCT, payload: product });
  };
}

export function delProduct(product) {
  return dispatch => {
    dispatch({ type: DEL_PRODUCT, payload: product });
  };
}

export function addService(service) {
  return dispatch => {
    dispatch({ type: ADD_SERVICE, payload: service });
  };
}

export function delService(service) {
  return dispatch => {
    dispatch({ type: DEL_SERVICE, payload: service });
  };
}

export function addAdditional(additional) {
  return dispatch => {
    dispatch({ type: ADD_ADDITIONAL, payload: additional });
  };
}

export function delAdditional(additional) {
  return dispatch => {
    dispatch({ type: DEL_ADDITIONAL, payload: additional });
  };
}

export function addDiverse(diverse) {
  return dispatch => {
    dispatch({ type: ADD_DIVERSE, payload: diverse });
  };
}

export function delDiverse(diverse) {
  return dispatch => {
    dispatch({ type:DEL_DIVERSE, payload: diverse });
  };
}
