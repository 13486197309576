import api from '../../../../api'

export const FETCH_TELEMETRY_TEMPERATURE_PENDING = 'FETCH_TELEMETRY_TEMPERATURE_PENDING'
export const FETCH_TELEMETRY_TEMPERATURE_FULFILLED = 'FETCH_TELEMETRY_TEMPERATURE_FULFILLED'
export const FETCH_TELEMETRY_TEMPERATURE_REJECTED = 'FETCH_TELEMETRY_TEMPERATURE_REJECTED'

export const CHANGE_TELEMETRY_TEMPERATURE = 'CHANGE_TELEMETRY_TEMPERATURE'

export function changeTelemetryTemperature(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_TELEMETRY_TEMPERATURE, payload: { name, value } })
  }
}

export function findAllTelemetryTemperatures(filter, first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_TELEMETRY_TEMPERATURE_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllTelemetryTemperatures {
                  historicoPosicoesTelemetria (input: {
                    veiculo: ${filter.veiculo}
                    motorista: 0
                    garagem: 0
                    evento: 99
                    velocidadeTipo: 0
                    velocidadeValor: 0
                    rpmTipo: 0
                    rpmValor: 0
                    entrada1: 0
                    entrada2: 0
                    entrada3: 0
                    entrada4: 0
                    entrada5: 0
                    entrada6: 0
                    entrada7: 0
                    entrada8: 0
                    saida1: 0
                    saida2: 0
                    saida3: 0
                    saida4: 0
                    dataHoraInicial: "${filter.dataHoraInicial}"
                    dataHoraFinal: "${filter.dataHoraFinal}"

                  } first: ${first} offset: ${offset}) {
                    dataHoraServidor
                    dataHoraGps
                    temperatura
                    latitude
                    longitude
                    veiculo {
                      placa
                      descricao
                      clienteMatriz {
                        razaoSocial
                      }
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_TELEMETRY_TEMPERATURE_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { historicoPosicoesTelemetria } = data
        dispatch({ type: FETCH_TELEMETRY_TEMPERATURE_FULFILLED, payload: historicoPosicoesTelemetria });
        resolve(historicoPosicoesTelemetria);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_TELEMETRY_TEMPERATURE_REJECTED, error });
      reject(error);
    });

  });

}
