import api from '../../../../api'

export const FETCH_SYNTHETIC_DETAILED_DRIVER_PENDING = 'FETCH_SYNTHETIC_DETAILED_DRIVER_PENDING'
export const FETCH_SYNTHETIC_DETAILED_DRIVER_FULFILLED = 'FETCH_SYNTHETIC_DETAILED_DRIVER_FULFILLED'
export const FETCH_SYNTHETIC_DETAILED_DRIVER_REJECTED = 'FETCH_SYNTHETIC_DETAILED_DRIVER_REJECTED'

export const DELETE_SYNTHETIC_DETAILED_DRIVER_PENDING = 'DELETE_SYNTHETIC_DETAILED_DRIVER_PENDING'
export const DELETE_SYNTHETIC_DETAILED_DRIVER_FULFILLED = 'DELETE_SYNTHETIC_DETAILED_DRIVER_FULFILLED'
export const DELETE_SYNTHETIC_DETAILED_DRIVER_REJECTED = 'DELETE_SYNTHETIC_DETAILED_DRIVER_REJECTED'

export const CHANGE_SYNTHETIC_DETAILED_DRIVER = 'CHANGE_SYNTHETIC_DETAILED_DRIVER'

export function changeSyntheticDetailedDriver(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_SYNTHETIC_DETAILED_DRIVER, payload: { name, value } })
  }
}

export function findAllSyntheticDetailedDrivers(filter, first = 12000, offset = 0) {
  
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SYNTHETIC_DETAILED_DRIVER_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllSyntheticDetailedDrivers {
                  reportSinteticoDetalhadoMotorista (input: {
                    motorista: ${filter.motorista}
                    dataHoraInicial: "${filter.dataHoraInicial}"
                    dataHoraFinal: "${filter.dataHoraFinal}"
                  } first: ${first} offset: ${offset}) {
                    diaMes 
                    veiculo 
                    placa
                    distancia 
                    tempoIgnicao 
                    tempoMotor 
                    tempoMarchaLenta 
                    tempoMovimento 
                    tempoChuva 
                    tempoExcessoVelocidade 
                    quantidadeEventosVelocidade 
                    maximaVelocidadeSeco
                    maximaVelocidadeChuva 
                    quantidadeEventosBanguela 
                    maximaBanguela 
                    tempoExcessoRPM 
                    quantidadeEventosRPM 
                    maximaRPM 
                    quantidadeEventosTemperatura 
                    maximaTemperatura 
                    quantidadeEventosFreadaBrusca 
                    maximaFreadaBrusca 
                    quantidadeEventosAceleracaoParado 
                    maximaAceleracaoParado 
                    quantidadeEventosAceleracaoMovimento 
                    maximaAceleracaoMovimento
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SYNTHETIC_DETAILED_DRIVER_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { reportSinteticoDetalhadoMotorista } = data
        dispatch({ type: FETCH_SYNTHETIC_DETAILED_DRIVER_FULFILLED, payload: reportSinteticoDetalhadoMotorista });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SYNTHETIC_DETAILED_DRIVER_REJECTED, error });
      reject(error);
    });

  });

}
