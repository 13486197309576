import api from '../../../../api'

export const FETCH_TRACKER_TRIPS_MADE_PENDING = 'FETCH_TRACKER_TRIPS_MADE_PENDING'
export const FETCH_TRACKER_TRIPS_MADE_FULFILLED = 'FETCH_TRACKER_TRIPS_MADE_FULFILLED'
export const FETCH_TRACKER_TRIPS_MADE_REJECTED = 'FETCH_TRACKER_TRIPS_MADE_REJECTED'

export const CHANGE_TRACKER_TRIPS_MADE = 'CHANGE_TRACKER_TRIPS_MADE'

export function changeTrackerTripsMade(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_TRACKER_TRIPS_MADE, payload: { name, value } })
  }
}

export function findAllTrackerTripsMades(veiculo = 0, startPeriod = '', stopPeriod = '') {
  
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_TRACKER_TRIPS_MADE_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllTrackerTripsMades {
                  viagensRealizadasPorVeiculoGPS (
                    veiculo: ${veiculo} 
                    startPeriod: "${startPeriod}",
                    stopPeriod: "${stopPeriod}"
                  ) {
                    placa
                    motorista 
                    dataHoraInicial
                    dataHoraFinal
                    odometroInicial
                    odometroFinal
                    localizacaoInicial
                    localizacaoFinal
                    tempoViagem
                    tempoParado
                    distancia
                    velocidadeMaxima
                    latitudeInicial
                    longitudeInicial
                    latitudeFinal
                    longitudeFinal
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_TRACKER_TRIPS_MADE_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { viagensRealizadasPorVeiculoGPS } = data
        dispatch({ type: FETCH_TRACKER_TRIPS_MADE_FULFILLED, payload: viagensRealizadasPorVeiculoGPS });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_TRACKER_TRIPS_MADE_REJECTED, error });
      reject(error);
    });

  });

}
