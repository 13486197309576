import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const EconomicUse = Loadable({
  loader: () => import("./economicUse/EconomicUse"),
  loading: Loading
});

const ContainerUse = Loadable({
  loader: () => import("./containerUse/ContainerUse"),
  loading: Loading
});

const GensetSG3000e = Loadable({
  loader: () => import("./gensetSG3000e/GensetSG3000e"),
  loading: Loading
});

const EventsSG3000e = Loadable({
  loader: () => import("./gensetSG3000e/EventsSG3000e"),
  loading: Loading
});

const TemperatureAndUmidity = Loadable({
  loader: () => import("./temperatureAndUmidity/TemperatureAndUmidity"),
  loading: Loading
});

const Temperature3Zones = Loadable({
  loader: () => import("./temperature3Zones/Temperature3Zones"),
  loading: Loading
});

const TelemetryTemperature = Loadable({
  loader: () => import("./telemetryTemperature/TelemetryTemperature"),
  loading: Loading
});

const EventsPeriod = Loadable({
  loader: () => import("./eventsPeriod/EventsPeriod"),
  loading: Loading
});

const EventsVehiclesPeriod = Loadable({
  loader: () => import("./eventsVehiclePeriod/EventsVehiclesPeriod"),
  loading: Loading
});

const EventsDriverPeriod = Loadable({
  loader: () => import("./eventsDriverPeriod/EventsDriverPeriod"),
  loading: Loading
});

const EventsDriversPeriod = Loadable({
  loader: () => import("./eventsDriversPeriod/EventsDriversPeriod"),
  loading: Loading
});

const FuelRpmSpeedTemperature = Loadable({
  loader: () => import("./fuelRpmSpeedTemperature/FuelRpmSpeedTemperature"),
  loading: Loading
});

const FlotCharts = Loadable({
  loader: () => import("./components/FlotCharts"),
  loading: Loading
});
const ChartJs = Loadable({
  loader: () => import("./components/ChartJs"),
  loading: Loading
});
const MorrisCharts = Loadable({
  loader: () => import("./components/MorrisCharts"),
  loading: Loading
});
const SparklineCharts = Loadable({
  loader: () => import("./components/SparklineCharts"),
  loading: Loading
});
const EasyPieCharts = Loadable({
  loader: () => import("./components/EasyPieCharts"),
  loading: Loading
});
const Dygraphs = Loadable({
  loader: () => import("./components/Dygraphs"),
  loading: Loading
});
const HighchartTables = Loadable({
  loader: () => import("./components/HighchartTables"),
  loading: Loading
});

export const routes = [
  {
    path: "/graphs/economicuse",
    exact: true,
    component: EconomicUse,
    name: "Economic Use"
  },

  {
    path: "/graphs/temperatureAndUmidity",
    exact: true,
    component: TemperatureAndUmidity,
    name: "Temperature And Umidity"
  },

  {
    path: "/graphs/temperature3Zones",
    exact: true,
    component: Temperature3Zones,
    name: "Temperature 3 Zones"
  },

  {
    path: "/graphs/telemetryTemperature",
    exact: true,
    component: TelemetryTemperature,
    name: "Telemetry Temperature"
  },

  {
    path: "/graphs/containerUse",
    exact: true,
    component: ContainerUse,
    name: "Genset"
  },

  {
    path: "/graphs/gensetSG3000e",
    exact: true,
    component: GensetSG3000e,
    name: "GensetSG3000e"
  },

  {
    path: "/graphs/eventsSG3000e",
    exact: true,
    component: EventsSG3000e,
    name: "EventsSG3000e"
  },

  {
    path: "/graphs/eventsperiod",
    exact: true,
    component: EventsPeriod,
    name: "Events Period"
  },

  {
    path: "/graphs/eventsvehiclesperiod",
    exact: true,
    component: EventsVehiclesPeriod,
    name: "Events Vehicles Period"
  },

  {
    path: "/graphs/eventsdriverperiod",
    exact: true,
    component: EventsDriverPeriod,
    name: "Events Driver Period"
  },

  {
    path: "/graphs/eventsdriversperiod",
    exact: true,
    component: EventsDriversPeriod,
    name: "Events Drivers Period"
  },

  {
    path: "/graphs/fuelRpmSpeedTemperature",
    exact: true,
    component: FuelRpmSpeedTemperature,
    name: "Fuel RPM Speed Temperature"
  },

  {
    path: "/graphs/flot",
    exact: true,
    component: FlotCharts,
    name: "FlotCharts"
  },

  {
    path: "/graphs/chartjs",
    exact: true,
    component: ChartJs,
    name: "ChartJs"
  },

  {
    path: "/graphs/morris",
    exact: true,
    component: MorrisCharts,
    name: "MorrisCharts"
  },

  {
    path: "/graphs/sparklines",
    exact: true,
    component: SparklineCharts,
    name: "SparklineCharts"
  },

  {
    path: "/graphs/easy-pie-charts",
    exact: true,
    component: EasyPieCharts,
    name: "EasyPieCharts"
  },

  {
    path: "/graphs/dygraphs",
    exact: true,
    component: Dygraphs,
    name: "Dygraphs"
  },
  {
    path: "/graphs/highchart-table",
    exact: true,
    component: HighchartTables,
    name: "HighchartTables"
  }
];
