import { 
    SHOW_SAVED_TOAST_MESSAGE, 
    SHOW_REMOVED_TOAST_MESSAGE,
    SHOW_ERROR_TOAST_MESSAGE,
    SHOW_INFO_TOAST_MESSAGE,
    CLEAR_TOAST_MESSAGE
} from './headerActions'

const initialState = {
    loading: false,
    occurred: null,
    message: '',
}

export function headerReducer(state = initialState, action) {
    switch (action.type) {
        case CLEAR_TOAST_MESSAGE:
            return Object.assign({}, state, {
                loading: false,
                occurred: null,
                message: ''
            })
        case SHOW_SAVED_TOAST_MESSAGE:
            return Object.assign({}, state, {
                loading: false,
                occurred: new Date(),
                type: 'success',
                message: 'Dados Gravados Com Sucesso!'
            })
        case SHOW_REMOVED_TOAST_MESSAGE:
            return Object.assign({}, state, {
                loading: false,
                occurred: new Date(),
                type: 'success',
                message: 'Dados Excluídos Com Sucesso!'
            })
        case SHOW_ERROR_TOAST_MESSAGE:
            return Object.assign({}, state, {
                loading: false,
                occurred: new Date(),
                type: 'error',
                message: action.payload,
            })
        case SHOW_INFO_TOAST_MESSAGE:
            return Object.assign({}, state, {
                loading: false,
                occurred: new Date(),
                type: 'success',
                message: action.payload,
            })
        default:
            return state
    }
}