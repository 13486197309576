import {

    FETCH_SYNTHETIC_DETAILED_FLEET_PENDING,
    FETCH_SYNTHETIC_DETAILED_FLEET_FULFILLED,
    FETCH_SYNTHETIC_DETAILED_FLEET_REJECTED,

    DELETE_SYNTHETIC_DETAILED_FLEET_PENDING,
    DELETE_SYNTHETIC_DETAILED_FLEET_FULFILLED,
    DELETE_SYNTHETIC_DETAILED_FLEET_REJECTED,

    CHANGE_SYNTHETIC_DETAILED_FLEET

} from './syntheticDetailedFleetActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfSyntheticDetailedFleet: [],
    syntheticDetailedFleet: {
        matriz: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function syntheticDetailedFleetReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_SYNTHETIC_DETAILED_FLEET:
            return Object.assign({}, state, {
                syntheticDetailedFleet: {
                    ...state.syntheticDetailedFleet,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_SYNTHETIC_DETAILED_FLEET_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfSyntheticDetailedFleet: [],
            })
        case FETCH_SYNTHETIC_DETAILED_FLEET_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfSyntheticDetailedFleet: action.payload
            })
        case FETCH_SYNTHETIC_DETAILED_FLEET_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfSyntheticDetailedFleet: [],
                error: action.payload
            })

        case DELETE_SYNTHETIC_DETAILED_FLEET_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_SYNTHETIC_DETAILED_FLEET_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_SYNTHETIC_DETAILED_FLEET_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}