import {

    FETCH_TRIGGER_TIME_HISTORY_PENDING,
    FETCH_TRIGGER_TIME_HISTORY_FULFILLED,
    FETCH_TRIGGER_TIME_HISTORY_REJECTED,

    DELETE_TRIGGER_TIME_HISTORY_PENDING,
    DELETE_TRIGGER_TIME_HISTORY_FULFILLED,
    DELETE_TRIGGER_TIME_HISTORY_REJECTED,

    CHANGE_TRIGGER_TIME_HISTORY

} from './triggerTimeHistoryActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfTriggerTimeHistory: [],
    triggerTimeHistory: {
        veiculo: 0,
        motorista: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function triggerTimeHistoryReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_TRIGGER_TIME_HISTORY:
            return Object.assign({}, state, {
                triggerTimeHistory: {
                    ...state.triggerTimeHistory,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_TRIGGER_TIME_HISTORY_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfTriggerTimeHistory: [],
            })
        case FETCH_TRIGGER_TIME_HISTORY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfTriggerTimeHistory: action.payload
            })
        case FETCH_TRIGGER_TIME_HISTORY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfTriggerTimeHistory: [],
                error: action.payload
            })

        case DELETE_TRIGGER_TIME_HISTORY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_TRIGGER_TIME_HISTORY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_TRIGGER_TIME_HISTORY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}