import {

    FETCH_SPEEDING_EVENTS_PENDING,
    FETCH_SPEEDING_EVENTS_FULFILLED,
    FETCH_SPEEDING_EVENTS_REJECTED,

    DELETE_SPEEDING_EVENTS_PENDING,
    DELETE_SPEEDING_EVENTS_FULFILLED,
    DELETE_SPEEDING_EVENTS_REJECTED,

    CHANGE_SPEEDING_EVENTS

} from './speedingEventsActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfSpeedingEvents: [],
    speedingEvents: {
        matriz: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function speedingEventsReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_SPEEDING_EVENTS:
            return Object.assign({}, state, {
                speedingEvents: {
                    ...state.speedingEvents,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_SPEEDING_EVENTS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfSpeedingEvents: [],
            })
        case FETCH_SPEEDING_EVENTS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfSpeedingEvents: action.payload
            })
        case FETCH_SPEEDING_EVENTS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfSpeedingEvents: [],
                error: action.payload
            })

        case DELETE_SPEEDING_EVENTS_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_SPEEDING_EVENTS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_SPEEDING_EVENTS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}