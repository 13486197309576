import { titleCase } from '../../../../../common';
import { isNumber } from "@amcharts/amcharts4/core";

import {

    FETCH_CARDS_LIBRARIES_PENDING,
    FETCH_CARDS_LIBRARIES_FULFILLED,
    FETCH_CARDS_LIBRARIES_REJECTED,

    FETCH_CARDS_LIBRARY_PENDING,
    FETCH_CARDS_LIBRARY_FULFILLED,
    FETCH_CARDS_LIBRARY_REJECTED,

    CREATE_CARDS_LIBRARY_PENDING,
    CREATE_CARDS_LIBRARY_FULFILLED,
    CREATE_CARDS_LIBRARY_REJECTED,

    UPDATE_CARDS_LIBRARY_PENDING,
    UPDATE_CARDS_LIBRARY_FULFILLED,
    UPDATE_CARDS_LIBRARY_REJECTED,

    DELETE_CARDS_LIBRARY_PENDING,
    DELETE_CARDS_LIBRARY_FULFILLED,
    DELETE_CARDS_LIBRARY_REJECTED,
    
    CHANGE_CARDS_LIBRARY,
    
    ADD_CARDS_LIBRARY

} from './cardLibraryActions'

const initialState = {
    loading: false,
    listOfCardsLibraries: [],
    cardLibrary: {
        codigo: 0,
        matriz: 0,
        descricao: '',
        serialCartao: 0,
        motoristaCartao: '',
        cartoes: [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1],
        motoristas: [],
        clienteMatriz: {
            codigo: 0,
            razaoSocial: '',
        }
    },
    error: null,
}

export function cardLibraryReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_CARDS_LIBRARY: 
            if (isNumber(parseInt(action.payload.name))) {
                let cartoes = Array.isArray(state.cardLibrary.cartoes) ? state.cardLibrary.cartoes : state.cardLibrary.cartoes.split(';');
                cartoes[parseInt(action.payload.name)] = action.payload.value;
                return Object.assign({}, state, {
                    cardLibrary: { ...state.cardLibrary, 
                       cartoes,
                    }
                })
            } else {
                return Object.assign({}, state, {
                    cardLibrary: { ...state.cardLibrary, 
                        [action.payload.name]: titleCase(action.payload.value)
                    }
                })
            }
        case ADD_CARDS_LIBRARY: 
            return Object.assign({}, state, {
                cardLibrary: { ...initialState.cardLibrary }
            })
        case FETCH_CARDS_LIBRARIES_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_CARDS_LIBRARIES_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfCardsLibraries: action.payload
            })
        case FETCH_CARDS_LIBRARIES_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_CARDS_LIBRARY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case CREATE_CARDS_LIBRARY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                cardLibrary: action.payload
            })
        case CREATE_CARDS_LIBRARY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_CARDS_LIBRARY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_CARDS_LIBRARY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                cardLibrary: action.payload
            })
        case UPDATE_CARDS_LIBRARY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_CARDS_LIBRARY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_CARDS_LIBRARY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                cardLibrary: action.payload
            })
        case FETCH_CARDS_LIBRARY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_CARDS_LIBRARY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_CARDS_LIBRARY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_CARDS_LIBRARY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}