import {

    FETCH_FUEL_RPM_SPEED_TEMPERATURE_PENDING,
    FETCH_FUEL_RPM_SPEED_TEMPERATURE_FULFILLED,
    FETCH_FUEL_RPM_SPEED_TEMPERATURE_REJECTED,

    DELETE_FUEL_RPM_SPEED_TEMPERATURE_PENDING,
    DELETE_FUEL_RPM_SPEED_TEMPERATURE_FULFILLED,
    DELETE_FUEL_RPM_SPEED_TEMPERATURE_REJECTED,

    CHANGE_FUEL_RPM_SPEED_TEMPERATURE,

    DEFAULT_FUEL_RPM_SPEED_TEMPERATURE,

} from './fuelRpmSpeedTemperatureActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfFuelRpmSpeedTemperature: [],
    fuelRpmSpeedTemperature: {
        veiculo: 0,
        motorista: 0,
        garagem: 0,
        velocidadeTipo: 0,
        velocidadeValor: 0,
        rpmTipo: 0,
        rpmValor: 0,
        evento: 99,
        entrada1: 0,
        entrada2: 0,
        entrada3: 0,
        entrada4: 0,
        entrada5: 0,
        entrada6: 0,
        entrada7: 0,
        entrada8: 0,
        saida1: 0,
        saida2: 0,
        saida3: 0,
        saida4: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function fuelRpmSpeedTemperatureReducer(state = initialState, action) {
    switch (action.type) {
        case DEFAULT_FUEL_RPM_SPEED_TEMPERATURE:
            return Object.assign({}, state, {
                ...initialState
            })
        case CHANGE_FUEL_RPM_SPEED_TEMPERATURE:
            return Object.assign({}, state, {
                fuelRpmSpeedTemperature: {
                    ...state.fuelRpmSpeedTemperature,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_FUEL_RPM_SPEED_TEMPERATURE_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfFuelRpmSpeedTemperature: initialState.listOfFuelRpmSpeedTemperature,
            })
        case FETCH_FUEL_RPM_SPEED_TEMPERATURE_FULFILLED:
            console.log(action.payload)
            return Object.assign({}, state, {
                loading: false,
                listOfFuelRpmSpeedTemperature: action.payload
            })
        case FETCH_FUEL_RPM_SPEED_TEMPERATURE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfFuelRpmSpeedTemperature: initialState.listOfFuelRpmSpeedTemperature,
                error: action.payload
            })

        case DELETE_FUEL_RPM_SPEED_TEMPERATURE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_FUEL_RPM_SPEED_TEMPERATURE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_FUEL_RPM_SPEED_TEMPERATURE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}