import {

    FETCH_TELEMETRY_HISTORY_PENDING,
    FETCH_TELEMETRY_HISTORY_FULFILLED,
    FETCH_TELEMETRY_HISTORY_REJECTED,

    DEFAULT_TELEMETRY_HISTORY,

    CHANGE_TELEMETRY_HISTORY

} from './telemetryHistoryActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfTelemetryHistory: [],
    telemetryHistory: {
        veiculo: 0,
        motorista: 0,
        garagem: 0,
        velocidadeTipo: 0,
        velocidadeValor: 0,
        rpmTipo: 0,
        rpmValor: 0,
        evento: 99,
        entrada1: 0,
        entrada2: 0,
        entrada3: 0,
        entrada4: 0,
        entrada5: 0,
        entrada6: 0,
        entrada7: 0,
        entrada8: 0,
        saida1: 0,
        saida2: 0,
        saida3: 0,
        saida4: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function telemetryHistoryReducer(state = initialState, action) {
    switch (action.type) {
        case DEFAULT_TELEMETRY_HISTORY:
            return Object.assign({}, state, {
                ...initialState
            })
        case CHANGE_TELEMETRY_HISTORY:
            return Object.assign({}, state, {
                telemetryHistory: {
                    ...state.telemetryHistory,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_TELEMETRY_HISTORY_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfTelemetryHistory: [],
            })
        case FETCH_TELEMETRY_HISTORY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfTelemetryHistory: action.payload
            })
        case FETCH_TELEMETRY_HISTORY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfTelemetryHistory: [],
                error: action.payload
            })
        default:
            return state
    }
}