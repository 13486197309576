import {

    FETCH_IRREGULAR_DRIVERS_PENDING,
    FETCH_IRREGULAR_DRIVERS_FULFILLED,
    FETCH_IRREGULAR_DRIVERS_REJECTED,

    DELETE_IRREGULAR_DRIVERS_PENDING,
    DELETE_IRREGULAR_DRIVERS_FULFILLED,
    DELETE_IRREGULAR_DRIVERS_REJECTED,

    CHANGE_IRREGULAR_DRIVERS

} from './irregularDriversActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfIrregularDrivers: [],
    irregularDrivers: {
        matriz: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        dataFinal: moment().utc().format("DD/MM/YYYY")
    },
    error: null,
}

export function irregularDriversReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_IRREGULAR_DRIVERS:
            return Object.assign({}, state, {
                irregularDrivers: {
                    ...state.irregularDrivers,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_IRREGULAR_DRIVERS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfIrregularDrivers: [],
            })
        case FETCH_IRREGULAR_DRIVERS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfIrregularDrivers: action.payload
            })
        case FETCH_IRREGULAR_DRIVERS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfIrregularDrivers: [],
                error: action.payload
            })

        case DELETE_IRREGULAR_DRIVERS_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_IRREGULAR_DRIVERS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_IRREGULAR_DRIVERS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}