import {

    FETCH_VEHICLE_PORTS_PENDING,
    FETCH_VEHICLE_PORTS_FULFILLED,
    FETCH_VEHICLE_PORTS_REJECTED,

    FETCH_VEHICLE_PORT_PENDING,
    FETCH_VEHICLE_PORT_FULFILLED,
    FETCH_VEHICLE_PORT_REJECTED,

    CREATE_VEHICLE_PORT_PENDING,
    CREATE_VEHICLE_PORT_FULFILLED,
    CREATE_VEHICLE_PORT_REJECTED,

    UPDATE_VEHICLE_PORT_PENDING,
    UPDATE_VEHICLE_PORT_FULFILLED,
    UPDATE_VEHICLE_PORT_REJECTED,

    DELETE_VEHICLE_PORT_PENDING,
    DELETE_VEHICLE_PORT_FULFILLED,
    DELETE_VEHICLE_PORT_REJECTED,
    
    CHANGE_VEHICLE_PORT,
    
    ADD_VEHICLE_PORT

} from './vehiclePortActions'

import { toJSON } from '../../../../../common/utils'

const titleCase = (str) => {
    if (!str) return;
    return str.toLowerCase().split(' ').map(function(word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}

const initialState = {
    loading: false,
    listOfVehiclePorts: [],
    vehiclePort: {
        codigo: 0,
        matriz: 0,
        descricao: '',
        telemetria: {
            entrada1: '',
            entrada2: '',
            entrada3: '',
            entrada4: '',
            entrada5: '',
            entrada6: '',
            entrada7: '',
            entrada8: '',
            saida1: '',
            saida2: '',
            saida3: '',
            saida4: '',
            saida5: '',
            saida6: '',
            saida7: '',
            saida8: '',
        },
        rastreador: {
            entrada1: '',
            entrada2: '',
            entrada3: '',
            entrada4: '',
            entrada5: '',
            entrada6: '',
            entrada7: '',
            entrada8: '',
            saida1: '',
            saida2: '',
            saida3: '',
            saida4: '',
            saida5: '',
            saida6: '',
            saida7: '',
            saida8: '',
        },
        container: {
            entrada1: '',
            entrada2: '',
            entrada3: '',
            entrada4: '',
            entrada5: '',
            entrada6: '',
            entrada7: '',
            entrada8: '',
            saida1: '',
            saida2: '',
            saida3: '',
            saida4: '',
            saida5: '',
            saida6: '',
            saida7: '',
            saida8: '',
        },
        clienteMatriz: {
            codigo: 0,
            razaoSocial: '',
        }
    },
    error: null,
}

export function vehiclePortReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_VEHICLE_PORT: 
            let value = '';
            if (typeof action.payload.value === 'string') {
                value = titleCase(action.payload.value);
            } else {
                value = action.payload.value;
            }
            return Object.assign({}, state, {
                vehiclePort: { ...state.vehiclePort, 
                    [action.payload.name]: value
                }
            })
        case ADD_VEHICLE_PORT: 
            return Object.assign({}, state, {
                vehiclePort: { ...initialState.vehiclePort }
            })
        case FETCH_VEHICLE_PORTS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfVehiclePorts: [],
            })
        case FETCH_VEHICLE_PORTS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfVehiclePorts: action.payload
            })
        case FETCH_VEHICLE_PORTS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_VEHICLE_PORT_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //vehiclePort: { ...initialState.vehiclePort },
            })
        case CREATE_VEHICLE_PORT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                vehiclePort: action.payload
            })
        case CREATE_VEHICLE_PORT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_VEHICLE_PORT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_VEHICLE_PORT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                vehiclePort: action.payload
            })
        case UPDATE_VEHICLE_PORT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_VEHICLE_PORT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_VEHICLE_PORT_FULFILLED:
            const { rastreador, telemetria, container } = action.payload
            return Object.assign({}, state, {
                loading: false,
                vehiclePort: { 
                    ...action.payload,
                    rastreador: toJSON(rastreador),
                    telemetria: toJSON(telemetria),
                    container: toJSON(container)
                }
            })
        case FETCH_VEHICLE_PORT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_VEHICLE_PORT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_VEHICLE_PORT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_VEHICLE_PORT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}