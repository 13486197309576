import {

    FETCH_MAINTENCE_PLANS_PENDING,
    FETCH_MAINTENCE_PLANS_FULFILLED,
    FETCH_MAINTENCE_PLANS_REJECTED,

    FETCH_MAINTENCE_PLAN_PENDING,
    FETCH_MAINTENCE_PLAN_FULFILLED,
    FETCH_MAINTENCE_PLAN_REJECTED,

    CREATE_MAINTENCE_PLAN_PENDING,
    CREATE_MAINTENCE_PLAN_FULFILLED,
    CREATE_MAINTENCE_PLAN_REJECTED,

    UPDATE_MAINTENCE_PLAN_PENDING,
    UPDATE_MAINTENCE_PLAN_FULFILLED,
    UPDATE_MAINTENCE_PLAN_REJECTED,

    DELETE_MAINTENCE_PLAN_PENDING,
    DELETE_MAINTENCE_PLAN_FULFILLED,
    DELETE_MAINTENCE_PLAN_REJECTED,
    
    CHANGE_MAINTENCE_PLAN,
    
    ADD_MAINTENCE_PLAN

} from './maintencePlanActions'

const titleCase = (str) => {
    if (!str) return;
    return str.toLowerCase().split(' ').map(function(word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}

const initialState = {
    loading: false,
    typesOfMaintence: [
        { value: "0", label: "Corretiva" },
        { value: "1", label: "Preditiva" },
        { value: "2", label: "Preventiva" },
        { value: "3", label: "Reforma" },
    ],
    listOfMaintencePlans: [],
    maintencePlan: {
        codigo: 0,
        matriz: 0,
        descricao: '',
        unidade: 0,
        intervalo: 0,
        antecipacao: 0,
        tipoAntecipacao: 0,
        tipoManutencao: 0,
        clienteMatriz: {
            codigo: 0,
            razaoSocial: '',
        }
    },
    error: null,
}

export function maintencePlanReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_MAINTENCE_PLAN:
        return Object.assign({}, state, {
            maintencePlan: { ...state.maintencePlan, 
                [action.payload.name]: titleCase(action.payload.value)
            }
        })
        case ADD_MAINTENCE_PLAN: 
            return Object.assign({}, state, {
                maintencePlan: { ...initialState.maintencePlan }
            })
        case FETCH_MAINTENCE_PLANS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfMaintencePlans: [],
            })
        case FETCH_MAINTENCE_PLANS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfMaintencePlans: action.payload
            })
        case FETCH_MAINTENCE_PLANS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_MAINTENCE_PLAN_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //maintencePlan: { ...initialState.maintencePlan },
            })
        case CREATE_MAINTENCE_PLAN_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                maintencePlan: action.payload
            })
        case CREATE_MAINTENCE_PLAN_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_MAINTENCE_PLAN_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_MAINTENCE_PLAN_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                maintencePlan: action.payload
            })
        case UPDATE_MAINTENCE_PLAN_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_MAINTENCE_PLAN_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_MAINTENCE_PLAN_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                maintencePlan: action.payload
            })
        case FETCH_MAINTENCE_PLAN_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_MAINTENCE_PLAN_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_MAINTENCE_PLAN_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_MAINTENCE_PLAN_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}