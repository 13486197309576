import {

    FETCH_MONTHLY_CLOSING_PENDING,
    FETCH_MONTHLY_CLOSING_FULFILLED,
    FETCH_MONTHLY_CLOSING_REJECTED,

    CHANGE_MONTHLY_CLOSING

} from './monthlyClosingActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfMonthlyClosing: [],
    monthlyClosing: {
        mesAno: moment().utc().format("MM/YYYY"),
        matriz: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        dataFinal: moment().utc().format("DD/MM/YYYY")
    },
    error: null,
}

export function monthlyClosingReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_MONTHLY_CLOSING:
            return Object.assign({}, state, {
                monthlyClosing: {
                    ...state.monthlyClosing,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_MONTHLY_CLOSING_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfMonthlyClosing: [],
            })
        case FETCH_MONTHLY_CLOSING_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfMonthlyClosing: action.payload
            })
        case FETCH_MONTHLY_CLOSING_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfMonthlyClosing: [],
                error: action.payload
            })

        default:
            return state
    }
}