import {

    FETCH_SCORS_PENDING,
    FETCH_SCORS_FULFILLED,
    FETCH_SCORS_REJECTED,

    FETCH_SCORE_PENDING,
    FETCH_SCORE_FULFILLED,
    FETCH_SCORE_REJECTED,

    CREATE_SCORE_PENDING,
    CREATE_SCORE_FULFILLED,
    CREATE_SCORE_REJECTED,

    UPDATE_SCORE_PENDING,
    UPDATE_SCORE_FULFILLED,
    UPDATE_SCORE_REJECTED,

    DELETE_SCORE_PENDING,
    DELETE_SCORE_FULFILLED,
    DELETE_SCORE_REJECTED,
    
    CHANGE_SCORE,
    
    ADD_SCORE

} from './scoreActions'

const titleCase = (str) => {
    if (!str) return;
    return str.toLowerCase().split(' ').map(function(word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}

const initialState = {
    loading: false,
    listOfScors: [],
    score: {
        codigo: 0,
        matriz: 0,
        descricao: '',
        clienteMatriz: {
            codigo: 0,
            razaoSocial: '',
        }
    },
    error: null,
}

export function scoreReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_SCORE:
            
        let value = ""
                
        if (action.payload.name.includes('mail')) {
            value = action.payload.value.toLowerCase();
        } else 
            if (action.payload.name.includes('apelido')) {
                value = action.payload.value;
            } else {
                value = titleCase(action.payload.value);
            }
        return Object.assign({}, state, {
            score: { ...state.score, 
                [action.payload.name]: value
            }
        })
        case ADD_SCORE: 
            return Object.assign({}, state, {
                score: { ...initialState.score }
            })
        case FETCH_SCORS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfScors: [],
            })
        case FETCH_SCORS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfScors: action.payload
            })
        case FETCH_SCORS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_SCORE_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //score: { ...initialState.score },
            })
        case CREATE_SCORE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                score: action.payload
            })
        case CREATE_SCORE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_SCORE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_SCORE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                score: action.payload
            })
        case UPDATE_SCORE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_SCORE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_SCORE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                score: action.payload
            })
        case FETCH_SCORE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_SCORE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_SCORE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_SCORE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}