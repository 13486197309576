import {

    FETCH_TRACKER_TRIPS_MADE_PENDING,
    FETCH_TRACKER_TRIPS_MADE_FULFILLED,
    FETCH_TRACKER_TRIPS_MADE_REJECTED,

    CHANGE_TRACKER_TRIPS_MADE

} from './trackerTripsMadeActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfTrackerTripsMade: [],
    trackerTripsMade: {
        veiculo: 0,
        descricao: "",
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function trackerTripsMadeReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_TRACKER_TRIPS_MADE:
            return Object.assign({}, state, {
                trackerTripsMade: {
                    ...state.trackerTripsMade,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_TRACKER_TRIPS_MADE_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfTrackerTripsMade: [],
            })
        case FETCH_TRACKER_TRIPS_MADE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfTrackerTripsMade: action.payload
            })
        case FETCH_TRACKER_TRIPS_MADE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfTrackerTripsMade: [],
                error: action.payload
            })

        default:
            return state
    }
}