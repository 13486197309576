import {

    FETCH_EVENT_HISTORY_PENDING,
    FETCH_EVENT_HISTORY_FULFILLED,
    FETCH_EVENT_HISTORY_REJECTED,

    DELETE_EVENT_HISTORY_PENDING,
    DELETE_EVENT_HISTORY_FULFILLED,
    DELETE_EVENT_HISTORY_REJECTED,

    CHANGE_EVENT_HISTORY

} from './eventHistoryActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfEventHistory: [],
    eventHistory: {
        veiculo: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function eventHistoryReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_EVENT_HISTORY:
            return Object.assign({}, state, {
                eventHistory: {
                    ...state.eventHistory,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_EVENT_HISTORY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_EVENT_HISTORY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfEventHistory: action.payload
            })
        case FETCH_EVENT_HISTORY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfEventHistory: [],
                error: action.payload
            })

        case DELETE_EVENT_HISTORY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_EVENT_HISTORY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_EVENT_HISTORY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}