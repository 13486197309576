import {

    FETCH_STATS_PENDING,
    FETCH_STATS_FULFILLED,
    FETCH_STATS_REJECTED,

} from './statsActions'

const initialState = {
    loading: false,
    statusVeiculos: {
        veiculosAtivos: 0,
        veiclosOnline: 0,
        veiculosOffline: 0,
    },
    error: null,
}

export function statsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_STATS_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_STATS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                statusVeiculos: action.payload
            })
        case FETCH_STATS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                statusVeiculos: initialState,
            })
        default:
            return state
    }
}