import api from '../../../../../api'

export const FETCH_MAINTENCE_SCHEDULES_PENDING = 'FETCH_MAINTENCE_SCHEDULES_PENDING'
export const FETCH_MAINTENCE_SCHEDULES_FULFILLED = 'FETCH_MAINTENCE_SCHEDULES_FULFILLED'
export const FETCH_MAINTENCE_SCHEDULES_REJECTED = 'FETCH_MAINTENCE_SCHEDULES_REJECTED'

export const FETCH_PENDING_MAINTENCE_SCHEDULES_PENDING = 'FETCH_PENDING_MAINTENCE_SCHEDULES_PENDING'
export const FETCH_PENDING_MAINTENCE_SCHEDULES_FULFILLED = 'FETCH_PENDING_MAINTENCE_SCHEDULES_FULFILLED'
export const FETCH_PENDING_MAINTENCE_SCHEDULES_REJECTED = 'FETCH_PENDING_MAINTENCE_SCHEDULES_REJECTED'

export const FETCH_VEHICLE_MAINTENCE_SCHEDULES_PENDING = 'FETCH_VEHICLE_MAINTENCE_SCHEDULES_PENDING'
export const FETCH_VEHICLE_MAINTENCE_SCHEDULES_FULFILLED = 'FETCH_VEHICLE_MAINTENCE_SCHEDULES_FULFILLED'
export const FETCH_VEHICLE_MAINTENCE_SCHEDULES_REJECTED = 'FETCH_VEHICLE_MAINTENCE_SCHEDULES_REJECTED'

export const FETCH_MAINTENCE_SCHEDULE_PENDING = 'FETCH_MAINTENCE_SCHEDULE_PENDING'
export const FETCH_MAINTENCE_SCHEDULE_FULFILLED = 'FETCH_MAINTENCE_SCHEDULE_FULFILLED'
export const FETCH_MAINTENCE_SCHEDULE_REJECTED = 'FETCH_MAINTENCE_SCHEDULE_REJECTED'

export const CREATE_MAINTENCE_SCHEDULE_PENDING = 'CREATE_MAINTENCE_SCHEDULE_PENDING'
export const CREATE_MAINTENCE_SCHEDULE_FULFILLED = 'CREATE_MAINTENCE_SCHEDULE_FULFILLED'
export const CREATE_MAINTENCE_SCHEDULE_REJECTED = 'CREATE_MAINTENCE_SCHEDULE_REJECTED'

export const UPDATE_MAINTENCE_SCHEDULE_PENDING = 'UPDATE_MAINTENCE_SCHEDULE_PENDING'
export const UPDATE_MAINTENCE_SCHEDULE_FULFILLED = 'UPDATE_MAINTENCE_SCHEDULE_FULFILLED'
export const UPDATE_MAINTENCE_SCHEDULE_REJECTED = 'UPDATE_MAINTENCE_SCHEDULE_REJECTED'

export const CLEAR_MAINTENCE_SCHEDULE_PENDING = 'CLEAR_MAINTENCE_SCHEDULE_PENDING'
export const CLEAR_MAINTENCE_SCHEDULE_FULFILLED = 'CLEAR_MAINTENCE_SCHEDULE_FULFILLED'
export const CLEAR_MAINTENCE_SCHEDULE_REJECTED = 'CLEAR_MAINTENCE_SCHEDULE_REJECTED'

export const DELETE_MAINTENCE_SCHEDULE_PENDING = 'DELETE_MAINTENCE_SCHEDULE_PENDING'
export const DELETE_MAINTENCE_SCHEDULE_FULFILLED = 'DELETE_MAINTENCE_SCHEDULE_FULFILLED'
export const DELETE_MAINTENCE_SCHEDULE_REJECTED = 'DELETE_MAINTENCE_SCHEDULE_REJECTED'

export const CHANGE_MAINTENCE_SCHEDULE = 'CHANGE_MAINTENCE_SCHEDULE'

export const ADD_MAINTENCE_SCHEDULE = 'ADD_MAINTENCE_SCHEDULE'

const toFloat = (value) => {
  value = `${value}`.replace(",",".");
  return parseFloat(value);
}

export function changeMaintenceSchedule(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_MAINTENCE_SCHEDULE, payload: { name, value } })
  }
}
 
export function addMaintenceSchedule() {
  return dispatch => {
    dispatch({ type: ADD_MAINTENCE_SCHEDULE });
  };
}

export function findAllMaintenceSchedules(first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_MAINTENCE_SCHEDULES_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
                query findAllMaintenceSchedules {
                  agendasManutencao (first: ${first} offset: ${offset}) {
                    codigo
                    veiculo
                    planoManutencao
                    dataInicial
                    odometroAtual
                    agendaVeiculo {
                      placa
                      descricao
                    }
                    manutencaoPlano {
                      descricao
                    } 
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_MAINTENCE_SCHEDULES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { agendasManutencao } = data
        dispatch({ type: FETCH_MAINTENCE_SCHEDULES_FULFILLED, payload: agendasManutencao });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_MAINTENCE_SCHEDULES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAllMaintenceSchedulesByDescription(search = '', first = 1000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_MAINTENCE_SCHEDULES_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
                query findAllMaintenceSchedules {
                  agendasManutencaoPorDescricao (search: "${search}" first: ${first} offset: ${offset}) {
                    codigo
                    veiculo
                    planoManutencao
                    dataInicial
                    odometroAtual
                    agendaVeiculo {
                      placa
                      descricao
                    }
                    manutencaoPlano {
                      descricao
                    } 
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_MAINTENCE_SCHEDULES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { agendasManutencaoPorDescricao } = data
        dispatch({ type: FETCH_MAINTENCE_SCHEDULES_FULFILLED, payload: agendasManutencaoPorDescricao });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_MAINTENCE_SCHEDULES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAllPendingMaintenceSchedules(vehicle = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PENDING_MAINTENCE_SCHEDULES_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
                query findAllMaintencePlans {
                  agendasManutencaoPendente(veiculo: ${vehicle}) {
                    codigo
                    veiculo
                    planoManutencao
                    manutencaoPlano {
                      descricao
                      unidade
                      intervalo
                      antecipacao
                      tipoAntecipacao
                    } 
                    dataInicial
                    odometroAtual
                    agendaVeiculo {
                      placa
                      descricao
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_PENDING_MAINTENCE_SCHEDULES_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { agendasManutencaoPendente } = data
        dispatch({ type: FETCH_PENDING_MAINTENCE_SCHEDULES_FULFILLED, payload: agendasManutencaoPendente });
        resolve(agendasManutencaoPendente);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PENDING_MAINTENCE_SCHEDULES_REJECTED, error });
      reject(error);
    });

  });

}

export function findAllMaintenceSchedulesByVehicle(vehicle) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_VEHICLE_MAINTENCE_SCHEDULES_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
                query findAllMaintenceSchedules {
                  agendasManutencaoPorVeiculo (veiculo: ${vehicle}) {
                    codigo
                    veiculo
                    planoManutencao
                    manutencaoPlano {
                      descricao
                      unidade
                      intervalo
                      antecipacao
                      tipoAntecipacao
                    } 
                    dataInicial
                    odometroAtual
                    agendaVeiculo {
                      placa
                      descricao
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_VEHICLE_MAINTENCE_SCHEDULES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { agendasManutencaoPorVeiculo } = data
        dispatch({ type: FETCH_VEHICLE_MAINTENCE_SCHEDULES_FULFILLED, payload: agendasManutencaoPorVeiculo });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_VEHICLE_MAINTENCE_SCHEDULES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findMaintenceSchedule(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_MAINTENCE_SCHEDULE_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                    query findMaintenceSchedule {
                      agendaManutencao (codigo: ${codigo}) {
                        codigo
                        veiculo
                        planoManutencao
                        dataInicial
                        odometroAtual
                        reagendar
                        agendaVeiculo {
                          placa
                          descricao
                        }
                      }
                    }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_MAINTENCE_SCHEDULE_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { agendaManutencao } = data
        dispatch({ type: FETCH_MAINTENCE_SCHEDULE_FULFILLED, payload: agendaManutencao });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_MAINTENCE_SCHEDULE_REJECTED, error });
      resolve(error);
    });

  });

}

export function createMaintenceSchedule(maintenceSchedule) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_MAINTENCE_SCHEDULE_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation createMaintenceSchedule {
            createAgendaManutencao (input: {
              codigo: ${maintenceSchedule.codigo}
              veiculo: ${maintenceSchedule.veiculo}
              planoManutencao: ${maintenceSchedule.planoManutencao}
              dataInicial: "${maintenceSchedule.dataInicial}"
              odometroAtual: ${toFloat(maintenceSchedule.odometroAtual)}
              reagendar: ${maintenceSchedule.reagendar}
            } ) {
              codigo
              veiculo
              planoManutencao
              dataInicial
              odometroAtual
              reagendar
              agendaVeiculo {
                placa
                descricao
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_MAINTENCE_SCHEDULE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createAgendaManutencao } = data
        dispatch({ type: CREATE_MAINTENCE_SCHEDULE_FULFILLED, payload: createAgendaManutencao });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_MAINTENCE_SCHEDULE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateMaintenceSchedule(maintenceSchedule) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_MAINTENCE_SCHEDULE_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation UpdateMaintenceSchedule {
            updateAgendaManutencao (input: {
              codigo: ${maintenceSchedule.codigo}
              veiculo: ${maintenceSchedule.veiculo}
              planoManutencao: ${maintenceSchedule.planoManutencao}
              dataInicial: "${maintenceSchedule.dataInicial}"
              odometroAtual: ${toFloat(maintenceSchedule.odometroAtual)}
              reagendar: ${maintenceSchedule.reagendar}
            } ) {
              codigo
              veiculo
              planoManutencao
              dataInicial
              odometroAtual
              reagendar
              agendaVeiculo {
                placa
                descricao
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_MAINTENCE_SCHEDULE_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateAgendaManutencao } = data
        dispatch({ type: UPDATE_MAINTENCE_SCHEDULE_FULFILLED, payload: updateAgendaManutencao });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_MAINTENCE_SCHEDULE_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
}

export function clearMaintenceSchedule(maintenceSchedule) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CLEAR_MAINTENCE_SCHEDULE_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation ClearMaintenceSchedule {
            baixarAgendaManutencao (
              input: {
                codigo: ${maintenceSchedule.codigo}
                dataManutencao: "${maintenceSchedule.dataManutencao}"
                odometroAtual: ${toFloat(maintenceSchedule.odometro)}
                prorrogar: ${maintenceSchedule.prorrogar}
                valorProrrogar: "${maintenceSchedule.valorProrrogar}"
                intervalo: ${maintenceSchedule.intervalo}
                observacoes: "${maintenceSchedule.observacoes}"
              }
            )
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CLEAR_MAINTENCE_SCHEDULE_REJECTED, payload: errors });
        resolve(false);
      } else {
        const { baixarAgendaManutencao } = data
        dispatch({ type: CLEAR_MAINTENCE_SCHEDULE_FULFILLED, payload: baixarAgendaManutencao });
        resolve(baixarAgendaManutencao);
      }
    }).catch((errors) => {
      dispatch({ type: CLEAR_MAINTENCE_SCHEDULE_REJECTED, errors });
      resolve(false);
    });
  });
}

export function removeMaintenceSchedule(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_MAINTENCE_SCHEDULE_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation RemoveMaintenceSchedule {
            deleteAgendaManutencao (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_MAINTENCE_SCHEDULE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteAgendaManutencao } = data
        dispatch({ type: DELETE_MAINTENCE_SCHEDULE_FULFILLED, payload: deleteAgendaManutencao });
        dispatch(findAllMaintenceSchedules());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_MAINTENCE_SCHEDULE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
