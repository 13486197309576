import {

    FETCH_MAINTENCE_SCHEDULES_PENDING,
    FETCH_MAINTENCE_SCHEDULES_FULFILLED,
    FETCH_MAINTENCE_SCHEDULES_REJECTED,

    FETCH_VEHICLE_MAINTENCE_SCHEDULES_PENDING,
    FETCH_VEHICLE_MAINTENCE_SCHEDULES_FULFILLED,
    FETCH_VEHICLE_MAINTENCE_SCHEDULES_REJECTED,

    FETCH_PENDING_MAINTENCE_SCHEDULES_PENDING,
    FETCH_PENDING_MAINTENCE_SCHEDULES_FULFILLED,
    FETCH_PENDING_MAINTENCE_SCHEDULES_REJECTED,

    FETCH_MAINTENCE_SCHEDULE_PENDING,
    FETCH_MAINTENCE_SCHEDULE_FULFILLED,
    FETCH_MAINTENCE_SCHEDULE_REJECTED,

    CREATE_MAINTENCE_SCHEDULE_PENDING,
    CREATE_MAINTENCE_SCHEDULE_FULFILLED,
    CREATE_MAINTENCE_SCHEDULE_REJECTED,

    UPDATE_MAINTENCE_SCHEDULE_PENDING,
    UPDATE_MAINTENCE_SCHEDULE_FULFILLED,
    UPDATE_MAINTENCE_SCHEDULE_REJECTED,

    DELETE_MAINTENCE_SCHEDULE_PENDING,
    DELETE_MAINTENCE_SCHEDULE_FULFILLED,
    DELETE_MAINTENCE_SCHEDULE_REJECTED,
    
    CHANGE_MAINTENCE_SCHEDULE,
    
    ADD_MAINTENCE_SCHEDULE

} from './maintenceScheduleActions'

import { titleCase } from '../../../../../common';

const initialState = {
    loading: false,
    typesOfMaintence: [
        { value: "0", label: "Corretiva" },
        { value: "1", label: "Preditiva" },
        { value: "2", label: "Preventiva" },
        { value: "3", label: "Reforma" },
    ],
    listOfMaintenceSchedules: [],
    listOfVehicleMaintenceSchedules: [],
    listOfPendingMaintenace: [],
    maintenceSchedule: {
        codigo: 0,
        veiculo: 0,
        planoManutencao: 0,
        dataInicial: '',
        odometroAtual: 0,
        reagendar: 0,
        agendaVeiculo: {
            placa: '',
            descricao: ''
        },
        manutencaoPlano: {
            descricao: ''
        }
    },
    error: null,
}

export function maintenceScheduleReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_MAINTENCE_SCHEDULE:
        return Object.assign({}, state, {
            maintenceSchedule: { ...state.maintenceSchedule, 
                [action.payload.name]: titleCase(action.payload.value)
            }
        })
        case ADD_MAINTENCE_SCHEDULE: 
            return Object.assign({}, state, {
                maintenceSchedule: { ...initialState.maintenceSchedule }
            })

        case FETCH_MAINTENCE_SCHEDULES_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfMaintenceSchedules: [],
            })
        case FETCH_MAINTENCE_SCHEDULES_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfMaintenceSchedules: action.payload
            })
        case FETCH_MAINTENCE_SCHEDULES_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_VEHICLE_MAINTENCE_SCHEDULES_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfVehicleMaintenceSchedules: [],
            })
        case FETCH_VEHICLE_MAINTENCE_SCHEDULES_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfVehicleMaintenceSchedules: action.payload
            })
        case FETCH_VEHICLE_MAINTENCE_SCHEDULES_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_MAINTENCE_SCHEDULE_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //maintenceSchedule: { ...initialState.maintenceSchedule },
            })
        case CREATE_MAINTENCE_SCHEDULE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                maintenceSchedule: action.payload
            })
        case CREATE_MAINTENCE_SCHEDULE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_MAINTENCE_SCHEDULE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_MAINTENCE_SCHEDULE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                maintenceSchedule: action.payload
            })
        case UPDATE_MAINTENCE_SCHEDULE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_MAINTENCE_SCHEDULE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_MAINTENCE_SCHEDULE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                maintenceSchedule: action.payload
            })
        case FETCH_MAINTENCE_SCHEDULE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_MAINTENCE_SCHEDULE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_MAINTENCE_SCHEDULE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_MAINTENCE_SCHEDULE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_PENDING_MAINTENCE_SCHEDULES_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })

        case FETCH_PENDING_MAINTENCE_SCHEDULES_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfPendingMaintenace: action.payload
            })

        case FETCH_PENDING_MAINTENCE_SCHEDULES_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}