import api from '../../../../api'

export const FETCH_EVENTS_DRIVER_PENDING = 'FETCH_EVENTS_DRIVER_PENDING'
export const FETCH_EVENTS_DRIVER_FULFILLED = 'FETCH_EVENTS_DRIVER_FULFILLED'
export const FETCH_EVENTS_DRIVER_REJECTED = 'FETCH_EVENTS_DRIVER_REJECTED'

export const DELETE_EVENTS_DRIVER_PENDING = 'DELETE_EVENTS_DRIVER_PENDING'
export const DELETE_EVENTS_DRIVER_FULFILLED = 'DELETE_EVENTS_DRIVER_FULFILLED'
export const DELETE_EVENTS_DRIVER_REJECTED = 'DELETE_EVENTS_DRIVER_REJECTED'

export const CHANGE_EVENTS_DRIVER = 'CHANGE_EVENTS_DRIVER'

export function changeEventsDriverPeriod(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_EVENTS_DRIVER, payload: { name, value } })
  }
}

export function findAllEventsDriverPeriod(filter) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_EVENTS_DRIVER_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllEventsDriverPeriod {
                  eventosMotoristaPorMotorista (input: {
                    motorista: ${filter.motorista}
                    matriz: ${filter.matriz}
                    dataHoraInicial: "${filter.dataHoraInicial}"
                    dataHoraFinal: "${filter.dataHoraFinal}"
                  }) {
                    nome
                    conducaoBanguela
                    velocidadeSeco
                    velocidadeChuva
                    excessoRpm
                    freadaBrusca
                    aceleracaoMovimento
                    aceleracaoParado
                    excessoTemperatura
                    entradaUm
                    entradaDois
                    entradaTres
                    entradaQuatro
                    entradaCinco
                    entradaSeis
                    entradaSete
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_EVENTS_DRIVER_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { eventosMotoristaPorMotorista } = data
        dispatch({ type: FETCH_EVENTS_DRIVER_FULFILLED, payload: eventosMotoristaPorMotorista });
        resolve(eventosMotoristaPorMotorista);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_EVENTS_DRIVER_REJECTED, error });
      reject(error);
    });

  });

}
