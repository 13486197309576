import { SHOW_SAVED_TOAST_MESSAGE, SHOW_REMOVED_TOAST_MESSAGE } from '../../../../../common/layout/components/header/store';
import api from '../../../../../api';

export const FETCH_VEHICLE_PORTS_PENDING = 'FETCH_VEHICLE_PORTS_PENDING';
export const FETCH_VEHICLE_PORTS_FULFILLED = 'FETCH_VEHICLE_PORTS_FULFILLED';
export const FETCH_VEHICLE_PORTS_REJECTED = 'FETCH_VEHICLE_PORTS_REJECTED';

export const FETCH_VEHICLE_PORT_PENDING = 'FETCH_VEHICLE_PORT_PENDING';
export const FETCH_VEHICLE_PORT_FULFILLED = 'FETCH_VEHICLE_PORT_FULFILLED';
export const FETCH_VEHICLE_PORT_REJECTED = 'FETCH_VEHICLE_PORT_REJECTED';

export const CREATE_VEHICLE_PORT_PENDING = 'CREATE_VEHICLE_PORT_PENDING';
export const CREATE_VEHICLE_PORT_FULFILLED = 'CREATE_VEHICLE_PORT_FULFILLED';
export const CREATE_VEHICLE_PORT_REJECTED = 'CREATE_VEHICLE_PORT_REJECTED';

export const UPDATE_VEHICLE_PORT_PENDING = 'UPDATE_VEHICLE_PORT_PENDING';
export const UPDATE_VEHICLE_PORT_FULFILLED = 'UPDATE_VEHICLE_PORT_FULFILLED';
export const UPDATE_VEHICLE_PORT_REJECTED = 'UPDATE_VEHICLE_PORT_REJECTED';

export const DELETE_VEHICLE_PORT_PENDING = 'DELETE_VEHICLE_PORT_PENDING';
export const DELETE_VEHICLE_PORT_FULFILLED = 'DELETE_VEHICLE_PORT_FULFILLED';
export const DELETE_VEHICLE_PORT_REJECTED = 'DELETE_VEHICLE_PORT_REJECTED';

export const CHANGE_VEHICLE_PORT = 'CHANGE_VEHICLE_PORT';
export const ADD_VEHICLE_PORT = 'ADD_VEHICLE_PORT';

export function toJsonGraphQL(json) {
  let result = "";
  JSON.stringify(json, function(key, value) {
      if (key.trim() !== "") {
          result += `${key}: \\\"${value}\\\",`;
      }
      return value;
  })
  return result.substr(0, result.length - 1);
}

export function changeVehiclePort(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_VEHICLE_PORT, payload: { name, value } })
  }
}

export function addVehiclePort() {
  return dispatch => {
    dispatch({ type: ADD_VEHICLE_PORT });
  };
}

export function findAllVehiclePorts(matriz = 1, first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_VEHICLE_PORTS_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findAllVehiclePorts {
                  veiculoPortas (first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                    rastreador
                    telemetria
                    container
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_VEHICLE_PORTS_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { veiculoPortas } = data
        dispatch({ type: FETCH_VEHICLE_PORTS_FULFILLED, payload: veiculoPortas });
        resolve(data);
      }
    }).catch((errors) => {
      dispatch({ type: FETCH_VEHICLE_PORTS_REJECTED, errors });
      reject(errors);
    });

  });

}

export function findAllVehiclePortsByDescription(search = '', first = 1000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_VEHICLE_PORTS_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findAllVehiclePortsByDescription {
                  veiculoPortasPorDescricao (search: "${search}" first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                    rastreador
                    telemetria
                    container
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_VEHICLE_PORTS_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { veiculoPortasPorDescricao } = data
        dispatch({ type: FETCH_VEHICLE_PORTS_FULFILLED, payload: veiculoPortasPorDescricao });
        resolve(veiculoPortasPorDescricao);
      }
    }).catch((errors) => {
      dispatch({ type: FETCH_VEHICLE_PORTS_REJECTED, errors });
      reject(errors);
    });

  });

}

export function findVehiclePort(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_VEHICLE_PORT_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                    query findVehiclePort {
                      veiculoPorta (codigo: ${codigo}) {
                        codigo
                        matriz
                        descricao
                        rastreador
                        telemetria
                        container
                        clienteMatriz {
                          codigo
                          razaoSocial
                        }
                      }
                    }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_VEHICLE_PORT_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { veiculoPorta } = data
        dispatch({ type: FETCH_VEHICLE_PORT_FULFILLED, payload: veiculoPorta });
        resolve(data);
      }
    }).catch((errors) => {
      dispatch({ type: FETCH_VEHICLE_PORT_REJECTED, errors });
      reject(errors);
    });

  });

}

export function createVehiclePort(vehiclePort) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_VEHICLE_PORT_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation createVehiclePort {
            createVeiculoPorta (input: {
              codigo: ${vehiclePort.codigo}
              matriz: ${vehiclePort.matriz}
              descricao: "${vehiclePort.descricao}"
              rastreador: "\{ ${toJsonGraphQL(vehiclePort.rastreador)} }\"
              telemetria: "\{ ${toJsonGraphQL(vehiclePort.telemetria)} }\"
              container: "\{ ${toJsonGraphQL(vehiclePort.container)} }\"
            } ) {
              codigo
              matriz
              descricao
              rastreador
              telemetria
              container
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_VEHICLE_PORT_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { createVeiculoPorta } = data
        dispatch({ type: CREATE_VEHICLE_PORT_FULFILLED, payload: createVeiculoPorta });
        dispatch({ type: SHOW_SAVED_TOAST_MESSAGE });
        dispatch(findAllVehiclePorts());
        resolve({ status: true, data: createVeiculoPorta });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_VEHICLE_PORT_REJECTED, errors });
      reject(errors);
    });
  });
}

export function updateVehiclePort(vehiclePort) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_VEHICLE_PORT_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation UpdateVehiclePort {
            updateVeiculoPorta (input: {
              codigo: ${vehiclePort.codigo}
              matriz: ${vehiclePort.matriz}
              descricao: "${vehiclePort.descricao}"
              rastreador: "\{ ${toJsonGraphQL(vehiclePort.rastreador)} }\"
              telemetria: "\{ ${toJsonGraphQL(vehiclePort.telemetria)} }\"
              container: "\{ ${toJsonGraphQL(vehiclePort.container)} }\"
            } ) {
              codigo
              matriz
              descricao
              rastreador
              telemetria
              container
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_VEHICLE_PORT_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { updateVeiculoPorta } = data
        dispatch({ type: UPDATE_VEHICLE_PORT_FULFILLED, payload: updateVeiculoPorta });
        dispatch({ type: SHOW_SAVED_TOAST_MESSAGE });
        dispatch(findAllVehiclePorts());
        resolve({ status: true, data: updateVeiculoPorta });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_VEHICLE_PORT_REJECTED, errors });
      reject(errors);
    });
  });
}

export function removeVehiclePort(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_VEHICLE_PORT_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation RemoveVehiclePort {
            deleteVeiculoPorta (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_VEHICLE_PORT_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { deleteVeiculoPorta } = data
        dispatch({ type: DELETE_VEHICLE_PORT_FULFILLED, payload: deleteVeiculoPorta });
        dispatch({ type: SHOW_REMOVED_TOAST_MESSAGE });
        dispatch(findAllVehiclePorts());
        resolve();
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_VEHICLE_PORT_REJECTED, errors });
      reject(errors);
    });
  });
}
