import api from '../../../../api'

export const FETCH_FUEL_RPM_SPEED_TEMPERATURE_PENDING = 'FETCH_FUEL_RPM_SPEED_TEMPERATURE_PENDING'
export const FETCH_FUEL_RPM_SPEED_TEMPERATURE_FULFILLED = 'FETCH_FUEL_RPM_SPEED_TEMPERATURE_FULFILLED'
export const FETCH_FUEL_RPM_SPEED_TEMPERATURE_REJECTED = 'FETCH_FUEL_RPM_SPEED_TEMPERATURE_REJECTED'

export const DELETE_FUEL_RPM_SPEED_TEMPERATURE_PENDING = 'DELETE_FUEL_RPM_SPEED_TEMPERATURE_PENDING'
export const DELETE_FUEL_RPM_SPEED_TEMPERATURE_FULFILLED = 'DELETE_FUEL_RPM_SPEED_TEMPERATURE_FULFILLED'
export const DELETE_FUEL_RPM_SPEED_TEMPERATURE_REJECTED = 'DELETE_FUEL_RPM_SPEED_TEMPERATURE_REJECTED'

export const CHANGE_FUEL_RPM_SPEED_TEMPERATURE = 'CHANGE_FUEL_RPM_SPEED_TEMPERATURE'

export const DEFAULT_FUEL_RPM_SPEED_TEMPERATURE = 'DEFAULT_FUEL_RPM_SPEED_TEMPERATURE'

export function defaultFuelRpmSpeedTemperature() { 
  return dispatch => {
    dispatch({ type: DEFAULT_FUEL_RPM_SPEED_TEMPERATURE })
  }
}

export function changeFuelRpmSpeedTemperature(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_FUEL_RPM_SPEED_TEMPERATURE, payload: { name, value } })
  }
}

export function findAllFuelRpmSpeedTemperatures(filter, first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_FUEL_RPM_SPEED_TEMPERATURE_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllFuelRpmSpeedTemperatures {
                  historicoPosicoesTelemetria (input: {
                    veiculo: ${filter.veiculo}
                    motorista: ${filter.motorista}
                    garagem: ${filter.garagem}
                    evento: ${filter.evento} 
                    velocidadeTipo: ${filter.velocidadeTipo}
                    velocidadeValor: ${filter.velocidadeValor}
                    rpmTipo: ${filter.rpmTipo}
                    rpmValor: ${filter.rpmValor}
                    entrada1: ${filter.entrada1}
                    entrada2: ${filter.entrada2}
                    entrada3: ${filter.entrada3}
                    entrada4: ${filter.entrada4}
                    entrada5: ${filter.entrada5}
                    entrada6: ${filter.entrada6}
                    entrada7: ${filter.entrada7}
                    entrada8: ${filter.entrada8}
                    saida1: ${filter.saida1}
                    saida2: ${filter.saida2}
                    saida3: ${filter.saida3}
                    saida4: ${filter.saida4}
                    dataHoraInicial: "${filter.dataHoraInicial}"
                    dataHoraFinal: "${filter.dataHoraFinal}"
                  } first: ${first} offset: ${offset}) {
                    veiculo {
                      placa
                      descricao
                      clienteMatriz {
                        razaoSocial
                      }
                      veiculoGaragem {
                        descricao
                      }
                      capacidadeTanque
                      tensaoVazio
                      tensao1Quarto
                      tensaoMeio
                      tensao3Quartos
                      tensaoCheio
                      leituraInversa
                    }
                    modulo
                    dataHoraServidor
                    dataHoraGps
                    latitude
                    longitude
                    numeroLog
                    tipoEquipamento
                    codigoLog
                    dataHoraTelemetria
                    odometro
                    velocidade
                    rpm
                    temperatura
                    valorAnalogico
                    entradaUm
                    entradaDois
                    entradaTres
                    entradaQuatro
                    entradaCinco
                    entradaSeis
                    entradaSete
                    entradaOito
                    saidaUm
                    saidaDois
                    saidaTres
                    saidaQuatro
                    serialRfid
                    contadorEntradaUm
                    contadorEntradaDois
                    contadorEntradaTres
                    contadorEntradaQuatro
                    contadorEntradaCinco
                    contadorEntradaSeis
                    contadorEntradaSete
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_FUEL_RPM_SPEED_TEMPERATURE_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { historicoPosicoesTelemetria } = data
        dispatch({ type: FETCH_FUEL_RPM_SPEED_TEMPERATURE_FULFILLED, payload: historicoPosicoesTelemetria });
        resolve(historicoPosicoesTelemetria);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_FUEL_RPM_SPEED_TEMPERATURE_REJECTED, error });
      reject(error);
    });

  });

}
