import api from '../../../../api'

export const FETCH_IDLE_ENGINE_PENDING = 'FETCH_IDLE_ENGINE_PENDING'
export const FETCH_IDLE_ENGINE_FULFILLED = 'FETCH_IDLE_ENGINE_FULFILLED'
export const FETCH_IDLE_ENGINE_REJECTED = 'FETCH_IDLE_ENGINE_REJECTED'

export const CHANGE_IDLE_ENGINE = 'CHANGE_IDLE_ENGINE'

export function changeIdleEngine(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_IDLE_ENGINE, payload: { name, value } })
  }
}

export function findAllIdleEngine(idleEngine) {
  
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_IDLE_ENGINE_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllIdleEngine {
                  historicoMotorOcioso (input: {
                    veiculo: ${idleEngine.veiculo}
                    dataHoraInicial: "${idleEngine.dataHoraInicial}"
                    dataHoraFinal: "${idleEngine.dataHoraFinal}" }) {
                      motorista {
                        nome
                      }
                      dataHoraGps
                      endereco
                      latitude
                      longitude
                      contadorEntradaUm
                    }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_IDLE_ENGINE_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { historicoMotorOcioso } = data
        dispatch({ type: FETCH_IDLE_ENGINE_FULFILLED, payload: historicoMotorOcioso });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_IDLE_ENGINE_REJECTED, error });
      reject(error);
    });

  });

}
