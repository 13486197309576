import React from "react";
import decode from "jwt-decode";
import swal from 'sweetalert';

import { format, isBefore, addDays, addMonths, parse, differenceInHours, differenceInMinutes } from 'date-fns';

import { config } from "../../../config/config";

export function empty(e) {
  if ((e) && (e instanceof String)) e = e.replace(/\s/g, "");
  switch (e) {
    case "":
    case 0:
    case "0":
    case null:
    case false:
    case undefined:
    case typeof (e) == "undefined":
      return true;
    default:
      return false;
  }
}

export function formatTime(seconds) {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.round(seconds % 60);
  return [
    h > 9 ? h : (h ? '0' + h : h || '00'),
    m > 9 ? m : (m ? '0' + m : m || '00'),
    s > 9 ? s : '0' + s
  ].filter(Boolean).join(':');
}

export function titleCase(str) {
  if (!str || !(typeof str === 'string')) return str;
  return str.toLowerCase().split(' ').map(function (word) {
    return (word.charAt(0).toUpperCase() + word.slice(1));
  }).join(' ');
}

export const toDecimalScale = (value) => {
  let decimal = toFloat(value).toFixed(2);
  return decimal.replace('.',',');
}

export function toFloat(value) {
  return isNaN(parseFloat(`${value}`.replace(",", "."))) ? 0 : parseFloat(`${value}`.replace(",", "."));
}

export function toInt(value) {
  return isNaN(parseInt(value)) ? 0 : parseInt(value);
}

export function testBit(byte, bit) {
  return ((byte >> bit) % 2 !== 0);
}

export function between(number, from, to) {
  return (number >> from) & ((1 << to - from) - 1);
}

export function from(number, from) {
  return number >> from;
}

export function to(number, to) {
  return this.between(number, 0, to);
}

export function setBit(byte, bit) {
  return (byte | (1 << bit));
}

export function clearBit(byte, bit) {
  return byte & ~(1 << bit);
}

export function parseBool(b) {
  return !(/^(false|0)$/i).test(b) && !!b;
}

export function combustivelValor(i_min_1_4, i_max_1_4, i_min_1_2, i_max_1_2,
  i_min_3_4, i_max_3_4, i_min_1, i_max_1, i_total_litros, i_equivalencia,
  i_valor_lido) {

  let indice_equ_nivel_min_1_4 = 0;
  let indice_equ_nivel_max_1_4 = 0;
  let indice_equ_nivel_min_1_2 = 0;
  let indice_equ_nivel_max_1_2 = 0;
  let indice_equ_nivel_min_3_4 = 0;
  let indice_equ_nivel_max_3_4 = 0;
  let indice_equ_nivel_min_1 = 0;
  let indice_equ_nivel_max_1 = 0;
  let indice_equ_nivel_total_litros = 0;
  let litrosportensao = 0;
  let litrosporfaixa = 0;

  let range = 0;
  let maxfaixa = 0;
  let minfaixa = 0;
  let resultado = 0;
  let tensao = (i_valor_lido * 0.003225806);
  let nivel = 0;

  let result = {
    valor: 0,
    nivel: 0,
  };

  indice_equ_nivel_min_1_4 = i_min_1_4; //-- "tensao min. 1/4" de configuracoes avancadas)
  indice_equ_nivel_max_1_4 = i_max_1_4; //-- "tensao max. 1/4" de configuracoes avancadas)

  indice_equ_nivel_min_1_2 = i_max_1_4; //-- "tensao min. 1/2" de configuracoes avancadas)
  indice_equ_nivel_max_1_2 = i_max_1_2; //-- "tensao max. 1/2" de configuracoes avancadas)

  indice_equ_nivel_min_3_4 = i_max_1_2; //-- "tensao min. 3/4" de configuracoes avancadas)
  indice_equ_nivel_max_3_4 = i_max_3_4; //-- "tensao max. 3/4" de configuracoes avancadas)

  indice_equ_nivel_min_1 = i_max_3_4; //-- "tensao min. cheio" de configuracoes avancadas)
  indice_equ_nivel_max_1 = i_max_1;   //-- "tensao max. cheio" de configuracoes avancadas

  indice_equ_nivel_total_litros = i_total_litros;

  result.valor = 0;
  result.nivel = 0;

  if ((indice_equ_nivel_min_1_4 > 0) && (indice_equ_nivel_max_1_4 > 0) &&
    (indice_equ_nivel_min_1_2 > 0) && (indice_equ_nivel_max_1_2 > 0) &&
    (indice_equ_nivel_min_3_4 > 0) && (indice_equ_nivel_max_3_4 > 0) &&
    (indice_equ_nivel_min_1 > 0) && (indice_equ_nivel_max_1 > 0) &&
    (indice_equ_nivel_total_litros > 0)) {

    if (!i_equivalencia) {

      indice_equ_nivel_min_1_4 = i_min_1_4; //-- "tensao min. 1/4" de configuracoes avancadas)
      indice_equ_nivel_max_1_4 = i_max_1_4; //-- "tensao max. 1/4" de configuracoes avancadas)

      indice_equ_nivel_min_1_2 = i_max_1_4; //-- "tensao min. 1/2" de configuracoes avancadas)
      indice_equ_nivel_max_1_2 = i_max_1_2; //-- "tensao max. 1/2" de configuracoes avancadas)

      indice_equ_nivel_min_3_4 = i_max_1_2; //-- "tensao min. 3/4" de configuracoes avancadas)
      indice_equ_nivel_max_3_4 = i_max_3_4; //-- "tensao max. 3/4" de configuracoes avancadas)

      indice_equ_nivel_min_1 = i_max_3_4; //-- "tensao min. cheio" de configuracoes avancadas)
      indice_equ_nivel_max_1 = i_max_1;   //-- "tensao max. cheio" de configuracoes avancadas

      //-- se for leitura normal (campo "leitura inversa" de configuracoes avancadas nao esta marcado!)
      if ((tensao >= indice_equ_nivel_min_1_4) && (tensao <= indice_equ_nivel_max_1_4)) {
        range = indice_equ_nivel_max_1_4 - indice_equ_nivel_min_1_4;
        maxfaixa = indice_equ_nivel_max_1_4;
        minfaixa = indice_equ_nivel_min_1_4;
        nivel = 0;
      } else if ((tensao >= indice_equ_nivel_max_1_4) && (tensao <= indice_equ_nivel_max_1_2)) {
        range = indice_equ_nivel_max_1_2 - indice_equ_nivel_max_1_4;
        maxfaixa = indice_equ_nivel_max_1_2;
        minfaixa = indice_equ_nivel_max_1_4;
        nivel = 1;
      } else if ((tensao >= indice_equ_nivel_max_1_2) && (tensao <= indice_equ_nivel_max_3_4)) {
        range = indice_equ_nivel_max_3_4 - indice_equ_nivel_max_1_2;
        maxfaixa = indice_equ_nivel_max_3_4;
        minfaixa = indice_equ_nivel_max_1_2;
        nivel = 2;
      } else if ((tensao >= indice_equ_nivel_max_3_4) && (tensao <= indice_equ_nivel_max_1)) {
        range = indice_equ_nivel_max_1 - indice_equ_nivel_max_3_4;
        maxfaixa = indice_equ_nivel_max_1;
        minfaixa = indice_equ_nivel_max_3_4;
        nivel = 3;
      } else if (tensao < indice_equ_nivel_min_1_4) {
        range = indice_equ_nivel_max_1_4 - indice_equ_nivel_min_1_4;
        maxfaixa = indice_equ_nivel_max_1_4;
        minfaixa = indice_equ_nivel_min_1_4;
        nivel = 0;
      } else if (tensao > indice_equ_nivel_max_1) {
        range = indice_equ_nivel_max_1 - indice_equ_nivel_max_3_4;
        maxfaixa = indice_equ_nivel_max_1;
        minfaixa = indice_equ_nivel_max_3_4;
        nivel = 3;
      }
    } else {
      indice_equ_nivel_min_1_4 = i_max_1;
      indice_equ_nivel_max_1_4 = i_max_3_4;

      indice_equ_nivel_min_1_2 = i_max_3_4;
      indice_equ_nivel_max_1_2 = i_max_1_2;

      indice_equ_nivel_min_3_4 = i_max_1_2;
      indice_equ_nivel_max_3_4 = i_max_1_4;

      indice_equ_nivel_min_1 = i_max_1_4;
      indice_equ_nivel_max_1 = i_min_1_4;

      // -- se for leitura inversa (campo "leitura inversa" de configuracoes avancadas esta marcado!)
      if ((tensao >= indice_equ_nivel_max_1_4) && (tensao <= indice_equ_nivel_min_1_4)) {
        range = indice_equ_nivel_min_1_4 - indice_equ_nivel_max_1_4;
        maxfaixa = indice_equ_nivel_min_1_4;
        minfaixa = indice_equ_nivel_max_1_4;
        nivel = 0;
      } else if ((tensao >= indice_equ_nivel_max_1_2) && (tensao <= indice_equ_nivel_max_1_4)) {
        range = indice_equ_nivel_max_1_4 - indice_equ_nivel_max_1_2;
        maxfaixa = indice_equ_nivel_max_1_4;
        minfaixa = indice_equ_nivel_max_1_2;
        nivel = 1;
      } else if ((tensao >= indice_equ_nivel_max_3_4) && (tensao <= indice_equ_nivel_max_1_2)) {
        range = indice_equ_nivel_max_1_2 - indice_equ_nivel_max_3_4;
        maxfaixa = indice_equ_nivel_max_1_2;
        minfaixa = indice_equ_nivel_max_3_4;
        nivel = 2;
      } else if ((tensao >= indice_equ_nivel_max_1) && (tensao <= indice_equ_nivel_max_3_4)) {
        range = indice_equ_nivel_max_3_4 - indice_equ_nivel_max_1;
        maxfaixa = indice_equ_nivel_max_3_4;
        minfaixa = indice_equ_nivel_max_1;
        nivel = 3;
      } else if (tensao < indice_equ_nivel_min_1_4) {
        range = indice_equ_nivel_max_3_4 - indice_equ_nivel_max_1;
        maxfaixa = indice_equ_nivel_max_3_4;
        minfaixa = indice_equ_nivel_max_1;
        nivel = 3;
      } else if (tensao > indice_equ_nivel_max_1) {
        range = indice_equ_nivel_min_1_4 - indice_equ_nivel_max_1_4;
        maxfaixa = indice_equ_nivel_min_1_4;
        minfaixa = indice_equ_nivel_max_1_4;
        nivel = 0;
      }
    }

    if (indice_equ_nivel_total_litros > 0) {
      litrosporfaixa = (indice_equ_nivel_total_litros / 4);
    }

    if ((litrosporfaixa > 0) && (range > 0)) {
      litrosportensao = (litrosporfaixa / range);
    }

    if (!i_equivalencia) {
      //-- se for leitura normal (campo "leitura inversa" de configuracoes avancadas nao esta marcado!)
      resultado = (litrosportensao * (tensao - minfaixa)) + (nivel * litrosporfaixa);
    } else {
      //-- se for leitura inversa (campo "leitura inversa" de configuracoes avancadas esta marcado!)
      resultado = (litrosportensao * (maxfaixa - tensao)) + (nivel * litrosporfaixa);
    }

    //-- checa limites minimo e maximo
    if (resultado < 0) {
      resultado = 0;
    } else if (resultado > indice_equ_nivel_total_litros) {
      resultado = indice_equ_nivel_total_litros;
    }

    // -- retorna o valor em litros calculado de acordo com o valor de tensao passado como parametro
    result.valor = resultado;
    result.nivel = nivel;

  }

  return result.valor || 0;

}

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export function getContrastYIQ(hexcolor){
  hexcolor = hexcolor.replace("#", "");
  var r = parseInt(hexcolor.substr(0,2),16);
  var g = parseInt(hexcolor.substr(2,2),16);
  var b = parseInt(hexcolor.substr(4,2),16);
  var yiq = ((r*299)+(g*587)+(b*114))/1000;
  return (yiq >= 128) || isNaN(yiq) ? 'black' : 'white';
}

export function toJSON(json) {
  let result = {};
  json.replace("{","").replace("}","").trim().split(",").forEach((element) => {
      let data = element.split(":");
      result = { 
          ...result, 
          [data[0]]: data[1].trim().replace(/['"]+/g, '')
      };
      
  });
  return result;
}

export function beforeMaskedValueChange(newState, oldState, userInput) {
  var { value } = newState;
  var selection = newState.selection;
  var cursorPosition = selection ? selection.start : null;
  if ((userInput !== null) && (userInput.trim() !== "") && (parseInt(userInput) >= 0)) {
    if (oldState.value.length === 14) {
      if (cursorPosition === value.length) {
        cursorPosition += 2;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value + userInput;
      this.setState({ maskCpfCnpj: "99.999.999/9999-99"})
    }
  } else
    if ((oldState.value.length >= 15) && (newState.value.length <= 14))  {
      this.setState({ maskCpfCnpj: "999.999.999-99"})
    }
  return { value, selection };
}

export function range(start, end) {
  return Array(20).fill().map((_, idx) => start + idx)
}

export const getUnity = (unity) => ({
	0: 'Quilômetro',
	1: 'Dia',
    2: 'Mês',
    3: 'Horas',
}[unity] || '----')

export const getUnityLabel = (unity, all = true) => ({
	0: 'Km',
	1: all ? 'Dia(s)' : '',
  2: all ? 'Mês(es)' : '',
  3: all ? 'Hora(s)' : '',
}[unity] || '');

export const handleClearStorage = () => {
  return new Promise(async (resolve, reject) => {
    try {
      await localStorage.removeItem("@monitoras-web/gensetDashboard/gensets");
      await localStorage.removeItem("@monitoras-web/vehiclesInMap/vehicles");
      await localStorage.removeItem("@monitoras-web/vehiclesTelemetry/vehicles");
      await localStorage.removeItem("@monitoras-web/filterOptions");
      return resolve(true);
    } catch (err) {
      reject(err);
    };
  });
}

export const resetHistory = (history, path) => {
  history.entries = [];
  history.index = -1;
  history.push(path);
}

export const decodeToken = () => {
  const token = localStorage.getItem(config.tokenKey);
  return decode(token);
}

export const nullOrEmpty = (field) => {
  return !field || field === null || field.trim() === "";
}

export const Loading = () => {
  return (<i className="fa fa-spinner fa-spin fadeInOut" style={{ fontSize: "24px", opacity: 0.8 }} />);
}

export const showMessage = (message = '', icon = "success") => {
  swal(message, {
    icon
  });
}

export const getMaintenanceColor = (maintenance) => {
  switch(maintenance) {
      case 1: return 'green';
      case 2: return '#eae352';
      case 3: return 'red';
      default: return 'black';
  }
}

export const getMaintenanceTitle = (maintenance) => {
  switch(maintenance) {
      case 2: return 'À Vencer';
      case 3: return 'Vencida';
      default: return 'Dentro do Prazo';
  }
}

export const getLastMaintenance = (row) => {
  switch (row.manutencaoPlano.unidade) {
      case 0:
          return `${row.odometroAtual} ${getUnityLabel(row.manutencaoPlano.unidade)}`;
      case 3:
          return `${row.odometroAtual} ${getUnityLabel(row.manutencaoPlano.unidade)}`;
      default:
          return `${row.dataInicial}`;
  }
}

export const getNextMaintenance = (row) => {
  switch (row.manutencaoPlano.unidade) {
      case 0:
          return `${(row.odometroAtual + row.manutencaoPlano.intervalo)}`;
      case 1:
          return `${format(addDays(parse(row.dataInicial, 'dd/MM/yyyy', new Date()), row.manutencaoPlano.intervalo), 'dd/MM/yyyy')}`;
      case 2:
          switch (row.manutencaoPlano.tipoAntecipacao) {
              case 1:
                  return `${format(addMonths(parse(row.dataInicial, 'dd/MM/yyyy', new Date()), row.manutencaoPlano.intervalo), 'dd/MM/yyyy')}`;
              default:
                  return `${format(addMonths(parse(row.dataInicial, 'dd/MM/yyyy', new Date()), row.manutencaoPlano.intervalo), 'dd/MM/yyyy')}`;
          }
      case 3:
          return `${row.odometroAtual}`;
      default:
          return `${format(parse(row.dataInicial, 'dd/MM/yyyy', new Date()), 'dd/MM/yyyy')}`;
  }
}

export const getStatusMaintenance = (row, vehicle) => {
  if (!vehicle) return;
  let nextMaintenance;
  let nextAnticipation;
  switch (row.manutencaoPlano.unidade) {
      case 0:
          // se odometro atual do veiculo > que o odometro do agendamento + o valor do intervalo
          if (parseFloat(vehicle.odometro.replace('km', '').trim()) >= (row.odometroAtual + row.manutencaoPlano.intervalo)) {
              return 3;
              // se odometro atual do veiculo > que o odometro do agendamento + o valor do intervalo - o valor da antecipacao
          } else if (parseFloat(vehicle.odometro.replace('km', '').trim()) >= (row.odometroAtual + (row.manutencaoPlano.intervalo - row.manutencaoPlano.antecipacao))) {
              return 2;
          } else {
              return 1;
          }
      case 1:
          // pega proxima data de vencimento a partir da data inicial do agendamento + o valor do intervalo
          nextMaintenance = format(addDays(parse(row.dataInicial, 'dd/MM/yyyy', new Date()), row.manutencaoPlano.intervalo), 'dd/MM/yyyy');
          // pega proxima data de vencimento a partir da data inicial do agendamento + o valor do intervalo - o valor da antecipacao
          nextAnticipation = format(addDays(parse(row.dataInicial, 'dd/MM/yyyy', new Date()), (row.manutencaoPlano.intervalo - row.manutencaoPlano.antecipacao)), 'dd/MM/yyyy');
          // se a data calculada sem antecipacao for maior que a data atual da posicao do veículo, manutencao vencida
          if (isBefore(parse(nextMaintenance, 'dd/MM/yyyy', new Date()), parse(vehicle.dataHoraGps, 'dd/MM/yyyy HH:mm:ss', new Date()))) {
              return 3;
              // se a data calculada com antecipacao for maior que a data atual da posicao do veículo, manutencao vencida
          } else if (isBefore(parse(nextAnticipation, 'dd/MM/yyyy', new Date()), parse(vehicle.dataHoraGps, 'dd/MM/yyyy HH:mm:ss', new Date()))) {
              return 2;
          } else {
              return 1;
          }
      case 2:
          switch (row.manutencaoPlano.tipoAntecipacao) {
              case 1:
                  // pega proxima data de vencimento a partir da data inicial do agendamento + o valor do intervalo
                  nextMaintenance = format(addDays(parse(row.dataInicial, 'dd/MM/yyyy', new Date()), row.manutencaoPlano.intervalo), 'dd/MM/yyyy');
                  // pega proxima data de vencimento a partir da data inicial do agendamento + o valor do intervalo - o valor da antecipacao
                  nextAnticipation = format(addDays(parse(row.dataInicial, 'dd/MM/yyyy', new Date()), (row.manutencaoPlano.intervalo - row.manutencaoPlano.antecipacao)), 'dd/MM/yyyy');
                  // se a data calculada sem antecipacao for maior que a data atual da posicao do veículo, manutencao vencida
                  if (isBefore(parse(nextMaintenance, 'dd/MM/yyyy', new Date()), parse(vehicle.dataHoraGps, 'dd/MM/yyyy HH:mm:ss', new Date()))) {
                      return 3;
                      // se a data calculada com antecipacao for maior que a data atual da posicao do veículo, manutencao vencida
                  } else if (isBefore(parse(nextAnticipation, 'dd/MM/yyyy', new Date()), parse(vehicle.dataHoraGps, 'dd/MM/yyyy HH:mm:ss', new Date()))) {
                      return 2;
                  } else {
                      return 1;
                  }
              default:
                  // pega proxima data de vencimento a partir da data inicial do agendamento + o valor do intervalo
                  nextMaintenance = format(addMonths(parse(row.dataInicial, 'dd/MM/yyyy', new Date()), row.manutencaoPlano.intervalo), 'dd/MM/yyyy');
                  // pega proxima data de vencimento a partir da data inicial do agendamento + o valor do intervalo - o valor da antecipacao
                  nextAnticipation = format(addMonths(parse(row.dataInicial, 'dd/MM/yyyy', new Date()), (row.manutencaoPlano.intervalo - row.manutencaoPlano.antecipacao)), 'dd/MM/yyyy');
                  // se a data calculada sem antecipacao for maior que a data atual da posicao do veículo, manutencao vencida
                  if (isBefore(parse(nextMaintenance, 'dd/MM/yyyy', new Date()), parse(vehicle.dataHoraGps, 'dd/MM/yyyy HH:mm:ss', new Date()))) {
                      return 3;
                      // se a data calculada com antecipacao for maior que a data atual da posicao do veículo, manutencao vencida
                  } else if (isBefore(parse(nextAnticipation, 'dd/MM/yyyy', new Date()), parse(vehicle.dataHoraGps, 'dd/MM/yyyy HH:mm:ss', new Date()))) {
                      return 2;
                  } else {
                      return 1;
                  }
          }
      default: return 0;
  }
}

export const getAccessGroups = () => {
  let token = localStorage.getItem("@monitoras-web/token");
  if (token) {
    const data = decode(token);
    return data.rules.split(';');
  } else {
    return ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0"];
  } 
}

export const getStatusGprs = (ignicao, grsTimeStamp) => {
  let hours = differenceInMinutes(new Date(), parse(grsTimeStamp, 'dd/MM/yyyy HH:mm:ss', new Date()));
  if (ignicao) {
    if (hours <= 10) {
      return '#239B56';
    } else {
      return '#E74C3C';
    }
  } else {
    if (hours <= 120) {
      return '#239B56';
    } else {
      return '#E74C3C';
    }
  }
}