import {

    FETCH_VEHICLE_TYPES_PENDING,
    FETCH_VEHICLE_TYPES_FULFILLED,
    FETCH_VEHICLE_TYPES_REJECTED,

    FETCH_VEHICLE_TYPE_PENDING,
    FETCH_VEHICLE_TYPE_FULFILLED,
    FETCH_VEHICLE_TYPE_REJECTED,

    CREATE_VEHICLE_TYPE_PENDING,
    CREATE_VEHICLE_TYPE_FULFILLED,
    CREATE_VEHICLE_TYPE_REJECTED,

    UPDATE_VEHICLE_TYPE_PENDING,
    UPDATE_VEHICLE_TYPE_FULFILLED,
    UPDATE_VEHICLE_TYPE_REJECTED,

    DELETE_VEHICLE_TYPE_PENDING,
    DELETE_VEHICLE_TYPE_FULFILLED,
    DELETE_VEHICLE_TYPE_REJECTED,
    
    CHANGE_VEHICLE_TYPE,
    
    ADD_VEHICLE_TYPE

} from './vehicleTypeActions'

const titleCase = (str) => {
    if (!str) return;
    return str.toLowerCase().split(' ').map(function(word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}

const initialState = {
    loading: false,
    listOfVehicleTypes: [],
    vehicleType: {
        codigo: 0,
        matriz: 0,
        descricao: '',
        ligadaIcon: 0,
        desligadaIcon: 0,
        panicoIcon: 0,
        terminalIcon: 0,
        clienteMatriz: {
            codigo: 0,
            razaoSocial: '',
        }
    },
    error: null,
}

export function vehicleTypeReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_VEHICLE_TYPE: 
            let value = ""
                
            if (action.payload.name.includes('mail')) {
                value = action.payload.value.toLowerCase();
            } else 
                if (action.payload.name.includes('apelido')) {
                    value = action.payload.value;
                } else {
                    value = titleCase(action.payload.value);
                }

            return Object.assign({}, state, {
                vehicleType: { ...state.vehicleType, 
                    [action.payload.name]: value
                }
            })
        case ADD_VEHICLE_TYPE: 
            return Object.assign({}, state, {
                vehicleType: { ...initialState.vehicleType }
            })
        case FETCH_VEHICLE_TYPES_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfVehicleTypes: [],
            })
        case FETCH_VEHICLE_TYPES_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfVehicleTypes: action.payload
            })
        case FETCH_VEHICLE_TYPES_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_VEHICLE_TYPE_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //vehicleType: { ...initialState.vehicleType },
            })
        case CREATE_VEHICLE_TYPE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                vehicleType: action.payload
            })
        case CREATE_VEHICLE_TYPE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_VEHICLE_TYPE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_VEHICLE_TYPE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                vehicleType: action.payload
            })
        case UPDATE_VEHICLE_TYPE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_VEHICLE_TYPE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_VEHICLE_TYPE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                vehicleType: action.payload
            })
        case FETCH_VEHICLE_TYPE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_VEHICLE_TYPE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_VEHICLE_TYPE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_VEHICLE_TYPE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}