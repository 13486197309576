import {

    FETCH_CARDS_PENDING,
    FETCH_CARDS_FULFILLED,
    FETCH_CARDS_REJECTED,

    FETCH_CARD_PENDING,
    FETCH_CARD_FULFILLED,
    FETCH_CARD_REJECTED,

    CREATE_CARD_PENDING,
    CREATE_CARD_FULFILLED,
    CREATE_CARD_REJECTED,

    UPDATE_CARD_PENDING,
    UPDATE_CARD_FULFILLED,
    UPDATE_CARD_REJECTED,

    DELETE_CARD_PENDING,
    DELETE_CARD_FULFILLED,
    DELETE_CARD_REJECTED,
    
    CHANGE_CARD,
    
    ADD_CARD

} from './cardActions'

const titleCase = (str) => {
    if (!str) return;
    return str.toLowerCase().split(' ').map(function(word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}

const initialState = {
    loading: false,
    listOfCards: [],
    card: {
        codigo: 0,
        matriz: 0,
        serial: 0,
        codigoInterno: 0,
        tipoCartao: 0,
        motorista: 0,
        clienteMatriz: {
            codigo: 0,
            razaoSocial: '',
        }
    },
    error: null,
}

export function cardReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_CARD: 
            let value = ""
                
            if (action.payload.name.includes('mail')) {
                value = action.payload.value.toLowerCase();
            } else 
                if (action.payload.name.includes('apelido')) {
                    value = action.payload.value;
                } else {
                    value = titleCase(action.payload.value);
                }

            return Object.assign({}, state, {
                card: { ...state.card, 
                    [action.payload.name]: value
                }
            })
        case ADD_CARD: 
            return Object.assign({}, state, {
                card: { ...initialState.card }
            })
        case FETCH_CARDS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfCards: [],
            })
        case FETCH_CARDS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfCards: action.payload
            })
        case FETCH_CARDS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_CARD_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //card: { ...initialState.card },
            })
        case CREATE_CARD_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                card: action.payload
            })
        case CREATE_CARD_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_CARD_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_CARD_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                card: action.payload
            })
        case UPDATE_CARD_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_CARD_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_CARD_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                card: action.payload
            })
        case FETCH_CARD_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_CARD_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_CARD_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_CARD_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}