import {

    FETCH_EXPENSES_PENDING,
    FETCH_EXPENSES_FULFILLED,
    FETCH_EXPENSES_REJECTED,

    FETCH_EXPENSE_PENDING,
    FETCH_EXPENSE_FULFILLED,
    FETCH_EXPENSE_REJECTED,

    CREATE_EXPENSE_PENDING,
    CREATE_EXPENSE_FULFILLED,
    CREATE_EXPENSE_REJECTED,

    UPDATE_EXPENSE_PENDING,
    UPDATE_EXPENSE_FULFILLED,
    UPDATE_EXPENSE_REJECTED,

    DELETE_EXPENSE_PENDING,
    DELETE_EXPENSE_FULFILLED,
    DELETE_EXPENSE_REJECTED,

    CHANGE_EXPENSE,

    ADD_EXPENSE,
    ADD_PRODUCT,
    DEL_PRODUCT,
    ADD_SERVICE,
    DEL_SERVICE,
    ADD_ADDITIONAL,
    DEL_ADDITIONAL,
    ADD_DIVERSE,
    DEL_DIVERSE

} from './expenseActions'

import { titleCase } from '../../../../common'

const initialState = {
    loading: false,
    listOfExpenses: [],
    expense: {
        codigo: 0,
        numeroNota: 0,
        dataEmissao: '',
        dataVencimento: '',
        fornecedor: 0,
        odometro: 0,
        veiculo: 0,
        motorista: 0,
        descricao: '',
        veiculoDespesa: {
            codigo: 0,
            placa: '',
            descricao: ''
        },
        motoristaDespesa: {
            codigo: 0,
            nome: ''
        },
        fornecedorDespesa: {
            codigo: 0,
            razaoSocial: '',
        },
        produtos: [],
        servicos: [],
        adicionais: [],
        diversas: []
    },
    error: null,
}

export function expenseReducer(state = initialState, action) {
    let produtos = [];
    let servicos = [];
    let adicionais = [];
    let diversas = [];
    switch (action.type) {
        case CHANGE_EXPENSE:
            return Object.assign({}, state, {
                expense: {
                    ...state.expense,
                    [action.payload.name]: titleCase(action.payload.value)
                }
            })
        case ADD_EXPENSE:
            return Object.assign({}, state, {
                expense: { ...initialState.expense,
                    produtos: [],
                    servicos: [],
                    adicionais: [],
                    diversas: []
                }
            })
        case FETCH_EXPENSES_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_EXPENSES_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfExpenses: action.payload
            })
        case FETCH_EXPENSES_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_EXPENSE_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //expense: { ...initialState.expense },
            })
        case CREATE_EXPENSE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                expense: { ...initialState.expense,
                    produtos: [],
                    servicos: [],
                    adicionais: [],
                    diversas: []
                }
            })
        case CREATE_EXPENSE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_EXPENSE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_EXPENSE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                expense: action.payload
            })
        case UPDATE_EXPENSE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_EXPENSE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_EXPENSE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                expense: action.payload
            })
        case FETCH_EXPENSE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_EXPENSE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_EXPENSE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_EXPENSE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case ADD_PRODUCT:
            produtos = state.expense.produtos;
            produtos.push(action.payload);
            return Object.assign({}, state, {
                expense: { ...state.expense, produtos }
            })

        case DEL_PRODUCT:
            produtos = state.expense.produtos;
            produtos.splice(action.payload, 1);
            return Object.assign({}, state, {
                expense: { ...state.expense, produtos }
            })

        case ADD_SERVICE:
            servicos = state.expense.servicos;
            servicos.push(action.payload);
            return Object.assign({}, state, {
                expense: { ...state.expense, servicos }
            })

        case DEL_SERVICE:
            servicos = state.expense.servicos;
            servicos.splice(action.payload, 1);
            return Object.assign({}, state, {
                expense: { ...state.expense, servicos }
            })

        case ADD_ADDITIONAL:
            adicionais = state.expense.adicionais;
            adicionais.push(action.payload);
            return Object.assign({}, state, {
                expense: { ...state.expense, adicionais }
            })

        case DEL_ADDITIONAL:
            adicionais = state.expense.adicionais;
            adicionais.splice(action.payload, 1);
            return Object.assign({}, state, {
                expense: { ...state.expense, adicionais }
            })

        case ADD_DIVERSE:
            diversas = state.expense.diversas;
            diversas.push(action.payload);
            return Object.assign({}, state, {
                expense: { ...state.expense, diversas }
            })

        case DEL_DIVERSE:
            diversas = state.expense.diversas;
            diversas.splice(action.payload, 1);
            return Object.assign({}, state, {
                expense: { ...state.expense, diversas }
            })
            
        default:
            return state
    }
}