import api from '../../../../../api'

export const FETCH_SUPPLIER_ACTIVITIES_PENDING = 'FETCH_SUPPLIER_ACTIVITIES_PENDING'
export const FETCH_SUPPLIER_ACTIVITIES_FULFILLED = 'FETCH_SUPPLIER_ACTIVITIES_FULFILLED'
export const FETCH_SUPPLIER_ACTIVITIES_REJECTED = 'FETCH_SUPPLIER_ACTIVITIES_REJECTED'

export const FETCH_SUPPLIER_ACTIVITY_PENDING = 'FETCH_SUPPLIER_ACTIVITY_PENDING'
export const FETCH_SUPPLIER_ACTIVITY_FULFILLED = 'FETCH_SUPPLIER_ACTIVITY_FULFILLED'
export const FETCH_SUPPLIER_ACTIVITY_REJECTED = 'FETCH_SUPPLIER_ACTIVITY_REJECTED'

export const CREATE_SUPPLIER_ACTIVITY_PENDING = 'CREATE_SUPPLIER_ACTIVITY_PENDING'
export const CREATE_SUPPLIER_ACTIVITY_FULFILLED = 'CREATE_SUPPLIER_ACTIVITY_FULFILLED'
export const CREATE_SUPPLIER_ACTIVITY_REJECTED = 'CREATE_SUPPLIER_ACTIVITY_REJECTED'

export const UPDATE_SUPPLIER_ACTIVITY_PENDING = 'UPDATE_SUPPLIER_ACTIVITY_PENDING'
export const UPDATE_SUPPLIER_ACTIVITY_FULFILLED = 'UPDATE_SUPPLIER_ACTIVITY_FULFILLED'
export const UPDATE_SUPPLIER_ACTIVITY_REJECTED = 'UPDATE_SUPPLIER_ACTIVITY_REJECTED'

export const DELETE_SUPPLIER_ACTIVITY_PENDING = 'DELETE_SUPPLIER_ACTIVITY_PENDING'
export const DELETE_SUPPLIER_ACTIVITY_FULFILLED = 'DELETE_SUPPLIER_ACTIVITY_FULFILLED'
export const DELETE_SUPPLIER_ACTIVITY_REJECTED = 'DELETE_SUPPLIER_ACTIVITY_REJECTED'

export const CHANGE_SUPPLIER_ACTIVITY = 'CHANGE_SUPPLIER_ACTIVITY'

export const ADD_SUPPLIER_ACTIVITY = 'ADD_SUPPLIER_ACTIVITY'

export function changeSupplierActivity(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_SUPPLIER_ACTIVITY, payload: { name, value } })
  }
}

export function addSupplierActivity() {
  return dispatch => {
    dispatch({ type: ADD_SUPPLIER_ACTIVITY });
  };
}

export function findAllSupplierActivities(first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SUPPLIER_ACTIVITIES_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllSupplierActivities {
                  ramosAtividade (first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SUPPLIER_ACTIVITIES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { ramosAtividade } = data
        dispatch({ type: FETCH_SUPPLIER_ACTIVITIES_FULFILLED, payload: ramosAtividade });
        resolve(ramosAtividade);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SUPPLIER_ACTIVITIES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAllSupplierActivitiesByName(search = '', first = 1000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SUPPLIER_ACTIVITIES_PENDING });

    if (search.trim().length <= 2) {
      dispatch(findAllSupplierActivities());
      return;
    }

    api({
      method: 'post',        
      data: {
        query: `
                query findAllSupplierActivities {
                  ramosAtividadePorNome (search: "${search}" first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SUPPLIER_ACTIVITIES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { ramosAtividadePorNome } = data
        dispatch({ type: FETCH_SUPPLIER_ACTIVITIES_FULFILLED, payload: ramosAtividadePorNome });
        resolve(ramosAtividadePorNome);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SUPPLIER_ACTIVITIES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findSupplierActivity(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SUPPLIER_ACTIVITY_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                    query findSupplierActivity {
                      ramoAtividade (codigo: ${codigo}) {
                        codigo
                        descricao
                      }
                    }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SUPPLIER_ACTIVITY_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { ramoAtividade } = data
        dispatch({ type: FETCH_SUPPLIER_ACTIVITY_FULFILLED, payload: ramoAtividade });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SUPPLIER_ACTIVITY_REJECTED, error });
      resolve(error);
    });

  });

}

export function createSupplierActivity(supplierActivity) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_SUPPLIER_ACTIVITY_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation createSupplierActivity {
            createRamoAtividade (input: {
              codigo: ${supplierActivity.codigo}
              descricao: "${supplierActivity.descricao}"
            } ) {
              codigo
              descricao
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_SUPPLIER_ACTIVITY_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createRamoAtividade } = data
        dispatch({ type: CREATE_SUPPLIER_ACTIVITY_FULFILLED, payload: createRamoAtividade });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_SUPPLIER_ACTIVITY_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateSupplierActivity(supplierActivity) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_SUPPLIER_ACTIVITY_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation UpdateSupplierActivity {
            updateRamoAtividade (input: {
              codigo: ${supplierActivity.codigo}
              descricao: "${supplierActivity.descricao}"
            } ) {
              codigo
              descricao
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_SUPPLIER_ACTIVITY_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateRamoAtividade } = data
        dispatch({ type: UPDATE_SUPPLIER_ACTIVITY_FULFILLED, payload: updateRamoAtividade });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_SUPPLIER_ACTIVITY_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
}

export function removeSupplierActivity(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_SUPPLIER_ACTIVITY_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation RemoveSupplierActivity {
            deleteRamoAtividade (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_SUPPLIER_ACTIVITY_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteRamoAtividade } = data
        dispatch({ type: DELETE_SUPPLIER_ACTIVITY_FULFILLED, payload: deleteRamoAtividade });
        dispatch(findAllSupplierActivities());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_SUPPLIER_ACTIVITY_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
