import { SHOW_ERROR_TOAST_MESSAGE } from '../../../../common/layout/components/header/store';
import api from '../../../../api'

export const FETCH_GENSET_SG3000E_PENDING = 'FETCH_GENSET_SG3000E_PENDING'
export const FETCH_GENSET_SG3000E_FULFILLED = 'FETCH_GENSET_SG3000E_FULFILLED'
export const FETCH_GENSET_SG3000E_REJECTED = 'FETCH_GENSET_SG3000E_REJECTED'

export const CHANGE_GENSET_SG3000E = 'CHANGE_GENSET_SG3000E'
export const DEFAULT_GENSET_SG3000E = 'DEFAULT_GENSET_SG3000E'

export function showMessageSegment() { 
  return dispatch => {
    dispatch({ type: SHOW_ERROR_TOAST_MESSAGE, payload: "ATENÇÃO!\nO Período contém erros na coleta de dados!" })
  }
}

export function defaultGensetSG3000e() { 
  return dispatch => {
    dispatch({ type: DEFAULT_GENSET_SG3000E })
  }
}
 
export function changeGensetSG3000e(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_GENSET_SG3000E, payload: { name, value } })
  }
}

export function findAllGensetSG3000es(filter, first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_GENSET_SG3000E_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllGensetSG3000es {
                  historicoPosicoesContainer (input: {
                    veiculo: ${filter.veiculo}
                    dataHoraInicial: "${filter.dataHoraInicial}"
                    dataHoraFinal: "${filter.dataHoraFinal}"
                  } first: ${first} offset: ${offset}) {
                    modulo
                    dataHoraServidor
                    dataHoraGps
                    latitude
                    longitude
                    tipoEquipamento
                    serial
                    valorHorimetro
                    valorAnalogico
                    valorCombustivel
                    entradaUm
                    entradaDois
                    entradaTres
                    entradaQuatro
                    saidaUm
                    saidaDois
                    saidaTres
                    saidaQuatro
                    serialDois
                    entradaCinco
                    entradaSeis
                    entradaSete
                    entradaOito
                    valorHorimetroDois
                    valorHorimetroTres
                    tensaoDisplay
                    tensaoBateria
                    temperaturaAgua
                    frequenciaRede
                    tensaoRede
                    correnteGerador
                    correnteUso
                    rpmMotor
                    statusErro
                    statusError
                    statusSpeed
                    ecuPower
                    ecuStatus
                    veiculo {
                      placa
                      descricao
                      veiculoTipo {
                        ligadaIcon
                        desligadaIcon
                        panicoIcon
                        terminalIcon 
                      }
                      clienteMatriz {
                        razaoSocial
                      }
                      veiculoGaragem {
                        descricao
                      }
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_GENSET_SG3000E_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { historicoPosicoesContainer } = data
        dispatch({ type: FETCH_GENSET_SG3000E_FULFILLED, payload: historicoPosicoesContainer });
        resolve(historicoPosicoesContainer);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_GENSET_SG3000E_REJECTED, error });
      reject(error);
    });

  });

}

export function findAllEventsSG3000es(filter, first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_GENSET_SG3000E_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllEventsSG3000es {
                  historicoEventosContainer (input: {
                    matriz: ${filter.matriz}
                    dataHoraInicial: "${filter.dataHoraInicial}"
                    dataHoraFinal: "${filter.dataHoraFinal}"
                  } first: ${first} offset: ${offset}) {
                    serial
                    hoursMonth
                    lowRpm
                    highRpm
                    highTemperature
                    oilLow
                    voltageNetwork
                    batteryVoltage
                    fuelRelayOn
                    airheaterOn
                    airheaterOff
                    starter
                    restart20Minutes
                    init
                    shutdown
                    notState
                    hardwareError
                    startDelay
                    pti
                    autoConfiguration
                    stoppedByUser
                    delayedOutput
                    loadMeasure
                    delayedVoltage
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_GENSET_SG3000E_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { historicoEventosContainer } = data
        dispatch({ type: FETCH_GENSET_SG3000E_FULFILLED, payload: historicoEventosContainer });
        resolve(historicoEventosContainer);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_GENSET_SG3000E_REJECTED, error });
      reject(error);
    });

  });

}
