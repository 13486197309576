import {

    FETCH_STOP_TIME_HISTORY_PENDING,
    FETCH_STOP_TIME_HISTORY_FULFILLED,
    FETCH_STOP_TIME_HISTORY_REJECTED,

    CHANGE_STOP_TIME_HISTORY

} from './stopTimeHistoryActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfStopTimeHistory: [],
    stopTimeHistory: {
        veiculo: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function stopTimeHistoryReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_STOP_TIME_HISTORY:
            return Object.assign({}, state, {
                stopTimeHistory: {
                    ...state.stopTimeHistory,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_STOP_TIME_HISTORY_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfStopTimeHistory: [],
            })
        case FETCH_STOP_TIME_HISTORY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfStopTimeHistory: action.payload
            })
        case FETCH_STOP_TIME_HISTORY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfStopTimeHistory: [],
                error: action.payload
            })

        default:
            return state
    }
}