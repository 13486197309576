import api from '../../../../api'

export const FETCH_DRIVER_SCORE_PENDING = 'FETCH_DRIVER_SCORE_PENDING'
export const FETCH_DRIVER_SCORE_FULFILLED = 'FETCH_DRIVER_SCORE_FULFILLED'
export const FETCH_DRIVER_SCORE_REJECTED = 'FETCH_DRIVER_SCORE_REJECTED'

export const DELETE_DRIVER_SCORE_PENDING = 'DELETE_DRIVER_SCORE_PENDING'
export const DELETE_DRIVER_SCORE_FULFILLED = 'DELETE_DRIVER_SCORE_FULFILLED'
export const DELETE_DRIVER_SCORE_REJECTED = 'DELETE_DRIVER_SCORE_REJECTED'

export const CHANGE_DRIVER_SCORE = 'CHANGE_DRIVER_SCORE'

export function changeDriverScore(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_DRIVER_SCORE, payload: { name, value } })
  }
}

export function findAllDriverScores(filter, first = 12000, offset = 0) {
  
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_DRIVER_SCORE_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllDriverScores {
                  reportPontuacaoMotorista (input: {
                    matriz: ${filter.matriz}
                    dataHoraInicial: "${filter.dataHoraInicial}"
                    dataHoraFinal: "${filter.dataHoraFinal}"
                  }) {
                    motorista
                    serialRfid
                    excessoVelocidadeSeco 
                    excessoVelocidadeChuva 
                    excessoRPM 
                    conducaoBanguela 
                    freadaBrusca 
                    aceleracaoBruscaParado 
                    aceleracaoBruscaMovimento 
                    excessoTemperatura 
                    totalPontos 
                    distanciaPercorrida 
                    intervaloHoras 
                    mediaPontosDistancia 
                    mediaPontosIntervalo 
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_DRIVER_SCORE_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { reportPontuacaoMotorista } = data
        dispatch({ type: FETCH_DRIVER_SCORE_FULFILLED, payload: reportPontuacaoMotorista });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_DRIVER_SCORE_REJECTED, error });
      reject(error);
    });

  });

}
