import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const ListCustomer = Loadable({
  loader: () => import("./customer/ListCustomer"),
  loading: Loading
});

const FormCustomer = Loadable({
  loader: () => import("./customer/FormCustomer"),
  loading: Loading
});

const ListDriver = Loadable({
  loader: () => import("./driver/ListDriver"),
  loading: Loading
});

const FormDriver = Loadable({
  loader: () => import("./driver/FormDriver"),
  loading: Loading
});

const ListCard = Loadable({
  loader: () => import("./driver/card/ListCard"),
  loading: Loading
});

const FormCard = Loadable({
  loader: () => import("./driver/card/FormCard"),
  loading: Loading
});

const ListCardLibrary = Loadable({
  loader: () => import("./driver/cardLibrary/ListCardLibrary"),
  loading: Loading
});

const FormCardLibrary = Loadable({
  loader: () => import("./driver/cardLibrary/FormCardLibrary"),
  loading: Loading
});

const ListGarage = Loadable({
  loader: () => import("./vehicle/garage/ListGarage"),
  loading: Loading
});

const FormGarage = Loadable({
  loader: () => import("./vehicle/garage/FormGarage"),
  loading: Loading
});

const ListGroup = Loadable({
  loader: () => import("./vehicle/group/ListGroup"),
  loading: Loading
});

const FormGroup = Loadable({
  loader: () => import("./vehicle/group/FormGroup"),
  loading: Loading
});

const ListFirmware = Loadable({
  loader: () => import("./peripheral/firmware/ListFirmware"),
  loading: Loading
});

const FormFirmware = Loadable({
  loader: () => import("./peripheral/firmware/FormFirmware"),
  loading: Loading
});

const ListSimCard = Loadable({
  loader: () => import("./peripheral/simCard/ListSimCard"),
  loading: Loading
});

const FormSimCard = Loadable({
  loader: () => import("./peripheral/simCard/FormSimCard"),
  loading: Loading
});

const FormPeripheral = Loadable({
  loader: () => import("./peripheral/peripheral/FormPeripheral"),
  loading: Loading
});

const ListPeripheral = Loadable({
  loader: () => import("./peripheral/peripheral/ListPeripheral"),
  loading: Loading
});

const ListVehicleType = Loadable({
  loader: () => import("./vehicle/vehicleType/ListVehicleType"),
  loading: Loading
});

const FormVehicleType = Loadable({
  loader: () => import("./vehicle/vehicleType/FormVehicleType"),
  loading: Loading
});

const ListVehiclePort = Loadable({
  loader: () => import("./vehicle/vehiclePort/ListVehiclePort"),
  loading: Loading
});

const FormVehiclePort = Loadable({
  loader: () => import("./vehicle/vehiclePort/FormVehiclePort"),
  loading: Loading
});

const ListVehicle = Loadable({
  loader: () => import("./vehicle/ListVehicle"),
  loading: Loading
});

const FormVehicle = Loadable({
  loader: () => import("./vehicle/FormVehicle"),
  loading: Loading
});

const ListSupplierActivity = Loadable({
  loader: () => import("./supplier/supplierActivity/ListSupplierActivity"),
  loading: Loading
});

const FormSupplierActivity = Loadable({
  loader: () => import("./supplier/supplierActivity/FormSupplierActivity"),
  loading: Loading
});

const ListSupplier = Loadable({
  loader: () => import("./supplier/ListSupplier"),
  loading: Loading
});

const FormSupplier = Loadable({
  loader: () => import("./supplier/FormSupplier"),
  loading: Loading
});

const ListServiceGroup = Loadable({
  loader: () => import("./supplier/serviceGroup/ListServiceGroup"),
  loading: Loading
});

const FormServiceGroup = Loadable({
  loader: () => import("./supplier/serviceGroup/FormServiceGroup"),
  loading: Loading
});

const ListProductOrService = Loadable({
  loader: () => import("./supplier/productOrService/ListProductOrService"),
  loading: Loading
});

const FormProductOrService = Loadable({
  loader: () => import("./supplier/productOrService/FormProductOrService"),
  loading: Loading
});

const ListTechnical = Loadable({
  loader: () => import("./technical/ListTechnical"),
  loading: Loading
});

const FormTechnical = Loadable({
  loader: () => import("./technical/FormTechnical"),
  loading: Loading
});

const FormUserProfile = Loadable({
  loader: () => import("./user/FormUserProfile"),
  loading: Loading
});

const ListUserType = Loadable({
  loader: () => import("./user/userType/ListUserType"),
  loading: Loading
});

const FormUserType = Loadable({
  loader: () => import("./user/userType/FormUserType"),
  loading: Loading
});


const FormUser = Loadable({
  loader: () => import("./user/FormUser"),
  loading: Loading
});

const ListUser = Loadable({
  loader: () => import("./user/ListUser"),
  loading: Loading
});

const ListMaintencePlan = Loadable({
  loader: () => import("./maintence/maintencePlan/ListMaintencePlan"),
  loading: Loading
});

const FormMaintencePlan = Loadable({
  loader: () => import("./maintence/maintencePlan/FormMaintencePlan"),
  loading: Loading
});

const ListMaintenceSchedule = Loadable({
  loader: () => import("./maintence/maintenceSchedule/ListMaintenceSchedule"),
  loading: Loading
});

const FormMaintenceSchedule = Loadable({
  loader: () => import("./maintence/maintenceSchedule/FormMaintenceSchedule"),
  loading: Loading
});

const ListFuel = Loadable({
  loader: () => import("./fuelSupply/ListFuelSupply"),
  loading: Loading
});

const FormFuelSupply = Loadable({
  loader: () => import("./fuelSupply/FormFuelSupply"),
  loading: Loading
});

const ListVehicleReservation = Loadable({
  loader: () => import("./reservation/ListVehicleReservation"),
  loading: Loading
});

const FormVehicleReservation = Loadable({
  loader: () => import("./reservation/FormVehicleReservation"),
  loading: Loading
});

const FormTypePoint = Loadable({
  loader: () => import("./knownPoint/typePoint/FormTypePoint"),
  loading: Loading
});

const ListTypePoint = Loadable({
  loader: () => import("./knownPoint/typePoint/ListTypePoint"),
  loading: Loading
});

const ListKnownPoint = Loadable({
  loader: () => import("./knownPoint/ListKnownPoint"),
  loading: Loading
});

const FormKnownPoint = Loadable({
  loader: () => import("./knownPoint/FormKnownPoint"),
  loading: Loading
});

const ListRiskArea = Loadable({
  loader: () => import("./riskArea/ListRiskArea"),
  loading: Loading
});

const FormRiskArea = Loadable({
  loader: () => import("./riskArea/FormRiskArea"),
  loading: Loading
});

const ListEvent = Loadable({
  loader: () => import("./event/ListEvent"),
  loading: Loading
});

const FormEvent = Loadable({
  loader: () => import("./event/FormEvent"),
  loading: Loading
});

const ListScore = Loadable({
  loader: () => import("./event/score/ListScore"),
  loading: Loading
});

const FormScore = Loadable({
  loader: () => import("./event/score/FormScore"),
  loading: Loading
});

const FormExpenses = Loadable({
  loader: () => import("./expense/FormExpenses"),
  loading: Loading
});

const ListExpenses = Loadable({
  loader: () => import("./expense/ListExpense"),
  loading: Loading
});

const FormCommand = Loadable({
  loader: () => import("./command/FormCommand"),
  loading: Loading
});

//-----------------------------------------------------------------------------

const FormElements = Loadable({
  loader: () => import("./components/FormElements"),
  loading: Loading
});

const FormLayouts = Loadable({
  loader: () => import("./components/layouts/FormLayouts"),
  loading: Loading
});

const FormValidation = Loadable({
  loader: () => import("./components/FormValidation"),
  loading: Loading
});

const BootstrapFormElements = Loadable({
  loader: () => import("./components/BootstrapFormElements"),
  loading: Loading
});

const BootstrapValidation = Loadable({
  loader: () => import("./components/bootstrap-validation/BootstrapValidation"),
  loading: Loading
});

const FormPlugins = Loadable({
  loader: () => import("./components/form-plugins/FormPlugins"),
  loading: Loading
});

const Wizards = Loadable({
  loader: () => import("./components/wizards/Wizards"),
  loading: Loading
});

const DropzoneDemo = Loadable({
  loader: () => import("./components/DropzoneDemo"),
  loading: Loading
});

const BootstrapEditors = Loadable({
  loader: () => import("./components/BootstrapEditors"),
  loading: Loading
});

const ImageCropping = Loadable({
  loader: () => import("./components/ImageCropping"),
  loading: Loading
});

export const routes = [
  {
    path: "/forms/listcustomer",
    exact: true,
    component: ListCustomer,
    name: "List Customer"
  },
  {
    path: "/forms/customer",
    exact: true,
    component: FormCustomer,
    name: "Form Customer"
  },
  {
    path: "/forms/listdriver",
    exact: true,
    component: ListDriver,
    name: "List Driver"
  },
  {
    path: "/forms/driver",
    exact: true,
    component: FormDriver,
    name: "Form Driver"
  },
  {
    path: "/forms/listcard",
    exact: true,
    component: ListCard,
    name: "List Card"
  },
  {
    path: "/forms/card",
    exact: true,
    component: FormCard,
    name: "Form Card"
  },
  {
    path: "/forms/listCardLibrary",
    exact: true,
    component: ListCardLibrary,
    name: "List Card Library"
  },
  {
    path: "/forms/cardLibrary",
    exact: true,
    component: FormCardLibrary,
    name: "Form Card Library"
  },
  {
    path: "/forms/listgarage",
    exact: true,
    component: ListGarage,
    name: "List Garage"
  },
  {
    path: "/forms/garage",
    exact: true,
    component: FormGarage,
    name: "Form Garage"
  },
  {
    path: "/forms/listgroup",
    exact: true,
    component: ListGroup,
    name: "List Group"
  },
  {
    path: "/forms/group",
    exact: true,
    component: FormGroup,
    name: "Form Group"
  },
  {
    path: "/forms/listfirmware",
    exact: true,
    component: ListFirmware,
    name: "List Firmware"
  },
  {
    path: "/forms/firmware",
    exact: true,
    component: FormFirmware,
    name: "Form Firmware"
  },
  {
    path: "/forms/listsimcard",
    exact: true,
    component: ListSimCard,
    name: "List SimCard"
  },
  {
    path: "/forms/simcard",
    exact: true,
    component: FormSimCard,
    name: "Form SimCard"
  },
  {
    path: "/forms/listperipheral",
    exact: true,
    component: ListPeripheral,
    name: "List Peripheral"
  },
  {
    path: "/forms/peripheral",
    exact: true,
    component: FormPeripheral,
    name: "Form Peripheral"
  },
  {
    path: "/forms/listvehicleType",
    exact: true,
    component: ListVehicleType,
    name: "List Of Vehicle Type"
  },
  {
    path: "/forms/vehicleType",
    exact: true,
    component: FormVehicleType,
    name: "Form Vehicle Type"
  },
  {
    path: "/forms/listvehiclePort",
    exact: true,
    component: ListVehiclePort,
    name: "List Of Vehicle Port"
  },
  {
    path: "/forms/vehiclePort",
    exact: true,
    component: FormVehiclePort,
    name: "Form Vehicle Port"
  },
  {
    path: "/forms/listvehicle",
    exact: true,
    component: ListVehicle,
    name: "List Vehicle"
  },
  {
    path: "/forms/vehicle",
    exact: true,
    component: FormVehicle,
    name: "Form Vehicle"
  },
  {
    path: "/forms/listsupplier",
    exact: true,
    component: ListSupplier,
    name: "List Supplier"
  },
  {
    path: "/forms/supplier",
    exact: true,
    component: FormSupplier,
    name: "Form Supplier"
  },
  {
    path: "/forms/listsupplieractivity",
    exact: true,
    component: ListSupplierActivity,
    name: "List Supplier Acitivity"
  },
  {
    path: "/forms/supplieractivity",
    exact: true,
    component: FormSupplierActivity,
    name: "Form Supplier Acitivity"
  },
  {
    path: "/forms/listproductOrservice",
    exact: true,
    component: ListProductOrService,
    name: "List Product Or Service"
  },
  {
    path: "/forms/productOrservice",
    exact: true,
    component: FormProductOrService,
    name: "Form Product Or Service"
  },
  {
    path: "/forms/listservicegroup",
    exact: true,
    component: ListServiceGroup,
    name: "List Service Group"
  },
  {
    path: "/forms/servicegroup",
    exact: true,
    component: FormServiceGroup,
    name: "Form Service Group"
  },
  {
    path: "/forms/listtechnical",
    exact: true,
    component: ListTechnical,
    name: "List Technical"
  },
  {
    path: "/forms/technical",
    exact: true,
    component: FormTechnical,
    name: "Form Technical"
  },
  
  {
    path: "/userTypes",
    exact: true,
    component: ListUserType,
    name: "List User Type"
  },
  {
    path: "/forms/userType",
    exact: true,
    component: FormUserType,
    name: "Form User Type"
  },

  {
    path: "/forms/listuser",
    exact: true,
    component: ListUser,
    name: "List User"
  },
  {
    path: "/forms/user",
    exact: true,
    component: FormUser,
    name: "Form User"
  },
  {
    path: "/forms/listmaintenceplan",
    exact: true,
    component: ListMaintencePlan,
    name: "List Maintence Plan"
  },
  {
    path: "/forms/maintenceplan",
    exact: true,
    component: FormMaintencePlan,
    name: "Form Maintence Plan"
  },
  {
    path: "/forms/listMaintenceSchedule",
    exact: true,
    component: ListMaintenceSchedule,
    name: "List Schedule Maintence"
  },
  {
    path: "/forms/maintenceSchedule",
    exact: true,
    component: FormMaintenceSchedule,
    name: "Form Schedule Maintence"
  },
  {
    path: "/forms/listfuel",
    exact: true,
    component: ListFuel,
    name: "List Fuel"
  },
  {
    path: "/forms/fuelSupply",
    exact: true,
    component: FormFuelSupply,
    name: "Form Fuel"
  },
  {
    path: "/forms/listVehicleReservation",
    exact: true,
    component: ListVehicleReservation,
    name: "List Reservation Vehicle"
  },
  {
    path: "/forms/vehicleReservation",
    exact: true,
    component: FormVehicleReservation,
    name: "Form Reservation Vehicle"
  },
  {
    path: "/forms/listtypepoint",
    exact: true,
    component: ListTypePoint,
    name: "List Point Type"
  },
  {
    path: "/forms/typepoint",
    exact: true,
    component: FormTypePoint,
    name: "Form Point Type"
  },
  {
    path: "/forms/knownpoint",
    exact: true,
    component: FormKnownPoint,
    name: "Form Known Point"
  },
  {
    path: "/forms/listknownpoint",
    exact: true,
    component: ListKnownPoint,
    name: "List Known Point"
  },
  {
    path: "/forms/riskarea",
    exact: true,
    component: FormRiskArea,
    name: "Form Risk Area"
  },
  {
    path: "/forms/listriskarea",
    exact: true,
    component: ListRiskArea,
    name: "List Risk Area"
  },
  {
    path: "/forms/event",
    exact: true,
    component: FormEvent,
    name: "Form Event"
  },
  {
    path: "/forms/listevent",
    exact: true,
    component: ListEvent,
    name: "List Event"
  },
  {
    path: "/forms/score",
    exact: true,
    component: FormScore,
    name: "Form Score"
  },
  {
    path: "/forms/listscore",
    exact: true,
    component: ListScore,
    name: "List Score"
  },
  {
    path: "/forms/listexpense",
    exact: true,
    component: ListExpenses,
    name: "List Expenses"
  },
  {
    path: "/forms/expense",
    exact: true,
    component: FormExpenses,
    name: "Form Expenses"
  },
  {
    path: "/forms/command",
    exact: true,
    component: FormCommand,
    name: "Form Command"
  },

  //-----------------------------------------------------------------------------
  {
    path: "/forms/elements",
    exact: true,
    component: FormElements,
    name: "Form Elements"
  },
  {
    path: "/forms/layouts",
    exact: true,
    component: FormLayouts,
    name: "Form Layouts"
  },
  {
    path: "/forms/form-validation",
    exact: true,
    component: FormValidation,
    name: "Form Validation"
  },
  {
    path: "/forms/bootstrap-form-elements",
    exact: true,
    component: BootstrapFormElements,
    name: "Bootstrap Form Elements"
  },
  {
    path: "/forms/bootstrap-validation",
    exact: true,
    component: BootstrapValidation,
    name: "Bootstrap Valliidation"
  },
  {
    path: "/forms/plugins",
    exact: true,
    component: FormPlugins,
    name: "Form Plugins"
  },
  {
    path: "/forms/wizards",
    exact: true,
    component: Wizards,
    name: "Wizards"
  },
  {
    path: "/forms/dropzone",
    exact: true,
    component: DropzoneDemo,
    name: "Dropzone"
  },
  {
    path: "/forms/bootstrap-editors",
    exact: true,
    component: BootstrapEditors,
    name: "Bootstrap Editors"
  },
  {
    path: "/forms/image-editor",
    exact: true,
    component: ImageCropping,
    name: "Image Cropping"
  }
];
