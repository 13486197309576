import {

    FETCH_GENSET_SG3000E_PENDING,
    FETCH_GENSET_SG3000E_FULFILLED,
    FETCH_GENSET_SG3000E_REJECTED,

    CHANGE_GENSET_SG3000E,

    DEFAULT_GENSET_SG3000E,

} from './gensetSG3000eActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfGensetSG3000e: [],
    gensetSG3000e: {
        veiculo: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function gensetSG3000eReducer(state = initialState, action) {
    switch (action.type) {
        case DEFAULT_GENSET_SG3000E:
            return Object.assign({}, state, {
                ...initialState
            })
        case CHANGE_GENSET_SG3000E:
            return Object.assign({}, state, {
                gensetSG3000e: {
                    ...state.gensetSG3000e,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_GENSET_SG3000E_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfGensetSG3000e: initialState.listOfGensetSG3000e,
            })
        case FETCH_GENSET_SG3000E_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfGensetSG3000e: action.payload
            })
        case FETCH_GENSET_SG3000E_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfGensetSG3000e: initialState.listOfGensetSG3000e,
                error: action.payload
            })

        default:
            return state
    }
}