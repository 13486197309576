import api from '../../../../../api'

export const FETCH_SERVICE_GROUPS_PENDING = 'FETCH_SERVICE_GROUPS_PENDING'
export const FETCH_SERVICE_GROUPS_FULFILLED = 'FETCH_SERVICE_GROUPS_FULFILLED'
export const FETCH_SERVICE_GROUPS_REJECTED = 'FETCH_SERVICE_GROUPS_REJECTED'

export const FETCH_SERVICE_GROUP_PENDING = 'FETCH_SERVICE_GROUP_PENDING'
export const FETCH_SERVICE_GROUP_FULFILLED = 'FETCH_SERVICE_GROUP_FULFILLED'
export const FETCH_SERVICE_GROUP_REJECTED = 'FETCH_SERVICE_GROUP_REJECTED'

export const CREATE_SERVICE_GROUP_PENDING = 'CREATE_SERVICE_GROUP_PENDING'
export const CREATE_SERVICE_GROUP_FULFILLED = 'CREATE_SERVICE_GROUP_FULFILLED'
export const CREATE_SERVICE_GROUP_REJECTED = 'CREATE_SERVICE_GROUP_REJECTED'

export const UPDATE_SERVICE_GROUP_PENDING = 'UPDATE_SERVICE_GROUP_PENDING'
export const UPDATE_SERVICE_GROUP_FULFILLED = 'UPDATE_SERVICE_GROUP_FULFILLED'
export const UPDATE_SERVICE_GROUP_REJECTED = 'UPDATE_SERVICE_GROUP_REJECTED'

export const DELETE_SERVICE_GROUP_PENDING = 'DELETE_SERVICE_GROUP_PENDING'
export const DELETE_SERVICE_GROUP_FULFILLED = 'DELETE_SERVICE_GROUP_FULFILLED'
export const DELETE_SERVICE_GROUP_REJECTED = 'DELETE_SERVICE_GROUP_REJECTED'

export const CHANGE_SERVICE_GROUP = 'CHANGE_SERVICE_GROUP'

export const ADD_SERVICE_GROUP = 'ADD_SERVICE_GROUP'

export function changeServiceGroup(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_SERVICE_GROUP, payload: { name, value } })
  }
}

export function addServiceGroup() {
  return dispatch => {
    dispatch({ type: ADD_SERVICE_GROUP });
  };
}

export function findAllServiceGroups(first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SERVICE_GROUPS_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                query findAllServiceGroups {
                  gruposServico (first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SERVICE_GROUPS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { gruposServico } = data
        dispatch({ type: FETCH_SERVICE_GROUPS_FULFILLED, payload: gruposServico });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SERVICE_GROUPS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAllServiceGroupsByDescription(search = '', first = 1000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SERVICE_GROUPS_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                query findAllServiceGroupsByDescription {
                  gruposServicoPorDescricao (search: "${search}" first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SERVICE_GROUPS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { gruposServicoPorDescricao } = data
        dispatch({ type: FETCH_SERVICE_GROUPS_FULFILLED, payload: gruposServicoPorDescricao });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SERVICE_GROUPS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findServiceGroup(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SERVICE_GROUP_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                    query findServiceGroup {
                      grupoServico (codigo: ${codigo}) {
                        codigo
                        matriz
                        descricao
                        clienteMatriz {
                          codigo
                          razaoSocial
                        }
                      }
                    }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SERVICE_GROUP_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { grupoServico } = data
        dispatch({ type: FETCH_SERVICE_GROUP_FULFILLED, payload: grupoServico });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SERVICE_GROUP_REJECTED, error });
      resolve(error);
    });

  });

}

export function createServiceGroup(grupo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_SERVICE_GROUP_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation createServiceGroup {
            createGrupoServico (input: {
              codigo: ${grupo.codigo}
              matriz: ${grupo.matriz}
              descricao: "${grupo.descricao}"
            } ) {
              codigo
              matriz
              descricao
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_SERVICE_GROUP_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createGrupoServico } = data
        dispatch({ type: CREATE_SERVICE_GROUP_FULFILLED, payload: createGrupoServico });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_SERVICE_GROUP_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateServiceGroup(grupo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_SERVICE_GROUP_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation UpdateServiceGroup {
            updateGrupoServico (input: {
              codigo: ${grupo.codigo}
              matriz: ${grupo.matriz}
              descricao: "${grupo.descricao}"
            } ) {
              codigo
              matriz
              descricao
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_SERVICE_GROUP_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateGrupoServico } = data
        dispatch({ type: UPDATE_SERVICE_GROUP_FULFILLED, payload: updateGrupoServico });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_SERVICE_GROUP_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
}

export function removeServiceGroup(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_SERVICE_GROUP_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation RemoveServiceGroup {
            deleteGrupoServico (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_SERVICE_GROUP_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteGrupoServico } = data
        dispatch({ type: DELETE_SERVICE_GROUP_FULFILLED, payload: deleteGrupoServico });
        dispatch(findAllServiceGroups());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_SERVICE_GROUP_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
