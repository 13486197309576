import axios from "axios";
import { config } from '../config/config';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_HOST}/graphql`,
});

api.interceptors.request.use(async _config => {
  const token = await localStorage.getItem(config.tokenKey); 
  if (token) {
    _config.headers.Authorization = `Bearer ${token}`;
  }
  return _config;
}, (error) => {
  return Promise.reject(error);
});

api.interceptors.response.use(undefined, async (error) => {
  /* if (`${error}`.includes("Network Error")) {
    await localStorage.removeItem("@monitoras-web/token");
    window.location.href = '/'
  } else {
    const { status } = error.response;

    if (status === 404) { }
  
    if (status === 500) {
      await localStorage.removeItem("@monitoras-web/token");
      window.location.href = '/'
    }
  } */
  return Promise.reject(error);
});

export default api;