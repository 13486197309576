import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useToasts } from 'react-toast-notifications'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'

import FullScreen from "../FullScreen";
import ToggleMenu from "../ToggleMenu";
import LoginInfo from "../../../navigation/components/LoginInfo";

import api from '../../../../api';
import { config } from '../../../../config/config';

// import SpeechButton from "../../voice-control/components/SpeechButton";
// import SearchMobile from "./SearchMobile";
// import { Activities } from "../../../activities";
// import { LanguageSelector } from "../../i18n";
// import RecentProjects from "../RecentProjects";

import { clearToastMessage } from './store';

const Header = (props) => {
  const { addToast } = useToasts();
  useEffect(() => {
    if (props.message.trim().length > 0) {
      addToast(props.message, {
        autoDismiss: props.type === 'error' ? false : true, 
        appearance: props.type
      });
      clearToastMessage();
    }
  }, [props.occurred])

  const handleLogout = async (e) => {
    const { history } = props;
    if (e) e.preventDefault();
    await localStorage.removeItem(config.tokenKey);
    // history.replace('/');
    window.location.reload(false);
  }

  return (
    <header id="header">
      <div id="logo-group">
        <span id="logo">
          {/*<img
              src="assets/img/logo.png" // place your logo here
              alt="SmartAdmin"
            />*/}
        </span>
        {/* Note: The activity badge color changes when clicked and resets the number to 0
         Suggestion: You may want to set a flag when this happens to tick off all checked messages / notifications */}

        {/* <Activities /> */}
      </div>

      {/* <RecentProjects /> */}
      
      <div className="pull-right" /*pulled right: nav area*/>
        <ToggleMenu
          className="btn-header pull-right" /* collapse menu button */
        />

        {/* #MOBILE */}
        {/*  Top menu profile link : this shows only when top menu is active */}
        <ul
          id="mobile-profile-img"
          className="header-dropdown-list hidden-xs padding-5"
        >
          <li className="">
            <a href="#/"
              className="dropdown-toggle no-margin userdropdown"
              data-toggle="dropdown"
            >
              <img
                src="assets/img/avatars/sunny.png"
                alt="John Doe"
                className="online"
              />
            </a>
            <ul className="dropdown-menu pull-right">
              <li>
                <a href="#/" className="padding-10 padding-top-0 padding-bottom-0">
                  <i className="fa fa-cog" /> Setting
                  </a>
              </li>
              <li className="divider" />
              <li>
                <a
                  href="#/views/profile"
                  className="padding-10 padding-top-0 padding-bottom-0"
                >
                  <i className="fa fa-user" />
                  <u>P</u>rofile
                  </a>
              </li>
              <li className="divider" />
              <li>
                <a href="#/"
                  className="padding-10 padding-top-0 padding-bottom-0"
                  data-action="toggleShortcut"
                >
                  <i className="fa fa-arrow-down" /> <u>S</u>hortcut
                  </a>
              </li>
              <li className="divider" />
              <li>
                <a href="#/"
                  className="padding-10 padding-top-0 padding-bottom-0"
                  data-action="launchFullscreen"
                >
                  <i className="fa fa-arrows-alt" /> Full
                    <u>S</u>creen
                  </a>
              </li>
              <li className="divider" />
              <li>
                <a
                  href="#/login"
                  className="padding-10 padding-top-5 padding-bottom-5"
                  data-action="userLogout"
                >
                  <i className="fa fa-sign-out fa-lg" />
                  <strong>
                    <u>L</u>ogout
                    </strong>
                </a>
              </li>
            </ul>
          </li>
        </ul>

        {/* logout button */}
        <div id="logout" className="btn-header transparent pull-right">
          <span>
            <a href="/#"
              onClick={(e) => handleLogout(e)}
              title="Sair do Sistema"
              data-logout-msg="You can improve your security further after logging out by closing this opened browser"
            >
              <i className="fa fa-sign-out" />
            </a>
          </span>
        </div>

        
        {/* search mobile button (this is hidden till mobile view port)
          <SearchMobile className="btn-header transparent pull-right" /> */}

        {/* input: search field 
          <form
            action="#/misc/search.html"
            className="header-search pull-right"
          >
            <input
              id="search-fld"
              type="text"
              name="param"
              placeholder="Find reports and more"
            />
            <button type="submit">
              <i className="fa fa-search" />
            </button>
            <a href="$" id="cancel-search-js" title="Cancel Search">
              <i className="fa fa-times" />
            </a>
          </form>*/}

        {/* <SpeechButton className="btn-header transparent pull-right hidden-sm hidden-xs" /> */}

        <FullScreen className="btn-header transparent pull-right" />
        
        <div id="loginInfo" className="btn-header transparent pull-right">
          <LoginInfo />
        </div>
        {/* multiple lang dropdown : find all flags in the flags page */}
        {/* <LanguageSelector /> */}
      </div>
      {/* end pulled right: nav area */}
    </header>
  );
}

const mapStateToProps = (state) => ({
  loading: state.header.loading,
  occurred: state.header.occurred,
  message: state.header.message,
  type: state.header.type,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  clearToastMessage,
}, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
