import api from '../../../../api'

export const FETCH_EVENTS_PERIOD_PENDING = 'FETCH_EVENTS_PERIOD_PENDING'
export const FETCH_EVENTS_PERIOD_FULFILLED = 'FETCH_EVENTS_PERIOD_FULFILLED'
export const FETCH_EVENTS_PERIOD_REJECTED = 'FETCH_EVENTS_PERIOD_REJECTED'

export const CHANGE_EVENTS_PERIOD = 'CHANGE_EVENTS_PERIOD'

export function changeEventsPeriod(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_EVENTS_PERIOD, payload: { name, value } })
  }
}

export function findAllEventsPeriods(filter) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_EVENTS_PERIOD_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllEventsPeriods {
                  graficoEventosOcorridos (
                    veiculo: ${filter.veiculo}
                    dataHoraInicial: "${filter.dataHoraInicial}"
                    dataHoraFinal: "${filter.dataHoraFinal}"
                  ) {
                      mensagem
                      eventos
                    }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_EVENTS_PERIOD_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { graficoEventosOcorridos } = data
        dispatch({ type: FETCH_EVENTS_PERIOD_FULFILLED, payload: graficoEventosOcorridos });
        resolve(graficoEventosOcorridos);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_EVENTS_PERIOD_REJECTED, error });
      reject(error);
    });

  });

}
