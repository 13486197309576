import {

    FETCH_PRODUCT_OR_SERVICES_PENDING,
    FETCH_PRODUCT_OR_SERVICES_FULFILLED,
    FETCH_PRODUCT_OR_SERVICES_REJECTED,

    FETCH_PRODUCT_OR_SERVICE_PENDING,
    FETCH_PRODUCT_OR_SERVICE_FULFILLED,
    FETCH_PRODUCT_OR_SERVICE_REJECTED,

    CREATE_PRODUCT_OR_SERVICE_PENDING,
    CREATE_PRODUCT_OR_SERVICE_FULFILLED,
    CREATE_PRODUCT_OR_SERVICE_REJECTED,

    UPDATE_PRODUCT_OR_SERVICE_PENDING,
    UPDATE_PRODUCT_OR_SERVICE_FULFILLED,
    UPDATE_PRODUCT_OR_SERVICE_REJECTED,

    DELETE_PRODUCT_OR_SERVICE_PENDING,
    DELETE_PRODUCT_OR_SERVICE_FULFILLED,
    DELETE_PRODUCT_OR_SERVICE_REJECTED,
    
    CHANGE_PRODUCT_OR_SERVICE,
    
    ADD_PRODUCT_OR_SERVICE

} from './productOrServiceActions'

const titleCase = (str) => {
    if (!str) return;
    return `${str}`.toLowerCase().split(' ').map(function(word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}

const initialState = {
    loading: false,
    listOfProductsOrServices: [],
    productOrService: {
        codigo: 0,
        matriz: 0,
        descricao: '',
        tipo: 0,
        grupoServico: 0,
        fornecedor: 0,
        valor: 0.00,
        duracaoMedia: 0,
        clienteMatriz: {
            codigo: 0,
            razaoSocial: '',
        }
    },
    error: null,
}

export function productOrServiceReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_PRODUCT_OR_SERVICE: 
            if (action.payload.name === "valor") {
                return Object.assign({}, state, {
                    productOrService: { ...state.productOrService, 
                        [action.payload.name]: action.payload.value
                    }
                })
            } else {
                return Object.assign({}, state, {
                    productOrService: { ...state.productOrService, 
                        [action.payload.name]: titleCase(action.payload.value)
                    }
                })
            }

        case ADD_PRODUCT_OR_SERVICE: 
            return Object.assign({}, state, {
                productOrService: { ...initialState.productOrService }
            })
        case FETCH_PRODUCT_OR_SERVICES_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfProductsOrServices: [],
            })
        case FETCH_PRODUCT_OR_SERVICES_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfProductsOrServices: action.payload
            })
        case FETCH_PRODUCT_OR_SERVICES_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_PRODUCT_OR_SERVICE_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //productOrService: { ...initialState.productOrService },
            })
        case CREATE_PRODUCT_OR_SERVICE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                productOrService: action.payload
            })
        case CREATE_PRODUCT_OR_SERVICE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_PRODUCT_OR_SERVICE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_PRODUCT_OR_SERVICE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                productOrService: action.payload
            })
        case UPDATE_PRODUCT_OR_SERVICE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_PRODUCT_OR_SERVICE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_PRODUCT_OR_SERVICE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                productOrService: action.payload
            })
        case FETCH_PRODUCT_OR_SERVICE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_PRODUCT_OR_SERVICE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_PRODUCT_OR_SERVICE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_PRODUCT_OR_SERVICE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}