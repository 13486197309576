import {

    FETCH_EVENTS_PENDING,
    FETCH_EVENTS_FULFILLED,
    FETCH_EVENTS_REJECTED,

    FETCH_EVENT_PENDING,
    FETCH_EVENT_FULFILLED,
    FETCH_EVENT_REJECTED,

    CREATE_EVENT_PENDING,
    CREATE_EVENT_FULFILLED,
    CREATE_EVENT_REJECTED,

    UPDATE_EVENT_PENDING,
    UPDATE_EVENT_FULFILLED,
    UPDATE_EVENT_REJECTED,

    DELETE_EVENT_PENDING,
    DELETE_EVENT_FULFILLED,
    DELETE_EVENT_REJECTED,
    
    CHANGE_EVENT,
    
    ADD_EVENT

} from './eventActions'

import { titleCase } from '../../../../common/utils'

const initialState = {
    loading: false,
    listOfEvents: [],
    event: {
        codigo: 0,
        matriz: 0,
        mensagem: '',
        cliente: 0,
        veiculo: 0,
        tipoVeiculo: 0,
        primeiraRegra: 0,
        primeiroValor: 0.0,
        segundaRegra: 0,
        segundoValor: 0.0,
        terceiraRegra: 0,
        terceiroValor: 0.0,
        quartaRegra: 0,
        quartoValor: 0.0,
        pontos: 0,
        acao: 0,
        cor: '',
        som: '',
        validadeInicial: '',
        validadeFinal: '',
        notificaEmail: false,
        notificaSms: false,
        notificaPush: false,
        baixaAutomatica: false,
        clienteMatriz: {
            codigo: 0,
            razaoSocial: '',
        }
    },
    error: null,
}

export function eventReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_EVENT:
        return Object.assign({}, state, {
            event: { ...state.event, 
                [action.payload.name]: titleCase(action.payload.value)
            }
        })
        case ADD_EVENT: 
            return Object.assign({}, state, {
                event: { ...initialState.event }
            })
        case FETCH_EVENTS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfEvents: [],
            })
        case FETCH_EVENTS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfEvents: action.payload
            })
        case FETCH_EVENTS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_EVENT_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //event: { ...initialState.event },
            })
        case CREATE_EVENT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                event: action.payload
            })
        case CREATE_EVENT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_EVENT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_EVENT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                event: action.payload
            })
        case UPDATE_EVENT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_EVENT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_EVENT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                event: action.payload
            })
        case FETCH_EVENT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_EVENT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_EVENT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_EVENT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}