import {

    FETCH_IDLE_ENGINE_PENDING,
    FETCH_IDLE_ENGINE_FULFILLED,
    FETCH_IDLE_ENGINE_REJECTED,

    CHANGE_IDLE_ENGINE

} from './idleEngineActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfIdleEngine: [],
    idleEngine: {
        veiculo: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: "00:00:00",
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().utc().format("HH:mm:ss"),
    },
    error: null,
}

export function idleEngineReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_IDLE_ENGINE:
            return Object.assign({}, state, {
                idleEngine: {
                    ...state.idleEngine,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_IDLE_ENGINE_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfIdleEngine: [],
            })
        case FETCH_IDLE_ENGINE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfIdleEngine: action.payload
            })
        case FETCH_IDLE_ENGINE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfIdleEngine: [],
                error: action.payload
            })

        default:
            return state
    }
}