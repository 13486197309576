import {

    FETCH_SERVICE_GROUPS_PENDING,
    FETCH_SERVICE_GROUPS_FULFILLED,
    FETCH_SERVICE_GROUPS_REJECTED,

    FETCH_SERVICE_GROUP_PENDING,
    FETCH_SERVICE_GROUP_FULFILLED,
    FETCH_SERVICE_GROUP_REJECTED,

    CREATE_SERVICE_GROUP_PENDING,
    CREATE_SERVICE_GROUP_FULFILLED,
    CREATE_SERVICE_GROUP_REJECTED,

    UPDATE_SERVICE_GROUP_PENDING,
    UPDATE_SERVICE_GROUP_FULFILLED,
    UPDATE_SERVICE_GROUP_REJECTED,

    DELETE_SERVICE_GROUP_PENDING,
    DELETE_SERVICE_GROUP_FULFILLED,
    DELETE_SERVICE_GROUP_REJECTED,
    
    CHANGE_SERVICE_GROUP,
    
    ADD_SERVICE_GROUP

} from './serviceGroupActions'

const titleCase = (str) => {
    if (!str) return;
    return str.toLowerCase().split(' ').map(function(word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}

const initialState = {
    loading: false,
    listOfServiceGroups: [],
    serviceGroup: {
        codigo: 0,
        matriz: 0,
        descricao: '',
        clienteMatriz: {
            codigo: 0,
            razaoSocial: '',
        }
    },
    error: null,
}

export function serviceGroupReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_SERVICE_GROUP: 
            return Object.assign({}, state, {
                serviceGroup: { ...state.serviceGroup, 
                    [action.payload.name]: titleCase(action.payload.value)
                }
            })
        case ADD_SERVICE_GROUP: 
            return Object.assign({}, state, {
                serviceGroup: { ...initialState.serviceGroup }
            })
        case FETCH_SERVICE_GROUPS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfServiceGroups: [],
            })
        case FETCH_SERVICE_GROUPS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfServiceGroups: action.payload
            })
        case FETCH_SERVICE_GROUPS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_SERVICE_GROUP_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //serviceGroup: { ...initialState.serviceGroup },
            })
        case CREATE_SERVICE_GROUP_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                serviceGroup: action.payload
            })
        case CREATE_SERVICE_GROUP_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_SERVICE_GROUP_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_SERVICE_GROUP_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                serviceGroup: action.payload
            })
        case UPDATE_SERVICE_GROUP_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_SERVICE_GROUP_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_SERVICE_GROUP_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                serviceGroup: action.payload
            })
        case FETCH_SERVICE_GROUP_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_SERVICE_GROUP_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_SERVICE_GROUP_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_SERVICE_GROUP_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}