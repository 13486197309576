import {

    FETCH_FUEL_SUPPLIES_PENDING,
    FETCH_FUEL_SUPPLIES_FULFILLED,
    FETCH_FUEL_SUPPLIES_REJECTED,

    FETCH_FUEL_SUPPLY_PENDING,
    FETCH_FUEL_SUPPLY_FULFILLED,
    FETCH_FUEL_SUPPLY_REJECTED,

    CREATE_FUEL_SUPPLY_PENDING,
    CREATE_FUEL_SUPPLY_FULFILLED,
    CREATE_FUEL_SUPPLY_REJECTED,

    UPDATE_FUEL_SUPPLY_PENDING,
    UPDATE_FUEL_SUPPLY_FULFILLED,
    UPDATE_FUEL_SUPPLY_REJECTED,

    DELETE_FUEL_SUPPLY_PENDING,
    DELETE_FUEL_SUPPLY_FULFILLED,
    DELETE_FUEL_SUPPLY_REJECTED,
    
    CHANGE_FUEL_SUPPLY,
    
    ADD_FUEL_SUPPLY

} from './fuelSupplyActions'

import { titleCase } from '../../../../common/utils'

const initialState = {
    loading: false,
    listOfFuelSupplies: [],
    fuelSupply: {
        codigo: 0,
        veiculo: 0,
        motorista: 0,
        combustivel: 1,
        dataMovimento: "",
        horaMovimento: "",
        odometro: 0.0,
        quantidade: 0.0,
        valorTotal: 0.0,
        vale: "",
        observacoes: "",
        veiculoAbastecido: {
            codigo: 0,
            placa: "",
            descricao: ""
        },
        motoristaVeiculo: {
            codigo: 0,
            nome: ""
        }
    },
    error: null,
}

export function fuelSupplyReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_FUEL_SUPPLY:
        if ((action.payload.name === "valorTotal") || (action.payload.name === "quantidade")) {
            return Object.assign({}, state, {
                fuelSupply: { ...state.fuelSupply, 
                    [action.payload.name]: action.payload.value,
                }
            })
        } else {
            return Object.assign({}, state, {
                fuelSupply: { ...state.fuelSupply, 
                    [action.payload.name]: titleCase(action.payload.value)
                }
            })
        }
        case ADD_FUEL_SUPPLY: 
            return Object.assign({}, state, {
                fuelSupply: { ...initialState.fuelSupply }
            })
        case FETCH_FUEL_SUPPLIES_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfFuelSupplies: [],
            })
        case FETCH_FUEL_SUPPLIES_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfFuelSupplies: action.payload
            })
        case FETCH_FUEL_SUPPLIES_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_FUEL_SUPPLY_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //fuelSupply: { ...initialState.fuelSupply },
            })
        case CREATE_FUEL_SUPPLY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                fuelSupply: action.payload
            })
        case CREATE_FUEL_SUPPLY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_FUEL_SUPPLY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_FUEL_SUPPLY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                fuelSupply: action.payload
            })
        case UPDATE_FUEL_SUPPLY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_FUEL_SUPPLY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_FUEL_SUPPLY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                fuelSupply: action.payload
            })
        case FETCH_FUEL_SUPPLY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_FUEL_SUPPLY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_FUEL_SUPPLY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_FUEL_SUPPLY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}