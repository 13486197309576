import api from '../../../../api'

export const FETCH_EVENT_HISTORY_PENDING = 'FETCH_EVENT_HISTORY_PENDING'
export const FETCH_EVENT_HISTORY_FULFILLED = 'FETCH_EVENT_HISTORY_FULFILLED'
export const FETCH_EVENT_HISTORY_REJECTED = 'FETCH_EVENT_HISTORY_REJECTED'

export const FETCH_EVENT_OCURRED_PENDING = 'FETCH_EVENT_OCURRED_PENDING'
export const FETCH_EVENT_OCURRED_FULFILLED = 'FETCH_EVENT_OCURRED_FULFILLED'
export const FETCH_EVENT_OCURRED_REJECTED = 'FETCH_EVENT_OCURRED_REJECTED'

export const UPDATE_EVENT_OCURRED_PENDING = 'UPDATE_EVENT_OCURRED_PENDING'
export const UPDATE_EVENT_OCURRED_FULFILLED = 'UPDATE_EVENT_OCURRED_FULFILLED'
export const UPDATE_EVENT_OCURRED_REJECTED = 'UPDATE_EVENT_OCURRED_REJECTED'

export const CANCEL_EVENT_HISTORY_PENDING = 'CANCEL_EVENT_HISTORY_PENDING'
export const CANCEL_EVENT_HISTORY_FULFILLED = 'CANCEL_EVENT_HISTORY_FULFILLED'
export const CANCEL_EVENT_HISTORY_REJECTED = 'CANCEL_EVENT_HISTORY_REJECTED'

export const DELETE_EVENT_HISTORY_PENDING = 'DELETE_EVENT_HISTORY_PENDING'
export const DELETE_EVENT_HISTORY_FULFILLED = 'DELETE_EVENT_HISTORY_FULFILLED'
export const DELETE_EVENT_HISTORY_REJECTED = 'DELETE_EVENT_HISTORY_REJECTED'

export const CHANGE_EVENT_HISTORY = 'CHANGE_EVENT_HISTORY'

export function changeEventHistory(name, value) {
  return dispatch => {
    dispatch({ 
      type: CHANGE_EVENT_HISTORY, 
      payload: { name, value } 
    })
  }
}

export function findAllEventHistorys(filter) {
  
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_EVENT_HISTORY_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllEventHistorys {
                  eventosOcorridos (
                    veiculo: ${filter.veiculo}
                    dataHoraInicial: "${filter.dataHoraInicial}"
                    dataHoraFinal: "${filter.dataHoraFinal}"
                  ) {
                    codigo
                    evento
                    veiculo
                    motorista
                    eventoMotorista {
                      nome
                    }
                    modulo
                    dataHoraServidor
                    dataHoraGps
                    mensagem
                    pontos
                    status
                    ocorrencias
                    ultimaDataHoraGps
                    latitude
                    longitude
                    usuario
                    eventoUsuario {
                      nome
                    }
                    dataHoraAtuacao
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_EVENT_HISTORY_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { eventosOcorridos } = data
        dispatch({ type: FETCH_EVENT_HISTORY_FULFILLED, payload: eventosOcorridos });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_EVENT_HISTORY_REJECTED, error });
      reject(error);
    });

  });

}

export function findEventOcurred(codigo = 0) {
  
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_EVENT_OCURRED_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllEventHistorys {
                  eventoOcorrido ( codigo: ${codigo} ) {
                    codigo
                    evento
                    veiculo
                    motorista
                    eventoMotorista {
                      nome
                    }
                    cor
                    modulo
                    dataHoraServidor
                    dataHoraGps
                    mensagem
                    pontos
                    status
                    ocorrencias
                    ultimaDataHoraGps
                    latitude
                    longitude
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_EVENT_OCURRED_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { eventoOcorrido } = data
        dispatch({ type: FETCH_EVENT_OCURRED_FULFILLED, payload: eventoOcorrido });
        resolve(eventoOcorrido);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_EVENT_OCURRED_REJECTED, error });
      reject(error);
    });

  });

}
 
export function updateEventOcurred(codigo, status, resolucao, observacoes) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_EVENT_OCURRED_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation UpdateEventOcurred {
            updateEventoOcorrido (input: {
              codigo: ${codigo}
              status: ${status}
              resolucao: "${resolucao}"
              observacoes: "${observacoes}"
            } )
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_EVENT_OCURRED_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateEventoOcorrido } = data
        dispatch({ type: UPDATE_EVENT_OCURRED_FULFILLED, payload: updateEventoOcorrido });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_EVENT_OCURRED_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
} 

export function removeEventOcurred(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_EVENT_HISTORY_PENDING });

    api({
      method: 'post',
      data: {
        query: `
          mutation RemoveEventOcurred {
            deleteEventoOcorrido (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        
        dispatch({ type: DELETE_EVENT_HISTORY_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      
      } else {
        
        const { deleteEventoOcorrido } = data
        dispatch({ type: DELETE_EVENT_HISTORY_FULFILLED, payload: deleteEventoOcorrido });
        resolve({ status: true });

      }
    }).catch((errors) => {
      dispatch({ type: DELETE_EVENT_HISTORY_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
