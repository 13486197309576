import React from "react";

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

class Stats extends React.Component {

  render() {
    const { veiculosAtivos, veiclosOnline, veiculosOffline } = this.props.statsVehicles;
    const className = this.props.className;
    return (
      <div className="col-xs-12 col-sm-5 col-md-5 col-lg-8">
        <ul id="sparks" className={className}>
          <li className="sparks-info">
            <h5 className="m-0">
              Veículos
              <span className="txt-color-blue" style={{ textAlign: 'right' }}>ativos: {veiculosAtivos}</span>
            </h5>
          </li>
          <li className="sparks-info">
            <h5 className="m-0">
              Veículos
              <span className="txt-color-blue" style={{ textAlign: 'right' }}>online: {veiclosOnline}</span>
            </h5>
          </li>
          <li className="sparks-info">
            <h5 className="m-0">
              Veículos
              <span className="txt-color-blue" style={{ textAlign: 'right' }}>offline: {veiculosOffline}</span>
            </h5>
          </li>
          <li className="sparks-info">
            <h5 className="m-0">
              Velocidade
              <span className="txt-color-blue" style={{ textAlign: 'right' }}>excessos: {0}</span>
            </h5>
          </li>
          <li className="sparks-info">
            <h5 className="m-0">
              RPM
              <span className="txt-color-blue" style={{ textAlign: 'right' }}>excessos: {0}</span>
            </h5>
          </li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.stats.loading,
  statsVehicles: state.stats.statusVeiculos,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({

}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Stats);