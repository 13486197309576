import { SHOW_SAVED_TOAST_MESSAGE, SHOW_REMOVED_TOAST_MESSAGE } from '../../../../../common/layout/components/header/store';
import api from '../../../../../api';

export const FETCH_VEHICLE_TYPES_PENDING = 'FETCH_VEHICLE_TYPES_PENDING';
export const FETCH_VEHICLE_TYPES_FULFILLED = 'FETCH_VEHICLE_TYPES_FULFILLED';
export const FETCH_VEHICLE_TYPES_REJECTED = 'FETCH_VEHICLE_TYPES_REJECTED';

export const FETCH_VEHICLE_TYPE_PENDING = 'FETCH_VEHICLE_TYPE_PENDING';
export const FETCH_VEHICLE_TYPE_FULFILLED = 'FETCH_VEHICLE_TYPE_FULFILLED';
export const FETCH_VEHICLE_TYPE_REJECTED = 'FETCH_VEHICLE_TYPE_REJECTED';

export const CREATE_VEHICLE_TYPE_PENDING = 'CREATE_VEHICLE_TYPE_PENDING';
export const CREATE_VEHICLE_TYPE_FULFILLED = 'CREATE_VEHICLE_TYPE_FULFILLED';
export const CREATE_VEHICLE_TYPE_REJECTED = 'CREATE_VEHICLE_TYPE_REJECTED';

export const UPDATE_VEHICLE_TYPE_PENDING = 'UPDATE_VEHICLE_TYPE_PENDING';
export const UPDATE_VEHICLE_TYPE_FULFILLED = 'UPDATE_VEHICLE_TYPE_FULFILLED';
export const UPDATE_VEHICLE_TYPE_REJECTED = 'UPDATE_VEHICLE_TYPE_REJECTED';

export const DELETE_VEHICLE_TYPE_PENDING = 'DELETE_VEHICLE_TYPE_PENDING';
export const DELETE_VEHICLE_TYPE_FULFILLED = 'DELETE_VEHICLE_TYPE_FULFILLED';
export const DELETE_VEHICLE_TYPE_REJECTED = 'DELETE_VEHICLE_TYPE_REJECTED';

export const CHANGE_VEHICLE_TYPE = 'CHANGE_VEHICLE_TYPE';
export const ADD_VEHICLE_TYPE = 'ADD_VEHICLE_TYPE';

export function changeVehicleType(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_VEHICLE_TYPE, payload: { name, value } })
  }
}

export function addVehicleType() {
  return dispatch => {
    dispatch({ type: ADD_VEHICLE_TYPE });
  };
}

export function findAllVehicleTypes(first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_VEHICLE_TYPES_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findAllVehicleTypes {
                  veiculoTipos (first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_VEHICLE_TYPES_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { veiculoTipos } = data
        dispatch({ type: FETCH_VEHICLE_TYPES_FULFILLED, payload: veiculoTipos });
        resolve(data);
      }
    }).catch((errors) => {
      dispatch({ type: FETCH_VEHICLE_TYPES_REJECTED, errors });
      reject(errors);
    });

  });

}



export function findAllVehicleTypesByDescription(search = '', first = 1000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_VEHICLE_TYPES_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findAllVehicleTypesByDescription {
                  veiculoTiposPorDescricao (search: "${search}" first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_VEHICLE_TYPES_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { veiculoTiposPorDescricao } = data
        dispatch({ type: FETCH_VEHICLE_TYPES_FULFILLED, payload: veiculoTiposPorDescricao });
        resolve(veiculoTiposPorDescricao);
      }
    }).catch((errors) => {
      dispatch({ type: FETCH_VEHICLE_TYPES_REJECTED, errors });
      reject(errors);
    });

  });

}

export function findAllVehicleTypesByCustomer(matriz = 0, first = 1000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_VEHICLE_TYPES_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findAllVehicleTypesByDescription {
                  veiculoTiposPorCliente (matriz: ${matriz} first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_VEHICLE_TYPES_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { veiculoTiposPorCliente } = data
        dispatch({ type: FETCH_VEHICLE_TYPES_FULFILLED, payload: veiculoTiposPorCliente });
        resolve(veiculoTiposPorCliente);
      }
    }).catch((errors) => {
      dispatch({ type: FETCH_VEHICLE_TYPES_REJECTED, errors });
      reject(errors);
    });

  });

}

export function findVehicleType(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_VEHICLE_TYPE_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                    query findVehicleType {
                      veiculoTipo (codigo: ${codigo}) {
                        codigo
                        matriz
                        descricao
                        ligadaIcon
                        desligadaIcon
                        panicoIcon
                        terminalIcon
                        clienteMatriz {
                          codigo
                          razaoSocial
                        }
                      }
                    }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_VEHICLE_TYPE_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { veiculoTipo } = data
        dispatch({ type: FETCH_VEHICLE_TYPE_FULFILLED, payload: veiculoTipo });
        resolve(data);
      }
    }).catch((errors) => {
      dispatch({ type: FETCH_VEHICLE_TYPE_REJECTED, errors });
      reject(errors);
    });

  });

}

export function createVehicleType(vehicleType) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_VEHICLE_TYPE_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation createVehicleType {
            createVeiculoTipo (input: {
              codigo: ${vehicleType.codigo}
              matriz: ${vehicleType.matriz}
              descricao: "${vehicleType.descricao}"
              ligadaIcon: ${vehicleType.ligadaIcon}
              desligadaIcon: ${vehicleType.desligadaIcon}
              panicoIcon: ${vehicleType.panicoIcon}
              terminalIcon: ${vehicleType.terminalIcon}
            } ) {
              codigo
              matriz
              descricao
              ligadaIcon
              desligadaIcon
              panicoIcon
              terminalIcon
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_VEHICLE_TYPE_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { createVeiculoTipo } = data
        dispatch({ type: CREATE_VEHICLE_TYPE_FULFILLED, payload: createVeiculoTipo });
        dispatch({ type: SHOW_SAVED_TOAST_MESSAGE });
        dispatch(findAllVehicleTypes());
        resolve({ status: true, data: createVeiculoTipo });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_VEHICLE_TYPE_REJECTED, errors });
      reject(errors);
    });
  });
}

export function updateVehicleType(vehicleType) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_VEHICLE_TYPE_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation UpdateVehicleType {
            updateVeiculoTipo (input: {
              codigo: ${vehicleType.codigo}
              matriz: ${vehicleType.matriz}
              descricao: "${vehicleType.descricao}"
              ligadaIcon: ${vehicleType.ligadaIcon}
              desligadaIcon: ${vehicleType.desligadaIcon}
              panicoIcon: ${vehicleType.panicoIcon}
              terminalIcon: ${vehicleType.terminalIcon}
            } ) {
              codigo
              matriz
              descricao
              ligadaIcon
              desligadaIcon
              panicoIcon
              terminalIcon
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_VEHICLE_TYPE_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { updateVeiculoTipo } = data
        dispatch({ type: UPDATE_VEHICLE_TYPE_FULFILLED, payload: updateVeiculoTipo });
        dispatch({ type: SHOW_SAVED_TOAST_MESSAGE });
        dispatch(findAllVehicleTypes());
        resolve({ status: true, data: updateVeiculoTipo });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_VEHICLE_TYPE_REJECTED, errors });
      reject(errors);
    });
  });
}

export function removeVehicleType(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_VEHICLE_TYPE_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation RemoveVehicleType {
            deleteVeiculoTipo (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_VEHICLE_TYPE_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { deleteVeiculoTipo } = data
        dispatch({ type: DELETE_VEHICLE_TYPE_FULFILLED, payload: deleteVeiculoTipo });
        dispatch({ type: SHOW_REMOVED_TOAST_MESSAGE });
        dispatch(findAllVehicleTypes());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_VEHICLE_TYPE_REJECTED, errors });
      reject(errors);
    });
  });
}
