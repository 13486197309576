import api from '../../../../../api'

export const FETCH_SCORS_PENDING = 'FETCH_SCORS_PENDING'
export const FETCH_SCORS_FULFILLED = 'FETCH_SCORS_FULFILLED'
export const FETCH_SCORS_REJECTED = 'FETCH_SCORS_REJECTED'

export const FETCH_SCORE_PENDING = 'FETCH_SCORE_PENDING'
export const FETCH_SCORE_FULFILLED = 'FETCH_SCORE_FULFILLED'
export const FETCH_SCORE_REJECTED = 'FETCH_SCORE_REJECTED'

export const CREATE_SCORE_PENDING = 'CREATE_SCORE_PENDING'
export const CREATE_SCORE_FULFILLED = 'CREATE_SCORE_FULFILLED'
export const CREATE_SCORE_REJECTED = 'CREATE_SCORE_REJECTED'

export const UPDATE_SCORE_PENDING = 'UPDATE_SCORE_PENDING'
export const UPDATE_SCORE_FULFILLED = 'UPDATE_SCORE_FULFILLED'
export const UPDATE_SCORE_REJECTED = 'UPDATE_SCORE_REJECTED'

export const DELETE_SCORE_PENDING = 'DELETE_SCORE_PENDING'
export const DELETE_SCORE_FULFILLED = 'DELETE_SCORE_FULFILLED'
export const DELETE_SCORE_REJECTED = 'DELETE_SCORE_REJECTED'

export const CHANGE_SCORE = 'CHANGE_SCORE'

export const ADD_SCORE = 'ADD_SCORE'

export function changeScore(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_SCORE, payload: { name, value } })
  }
}
 
export function addScore() {
  return dispatch => {
    dispatch({ type: ADD_SCORE });
  };
}

export function findAllScors(first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SCORS_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllScors {
                  grupos (first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SCORS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { grupos } = data
        dispatch({ type: FETCH_SCORS_FULFILLED, payload: grupos });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SCORS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findScore(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SCORE_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                    query findScore {
                      grupo (codigo: ${codigo}) {
                        codigo
                        matriz
                        descricao
                        clienteMatriz {
                          codigo
                          razaoSocial
                        }
                      }
                    }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SCORE_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { grupo } = data
        dispatch({ type: FETCH_SCORE_FULFILLED, payload: grupo });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SCORE_REJECTED, error });
      resolve(error);
    });

  });

}

export function createScore(grupo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_SCORE_PENDING });

    api({
      method: 'post',          
      data: {
        query: `
          mutation createScore {
            createGrupo (input: {
              codigo: ${grupo.codigo}
              matriz: ${grupo.matriz}
              descricao: "${grupo.descricao}"
            } ) {
              codigo
              matriz
              descricao
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_SCORE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createGrupo } = data
        dispatch({ type: CREATE_SCORE_FULFILLED, payload: createGrupo });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_SCORE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateScore(grupo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_SCORE_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation UpdateScore {
            updateGrupo (input: {
              codigo: ${grupo.codigo}
              matriz: ${grupo.matriz}
              descricao: "${grupo.descricao}"
            } ) {
              codigo
              matriz
              descricao
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_SCORE_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateGrupo } = data
        dispatch({ type: UPDATE_SCORE_FULFILLED, payload: updateGrupo });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_SCORE_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
} 

export function removeScore(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_SCORE_PENDING });

    api({
      method: 'post',          
      data: {
        query: `
          mutation RemoveScore {
            deleteGrupo (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_SCORE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteGrupo } = data
        dispatch({ type: DELETE_SCORE_FULFILLED, payload: deleteGrupo });
        dispatch(findAllScors());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_SCORE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
