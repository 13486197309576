import api from '../../../../../api'
import { findAllSimCardsNotInUse } from '../../simCard/store'

export const FETCH_PERIPHERALS_PENDING = 'FETCH_PERIPHERALS_PENDING'
export const FETCH_PERIPHERALS_FULFILLED = 'FETCH_PERIPHERALS_FULFILLED'
export const FETCH_PERIPHERALS_REJECTED = 'FETCH_PERIPHERALS_REJECTED'

export const FETCH_PERIPHERAL_PENDING = 'FETCH_PERIPHERAL_PENDING'
export const FETCH_PERIPHERAL_FULFILLED = 'FETCH_PERIPHERAL_FULFILLED'
export const FETCH_PERIPHERAL_REJECTED = 'FETCH_PERIPHERAL_REJECTED'

export const CREATE_PERIPHERAL_PENDING = 'CREATE_PERIPHERAL_PENDING'
export const CREATE_PERIPHERAL_FULFILLED = 'CREATE_PERIPHERAL_FULFILLED'
export const CREATE_PERIPHERAL_REJECTED = 'CREATE_PERIPHERAL_REJECTED'

export const UPDATE_PERIPHERAL_PENDING = 'UPDATE_PERIPHERAL_PENDING'
export const UPDATE_PERIPHERAL_FULFILLED = 'UPDATE_PERIPHERAL_FULFILLED'
export const UPDATE_PERIPHERAL_REJECTED = 'UPDATE_PERIPHERAL_REJECTED'

export const DELETE_PERIPHERAL_PENDING = 'DELETE_PERIPHERAL_PENDING'
export const DELETE_PERIPHERAL_FULFILLED = 'DELETE_PERIPHERAL_FULFILLED'
export const DELETE_PERIPHERAL_REJECTED = 'DELETE_PERIPHERAL_REJECTED'

export const CHANGE_PERIPHERAL = 'CHANGE_PERIPHERAL'

export const ADD_PERIPHERAL = 'ADD_PERIPHERAL'

export function changePeripheral(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_PERIPHERAL, payload: { name, value } })
  }
}

export function addPeripheral() {
  return dispatch => {
    dispatch({ type: ADD_PERIPHERAL });
  };
}

export function findAllPeripherals(first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PERIPHERALS_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllPeripherals {
                  perifericos (first: ${first} offset: ${offset}) {
                    codigo
                    matriz
                    modelo
                    firmware
                    simCard
                    serial
                    controleInterno
                    numeroCelular {
                      ddd
                      iccid
                      numero
                    }
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_PERIPHERALS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { perifericos } = data
        dispatch({ type: FETCH_PERIPHERALS_FULFILLED, payload: perifericos });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PERIPHERALS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAllPeripheralsBySerial(search = -1) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PERIPHERALS_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllPeripheralsBySerial {
                  perifericosPorSerial (search: "${search}") {
                    codigo
                    matriz
                    modelo
                    firmware
                    simCard
                    serial
                    controleInterno
                    numeroCelular {
                      ddd
                      iccid
                      numero
                    }
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_PERIPHERALS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { perifericosPorSerial } = data
        dispatch({ type: FETCH_PERIPHERALS_FULFILLED, payload: perifericosPorSerial });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PERIPHERALS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAllPeripheralsNotInUse(primary = -1, secondary = -1, first = 1000, offset = 0) {
 
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PERIPHERALS_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllPeripherals {
                  perifericosSemUso (primary: ${primary} secondary: ${secondary} first: ${first} offset: ${offset}) {
                    codigo
                    matriz
                    modelo
                    firmware
                    simCard
                    serial
                    controleInterno
                    numeroCelular {
                      codigo
                      iccid
                      numero
                    }
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_PERIPHERALS_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { perifericosSemUso } = data
        dispatch({ type: FETCH_PERIPHERALS_FULFILLED, payload: perifericosSemUso });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PERIPHERALS_REJECTED, error });
      reject(error);
    });

  });

}

export function findAllPeripheralsNotInUseBySerial(search = -1) {
 
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PERIPHERALS_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllPeripherals {
                  perifericosSemUsoPorSerial (search: ${search}) {
                    serial
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_PERIPHERALS_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { perifericosSemUsoPorSerial } = data
        dispatch({ type: FETCH_PERIPHERALS_FULFILLED, payload: perifericosSemUsoPorSerial });
        resolve(perifericosSemUsoPorSerial);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PERIPHERALS_REJECTED, error });
      reject(error);
    });

  });

}

export function findPeripheral(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PERIPHERAL_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                    query findPeripheral {
                      periferico (codigo: ${codigo}) {
                        codigo
                        matriz
                        modelo
                        firmware
                        simCard
                        serial
                        controleInterno
                        firmwareModulo {
                          descricao
                        }
                        numeroCelular {
                          iccid
                          ddd
                          numero
                        }
                        clienteMatriz {
                          razaoSocial
                        }
                      }
                    }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data;
      if (errors) {
        dispatch({ type: FETCH_PERIPHERAL_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { periferico } = data;
        dispatch(findAllSimCardsNotInUse(periferico.simCard));
        dispatch({ type: FETCH_PERIPHERAL_FULFILLED, payload: periferico });
        resolve(periferico);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PERIPHERAL_REJECTED, error });
      reject(error);
    });

  });

}

export function createPeripheral(grupo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_PERIPHERAL_PENDING });

    api({
      method: 'post',
      data: {
        query: `
          mutation createPeripheral {
            createPeriferico (input: {
              codigo: ${grupo.codigo}
              matriz: ${grupo.matriz}
              modelo: ${grupo.modelo}
              firmware: ${grupo.firmware}
              simCard: ${grupo.simCard}
              serial: ${grupo.serial}
              controleInterno: ${grupo.controleInterno}
            } ) {
              codigo
              matriz
              modelo
              firmware
              simCard
              serial
              controleInterno
              firmwareModulo {
                codigo
                descricao
              }
              numeroCelular {
                codigo
                iccid
                numero
              }
              clienteMatriz {
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_PERIPHERAL_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createPeriferico } = data
        dispatch({ type: CREATE_PERIPHERAL_FULFILLED, payload: createPeriferico });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_PERIPHERAL_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updatePeripheral(grupo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_PERIPHERAL_PENDING });

    api({
      method: 'post',
      data: {
        query: `
          mutation UpdatePeripheral {
            updatePeriferico (input: {
              codigo: ${grupo.codigo}
              matriz: ${grupo.matriz}
              modelo: ${grupo.modelo}
              firmware: ${grupo.firmware}
              simCard: ${grupo.simCard}
              serial: ${grupo.serial}
              controleInterno: ${grupo.controleInterno}
            } ) {
              codigo
              matriz
              modelo
              firmware
              simCard
              serial
              controleInterno
              firmwareModulo {
                codigo
                descricao
              }
              numeroCelular {
                codigo
                iccid
                numero
              }
              clienteMatriz {
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_PERIPHERAL_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updatePeriferico } = data
        dispatch({ type: UPDATE_PERIPHERAL_FULFILLED, payload: updatePeriferico });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_PERIPHERAL_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
}

export function removePeripheral(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_PERIPHERAL_PENDING });

    api({
      method: 'post',
      data: {
        query: `
          mutation RemovePeripheral {
            deletePeriferico (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_PERIPHERAL_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deletePeriferico } = data
        dispatch({ type: DELETE_PERIPHERAL_FULFILLED, payload: deletePeriferico });
        dispatch(findAllPeripherals())
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_PERIPHERAL_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
