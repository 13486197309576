import { SHOW_SAVED_TOAST_MESSAGE, SHOW_REMOVED_TOAST_MESSAGE } from '../../../../../common/layout/components/header/store';
import api from '../../../../../api';

export const FETCH_CARDS_LIBRARIES_PENDING = 'FETCH_CARDS_LIBRARIES_PENDING';
export const FETCH_CARDS_LIBRARIES_FULFILLED = 'FETCH_CARDS_LIBRARIES_FULFILLED';
export const FETCH_CARDS_LIBRARIES_REJECTED = 'FETCH_CARDS_LIBRARIES_REJECTED';

export const FETCH_CARDS_LIBRARY_PENDING = 'FETCH_CARDS_LIBRARY_PENDING';
export const FETCH_CARDS_LIBRARY_FULFILLED = 'FETCH_CARDS_LIBRARY_FULFILLED';
export const FETCH_CARDS_LIBRARY_REJECTED = 'FETCH_CARDS_LIBRARY_REJECTED';

export const CREATE_CARDS_LIBRARY_PENDING = 'CREATE_CARDS_LIBRARY_PENDING';
export const CREATE_CARDS_LIBRARY_FULFILLED = 'CREATE_CARDS_LIBRARY_FULFILLED';
export const CREATE_CARDS_LIBRARY_REJECTED = 'CREATE_CARDS_LIBRARY_REJECTED';

export const UPDATE_CARDS_LIBRARY_PENDING = 'UPDATE_CARDS_LIBRARY_PENDING';
export const UPDATE_CARDS_LIBRARY_FULFILLED = 'UPDATE_CARDS_LIBRARY_FULFILLED';
export const UPDATE_CARDS_LIBRARY_REJECTED = 'UPDATE_CARDS_LIBRARY_REJECTED';

export const DELETE_CARDS_LIBRARY_PENDING = 'DELETE_CARDS_LIBRARY_PENDING';
export const DELETE_CARDS_LIBRARY_FULFILLED = 'DELETE_CARDS_LIBRARY_FULFILLED';
export const DELETE_CARDS_LIBRARY_REJECTED = 'DELETE_CARDS_LIBRARY_REJECTED';

export const CHANGE_CARDS_LIBRARY = 'CHANGE_CARDS_LIBRARY';
export const ADD_CARDS_LIBRARY = 'ADD_CARDS_LIBRARY';

export function changeCardLibrary(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_CARDS_LIBRARY, payload: { name, value } })
  }
}

export function addCardLibrary() {
  return dispatch => {
    dispatch({ type: ADD_CARDS_LIBRARY });
  };
}

export function findAllCardLibraries(first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CARDS_LIBRARIES_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                  query findAllCardLibraries {
                    cartoesBiblioteca (first: ${first} offset: ${offset}) {
                      codigo
                      matriz
                      descricao
                      cartoes
                      motoristas {
                        serial
                        cartaoMotorista {
                          nome
                        }
                      }
                      clienteMatriz {
                        razaoSocial
                      }
                    }
                  }
                  `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CARDS_LIBRARIES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { cartoesBiblioteca } = data
        dispatch({ type: FETCH_CARDS_LIBRARIES_FULFILLED, payload: cartoesBiblioteca });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CARDS_LIBRARIES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAllCardLibrariesByDescription(search = '') {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CARDS_LIBRARIES_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                  query findAllCardLibrariesByDescription {
                    cartoesBibliotecaPorDescricao (search: "${search}") {
                      codigo
                      matriz
                      descricao
                      cartoes
                      motoristas {
                        serial
                        cartaoMotorista {
                          nome
                        }
                      }
                      clienteMatriz {
                        razaoSocial
                      }
                    }
                  }
                  `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CARDS_LIBRARIES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { cartoesBibliotecaPorDescricao } = data
        dispatch({ type: FETCH_CARDS_LIBRARIES_FULFILLED, payload: cartoesBibliotecaPorDescricao });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CARDS_LIBRARIES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findCardLibrary(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CARDS_LIBRARY_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                    query findCard {
                      cartaoBiblioteca (codigo: ${codigo}) {
                        codigo
                        matriz
                        descricao
                        cartoes
                        motoristas {
                          serial
                          cartaoMotorista {
                            nome
                          }
                        }
                        clienteMatriz {
                          razaoSocial
                        }
                      }
                    }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CARDS_LIBRARY_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { cartaoBiblioteca } = data
        dispatch({ type: FETCH_CARDS_LIBRARY_FULFILLED, payload: cartaoBiblioteca });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CARDS_LIBRARY_REJECTED, error });
      resolve(error);
    });

  });

}

export function createCardLibrary(cardLibrary) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_CARDS_LIBRARY_PENDING });
    api({
      method: 'post',         
      data: {
        query: `
          mutation createCard {
            createCartaoBiblioteca (input: {
              codigo: ${cardLibrary.codigo}
              matriz: ${cardLibrary.matriz}
              descricao: "${cardLibrary.descricao}"
              cartoes: "${cardLibrary.cartoes.join(";")}"
            } ) {
              codigo
              matriz
              descricao
              cartoes
              motoristas {
                serial
                cartaoMotorista {
                  nome
                }
              }
              clienteMatriz {
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_CARDS_LIBRARY_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { createCartaoBiblioteca } = data
        dispatch({ type: CREATE_CARDS_LIBRARY_FULFILLED, payload: createCartaoBiblioteca });
        dispatch({ type: SHOW_SAVED_TOAST_MESSAGE });
        dispatch(findAllCardLibraries());
        resolve();
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_CARDS_LIBRARY_REJECTED, errors });
      reject(errors);
    });
  });
}

export function updateCardLibrary(cardLibrary) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_CARDS_LIBRARY_PENDING });
    api({
      method: 'post',        
      data: {
        query: `
          mutation UpdateCard {
            updateCartaoBiblioteca (input: {
              codigo: ${cardLibrary.codigo}
              matriz: ${cardLibrary.matriz}
              descricao: "${cardLibrary.descricao}"
              cartoes: "${cardLibrary.cartoes.join(";")}"
            } ) {
              codigo
              matriz
              descricao
              cartoes
              motoristas {
                serial
                cartaoMotorista {
                  nome
                }
              }
              clienteMatriz {
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_CARDS_LIBRARY_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { updateCartaoBiblioteca } = data
        dispatch({ type: UPDATE_CARDS_LIBRARY_FULFILLED, payload: updateCartaoBiblioteca });
        dispatch({ type: SHOW_SAVED_TOAST_MESSAGE });
        dispatch(findAllCardLibraries());
        resolve();
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_CARDS_LIBRARY_REJECTED, errors });
      reject(errors);
    });
  });
}

export function removeCardLibrary(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_CARDS_LIBRARY_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation RemoveCard {
            deleteCartaoBiblioteca (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_CARDS_LIBRARY_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { deleteCartaoBiblioteca } = data
        dispatch({ type: DELETE_CARDS_LIBRARY_FULFILLED, payload: deleteCartaoBiblioteca });
        dispatch({ type: SHOW_REMOVED_TOAST_MESSAGE });
        dispatch(findAllCardLibraries());
        resolve();
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_CARDS_LIBRARY_REJECTED, errors });
      reject(errors);
    });
  });
}
