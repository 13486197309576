import { SHOW_SAVED_TOAST_MESSAGE, SHOW_REMOVED_TOAST_MESSAGE } from '../../../../common/layout/components/header/store';
import api from '../../../../api';

export const FETCH_TECHNICALS_PENDING = 'FETCH_TECHNICALS_PENDING';
export const FETCH_TECHNICALS_FULFILLED = 'FETCH_TECHNICALS_FULFILLED';
export const FETCH_TECHNICALS_REJECTED = 'FETCH_TECHNICALS_REJECTED';

export const FETCH_TECHNICAL_PENDING = 'FETCH_TECHNICAL_PENDING';
export const FETCH_TECHNICAL_FULFILLED = 'FETCH_TECHNICAL_FULFILLED';
export const FETCH_TECHNICAL_REJECTED = 'FETCH_TECHNICAL_REJECTED';

export const DELETE_TECHNICAL_PENDING = 'DELETE_TECHNICAL_PENDING';
export const DELETE_TECHNICAL_FULFILLED = 'DELETE_TECHNICAL_FULFILLED';
export const DELETE_TECHNICAL_REJECTED = 'DELETE_TECHNICAL_REJECTED';

export const POST_TECHNICAL_PENDING = 'POST_TECHNICAL_PENDING';
export const POST_TECHNICAL_CREATED = 'POST_TECHNICAL_CREATED';
export const POST_TECHNICAL_UPDATED = 'POST_TECHNICAL_UPDATED';
export const POST_TECHNICAL_REJECTED = 'POST_TECHNICAL_REJECTED';

export const CREATE_TECHNICAL = 'CREATE_TECHNICAL';
export const DATA_CHANGE_TECHNICAL = 'DATA_CHANGE_TECHNICAL';

export function technicalChange(name, value) {
  return dispatch => {
    dispatch({ type: DATA_CHANGE_TECHNICAL, payload: { name, value } })
  }
}

export function addTechnical() { 
  return dispatch => {
    dispatch({ type: CREATE_TECHNICAL });
  };
}

export function findAllTechnicals(first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_TECHNICALS_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          query findAllTechnicals {
            tecnicos (first: ${first} offset: ${offset}) {
                codigo
                matriz
                nome
                celular
                clienteMatriz {
                  codigo
                  razaoSocial
                }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_TECHNICALS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { tecnicos } = data
        dispatch({ type: FETCH_TECHNICALS_FULFILLED, payload: tecnicos });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_TECHNICALS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAllTechnicalsByName(search = '', first = 1000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_TECHNICALS_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          query findAllTechnicalsByName {
            tecnicosPorNome (search: "${search}" first: ${first} offset: ${offset}) {
                codigo
                matriz
                nome
                celular
                clienteMatriz {
                  codigo
                  razaoSocial
                }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_TECHNICALS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { tecnicosPorNome } = data
        dispatch({ type: FETCH_TECHNICALS_FULFILLED, payload: tecnicosPorNome });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_TECHNICALS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findTechnical(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_TECHNICAL_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          query findTechnical {
            tecnico (codigo: ${codigo}) {
              codigo
              matriz
              nome
              endereco
              cep
              bairro
              complemento
              estado
              cidade
              municipio {
                codigo
                nome
              }
              cpf
              matricula
              celular
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_TECHNICAL_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { tecnico } = data
        dispatch({ type: FETCH_TECHNICAL_FULFILLED, payload: tecnico });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_TECHNICAL_REJECTED, error });
      resolve(error);
    });

  });

}

export function createTechnical(technical) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: POST_TECHNICAL_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation createTecnico {
            createTecnico (input: {
              codigo: ${technical.codigo}
              matriz: ${technical.matriz}
              nome: "${technical.nome}"
              endereco: "${technical.endereco}"
              cep: "${technical.cep}"
              bairro: "${technical.bairro}"
              complemento: "${technical.complemento}"
              estado: ${technical.estado}
              cidade: ${technical.cidade}
              cpf: "${technical.cpf}"
              matricula: "${technical.matricula}"
              celular: "${technical.celular}"
            } ) {
              codigo
              matriz
              nome
              endereco
              cep
              bairro
              complemento
              estado
              cidade
              municipio {
                codigo
                nome
              }
              cpf
              matricula
              celular
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: POST_TECHNICAL_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { createTecnico } = data
        dispatch({ type: POST_TECHNICAL_CREATED, payload: createTecnico });
        dispatch({ type: SHOW_SAVED_TOAST_MESSAGE });
        dispatch(findAllTechnicals());
        resolve();
      }
    }).catch((error) => {
      dispatch({ type: POST_TECHNICAL_REJECTED, error });
      reject(error);
    });

  });
  
}

export function updateTechnical(technical) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: POST_TECHNICAL_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation updateTecnico {
            updateTecnico (input: {
              codigo: ${technical.codigo}
              matriz: ${technical.matriz}
              nome: "${technical.nome}"
              endereco: "${technical.endereco}"
              cep: "${technical.cep}"
              bairro: "${technical.bairro}"
              complemento: "${technical.complemento}"
              estado: ${technical.estado}
              cidade: ${technical.cidade}
              cpf: "${technical.cpf}"
              matricula: "${technical.matricula}"
              celular: "${technical.celular}"
            } ) {
              codigo
              matriz
              nome
              endereco
              cep
              bairro
              complemento
              estado
              cidade
              municipio {
                codigo
                nome
              }
              cpf
              matricula
              celular
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: POST_TECHNICAL_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { updateTecnico } = data
        dispatch({ type: POST_TECHNICAL_UPDATED, payload: updateTecnico });
        dispatch({ type: SHOW_SAVED_TOAST_MESSAGE });
        dispatch(findAllTechnicals());
        resolve();
      }
    }).catch((error) => {
      dispatch({ type: POST_TECHNICAL_REJECTED, error });
      reject(error);
    });

  });
}

export function removeTechnical(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_TECHNICAL_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation RemoveTechnical {
            deleteTecnico (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_TECHNICAL_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { deleteTecnico } = data
        dispatch({ type: DELETE_TECHNICAL_FULFILLED, payload: deleteTecnico });
        dispatch({ type: SHOW_REMOVED_TOAST_MESSAGE });
        dispatch(findAllTechnicals());
        resolve();
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_TECHNICAL_REJECTED, errors });
      reject(errors);
    });
  });
}
