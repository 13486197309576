import {

    FETCH_TEMPERATURE_3_ZONES_PENDING,
    FETCH_TEMPERATURE_3_ZONES_FULFILLED,
    FETCH_TEMPERATURE_3_ZONES_REJECTED,

    CHANGE_TEMPERATURE_3_ZONES,

    DEFAULT_TEMPERATURE_3_ZONES,

} from './temperature3ZonesActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfTemperature3Zones: [],
    temperature3Zones: {
        veiculo: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function temperature3ZonesReducer(state = initialState, action) {
    switch (action.type) {
        case DEFAULT_TEMPERATURE_3_ZONES:
            return Object.assign({}, state, {
                ...initialState
            })
        case CHANGE_TEMPERATURE_3_ZONES:
            return Object.assign({}, state, {
                temperature3Zones: {
                    ...state.temperature3Zones,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_TEMPERATURE_3_ZONES_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfTemperature3Zones: initialState.listOfTemperature3Zones,
            })
        case FETCH_TEMPERATURE_3_ZONES_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfTemperature3Zones: action.payload
            })
        case FETCH_TEMPERATURE_3_ZONES_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfTemperature3Zones: initialState.listOfTemperature3Zones,
                error: action.payload
            })

        default:
            return state
    }
}