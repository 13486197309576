import api from '../../../../api'

export const FETCH_EVENTS_VEHICLE_PENDING = 'FETCH_EVENTS_VEHICLE_PENDING'
export const FETCH_EVENTS_VEHICLE_FULFILLED = 'FETCH_EVENTS_VEHICLE_FULFILLED'
export const FETCH_EVENTS_VEHICLE_REJECTED = 'FETCH_EVENTS_VEHICLE_REJECTED'

export const DELETE_EVENTS_VEHICLE_PENDING = 'DELETE_EVENTS_VEHICLE_PENDING'
export const DELETE_EVENTS_VEHICLE_FULFILLED = 'DELETE_EVENTS_VEHICLE_FULFILLED'
export const DELETE_EVENTS_VEHICLE_REJECTED = 'DELETE_EVENTS_VEHICLE_REJECTED'

export const CHANGE_EVENTS_VEHICLE = 'CHANGE_EVENTS_VEHICLE'

export function changeEventsVehiclePeriod(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_EVENTS_VEHICLE, payload: { name, value } })
  }
}

export function findAllEventsVehiclePeriods(filter) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_EVENTS_VEHICLE_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllEventsVehiclePeriods {
                  eventosVeiculoPorCliente (input: {
                    matriz: ${filter.matriz}
                    motorista: ${filter.motorista},
                    dataHoraInicial: "${filter.dataHoraInicial}"
                    dataHoraFinal: "${filter.dataHoraFinal}"
                  }) {
                    nome
                    conducaoBanguela
                    velocidadeSeco
                    velocidadeChuva
                    excessoRpm
                    freadaBrusca
                    aceleracaoMovimento
                    aceleracaoParado
                    excessoTemperatura
                    entradaUm
                    entradaDois
                    entradaTres
                    entradaQuatro
                    entradaCinco
                    entradaSeis
                    entradaSete
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_EVENTS_VEHICLE_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { eventosVeiculoPorCliente } = data
        dispatch({ type: FETCH_EVENTS_VEHICLE_FULFILLED, payload: eventosVeiculoPorCliente });
        resolve(eventosVeiculoPorCliente);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_EVENTS_VEHICLE_REJECTED, error });
      reject(error);
    });

  });

}
