import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Shortcut, Navigation } from "../../navigation";
import { HoverText } from '../../utils';

import Ribbon from "./Ribbon";
import LayoutSwitcher from "./LayoutSwitcher";

import Header from "./header";
import Footer from "./Footer";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';

import { getContrastYIQ }  from "../../../common/utils";
import { routes } from "../../../routes";
import { config } from '../../../config/config';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const ActEvent = (props) => {
    const [resolution, setResolution] = useState('');
    const [comments, setComments] = useState('');
    const [status, setStatus] = useState(0);

    useEffect(() => {
        if (!props.showActEvent) {
            setResolution('');
            setComments('');
            setStatus('');
        }
    }, [props.showActEvent]);
    // disableBackdropClick
    return (
        <Dialog
            maxWidth={'sm'}
            fullWidth={true}
            disableEscapeKeyDown={true}
            open={props.showActEvent}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">MonitorAS - Atuar no Evento</DialogTitle>
            <DialogContent>
                <div className="widget-body no-padding">
                    <form className="smart-form">
                        <fieldset>
                            <div className="row">
                                <section className="col col-sx-12 col-md-12 col-lg-12" style={{
                                        backgroundColor: props.eventColor ? props.eventColor : 'gray',
                                        padding: '20px', color: props.eventColor ? getContrastYIQ(props.eventColor) : '#000', 
                                        maxWidth: '98%'
                                    }}>
                                    <h4>Descrição do Evento: {props.eventMessage}</h4>
                                    <h4>Data e Hora Ocorrência: {props.eventDateTime}</h4>
                                    <h4>Localização: {props.eventLatitude.toFixed(6)}, {props.eventLongitude.toFixed(6)} 
                                        <HoverText onClick={() => { }} >
                                            <i style={{ color: props.eventColor ? getContrastYIQ(props.eventColor) : '#000' , fontSize: '18px' }} className="fa fa-street-view" aria-hidden="true" title={"Visualizar Street View"} />
                                        </HoverText>
                                    </h4>
                                </section>
                            </div>
                            <div className="row">
                                <section className="col col-sx-12 col-md-12 col-lg-12">
                                    <label className="label">Resolução Aplicada ao Evento</label>
                                    <label className="input">
                                        <textarea className="form-control"
                                            style={{ resize: 'none', paddingLeft: 5, width: '98%' }} rows={4} placeholder="resolução aplicada ao evento"
                                            id="resolution" name="resolution" value={resolution} onChange={(e) => setResolution(e.target.value)} />
                                    </label>
                                </section>
                            </div>

                            <div className="row">
                                <section className="col col-sx-12 col-md-12 col-lg-12">
                                    <label className="label">Observações</label>
                                    <label className="input">
                                        <textarea className="form-control"
                                            style={{ resize: 'none', paddingLeft: 5, width: '98%' }} rows={4} placeholder="observações"
                                            id="comments" name="comments" value={comments} onChange={(e) => setComments(e.target.value)} />
                                    </label>
                                </section>
                            </div>

                            <div className="row">
                                <section className="col col-sx-12 col-md-12 col-lg-12">
                                    <label className="label">Situação</label>
                                    <label className="select">
                                        <select id="status" name="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                                            <option value={1}>Visualizado</option>
                                            <option value={2}>Pendente de Resolução</option>
                                            <option value={3}>Resolvido pelo Operador</option>
                                            <option value={4}>Resolvido pelo Cliente</option>
                                        </select>
                                        <i />
                                    </label>
                                </section>
                            </div>

                        </fieldset>
                    </form>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} variant="contained" color="secondary">
                    Cancelar
          </Button>
                <Button onClick={() => { props.handleConfirm(props.eventId, status, resolution, comments) }} variant="contained" color="primary">
                    Confirmar
          </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ActEvent;