import api from '../../../../api'
import * as moment from 'moment'

export const FETCH_COMMAND_HISTORY_PENDING = 'FETCH_COMMAND_HISTORY_PENDING'
export const FETCH_COMMAND_HISTORY_FULFILLED = 'FETCH_COMMAND_HISTORY_FULFILLED'
export const FETCH_COMMAND_HISTORY_REJECTED = 'FETCH_COMMAND_HISTORY_REJECTED'

export const CANCEL_COMMAND_HISTORY_PENDING = 'CANCEL_COMMAND_HISTORY_PENDING'
export const CANCEL_COMMAND_HISTORY_FULFILLED = 'CANCEL_COMMAND_HISTORY_FULFILLED'
export const CANCEL_COMMAND_HISTORY_REJECTED = 'CANCEL_COMMAND_HISTORY_REJECTED'

export const DELETE_COMMAND_HISTORY_PENDING = 'DELETE_COMMAND_HISTORY_PENDING'
export const DELETE_COMMAND_HISTORY_FULFILLED = 'DELETE_COMMAND_HISTORY_FULFILLED'
export const DELETE_COMMAND_HISTORY_REJECTED = 'DELETE_COMMAND_HISTORY_REJECTED'

export const CHANGE_COMMAND_HISTORY = 'CHANGE_COMMAND_HISTORY'

export function changeCommandHistory(name, value) {
  return dispatch => {
    dispatch({ 
      type: CHANGE_COMMAND_HISTORY, 
      payload: { name, value } 
    })
  }
}

export function findAllCommandHistorys(filter, first = 12000, offset = 0) {
  
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_COMMAND_HISTORY_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllCommandHistorys {
                  comandosEnviados (
                    veiculo: ${filter.veiculo}
                    dataHoraInicial: "${filter.dataHoraInicial}"
                    dataHoraFinal: "${filter.dataHoraFinal}"
                  ) {
                    codigo
                    veiculo
                    modulo
                    sequencia
                    tipoComando
                    usuario
                    usuarioEnvio {
                      nome
                    }
                    dataCadastro
                    dataAgendamento
                    dataExecucao
                    dataCancelamento
                    parametro
                    tentativas
                    execucoes
                    status
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_COMMAND_HISTORY_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { comandosEnviados } = data
        dispatch({ type: FETCH_COMMAND_HISTORY_FULFILLED, payload: comandosEnviados });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_COMMAND_HISTORY_REJECTED, error });
      reject(error);
    });

  });

}

export function cancelCommand(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CANCEL_COMMAND_HISTORY_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation UpdateComandoEnviado {
            updateComandoEnviado (input: {
              codigo: ${codigo}
              dataCancelamento: "${moment(new Date()).format("DD/MM/YYYY HH:mm:ss")}"
              status: 6
            }) {
              codigo
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CANCEL_COMMAND_HISTORY_REJECTED, payload: errors });
        reject({ status: false, error: errors });
      } else {
        const { updateComandoEnviado } = data
        dispatch({ type: CANCEL_COMMAND_HISTORY_FULFILLED, payload: updateComandoEnviado });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CANCEL_COMMAND_HISTORY_REJECTED, errors });
      reject({ status: false, error: errors });
    });
  });
}

export function resendCommand(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CANCEL_COMMAND_HISTORY_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation UpdateComandoEnviado {
            updateComandoEnviado (input: {
              codigo: ${codigo}
              dataExecucao: ""
              dataCancelamento: ""
              tentativas: 10
              execucoes: 0
              status: 0
            }) {
              codigo
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CANCEL_COMMAND_HISTORY_REJECTED, payload: errors });
        reject({ status: false, error: errors });
      } else {
        const { updateComandoEnviado } = data
        dispatch({ type: CANCEL_COMMAND_HISTORY_FULFILLED, payload: updateComandoEnviado });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CANCEL_COMMAND_HISTORY_REJECTED, errors });
      reject({ status: false, error: errors });
    });
  });
}

export function removeCommandHistory(veiculo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_COMMAND_HISTORY_PENDING });

    api({
      method: 'post',
      data: {
        query: `
          mutation RemoveCommandHistory {
            deleteHistoricoPosicoesContadorAcesso (veiculo: ${veiculo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_COMMAND_HISTORY_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteHistoricoPosicoesContadorAcesso } = data
        dispatch({ type: DELETE_COMMAND_HISTORY_FULFILLED, payload: deleteHistoricoPosicoesContadorAcesso });
        dispatch(findAllCommandHistorys());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_COMMAND_HISTORY_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
