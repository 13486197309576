import {

    FETCH_EVENTS_PERIOD_PENDING,
    FETCH_EVENTS_PERIOD_FULFILLED,
    FETCH_EVENTS_PERIOD_REJECTED,

    CHANGE_EVENTS_PERIOD

} from './eventsPeriodActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfEventsPeriod: {
        mensagem: '',
        eventos: 0
    },
    eventsPeriod: {
        veiculo: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function eventsPeriodReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_EVENTS_PERIOD:
            return Object.assign({}, state, {
                eventsPeriod: {
                    ...state.eventsPeriod,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_EVENTS_PERIOD_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfEventsPeriod: initialState.listOfEventsPeriod,
            })
        case FETCH_EVENTS_PERIOD_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfEventsPeriod: action.payload
            })
        case FETCH_EVENTS_PERIOD_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfEventsPeriod: initialState.listOfEventsPeriod,
                error: action.payload
            })

        default:
            return state
    }
}