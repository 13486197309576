import { SHOW_SAVED_TOAST_MESSAGE, SHOW_REMOVED_TOAST_MESSAGE } from '../../../../../common/layout/components/header/store';
import api from '../../../../../api';

import { toInt } from '../../../../../common/utils';

export const FETCH_CARDS_PENDING = 'FETCH_CARDS_PENDING';
export const FETCH_CARDS_FULFILLED = 'FETCH_CARDS_FULFILLED';
export const FETCH_CARDS_REJECTED = 'FETCH_CARDS_REJECTED';

export const FETCH_CARD_PENDING = 'FETCH_CARD_PENDING';
export const FETCH_CARD_FULFILLED = 'FETCH_CARD_FULFILLED';
export const FETCH_CARD_REJECTED = 'FETCH_CARD_REJECTED';

export const CREATE_CARD_PENDING = 'CREATE_CARD_PENDING';
export const CREATE_CARD_FULFILLED = 'CREATE_CARD_FULFILLED';
export const CREATE_CARD_REJECTED = 'CREATE_CARD_REJECTED';

export const UPDATE_CARD_PENDING = 'UPDATE_CARD_PENDING';
export const UPDATE_CARD_FULFILLED = 'UPDATE_CARD_FULFILLED';
export const UPDATE_CARD_REJECTED = 'UPDATE_CARD_REJECTED';

export const DELETE_CARD_PENDING = 'DELETE_CARD_PENDING';
export const DELETE_CARD_FULFILLED = 'DELETE_CARD_FULFILLED';
export const DELETE_CARD_REJECTED = 'DELETE_CARD_REJECTED';

export const CHANGE_CARD = 'CHANGE_CARD';
export const ADD_CARD = 'ADD_CARD';

export function changeCard(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_CARD, payload: { name, value } })
  }
}

export function addCard() {
  return dispatch => {
    dispatch({ type: ADD_CARD });
  };
}

export function findAllCards(matriz = 1, first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CARDS_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                  query findAllCards {
                    cartoes (first: ${first} offset: ${offset}) {
                      codigo
                      serial
                      codigoInterno
                      tipoCartao
                      motorista
                      cartaoMotorista {
                        nome
                      }
                      clienteMatriz {
                        razaoSocial
                      }
                    }
                  }
                  `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CARDS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { cartoes } = data
        dispatch({ type: FETCH_CARDS_FULFILLED, payload: cartoes });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CARDS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAllCardsBySerial(search = '', first = 1000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CARDS_PENDING });

    if (search.trim().length <= 2) {
      dispatch(findAllCards());
      return;
    }

    api({
      method: 'post',        
      data: {
        query: `
                  query findAllCardsBySerial {
                    cartoesPorSerial (search: "${search}" first: ${first} offset: ${offset}) {
                      codigo
                      serial
                      codigoInterno
                      tipoCartao
                      motorista
                      cartaoMotorista {
                        nome
                      }
                      clienteMatriz {
                        razaoSocial
                      }
                    }
                  }
                  `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CARDS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { cartoesPorSerial } = data
        dispatch({ type: FETCH_CARDS_FULFILLED, payload: cartoesPorSerial });
        resolve(cartoesPorSerial);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CARDS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findCard(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CARD_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                    query findCard {
                      cartao (codigo: ${codigo}) {
                        codigo
                        serial
                        codigoInterno
                        tipoCartao
                        motorista
                        cartaoMotorista {
                          nome
                        }
                        matriz
                        clienteMatriz {
                          razaoSocial
                        }
                      }
                    }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CARD_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { cartao } = data
        dispatch({ type: FETCH_CARD_FULFILLED, payload: cartao });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CARD_REJECTED, error });
      resolve(error);
    });

  });

}

export function createCard(card) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_CARD_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation createCard {
            createCartao (input: {
              codigo: ${card.codigo}
              matriz: ${card.matriz}
              serial: ${card.serial}
              codigoInterno: ${toInt(card.codigoInterno)}
              tipoCartao: ${card.tipoCartao}
              motorista: ${card.motorista}
            } ) {
              codigo
              serial
              codigoInterno
              tipoCartao
              motorista
              cartaoMotorista {
                codigo
                nome
              }
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_CARD_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { createCartao } = data
        dispatch({ type: CREATE_CARD_FULFILLED, payload: createCartao });
        dispatch({ type: SHOW_SAVED_TOAST_MESSAGE });
        resolve();
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_CARD_REJECTED, errors });
      reject(errors);
    });
  });
}

export function updateCard(card) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_CARD_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation UpdateCard {
            updateCartao (input: {
              codigo: ${card.codigo}
              matriz: ${card.matriz}
              serial: ${card.serial}
              codigoInterno: ${toInt(card.codigoInterno)}
              tipoCartao: ${card.tipoCartao}
              motorista: ${card.motorista}
            } ) {
              codigo
              serial
              codigoInterno
              tipoCartao
              motorista
              cartaoMotorista {
                codigo
                nome
              }
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_CARD_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { updateCartao } = data
        dispatch({ type: UPDATE_CARD_FULFILLED, payload: updateCartao });
        dispatch({ type: SHOW_SAVED_TOAST_MESSAGE });
        resolve();
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_CARD_REJECTED, errors });
      reject(errors);
    });
  });
}

export function removeCard(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_CARD_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation RemoveCard {
            deleteCartao (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_CARD_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { deleteCartao } = data
        dispatch({ type: DELETE_CARD_FULFILLED, payload: deleteCartao });
        dispatch({ type: SHOW_REMOVED_TOAST_MESSAGE });
        dispatch(findAllCards());
        resolve();
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_CARD_REJECTED, errors });
      reject(errors);
    });
  });
}
