import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const ExpenseHistory = Loadable({
  loader: () => import("./expenseHistory/ExpenseHistory"),
  loading: Loading
});

const ExpenseHistoryVehicle = Loadable({
  loader: () => import("./expenseHistory/ExpenseHistoryVehicle"),
  loading: Loading
});

const ExpenseHistoryProvider = Loadable({
  loader: () => import("./expenseHistory/ExpenseHistoryProvider"),
  loading: Loading
});

const StopTimeHistory = Loadable({
  loader: () => import("./stopTimeHistory/StopTimeHistory"),
  loading: Loading
});

const IdleEngineHistory = Loadable({
  loader: () => import("./idleEngineHistory/IdleEngineHistory"),
  loading: Loading
});

const MonthlyClosing = Loadable({
  loader: () => import("./monthlyClosing/MonthlyClosing"),
  loading: Loading
});

const TrackingHistory = Loadable({
  loader: () => import("./trackingHistory/TrackingHistory"),
  loading: Loading
});

const StopHistory = Loadable({
  loader: () => import("./components/StopHistory"),
  loading: Loading
});

const TelemetryHistory = Loadable({
  loader: () => import("./telemetryHistory/TelemetryHistory"),
  loading: Loading
});

const EconometerHistory = Loadable({
  loader: () => import("./econometerHistory/EconometerHistory"),
  loading: Loading
});

const AccessCounterHistory = Loadable({
  loader: () => import("./accessCounterHistory/AccessCounterHistory"),
  loading: Loading
});

const TriggerTimeHistory = Loadable({
  loader: () => import("./triggerTimeHistory/TriggerTimeHistory"),
  loading: Loading
});

const TravelHistory = Loadable({
  loader: () => import("./components/TravelHistory"),
  loading: Loading
});

const MaxSpeedHistory = Loadable({
  loader: () => import("./maxSpeedHistory/MaxSpeedHistory"),
  loading: Loading
});

const TemperatureHistory = Loadable({
  loader: () => import("./components/TemperatureHistory"),
  loading: Loading
});

const MaintenanceHistory = Loadable({
  loader: () => import("./maintenanceHistory/MaintenanceHistory"),
  loading: Loading
});

const FuelHistory = Loadable({
  loader: () => import("./fuelHistory/FuelHistory"),
  loading: Loading
});

const FuelExclusion = Loadable({
  loader: () => import("./fuelHistory/FuelExclusion"),
  loading: Loading
});

const CommandHistory = Loadable({
  loader: () => import("./commandHistory/CommandHistory"),
  loading: Loading
});

const EventHistory = Loadable({
  loader: () => import("./eventHistory/EventHistory"),
  loading: Loading
});

const IrregularDrivers = Loadable({
  loader: () => import("./irregularDrivers/IrregularDrivers"),
  loading: Loading
});

export const routes = [
  {
    path: "/historical/irregularDrivers",
    exact: true,
    component: IrregularDrivers,
    name: "Irregular Drivers"
  },
  {
    path: "/historical/trackinghistory",
    exact: true,
    component: TrackingHistory,
    name: "Tracking History"
  },

  {
    path: "/historical/telemetryhistory",
    exact: true,
    component: TelemetryHistory,
    name: "Telemetry History"
  },

  {
    path: "/historical/stophistory",
    exact: true,
    component: StopHistory,
    name: "Stop History"
  },

  {
    path: "/historical/idleEngineHistory",
    exact: true,
    component: IdleEngineHistory,
    name: "Idle Engine History"
  },

  {
    path: "/historical/telemetryhistory",
    exact: true,
    component: TelemetryHistory,
    name: "Telemetry History"
  },

  {
    path: "/historical/econometerhistory",
    exact: true,
    component: EconometerHistory,
    name: "Econometer History"
  },

  {
    path: "/historical/accessCounterHistory",
    exact: true,
    component: AccessCounterHistory,
    name: "Access Counter History"
  },

  {
    path: "/historical/triggerTimeHistory",
    exact: true,
    component: TriggerTimeHistory,
    name: "Trigger Time History"
  },

  {
    path: "/historical/travelhistory",
    exact: true,
    component: TravelHistory,
    name: "Travel History"
  },

  {
    path: "/historical/maxspeedhistory",
    exact: true,
    component: MaxSpeedHistory,
    name: "Max Speed History"
  },

  {
    path: "/historical/temperaturehistory",
    exact: true,
    component: TemperatureHistory,
    name: "Temperature History"
  },
  
  {
    path: "/historical/maintenancehistory",
    exact: true,
    component: MaintenanceHistory,
    name: "Maintenance History"
  },

  {
    path: "/historical/fuelhistory",
    exact: true,
    component: FuelHistory,
    name: "Fuel History"
  },

  {
    path: "/historical/fuelExclusion",
    exact: true,
    component: FuelExclusion,
    name: "Fuel Exclusion"
  },

  {
    path: "/historical/commandhistory",
    exact: true,
    component: CommandHistory,
    name: "Command History"
  },

  {
    path: "/historical/eventhistory",
    exact: true,
    component: EventHistory,
    name: "Event History"
  },

  {
    path: "/historical/monthlyClosing",
    exact: true,
    component: MonthlyClosing,
    name: "Monthly Closing"
  },

  {
    path: "/historical/stopTimeHistory",
    exact: true,
    component: StopTimeHistory,
    name: "Stop Time History"
  },

  {
    path: "/historical/expenseHistory",
    exact: true,
    component: ExpenseHistory,
    name: "Expense History"
  },

  {
    path: "/historical/expenseHistoryVehicle",
    exact: true,
    component: ExpenseHistoryVehicle,
    name: "Expense History Por Veículo"
  },

  {
    path: "/historical/expenseHistoryProvider",
    exact: true,
    component: ExpenseHistoryProvider,
    name: "Expense History Por Fornecedor"
  },

];
