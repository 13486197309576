import api from '../../../../api'

export const FETCH_RISK_AREAS_PENDING = 'FETCH_RISK_AREAS_PENDING'
export const FETCH_RISK_AREAS_FULFILLED = 'FETCH_RISK_AREAS_FULFILLED'
export const FETCH_RISK_AREAS_REJECTED = 'FETCH_RISK_AREAS_REJECTED'

export const FETCH_RISK_AREA_PENDING = 'FETCH_RISK_AREA_PENDING'
export const FETCH_RISK_AREA_FULFILLED = 'FETCH_RISK_AREA_FULFILLED'
export const FETCH_RISK_AREA_REJECTED = 'FETCH_RISK_AREA_REJECTED'

export const CREATE_RISK_AREA_PENDING = 'CREATE_RISK_AREA_PENDING'
export const CREATE_RISK_AREA_FULFILLED = 'CREATE_RISK_AREA_FULFILLED'
export const CREATE_RISK_AREA_REJECTED = 'CREATE_RISK_AREA_REJECTED'

export const UPDATE_RISK_AREA_PENDING = 'UPDATE_RISK_AREA_PENDING'
export const UPDATE_RISK_AREA_FULFILLED = 'UPDATE_RISK_AREA_FULFILLED'
export const UPDATE_RISK_AREA_REJECTED = 'UPDATE_RISK_AREA_REJECTED'

export const DELETE_RISK_AREA_PENDING = 'DELETE_RISK_AREA_PENDING'
export const DELETE_RISK_AREA_FULFILLED = 'DELETE_RISK_AREA_FULFILLED'
export const DELETE_RISK_AREA_REJECTED = 'DELETE_RISK_AREA_REJECTED'

export const CHANGE_RISK_AREA = 'CHANGE_RISK_AREA'

export const ADD_RISK_AREA = 'ADD_RISK_AREA'

export function changeRiskArea(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_RISK_AREA, payload: { name, value } })
  }
}
 
export function addRiskArea() {
  return dispatch => {
    dispatch({ type: ADD_RISK_AREA });
  };
}

export function findAllRiskAreas(first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_RISK_AREAS_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllRiskAreas {
                  areasRisco (first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_RISK_AREAS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { areasRisco } = data
        dispatch({ type: FETCH_RISK_AREAS_FULFILLED, payload: areasRisco });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_RISK_AREAS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAllRiskAreasByDescription(search = '', first = 1000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_RISK_AREAS_PENDING });

    if (search.trim().length <= 2) {
      dispatch(findAllRiskAreas());
      return;
    }

    api({
      method: 'post',       
      data: {
        query: `
                query findAllRiskAreas {
                  areasRiscoPorDescricao (search: "${search}" first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_RISK_AREAS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { areasRiscoPorDescricao } = data
        dispatch({ type: FETCH_RISK_AREAS_FULFILLED, payload: areasRiscoPorDescricao });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_RISK_AREAS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findRiskArea(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_RISK_AREA_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                    query findRiskArea {
                      areaRisco (codigo: ${codigo}) {
                        codigo
                        matriz
                        descricao
                        poligono
                        latitude
                        longitude
                        raio
                        eventoEntrada
                        eventoSaida
                        comandoEntrada
                        comandoSaida
                        veiculosArea {
                          codigo
                          placa
                          descricao
                        }
                        clienteMatriz {
                          codigo
                          razaoSocial
                        }
                      }
                    }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_RISK_AREA_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { areaRisco } = data
        dispatch({ type: FETCH_RISK_AREA_FULFILLED, payload: areaRisco });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_RISK_AREA_REJECTED, error });
      resolve(error);
    });

  });

}

export function createRiskArea(riskArea) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_RISK_AREA_PENDING });
 
    api({
      method: 'post',        
      data: {
        query: `
          mutation createRiskArea {
            createAreaRisco (input: {
              codigo: ${riskArea.codigo}
              matriz: ${riskArea.matriz}
              descricao: "${riskArea.descricao}"
              poligono: "${riskArea.poligono}"
              latitude: ${parseFloat(String(riskArea.latitude).replace(",","."))}
              longitude: ${parseFloat(String(riskArea.longitude).replace(",","."))}
              raio: ${riskArea.raio}
              eventoEntrada: ${riskArea.eventoEntrada}
              eventoSaida: ${riskArea.eventoSaida}
              comandoEntrada: ${riskArea.comandoEntrada}
              comandoSaida: ${riskArea.comandoSaida}
              areaVeiculos: "${riskArea.areaVeiculos};"
            } ) {
              codigo
              matriz
              descricao
              poligono
              latitude
              longitude
              raio
              eventoEntrada
              eventoSaida
              comandoEntrada
              comandoSaida
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_RISK_AREA_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createAreaRisco } = data
        dispatch({ type: CREATE_RISK_AREA_FULFILLED, payload: createAreaRisco });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_RISK_AREA_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateRiskArea(riskArea) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_RISK_AREA_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation UpdateRiskArea {
            updateAreaRisco (input: {
              codigo: ${riskArea.codigo}
              matriz: ${riskArea.matriz}
              descricao: "${riskArea.descricao}"
              poligono: "${riskArea.poligono}"
              latitude: ${parseFloat(String(riskArea.latitude).replace(",","."))}
              longitude: ${parseFloat(String(riskArea.longitude).replace(",","."))}
              raio: ${riskArea.raio}
              eventoEntrada: ${riskArea.eventoEntrada}
              eventoSaida: ${riskArea.eventoSaida}
              comandoEntrada: ${riskArea.comandoEntrada}
              comandoSaida: ${riskArea.comandoSaida}
              areaVeiculos: "${riskArea.areaVeiculos};"
            } ) {
              codigo
              matriz
              descricao
              poligono
              latitude
              longitude
              raio
              eventoEntrada
              eventoSaida
              comandoEntrada
              comandoSaida
              clienteMatriz {
                codigo
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_RISK_AREA_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateAreaRisco } = data
        dispatch({ type: UPDATE_RISK_AREA_FULFILLED, payload: updateAreaRisco });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_RISK_AREA_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
} 

export function removeRiskArea(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_RISK_AREA_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation RemoveRiskArea {
            deleteAreaRisco (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_RISK_AREA_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteAreaRisco } = data
        dispatch({ type: DELETE_RISK_AREA_FULFILLED, payload: deleteAreaRisco });
        dispatch(findAllRiskAreas());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_RISK_AREA_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
