import { SHOW_ERROR_TOAST_MESSAGE } from '../../../../common/layout/components/header/store';
import api from '../../../../api';

export const FETCH_TEMPERATURE_AND_UMIDITY_PENDING = 'FETCH_TEMPERATURE_AND_UMIDITY_PENDING';
export const FETCH_TEMPERATURE_AND_UMIDITY_FULFILLED = 'FETCH_TEMPERATURE_AND_UMIDITY_FULFILLED';
export const FETCH_TEMPERATURE_AND_UMIDITY_REJECTED = 'FETCH_TEMPERATURE_AND_UMIDITY_REJECTED';

export const CHANGE_TEMPERATURE_AND_UMIDITY = 'CHANGE_TEMPERATURE_AND_UMIDITY';

export function changeTemperatureAndUmidity(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_TEMPERATURE_AND_UMIDITY, payload: { name, value } })
  }
}

export function showMessageSegment() {
  return dispatch => {
    dispatch({ type: SHOW_ERROR_TOAST_MESSAGE, payload: "ATENÇÃO!\nO Período contém erros na coleta de dados!" })
  }
}

export function findAllTemperatureAndUmiditys(filter, first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_TEMPERATURE_AND_UMIDITY_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllTemperatureAndUmiditys {
                  historicoPosicoesTemperaturaUmidade (input: {
                    veiculo: ${filter.veiculo}
                    motorista: ${filter.motorista}
                    dataHoraInicial: "${filter.dataHoraInicial}"
                    dataHoraFinal: "${filter.dataHoraFinal}"
                  } first: ${first} offset: ${offset}) {
                    modulo
                    dataHoraServidor
                    dataHoraGps
                    latitude
                    longitude
                    tipoEquipamento
                    temperatura
                    temperaturaDois
                    umidade
                    statusTemperaturaUm
                    statusTemperaturaDois
                    statusTemperaturaTres
                    veiculo {
                      placa
                      descricao
                      clienteMatriz {
                        razaoSocial
                      }
                      veiculoGaragem {
                        descricao
                      }
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_TEMPERATURE_AND_UMIDITY_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { historicoPosicoesTemperaturaUmidade } = data
        dispatch({ type: FETCH_TEMPERATURE_AND_UMIDITY_FULFILLED, payload: historicoPosicoesTemperaturaUmidade });
        resolve(historicoPosicoesTemperaturaUmidade);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_TEMPERATURE_AND_UMIDITY_REJECTED, error });
      reject(error);
    });

  });

}
