import {

    FETCH_MAINTENANCE_HISTORY_PENDING,
    FETCH_MAINTENANCE_HISTORY_FULFILLED,
    FETCH_MAINTENANCE_HISTORY_REJECTED,

    DELETE_MAINTENANCE_HISTORY_PENDING,
    DELETE_MAINTENANCE_HISTORY_FULFILLED,
    DELETE_MAINTENANCE_HISTORY_REJECTED,

    CHANGE_MAINTENANCE_HISTORY,

    DEFAULT_MAINTENANCE_HISTORY

} from './maintenanceHistoryActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfMaintenanceHistory: [],
    maintenanceHistory: {
        matriz: 0,
        veiculo: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        dataFinal: moment().utc().format("DD/MM/YYYY")
    },
    error: null,
}

export function maintenanceHistoryReducer(state = initialState, action) {
    switch (action.type) {
        case DEFAULT_MAINTENANCE_HISTORY: 
            return Object.assign({}, state, {
                ...initialState,
            })
        case CHANGE_MAINTENANCE_HISTORY:
            return Object.assign({}, state, {
                maintenanceHistory: {
                    ...state.maintenanceHistory,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_MAINTENANCE_HISTORY_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfMaintenanceHistory: [],
            })
        case FETCH_MAINTENANCE_HISTORY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfMaintenanceHistory: action.payload
            })
        case FETCH_MAINTENANCE_HISTORY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfMaintenanceHistory: [],
                error: action.payload
            })

        case DELETE_MAINTENANCE_HISTORY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_MAINTENANCE_HISTORY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_MAINTENANCE_HISTORY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })
        default:
            return state
    }
}