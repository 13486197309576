import {

    FETCH_KNOWN_POINTS_PENDING,
    FETCH_KNOWN_POINTS_FULFILLED,
    FETCH_KNOWN_POINTS_REJECTED,

    FETCH_KNOWN_POINT_PENDING,
    FETCH_KNOWN_POINT_FULFILLED,
    FETCH_KNOWN_POINT_REJECTED,

    CREATE_KNOWN_POINT_PENDING,
    CREATE_KNOWN_POINT_FULFILLED,
    CREATE_KNOWN_POINT_REJECTED,

    UPDATE_KNOWN_POINT_PENDING,
    UPDATE_KNOWN_POINT_FULFILLED,
    UPDATE_KNOWN_POINT_REJECTED,

    DELETE_KNOWN_POINT_PENDING,
    DELETE_KNOWN_POINT_FULFILLED,
    DELETE_KNOWN_POINT_REJECTED,
    
    CHANGE_KNOWN_POINT,
    
    ADD_KNOWN_POINT

} from './knownPointActions'

const titleCase = (str) => {
    if (!(str instanceof String)) return str;
    return str.toLowerCase().split(' ').map(function(word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}

const initialState = {
    loading: false,
    listOfKnownPoints: [],
    knownPoint: {
        codigo: 0,
        matriz: 0,
        descricao: '',
        tipoPonto: 0,
        endereco: '',
        latitude: 0.0,
        longitude: 0.0,
        raio: 0,
        pontoPublico: false,
        pontoProximo: false,
        eventoProximidade: false,
        clienteMatriz: {
            codigo: 0,
            razaoSocial: '',
        }
    },
    error: null,
}

export function knownPointReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_KNOWN_POINT:
            
        let value = ""
                
        if (action.payload.name.includes('mail')) {
            value = action.payload.value.toLowerCase();
        } else 
            if (action.payload.name.includes('apelido')) {
                value = action.payload.value;
            } else {
                value = titleCase(action.payload.value);
            }
        return Object.assign({}, state, {
            knownPoint: { ...state.knownPoint, 
                [action.payload.name]: value
            }
        })
        case ADD_KNOWN_POINT: 
            return Object.assign({}, state, {
                knownPoint: { ...initialState.knownPoint }
            })
        case FETCH_KNOWN_POINTS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfKnownPoints: [],
            })
        case FETCH_KNOWN_POINTS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfKnownPoints: action.payload
            })
        case FETCH_KNOWN_POINTS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_KNOWN_POINT_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //knownPoint: { ...initialState.knownPoint },
            })
        case CREATE_KNOWN_POINT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                knownPoint: action.payload
            })
        case CREATE_KNOWN_POINT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_KNOWN_POINT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_KNOWN_POINT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                knownPoint: action.payload
            })
        case UPDATE_KNOWN_POINT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_KNOWN_POINT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_KNOWN_POINT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                knownPoint: action.payload
            })
        case FETCH_KNOWN_POINT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_KNOWN_POINT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_KNOWN_POINT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_KNOWN_POINT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}