import { SHOW_SAVED_TOAST_MESSAGE, SHOW_REMOVED_TOAST_MESSAGE } from '../../../../common/layout/components/header/store';
import api from '../../../../api';

export const FETCH_DRIVERS_PENDING = 'FETCH_DRIVERS_PENDING';
export const FETCH_DRIVERS_FULFILLED = 'FETCH_DRIVERS_FULFILLED';
export const FETCH_DRIVERS_REJECTED = 'FETCH_DRIVERS_REJECTED';

export const FETCH_DRIVER_PENDING = 'FETCH_DRIVER_PENDING';
export const FETCH_DRIVER_FULFILLED = 'FETCH_DRIVER_FULFILLED';
export const FETCH_DRIVER_REJECTED = 'FETCH_DRIVER_REJECTED';

export const CREATE_DRIVER_PENDING = 'CREATE_DRIVER_PENDING';
export const CREATE_DRIVER_FULFILLED = 'CREATE_DRIVER_FULFILLED';
export const CREATE_DRIVER_REJECTED = 'CREATE_DRIVER_REJECTED';

export const UPDATE_DRIVER_PENDING = 'UPDATE_DRIVER_PENDING';
export const UPDATE_DRIVER_FULFILLED = 'UPDATE_DRIVER_FULFILLED';
export const UPDATE_DRIVER_REJECTED = 'UPDATE_DRIVER_REJECTED';

export const DELETE_DRIVER_PENDING = 'DELETE_DRIVER_PENDING';
export const DELETE_DRIVER_FULFILLED = 'DELETE_DRIVER_FULFILLED';
export const DELETE_DRIVER_REJECTED = 'DELETE_DRIVER_REJECTED';

export const CHANGE_DRIVER = 'CHANGE_DRIVER';
export const ADD_DRIVER = 'ADD_DRIVER';

export function changeDriver(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_DRIVER, payload: { name, value } })
  }
}

export function addDriver() {
  return dispatch => {
    dispatch({ type: ADD_DRIVER });
  };
}

export function findAllDrivers(matriz = 1, first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_DRIVERS_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                  query findAllDrivers {
                    motoristas (first: ${first} offset: ${offset}) {
                      codigo
                      matriz
                      matricula
                      nome
                      apelido
                      celular
                      validadeCnh
                      clienteMatriz {
                        razaoSocial
                      }
                    }
                  }
                  `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_DRIVERS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { motoristas } = data
        dispatch({ type: FETCH_DRIVERS_FULFILLED, payload: motoristas });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_DRIVERS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAllDriversByName(search = '', first = 1000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_DRIVERS_PENDING });

    if (search.trim().length <= 2) {
      dispatch(findAllDrivers());
      return;
    }

    api({
      method: 'post',        
      data: {
        query: `
                  query findAllDriversByName {
                    motoristaPorNome (search: "${search}" first: ${first} offset: ${offset}) {
                      codigo
                      matriz
                      matricula
                      nome
                      apelido
                      celular
                      validadeCnh
                      clienteMatriz {
                        razaoSocial
                      }
                    }
                  }
                  `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_DRIVERS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { motoristaPorNome } = data
        dispatch({ type: FETCH_DRIVERS_FULFILLED, payload: motoristaPorNome });
        resolve(motoristaPorNome);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_DRIVERS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findDriver(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_DRIVER_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                    query findDriver {
                      motorista (codigo: ${codigo}) {
                        codigo
                        matriz
                        nome
                        apelido
                        endereco
                        cep
                        bairro
                        complemento
                        estado
                        cidade
                        municipio {
                          nome
                        }
                        cpf
                        rg
                        matricula
                        celular
                        cnh
                        validadeCnh
                        clienteMatriz {
                          razaoSocial
                        }
                      }
                    }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_DRIVER_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { motorista } = data
        dispatch({ type: FETCH_DRIVER_FULFILLED, payload: motorista });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_DRIVER_REJECTED, error });
      resolve(error);
    });

  });

}

export function createDriver(driver) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_DRIVER_PENDING });

    api({
      method: 'post',
      data: {
        query: `
          mutation createDriver {
            createMotorista(input: {
              codigo: ${driver.codigo}
              matriz: ${driver.matriz}
              nome: "${driver.nome}"
              apelido: "${driver.apelido}"
              endereco: "${driver.endereco}"
              cep: "${driver.cep}"
              bairro: "${driver.bairro}"
              complemento: "${driver.complemento}"
              estado: ${parseInt(driver.estado)}
              cidade: ${parseInt(driver.cidade)}
              cpf: "${driver.cpf}"
              rg: "${driver.rg}"
              matricula: "${driver.matricula}"
              celular: "${driver.celular}"
              cnh: "${driver.cnh}"
              validadeCnh: "${driver.validadeCnh}"
            } ) {
              codigo
              matriz
              nome
              apelido
              endereco
              cep
              bairro
              complemento
              estado
              cidade
              municipio {
                nome
              }
              cpf
              rg
              matricula
              celular
              cnh
              validadeCnh
              clienteMatriz {
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_DRIVER_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { createMotorista } = data
        dispatch({ type: CREATE_DRIVER_FULFILLED, payload: createMotorista });
        dispatch({ type: SHOW_SAVED_TOAST_MESSAGE });
        resolve();
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_DRIVER_REJECTED, errors });
      reject(errors);
    });
  });
}

export function updateDriver(driver) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_DRIVER_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation UpdateMotorista {
            updateMotorista(input: {
              codigo: ${driver.codigo}
              matriz: ${driver.matriz}
              nome: "${driver.nome}"
              apelido: "${driver.apelido}"
              endereco: "${driver.endereco}"
              cep: "${driver.cep}"
              bairro: "${driver.bairro}"
              complemento: "${driver.complemento}"
              estado: ${parseInt(driver.estado)}
              cidade: ${parseInt(driver.cidade)}
              cpf: "${driver.cpf}"
              rg: "${driver.rg}"
              matricula: "${driver.matricula}"
              celular: "${driver.celular}"
              cnh: "${driver.cnh}"
              validadeCnh: "${driver.validadeCnh}"
            } ) {
              codigo
              matriz
              nome
              apelido
              endereco
              cep
              bairro
              complemento
              estado
              cidade
              municipio {
                nome
              }
              cpf
              rg 
              matricula
              celular
              cnh
              validadeCnh
              clienteMatriz {
                razaoSocial
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_DRIVER_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { updateMotorista } = data
        dispatch({ type: UPDATE_DRIVER_FULFILLED, payload: updateMotorista });
        dispatch({ type: SHOW_SAVED_TOAST_MESSAGE });
        resolve();
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_DRIVER_REJECTED, errors });
      reject(errors);
    });
  });
}

export function removeDriver(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_DRIVER_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation DeleteMotorista {
            deleteMotorista(codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_DRIVER_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { deleteMotorista } = data
        dispatch({ type: DELETE_DRIVER_FULFILLED, payload: deleteMotorista });
        dispatch({ type: SHOW_REMOVED_TOAST_MESSAGE });
        dispatch(findAllDrivers());
        resolve();
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_DRIVER_REJECTED, errors });
      reject(errors);
    });
  });
}
